import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getStaffUtilisation,
	indexProjectPhasesByProjectId,
} from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";

const ENTITY_QUERY_KEY = "projectPhase";

export const PROJECTS_PHASES_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];
export const PROJECTS_PHASE_STAFF_UTILISATION_QUERY_KEY = (projectId: string,
	phaseId: string) => [ENTITY_QUERY_KEY, "staff-utilisation", { projectId, phaseId }];

export const useProjectsProjectPhases = (projectId: string) => useQuery({
	queryKey: PROJECTS_PHASES_QUERY_KEY(projectId),
	queryFn: () => indexProjectPhasesByProjectId(projectId).then(getDataFromResponse),
	enabled: !!projectId,
});

export const usePhaseStaffUtilisationReport = (projectId: string, projectPhaseId: string) => useQuery({
	queryKey: PROJECTS_PHASE_STAFF_UTILISATION_QUERY_KEY(projectId, projectPhaseId),
	queryFn: () => getStaffUtilisation(projectId, projectPhaseId).then(getDataFromResponse),
	enabled: !!projectId && !!projectPhaseId,
});