import type { FunctionComponent } from "react";

import Card from "~/components/Card";
import HorizontalStats from "~/components/HorizontalStats";
import type { HorizontalStat } from "~/components/HorizontalStats/horizontalStatsTypes.ts";
import OverflowPercentageChart from "~/components/OverflowPercentageChart";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";


type Props = {
	manDaysPlanned: number;
	manDaysTracked: number;
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	showBudget?: boolean;
	totalBudgetCents: number;
	totalManDays: number;
};

const ProjectBudgetSection: FunctionComponent<Props> = ({
	manDaysPlanned,
	manDaysTracked,
	budgetCentsPlanned,
	budgetCentsTracked,
	showBudget = false,

}) => {
	const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
	const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
	const percentageTracked = (trackedValue / plannedValue) * 100;

	const plannedValueFormatted = showBudget ? formatCentsToCurrency(plannedValue) : plannedValue.toFixed(0) + " PT";
	const trackedValueFormatted = showBudget ? formatCentsToCurrency(trackedValue) : trackedValue > 0 && trackedValue < 1 ? "<" : "" + +trackedValue.toFixed(0) + " PT";
	const restFormatted = showBudget ? formatCentsToCurrency(plannedValue - trackedValue) : (plannedValue - trackedValue).toFixed(0) + " PT";
	const percentageTrackedFormatted = percentageTracked === Infinity ? "n/a" : percentageTracked.toFixed(0) + "%";

	const stats: HorizontalStat[] = [{
		label: "Budget",
		value: plannedValueFormatted,
	},
		{
			label: "Verfügt",
			value: trackedValueFormatted,
		},
		{
			label: "Rest",
			value: restFormatted,
			isHighlighted: plannedValue - trackedValue < 0,
		}];


	return <Card title="Budgetstatus">
		<HorizontalStats stats={stats}>
			<OverflowPercentageChart percentage={percentageTracked === Infinity ? 0 : percentageTracked}
									 size={80}
									 thickness={0.4}>
				<div className="text-sm	 font-bold">{percentageTrackedFormatted}</div>
			</OverflowPercentageChart>
		</HorizontalStats>
	</Card>;
};

export default ProjectBudgetSection;