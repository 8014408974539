import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button/Button.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import RadioGroup from "~/components/formElements/RadioGroup";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import BudgetFormSection from "~/components/formSections/BudgetFormSection";
import CommentFormSection from "~/components/formSections/CommentFormSection";
import PlaceOfPerformanceFormSection from "~/components/formSections/PlaceOfPerformanceFormSection";
import TimingFormSection from "~/components/formSections/TimingFormSection";
import TitleFormSection from "~/components/formSections/TitleFormSection";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { DEFAULT_COUNTRY_ID } from "~/constants/form.ts";
import { useAuth } from "~/contexts/AuthContext";
import useApprovalLikelihoodOptions from "~/hooks/form/formOptionsData/useApprovalLikelihoodOptions.ts";
import useClientsContactPersonsSelectOptions
	from "~/hooks/form/formOptionsData/useClientsContactPersonsSelectOptions.tsx";
import useClientSelectOptions from "~/hooks/form/formOptionsData/useClientSelectOptions.ts";
import useClientsProgramsSelectOptions from "~/hooks/form/formOptionsData/useClientsProgramSelectOptions.ts";
import useClientsQuotaSelectOptions from "~/hooks/form/formOptionsData/useClientsQuotaSelectOptions.ts";
import useCountrySelectOptions from "~/hooks/form/formOptionsData/useCountrySelectOptions.ts";
import useLocationOptions from "~/hooks/form/formOptionsData/useLocationOptions.ts";
import useUserSelectOptions, { allActiveUsersFilterFn } from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectTitleValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useProjectTitleValidationComparisonData.ts";
import { createProject } from "~/modules/project/api/project/projectApiDispatchers.ts";
import type { CreateProjectData } from "~/modules/project/api/project/projectTypes.ts";
import ClientAndContactPersonFormSection
	from "~/modules/project/components/forms/formSections/ClientAndContactPersonFormSection";
import ProgramAndQuotaFormSection from "~/modules/project/components/forms/formSections/ProgramAndQuotaFormSection";
import { WorkingStatusId } from "~/types/entityIds.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter, transformEmptyDateValueToNull } from "~/utils/form/formUtils.ts";

type CreateProjectFormProps = {
	onSuccess: (value?: any) => void;
	onCancel: () => void;
	clientId?: string;
	clientContactPersonId?: string;
	programId?: string;
	quotaId?: string;
	isInternal?: boolean;
};

interface CreateProjectFormData extends Omit<CreateProjectData, "endDate" | "startDate"> {
	endDate: Date;
	startDate: Date;
}

const userFilterOptions = { filterFunction: allActiveUsersFilterFn };

const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
	onSuccess,
	onCancel,
	clientContactPersonId,
	clientId,
	programId,
	quotaId,
	isInternal,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);
	const { user } = useAuth();
	const [currentClientId, setCurrentClientId] = useState<string | null>(clientId || null);
	const titleComparisonData = useProjectTitleValidationComparisonData(currentClientId);

	const schema = useMemo(() => {
		return yup.object({
			approvalLikelihoodId: yup.string().required(),
			budgetCentsPlanned: yup.number().required(),
			clientContactPersonId: yup.string().required(),
			clientId: yup.string().required(),
			comment: yup.string().default(null),
			countryId: yup.string().required(),
			endDate: yup
				.date()
				.transform(transformEmptyDateValueToNull)
				.nullable()
				.required()
				.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
			isInternal: yup.boolean().required(),
			locationName: yup.string().required(),
			managedBy: yup.string().required(),
			manDaysPlanned: yup.number().typeError(t("form:validationErrors.number.requiredTypeError")).required(),
			programId: yup.string().default(null),
			quotaId: yup.string().default(null),
			startDate: yup.date().transform(transformEmptyDateValueToNull).nullable().required(),
			title: yup.string().required().uniqueProjectTitle(titleComparisonData),
			workingStatusId: yup.string().required(),
		});
	}, [titleComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			approvalLikelihoodId: "1",
			budgetCentsPlanned: 0,
			clientContactPersonId: clientContactPersonId || "",
			clientId: clientId || "",
			comment: "",
			countryId: DEFAULT_COUNTRY_ID,
			endDate: "" as unknown as Date,
			isInternal: isInternal || false,
			locationName: "",
			managedBy: user?.id || "",
			manDaysPlanned: 0,
			programId: programId || "",
			quotaId: quotaId || "",
			startDate: "" as unknown as Date,
			title: "",
			workingStatusId: "1",
		};
		/*		return {
					approvalLikelihoodId: "1",
					budgetCentsPlanned: 1000,
					clientContactPersonId: "12",
					clientId: clientId,
					comment: "Bla bla",
					countryId: "2",
					endDate: new Date("2023-12-12"),
					hasFreelancers: false,
					isInternal: isInternal || false,
					locationName: "Stuttgart",
					managedBy: user?.id,
					manDays: 100,
					programId: programId || "",
					quotaId: quotaId || "",
					startDate: new Date("2023-07-01"),
					title: "Neues Projekt",
					workingStatusId: "1",
				};*/
	}, [user, clientId, clientContactPersonId, isInternal, programId, quotaId]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid, isSubmitted },
		watch,
		setValue,
	} = useForm<CreateProjectFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateProjectFormData>(schema),
	});
	const selectedClientId = watch("clientId");

	useEffect(() => {
		setCurrentClientId(selectedClientId);
	}, [selectedClientId]);

	const approvalLikelihoodOptions = useApprovalLikelihoodOptions();
	const countrySelectOptions = useCountrySelectOptions();
	const managerSelectOptions = useUserSelectOptions(userFilterOptions);
	const clientSelectOptions = useClientSelectOptions();
	const clientContactPersonSelectOptions = useClientsContactPersonsSelectOptions(selectedClientId);
	const locationOptions = useLocationOptions();
	const programSelectOptions = useClientsProgramsSelectOptions(selectedClientId);
	const quotaSelectOptions = useClientsQuotaSelectOptions(selectedClientId);
	const workingStatusOptions = useWorkingStatusOptions(true);

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	useEffect(() => {
		setValue("clientContactPersonId", "");
		setValue("programId", "");
		setValue("quotaId", "");
	}, [selectedClientId, setValue]);

	const onSubmit: SubmitHandler<CreateProjectFormData> = async (data: CreateProjectFormData) => {
		setBusy(true);
		if (data.workingStatusId !== WorkingStatusId.Quotation) {
			data.approvalLikelihoodId = null;
		}

		const projectData = {
			...data,
			startDate: formatDateToYYYYMMDD(data.startDate),
			endDate: formatDateToYYYYMMDD(data.endDate),
		};

		try {
			const result = await createProject(projectData);
			const newProjectData = getDataFromResponse(result);
			onSuccess(newProjectData);
		} catch (error) {
			console.log(error);
		}
	};

	const showApprovalLikelihoodInput = watch("workingStatusId") === "1";

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h2">Neues Projekt erstellen</Headline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}

				<FormSectionGroup>
					<ClientAndContactPersonFormSection
						control={control}
						errors={errors}
						clientSelectOptions={clientSelectOptions}
						clientContactPersonSelectOptions={clientContactPersonSelectOptions}
						clientInputDisabled={!!clientId}
						clientContactPersonInputDisabled={!selectedClientId || !!clientContactPersonId}
					/>

					<TitleFormSection control={control}
									  title="Projektname" />

					<ProgramAndQuotaFormSection
						control={control}
						errors={errors}
						programInputDisabled={!selectedClientId || !!programId || programSelectOptions.length === 0}
						programSelectOptions={programSelectOptions}
						quotaInputDisabled={!selectedClientId || !!quotaId || quotaSelectOptions.length === 0}
						quotaSelectOptions={quotaSelectOptions}
					/>
					<FormSection title="Status">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<RadioGroup control={control}
											name="workingStatusId"
											options={workingStatusOptions} />
							</div>
							{showApprovalLikelihoodInput && (
								<div className="col-span-6">
									<RadioGroup
										control={control}
										name="approvalLikelihoodId"
										options={approvalLikelihoodOptions}
										label="Bewertung"
									/>
								</div>
							)}
						</div>
					</FormSection>
					<TimingFormSection control={control}
									   watch={watch}
									   setValue={setValue}
									   errors={errors}
									   title="Laufzeit" />
					<PlaceOfPerformanceFormSection
						errors={errors}
						control={control}
						locationOptions={locationOptions}
						countrySelectOptions={countrySelectOptions}
					/>
					<BudgetFormSection
						control={control}
						watch={watch}
						title="Kommerzielle Plandaten"
						errors={errors}
					/>
					<FormSection title="Verantwortlichkeit">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<Select
									name="managedBy"
									label={"Angebot Manager"}
									error={errors.managedBy?.message}
									optionsData={managerSelectOptions}
									control={control}
								/>
							</div>
						</div>
					</FormSection>
					<CommentFormSection
						control={control}
						placeholder={"Hier können allgemeine Anmerkungen zum Projekt hinterlegt werden."}
						label="Kommentar"
						title="Anmerkungen"
					/>
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default CreateProjectForm;
