import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexHolidays, indexHolidaysByYear } from "~/modules/absence/api/holiday/holidayApiDispatchers.ts";

export const HOLIDAYS_DEFAULT_QUERY_KEY = "holidays";

export const HOLIDAYS_BY_YEAR_QUERY_KEY = (year: string) => [HOLIDAYS_DEFAULT_QUERY_KEY, year];
export const ALL_HOLIDAYS_QUERY_KEY = [HOLIDAYS_DEFAULT_QUERY_KEY, "all"];
export const useHolidaysByYear = (year: string) => useQuery({
	queryKey: HOLIDAYS_BY_YEAR_QUERY_KEY(year),
	queryFn: () => indexHolidaysByYear({ year }).then(getDataFromResponse),
	enabled: !!year,
});

export const useAllHolidays = () => useQuery({
	queryKey: ALL_HOLIDAYS_QUERY_KEY,
	queryFn: () => indexHolidays().then(getDataFromResponse),
});