import type { QueriesOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getStaffMembersUtilisationReport,
	getYearlyUtilisationReport,
} from "~/modules/reports/api/utilisationReport/utilisationReportApiDispatchers.ts";

export const UTILISATION_REPORT_BASE_QUERY_KEY = "utilisation-report";

export const YEARLY_UTILISATION_REPORT_QUERY_KEY = (year: string) => [UTILISATION_REPORT_BASE_QUERY_KEY, year];
export const STAFF_MEMBERS_YEARLY_UTILISATION_REPORT_QUERY_KEY = (staffMemberId: string,
	year: number) => [UTILISATION_REPORT_BASE_QUERY_KEY, staffMemberId, year];
export const useYearlyUtilisationReport = (year:string) => useQuery({
    queryKey: YEARLY_UTILISATION_REPORT_QUERY_KEY(year),
    queryFn: () => getYearlyUtilisationReport(year).then(getDataFromResponse),
	enabled: !!year,
});

export const useStaffMembersYearlyUtilisationReport = ({ staffMemberId, year, options }: {
	staffMemberId: string,
	year: number,
	options?: QueriesOptions<any>
}) => useQuery({
    queryKey: STAFF_MEMBERS_YEARLY_UTILISATION_REPORT_QUERY_KEY(staffMemberId, year),
    queryFn: () => getStaffMembersUtilisationReport(year, staffMemberId).then(getDataFromResponse),
	...options,
});