import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexTaxClasses } from "~/modules/humanResources/api/taxClass/taxClassApiDispatchers.ts";

export const TAX_CLASSES_BASE_QUERY_KEY = "taxClasses";

export const ALL_TAX_CLASSES_QUERY_KEY = [TAX_CLASSES_BASE_QUERY_KEY, "all"];

export const useAllTaxClasses = () => useQuery({
    queryKey: ALL_TAX_CLASSES_QUERY_KEY,
    queryFn: () => indexTaxClasses().then(getDataFromResponse),
});