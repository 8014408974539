import { useMemo } from "react";

import { useAllProjectTags } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";

export function useProjectTagsByType(tagTypeId: ProjectTagTypeId) {
	const { data: allProjectTags } = useAllProjectTags();

	return useMemo(() => {
		if (allProjectTags) {
			return allProjectTags.filter(projectTag => projectTag.tagTypeId === tagTypeId);
		}
		return [];
	}, [allProjectTags, tagTypeId]);
}