import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import Avatar from "~/components/Avatar";
import type { UtilisationTableData } from "~/modules/project/types/projectViewTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

const getMonthColumnHeader = (month: string) => {
	return format(new Date(month), `MMM yy`, { locale: de });
};

const staffUtilisationColumnDefs = (monthsToDisplay: string[]) => {
	const columnHelper = createColumnHelper<UtilisationTableData>();

	const monthColumns = monthsToDisplay.map(month => {
		return columnHelper.accessor(`months.${month}.averageDays`, {
			header: getMonthColumnHeader(month),
			footer: (props) => {
				// Get all rows from the table
				const rows = props.table.getFilteredRowModel().rows;

				let averagesSum = 0;
				let numberOfNonEmptyValues = 0;
				rows.forEach(row => {
					const value = row.original.months[month]?.averageDays;
					const parsedValue = parseFloat(value)|| 0;
					averagesSum += parsedValue;
					if(parsedValue > 0) {
						numberOfNonEmptyValues++;
					}
				});

				// Calculate total days
				const totalDays = rows.reduce((sum, row) => {
					const value = row.original.months[month]?.totalDays;
					return sum + (parseFloat(value) || 0);
				}, 0);

				const avgDaysFormatted = averagesSum ? formatNumberWithComma(averagesSum/numberOfNonEmptyValues, 2) : "--";
				const totalDaysFormatted = totalDays ? formatNumberWithComma(totalDays, 2) : "--";

				if (avgDaysFormatted === "--" && totalDaysFormatted === "--") {
					return <span className="text-gray-400">--</span>;
				}

				return (
					<div className="flex flex-col font-medium">
						<span>{avgDaysFormatted}</span>
						<span className="text-xs text-gray-500">{totalDaysFormatted}</span>
					</div>
				);
			},
			cell: (props) => {
				const averageDays = props.getValue() ? formatNumberWithComma(parseFloat(props.getValue()), 2) : "--";
				const totalDays = props.row.original.months[month].totalDays;
				const totalDaysFormatted = totalDays ? formatNumberWithComma(parseFloat(totalDays), 2) : "--";

				if(averageDays === "--" && totalDaysFormatted === "--") {
					return <span className="text-gray-400">--</span>;
				}

				return <div className="flex flex-col">
					<span>{averageDays}</span>
					<span className="text-xs text-gray-500">{totalDaysFormatted}</span>
				</div>;
			},
			enableSorting: true,
		});
	});


	return [
		columnHelper.accessor("user.fullName", {
			header: "Name",
			id: "user",
			cell: props => {
				const user = props.row.original.user;
				return <div className="flex items-center gap-x-2">
					<Avatar image={user.avatarImage}
							firstName={user.firstName}
							lastName={user.lastName} />
					<div>{user.fullName}</div>
				</div>
			},
			enableSorting: true,
			meta: {
				widthPercentage: 25,
			},
			footer: () => <div className="flex flex-col items-center font-normal">
				<span>⌀ Tage pro Woche</span>
				<span className="text-xs text-gray-500">Tage gesamt Monat</span>
			</div>
		}),
		...monthColumns,
	];
};

export default staffUtilisationColumnDefs;