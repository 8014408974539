export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";

export type AuthTokens = {
    accessToken:string,
    refreshToken:string
}

export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getTokens= ():AuthTokens => {
    return {
        [ACCESS_TOKEN_KEY]: getAccessToken() || "",
        [REFRESH_TOKEN_KEY]: getRefreshToken() || ""
    };
}

export const deleteTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const setTokens = (tokenObject:AuthTokens) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, tokenObject[ACCESS_TOKEN_KEY]);
    localStorage.setItem(REFRESH_TOKEN_KEY, tokenObject[REFRESH_TOKEN_KEY]);
};
