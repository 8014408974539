import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeApiDispatchers.ts";

export const ABSENCE_TYPE_BASE_QUERY_KEY = "absenceTypes";

export const ALL_ABSENCE_TYPES_QUERY_KEY = [ABSENCE_TYPE_BASE_QUERY_KEY, 'all'];

export const useAllAbsenceTypes= () => useQuery({
    queryKey: ALL_ABSENCE_TYPES_QUERY_KEY,
    queryFn: () => indexAbsenceTypes().then(getDataFromResponse),

});