export enum GlobalReportsViewTabId {
	Utilisation = "utilisation",
	DailyRate = "dailyRate",
	Revenue = "revenue",
}

export enum GlobalReportsViewTabDisplayNames {
	utilisation = "Auslastung",
	dailyRate = "Tagessatz",
	revenue = "Umsatz",
}
