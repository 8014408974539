// namespace(s) for translation vars
// path for lang vars: /lang/{locale}_{namespace}.json
export enum Namespace {
	Default = "main",
}

// list of supported languages
export const supportedLanguages = ["de", "en"];

// the flavor currently only influcences the country flag in the language selector.
// so to make it what the user expects, we check which flavor he might prefer (and thus which flag he most associates with English)
const langs = typeof navigator === "undefined" ? [] : navigator.languages ?? [navigator.language];
const preferredFlavorEn =
	langs
		.find((locale) => /^en-/.test(locale))
		?.split("-")
		.pop() ?? "GB";

// define default flavors for ambiguous language definitions
export const defaultFlavours = new Map<(typeof supportedLanguages)[number], string>([
	["de", "DE"],
	["en", preferredFlavorEn],
]);
