import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Textarea from "~/components/formElements/Textarea";
import Headline from "~/components/Headline";
import Sidebar from "~/components/Sidebar";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import {
	updateStaffMemberCompensationReportNote,
} from "~/modules/humanResources/api/staffMemberCompensationReportNote/staffMemberCompensationReportNoteApiDispatchers.ts";
import {
	STAFF_MEMBERS_COMPENSATION_REPORT_NOTES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberCompensationReportNote/staffMemberCompensationReportNoteQueries.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdateCompensationReportNotesSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	notes: string;
	staffMemberId: string;
};

const UpdateCompensationReportNotesSidebar: FunctionComponent<UpdateCompensationReportNotesSidebarProps> = ({
	isOpen,
	onClose,
	notes,
	staffMemberId,
}) => {
	const queryClient = useQueryClient();
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const defaultValues = useMemo(() => {
		return {
			notes,
		};
	}, [notes]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<{
		notes: string
	}>
	({
		defaultValues: defaultValues,
	});

	const onSubmit = useCallback(async (data: {
		notes: string
	}) => {
		try {
			setIsBusy(true);
			await updateStaffMemberCompensationReportNote(staffMemberId, data.notes);
			queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_COMPENSATION_REPORT_NOTES_QUERY_KEY(staffMemberId) });
			onClose();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}

	}, [onClose, queryClient, staffMemberId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<form onSubmit={handleSubmit(onSubmit)}
				  onKeyDown={preventSubmitOnEnter}
				  className="flex min-h-full w-full flex-col justify-start"
			>
				<SidebarHeader>
					<Headline type="h4"
							  color="muted">
						Bemerkungen Gehaltsreport bearbeiten
					</Headline>
				</SidebarHeader>
				<SidebarContent>
					{isBusy && <SidebarBusyOverlay />}
					{!!serverErrorMessage &&
						<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
					<Textarea control={control}
							  name="notes"
							  rows={10}
					/>
				</SidebarContent>
				<SidebarFooter>
					<FormHasErrorsHint show={isSubmitted && !isValid}
									   className="mr-2" />
					<SubmitButton busy={isBusy}
								  disabled={!isDirty}>
						Speichern
					</SubmitButton>
					<Button theme="white"
							onClick={onClose}>
						Abbrechen
					</Button>
				</SidebarFooter>
			</form>
		</Sidebar>
	);
};

export default UpdateCompensationReportNotesSidebar;
