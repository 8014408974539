import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { All_LOCATIONS_QUERY_KEY, USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { USERS_ACTIVE_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import UpdateOrderForm from "~/modules/project/components/forms/UpdateOrderForm";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { TIME_TRACKING_BASE_QUERY_KEY } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";

type UpdateOrderSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
	projectId: string;
	orderData: Order;
};

const UpdateOrderSidebar: React.FunctionComponent<UpdateOrderSidebarProps> = ({
	isOpen,
	setOpen,
	clientId,
	projectId,
	orderData,
}) => {
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: PROJECTS_PHASES_QUERY_KEY(projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(projectId) });
		await queryClient.invalidateQueries({ queryKey: PROJECT_QUERY_KEY(projectId) });
		queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user!.id) });
		queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(user!.id) });
		queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(user!.id) });
		queryClient.invalidateQueries({ queryKey: [TIME_TRACKING_BASE_QUERY_KEY] });
		await queryClient.invalidateQueries({ queryKey: All_LOCATIONS_QUERY_KEY });
		setOpen(false);
	}, [projectId, queryClient, setOpen, user]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<UpdateOrderForm
				clientId={clientId}
				orderData={orderData}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)}
			/>
		</Sidebar>
	);
};

export default UpdateOrderSidebar;
