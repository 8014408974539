import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import {
	STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY,
} from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import {
	ALL_STAFF_MEMBER_EMPLOYMENTS_QUERY_KEY,
	STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import CreateEmploymentForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/EmploymentsSection/components/CreateEmploymentSidebar/components/CreateEmploymentForm";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type CreateEmploymentSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string;
};

const CreateEmploymentSidebar: FunctionComponent<CreateEmploymentSidebarProps> = ({
	isOpen,
	onClose,
	staffMemberId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY(staffMemberId) });
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBER_QUERY_KEY(staffMemberId) });
		queryClient.invalidateQueries({ queryKey: ALL_STAFF_MEMBERS_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId) });
		queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId) });
		queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId) });
		queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId) });
		queryClient.invalidateQueries({ queryKey: ALL_STAFF_MEMBER_EMPLOYMENTS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient, staffMemberId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<CreateEmploymentForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberId={staffMemberId} />
		</Sidebar>
	);
};

export default CreateEmploymentSidebar;
