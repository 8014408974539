import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import UpdateCompanyLocationFrom
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/UpdateCompanyLocationSidebar/components/UpdateCompanyLocationFrom";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type UpdatePersonalDetailsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateCompanyLocationSidebar: React.FunctionComponent<UpdatePersonalDetailsSidebarProps> = ({
	isOpen,
	onClose,
	staffMemberCoreData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBER_QUERY_KEY(staffMemberCoreData.id) });
		queryClient.invalidateQueries({ queryKey: ALL_STAFF_MEMBERS_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		onClose();
	}, [onClose, queryClient, staffMemberCoreData.id]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<UpdateCompanyLocationFrom
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberCoreData={staffMemberCoreData} />
		</Sidebar>
	);
};

export default UpdateCompanyLocationSidebar;
