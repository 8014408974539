import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { CreateOrderData, Order, UpdateOrderData } from "~/modules/project/api/order/orderTypes.ts";


type CreateOrderParamsType = {
	projectId: string;
	orderData: CreateOrderData;
}

export function getOrder(orderId: string): AxiosApiResponsePromise<Order> {
	return axiosApi.get(`/orders/${orderId}`);
}

export function getAllOrders(): AxiosApiResponsePromise<Order[]> {
	return axiosApi.get("orders");
}

export function getAllOrdersByProjectId(projectId: string): AxiosApiResponsePromise<Order[]> {
	return axiosApi.get(`projects/${projectId}/orders`);
}

export function createOrder({
	projectId,
	orderData,
}: CreateOrderParamsType): AxiosApiResponsePromise<Order> {
	return axiosApi.post(`/projects/${projectId}/orders`, { ...orderData });
}

export function updateOrder({ projectId, orderId, orderData }: {
	projectId: string,
	orderId: string,
	orderData: UpdateOrderData
}): AxiosApiResponsePromise<Order> {
	return axiosApi.put(`/projects/${projectId}/orders/${orderId}`, { ...orderData });
}

export function deleteOrder({ projectId, orderId }: {
	projectId: string,
	orderId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`/projects/${projectId}/orders/${orderId}`);
}
