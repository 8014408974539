import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAvailableReportingYears } from "~/modules/reports/api/availableYears/availableYearsApiDispatchers.ts";

export const AVAILABLE_REPORTING_YEARS_QUERY_KEY = ["reports", "availableYears"];

export const useAvailableReportingYears = () =>
	useQuery({
		queryKey: AVAILABLE_REPORTING_YEARS_QUERY_KEY,
		queryFn: () => getAvailableReportingYears().then(getDataFromResponse),
	});