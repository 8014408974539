import clsx from "clsx";
import Button from "components/buttons/Button";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";

import type { ButtonVariantsProps } from "~/components/buttons/Button/buttonVariants.ts";

type Props = ButtonVariantsProps & {
	busy?: boolean;
	disabled?: boolean;
	formName?: string;

}

const SubmitButton: React.FC<React.PropsWithChildren<Props>> = ({ children, busy, disabled, formName, theme="secondary",...rest }) => (
	<Button
		type="submit"
		disabled={disabled}
		form={formName}
		{...rest}
		className={clsx("flex gap-1", { "opacity-50": disabled })}
		theme={theme}
	>
		{children}
		{busy && <LoadingSpinner scale={0.7} />}
	</Button>
);

export default SubmitButton;
