import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { ALL_CLIENTS_QUERY_KEY, CLIENT_QUERY_KEY } from "~/modules/client/api/client/clientQueries.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import UpdateClientForm from "~/modules/client/components/forms/UpdateClientForm";

type UpdateClientSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientData: Client;
};

const UpdateClientSidebar: React.FunctionComponent<UpdateClientSidebarProps> = ({ isOpen, setOpen, clientData }) => {
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(async () => {
		queryClient.invalidateQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
		await queryClient.invalidateQueries({ queryKey: CLIENT_QUERY_KEY(clientData.id) });
		setOpen(false);
	}, [queryClient, clientData.id, setOpen]);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<>
				<UpdateClientForm clientData={clientData}
								  onSuccess={handleSuccess}
								  onCancel={() => setOpen(false)} />
			</>
		</Sidebar>
	);
};

export default UpdateClientSidebar;
