import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllClientContactPersons,
	getClientContactPerson,
	getClientContactPersonsByClient,
} from "~/modules/client/api/clientContactPerson/clientContactPersonApiDispatchers.ts";

const ENTITY_QUERY_KEY = "clientContactPerson";

export const ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];

export const CLIENT_CONTACT_PERSON_QUERY_KEY = (clientContactPersonId: string) => [ENTITY_QUERY_KEY, clientContactPersonId];

export const useClientContactPerson = (clientContactPersonId: string) => useQuery({
    queryKey: CLIENT_CONTACT_PERSON_QUERY_KEY(clientContactPersonId),
    queryFn: () => getClientContactPerson(clientContactPersonId).then(getDataFromResponse),
	enabled: !!clientContactPersonId,
});

export const useAllClientContactPersons = (enabled: boolean = true) => useQuery({
    queryKey: ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY,
    queryFn: () => getAllClientContactPersons().then(getDataFromResponse),
	enabled
});

export const useClientsContactPersons = (clientId: string) => useQuery({
    queryKey: CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY(clientId),
    queryFn: () => getClientContactPersonsByClient(clientId).then(getDataFromResponse),
	enabled: !!clientId,
});