import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import Button from "components/buttons/Button";
import { t } from "i18next";
import type { MouseEvent } from "react";
import { useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormInput from "~/components/formElements/FormInput";
import Input from "~/components/formElements/Input";
import Label from "~/components/formElements/Label";
import SubmitButton from "~/components/formElements/SubmitButton";
import Switch from "~/components/formElements/Switch";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { syncUserLocations } from "~/modules/location/api/location/locationApiDispatchers.ts";
import { USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import type { UserAvailableLocationType } from "~/modules/location/api/location/locationTypes.ts";
import { syncUserDeliverables } from "~/modules/timeTracking/api/deliverable/deliverableApiDispatchers.ts";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import type { UserAvailableDeliverableType } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";
import TimeTrackingFormTag from "~/modules/timeTracking/components/components/TimeTrackingFormTag";
import { TimeTrackingFormNamesEnum } from "~/modules/timeTracking/types/timeTrackingTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";


type Props = {
	assetType: "location" | "deliverable"
	userId: string,
	projectId: string | null | undefined,
	projectTitle: string | null | undefined,
	setCurrentFormName: (formId: TimeTrackingFormNamesEnum) => void;
	timeTrackingTypeId: string,
	existingAssets: UserAvailableLocationType[] | UserAvailableDeliverableType[]
};

type FormAsset = {
	displayName: string;
	isFavorite: boolean;
	isNew: boolean;
	toBeDeleted: boolean;
}

type SyncUserAssetsFormData = {
	newAssetDisplayName: string | null;
	newAssetIsFavorite: boolean;
	favoriteAssets: FormAsset[];
	assets: FormAsset[];
};
const SyncUserAssetsForm: React.FunctionComponent<Props> = ({
	assetType,
	existingAssets,
	userId,
	projectId,
	projectTitle,
	setCurrentFormName,
	timeTrackingTypeId,
}) => {


	const queryClient = useQueryClient();
	const [busy, setBusy] = useState(false);
	const schema = useMemo(() => {
		return yup.object().shape({
			newAssetDisplayName: yup.string().nullable().default(""),
			newAssetIsFavorite: yup.boolean().required(),
			assets: yup.array().of(yup.object({
				displayName: yup.string().required(),
				isFavorite: yup.boolean().required(),
				isNew: yup.boolean().required(),
				toBeDeleted: yup.boolean().required(),
			})).required(),
			favoriteAssets: yup.array().of(yup.object({
				displayName: yup.string().required(),
				isFavorite: yup.boolean().required(),
				isNew: yup.boolean().required(),
				toBeDeleted: yup.boolean().required(),
			})).required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			newAssetDisplayName: "",
			newAssetIsFavorite: false,
			assets: existingAssets.filter(asset => !asset.isFavorite).map((asset) => ({
				displayName: asset.displayName,
				isFavorite: asset.isFavorite,
				isNew: false,
				toBeDeleted: false,
			})),
			favoriteAssets: existingAssets.filter(asset => asset.isFavorite).map((asset) => ({
				displayName: asset.displayName,
				isFavorite: asset.isFavorite,
				isNew: false,
				toBeDeleted: false,
			})),
		};
	}, [existingAssets]);

	const {
		control,
		handleSubmit,
		formState: { isValid, isSubmitted },
		watch,
		setValue,
	} = useForm<SyncUserAssetsFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<SyncUserAssetsFormData>(schema),
	});
	let titleDeliverableFor = "";
	switch (timeTrackingTypeId) {
		case("1"):
			titleDeliverableFor = `Projekt "${projectTitle}"`;
			break;
		case("2"):
			titleDeliverableFor = "Erfassungen vom Typ \"Intern\"\"";
			break;
		case("3"):
			titleDeliverableFor = "Erfassungen vom Typ \"Sales\"\"";
			break;
	}

	const assetTypeDisplayName = assetType === "location" ? "Ort" : "Deliverable";
	const assetTypeDisplayNamePlural = assetType === "location" ? "Orte" : "Deliverables";
	const newAssetDisplayNamePlaceholder = assetType === "location" ? "Hamburg" : "Präsentation";

	const onSubmit = async (formData: SyncUserAssetsFormData) => {
		setBusy(true);
		try {


			const assetsToSync = [...formData.favoriteAssets, ...formData.assets].filter(asset => !asset.toBeDeleted).map(asset => ({
				displayName: asset.displayName,
				isFavorite: asset.isFavorite,
			}));

			if (assetType === "location") {
				const updateData = {
					projectId,
					timeTrackingTypeId,
					locations: assetsToSync,
				};
				await syncUserLocations({ userId, locationData: updateData });
				await queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(userId) });
			} else {
				const updateData = {
					projectId,
					timeTrackingTypeId,
					deliverables: assetsToSync,
				};
				await syncUserDeliverables({ userId, deliverableData: updateData });
				await queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(userId) });
			}

			setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM);
		} catch (error) {
			console.log(error);
		}
	};

	const { append: appendAsset, remove: removeAsset } = useFieldArray({
		control: control,
		name: "assets",
	});

	const { append: appendFavoriteAsset, remove: removeFavoriteAsset } = useFieldArray({
		control: control,
		name: "favoriteAssets",
	});

	const currentAssetValues = watch("assets");
	const currentFavoriteAssetValues = watch("favoriteAssets");
	const newAssetIsFavorite = watch("newAssetIsFavorite");
	const newAssetDisplayName = watch("newAssetDisplayName");

	const handleAssetClick = (event: MouseEvent, index: number, assetData: FormAsset) => {
		event.preventDefault();

		if (assetData.isFavorite) {
			removeFavoriteAsset(index);
		} else {
			removeAsset(index);
		}
	};

	const handleAddAssetClick = (event: MouseEvent) => {
		event.preventDefault();
		if (newAssetDisplayName === null || newAssetDisplayName === "") {
			return;
		}
		const assetData = {
			displayName: newAssetDisplayName,
			isFavorite: newAssetIsFavorite,
			isNew: true,
			toBeDeleted: false,
		};
		if (newAssetIsFavorite) {
			appendFavoriteAsset(assetData);
		} else {
			appendAsset(assetData);
		}

		setValue("newAssetDisplayName", "");
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>{assetTypeDisplayNamePlural} für {titleDeliverableFor} bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<div className="grow overflow-auto p-0">
				<div className="border-b border-gray-200 bg-white p-7">
					<div className="grid grid-cols-6 gap-x-6 gap-y-2">
						<div className="col-span-6">
							<Input
								name="newAssetDisplayName"
								autoFocus={true}
								label={`Neuer ${assetTypeDisplayName}`}
								placeholder={newAssetDisplayNamePlaceholder}
								control={control}
							/>
						</div>
						<div className="col-span-6 mt-1 flex justify-between">
							<Switch name={"newAssetIsFavorite"}
									control={control}
									label={"Favorit"} />
							<Button type="button"
									onClick={(event: MouseEvent) => handleAddAssetClick(event)}
									theme="accent"
									size="sm"
									disabled={!newAssetDisplayName}>
								<PlusIcon className="size-4" /> {assetTypeDisplayName} hinzufügen
							</Button>
						</div>
					</div>
				</div>
				<div className="px-7 pb-7">
					<FormInput className="mt-4">
						<Label>
							Favoriten
						</Label>
						<div className="flex flex-wrap gap-2 rounded-lg border p-2">
							{currentFavoriteAssetValues.length === 0 &&
								<div className="text-sm text-gray-400">Keine Favoriten vorhanden</div>}
							{currentFavoriteAssetValues.map((field, index) => {
								return (
									<TimeTrackingFormTag onClick={(event) => handleAssetClick(event, index, field)}
														 isNew={field.isNew}
														 toBeDeleted={field.toBeDeleted}
														 key={index}
														 showDeleteButton={true}
									>{field.displayName}</TimeTrackingFormTag>
								);
							})}
						</div>
					</FormInput>
					<FormInput className="col-span-6 mt-4">
						<Label>
							Weitere {assetTypeDisplayNamePlural}
						</Label>
						<div className="flex flex-wrap gap-2 rounded-lg border p-2">
							{currentAssetValues.length === 0 && <div className="text-sm text-gray-400">Keine
								weiteren {assetTypeDisplayNamePlural} vorhanden</div>}
							{currentAssetValues.map((field, index) => {
								return (
									<TimeTrackingFormTag onClick={(event) => handleAssetClick(event, index, field)}
														 isNew={field.isNew}
														 toBeDeleted={field.toBeDeleted}
														 showDeleteButton={true}
														 key={index}>{field.displayName}</TimeTrackingFormTag>
								);
							})}
						</div>
					</FormInput>
				</div>
			</div>
			<SidebarFooter>
				<Button className="mr-auto"
						theme="accent"
						onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					<ChevronLeftIcon className="mx-2 size-6" />
				</Button>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!isValid || busy}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={() => setCurrentFormName(TimeTrackingFormNamesEnum.TIME_TRACKING_FORM)}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default SyncUserAssetsForm;
