import { useQueryClient } from '@tanstack/react-query';
import type React from "react";
import { useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteOrder } from "~/modules/project/api/order/orderApiDispatchers.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";

type DeleteOrderModalProps = {
	isOpen: boolean;
	orderId: string;
	orderTitle: string;
	projectId: string;
	onCloseClick: () => void;
};

const DeleteOrderModal: React.FunctionComponent<DeleteOrderModalProps> = ({
	isOpen,
	orderId,
	orderTitle,
	projectId,
	onCloseClick,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteOrder({ orderId, projectId });
			await queryClient.invalidateQueries({queryKey:PROJECTS_ORDERS_QUERY_KEY(projectId)});
			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.orderId.find(
						(validationError) => validationError.rule === "orderIsDeletableRule",
					)
				) {
					setError("Die Bestellung kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Bestellung "${orderTitle}" löschen`,
		bodyText: `Möchtest Du diese Bestellung wirklich löschen?`,
		deleteButtonText: `Bestellung löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteOrderModal;
