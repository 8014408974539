import { useEffect, useState } from "react";
import type { Control, FieldErrors, Path, UseFormSetValue, UseFormWatch } from "react-hook-form";

import DatePickerInput from "~/components/formElements/DatePickerInput";
import type { FormSectionProps } from "~/components/formElements/FormSection";
import FormSection from "~/components/formElements/FormSection";

type TimingFormSectionFormDataType = {
	startDate: Date | null;
	endDate: Date | null;
};

type TimingFormSectionProps<T extends TimingFormSectionFormDataType> = FormSectionProps & {
	control: Control<T>;
	errors: FieldErrors<T>;
	setValue: UseFormSetValue<T>;
	watch: UseFormWatch<T>;
};

const TimingFormSection = <T extends TimingFormSectionFormDataType>({
	control,
	errors,
	title,
	setValue,
	watch,
}: TimingFormSectionProps<T>) => {
	const startDate = watch("startDate" as Path<T>);
	const endDate = watch("endDate" as Path<T>);
	const [valueWasAutoUpdated, setValueWasAutoUpdated] = useState<boolean>(false);

	useEffect(() => {
		if (!valueWasAutoUpdated && startDate && !endDate) {
			setValue("endDate" as Path<T>, startDate);
			setValueWasAutoUpdated(true);
		}
	}, [startDate, endDate, setValue, valueWasAutoUpdated]);

	useEffect(() => {
		if (! valueWasAutoUpdated && endDate && !startDate) {
			setValue("startDate" as Path<T>, endDate);
			setValueWasAutoUpdated(true);
		}
	}, [startDate, endDate, setValue, valueWasAutoUpdated]);

	return (
		<FormSection title={title}>
			<div className="grid grid-cols-6 gap-x-6 gap-y-2">
				<div className="col-span-3">
					<DatePickerInput
						name="startDate"
						label="Startdatum"
						control={control}
						error={errors.startDate?.message as string}
					/>
				</div>
				<div className="col-span-3">
					<DatePickerInput
						name="endDate"
						position="bottom-end"
						label="Enddatum"
						control={control}
						error={errors.endDate?.message as string}
					/>
				</div>
			</div>
		</FormSection>
	);
};

export default TimingFormSection;