import AbsencesView from "modules/absence/components/AbsencesView";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedData } from "~/hooks/useAggregatedData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useStaffMembersAbsences } from "~/modules/absence/api/absence/absenceQueries.ts";
import { useAllAbsenceTypes } from "~/modules/absence/api/absenceType/absenceTypeQueries.ts";
import { useAllHolidays } from "~/modules/absence/api/holiday/holidayQueries.ts";
import {
	useStaffMembersVacationLedgerReport,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { useStaffMembersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

const AbsencesPage: React.FunctionComponent = () => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences, "staffMember");

	if (viewGuard) return viewGuard;
	const { staffMemberId } = useParams();
	const { data, options } = useAggregatedData([

		{
			hook: () => useStaffMembersAbsences(staffMemberId || ""),
			name: "absences",
		},
		{
			hook: () => useStaffMembersVacationLedgerReport(staffMemberId || ""),
			name: "vacationLedgerReport",
		},
		{
			hook: () => useStaffMembersWorkingSchedules(staffMemberId || ""),
			name: "workingSchedules",
		},
		{ hook: () => useAllHolidays(), name: "holidays" },
		{ hook: () => useAllAbsenceTypes(), name: "absenceTypes" },
	]);
	const {
		absences,
		workingSchedules,
		absenceTypes,
		vacationLedgerReport,
	} = useMemo(() => data, [data]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	if (!staffMemberId) return null;

	return <AbsencesView
		absences={absences}
		absenceTypes={absenceTypes}
		staffMemberId={staffMemberId}
		workingSchedules={workingSchedules}
		vacationLedgerReport={vacationLedgerReport}
	/>;
};

export default AbsencesPage;