import { create } from "zustand";

type State = {
	updateAvailable: boolean;
	setUpdateAvailable: (updateAvailable: boolean) => void;

};

export const useUpdateCheckStore = create<State>((set) => ({
	updateAvailable: false,
	setUpdateAvailable: (updateAvailable: boolean) => set({ updateAvailable }),
}));