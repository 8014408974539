import { useMemo } from "react";

import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData, getInputDefaultOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	projectId: string;
	includeCtaOption?: boolean;
	valueProperty?: "id" | "displayName";
}

const useProjectsProjectRolesOptions = ({
	projectId,
	includeCtaOption,
	valueProperty = "id",
}: Props): FormInputOption[] => {
	const { data: projectRolesData } = useProjectProjectRoles(projectId);
	return useMemo(() => {
		if (projectRolesData) {
			const options = projectRolesData.sort(byObjectProperty("displayName")).map((projectRole) => {
				return createInputOptionData(projectRole.displayName, projectRole[valueProperty]);
			});

			if (includeCtaOption) {
				return [getInputDefaultOptionsData(), ...options];
			}
			return options;
		}
		return [];
	}, [projectRolesData, includeCtaOption, valueProperty]);
};

export default useProjectsProjectRolesOptions;

