import { useQueryClient } from "@tanstack/react-query";
import CreateVariableCompensationPercentageEntryForm
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/CreateVariableCompensationPercentageEntrySidebar/components/CreateVariableCompensationPercentageEntryForm";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageQueries.ts";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string;
};

const CreateVariableCompensationPercentageEntrySidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	staffMemberId,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_VARIABLE_COMPENSATION_PERCENTAGES_QUERY_KEY(staffMemberId) });
		onClose();
	}, [onClose, queryClient, staffMemberId]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			<CreateVariableCompensationPercentageEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberId={staffMemberId} />
		</Sidebar>
	);
};

export default CreateVariableCompensationPercentageEntrySidebar;
