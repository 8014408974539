export function reloadApp(url?: string) {
	if ("caches" in window) {
		caches.keys().then((names) => {
			names.forEach(name => {
				caches.delete(name);
			});
		});
	}
	const newUrl = url || window.location.href.split("?")[0];
	window.location.href = newUrl + "?reload=" + Date.now();
}