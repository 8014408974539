export function formatCentsToCurrency(cents: number, digits:number = 2): string {
	return (cents / 100).toLocaleString("de-DE", {
		style: "currency",
		currency: "EUR",
		maximumFractionDigits: digits
	});
}

export function formatNumberToCurrency(num: number) {
	num = num / 100;
	return num.toFixed(2).replace(".", ",").replace(/\d(?=(\d{3})+,)/g, "$&.");
}