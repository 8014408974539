import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getStaffing,
	indexStaffingByProjectId,
	indexUsersActiveStaffings,
} from "~/modules/project/api/staffing/staffingApiDispatchers.ts";

const ENTITY_QUERY_KEY = "staffing";

export const PROJECTS_STAFFINGS_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];
export const STAFFING_QUERY_KEY = (staffingId: string) => [ENTITY_QUERY_KEY, { staffingId }];

export const USERS_ACTIVE_STAFFINGS_QUERY_KEY = (userId: string) => [ENTITY_QUERY_KEY, { userId, "active": true }];
export const useStaffing = ({ projectId, staffingId }: {
	projectId: string,
	staffingId: string,
}) => useQuery({
	queryKey: STAFFING_QUERY_KEY(staffingId),
	queryFn: () => getStaffing({ projectId, staffingId }).then(getDataFromResponse),
});

export const useProjectsStaffings = (projectId:string) => useQuery({
	queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId),
	queryFn: () => indexStaffingByProjectId({ projectId }).then(getDataFromResponse),
	enabled: !!projectId,
});

export const useUsersActiveStaffings = (userId:string) => useQuery({
	queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(userId),
	queryFn: () => indexUsersActiveStaffings({ userId }).then(getDataFromResponse),
	enabled: !!userId,
});