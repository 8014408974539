import { PlusIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import type { FunctionComponent } from "react";

type NoReferencesExistMessageProps = {
	onCreateReferenceClick: () => void;
};

const NoReferencesExistMessage: FunctionComponent<NoReferencesExistMessageProps> = ({ onCreateReferenceClick }) => {
	return <div className="col-span-2 flex size-full justify-center">
		<button onClick={onCreateReferenceClick}
				className="group flex flex-col items-center text-gray-500 transition duration-100 hover:text-gray-600">
			<div className="h-20" />
			<div className="relative"><DocumentTextIcon className="size-48 shrink-0" />
				<div className="absolute right-2 top-24 flex size-16 items-center justify-center rounded-full
				bg-primary-300 opacity-0 duration-300 group-hover:opacity-100">
					<PlusIcon className="size-32 text-white " /></div>
			</div>
			<div className="mt-4">Für dieses Projekt existieren noch keine Referenzen.</div>
			<div className="mt-4 font-semibold">Klicke hier um eine Referenz zu erstellen.</div>
		</button>
	</div>;
};

export default NoReferencesExistMessage;