import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import Headline from "~/components/Headline";
import Section from "~/components/sections/Section";
import type { CareerLevel } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import HrMainPageTabs from "~/modules/humanResources/components/HrMainPageTabs";
import TeamCategory from "~/modules/humanResources/components/StaffTeamOverviewView/components/TeamCategory";
import TeamFteTotalsSection
	from "~/modules/humanResources/components/StaffTeamOverviewView/components/TeamFteTotalsSection";
import type { TeamFteTotals } from "~/modules/humanResources/types/teamOverviewTypes.ts";
import { getStaffMembersSplitByEmploymentStatus } from "~/modules/humanResources/utils/staffMemberOverviewUtils.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
	careerLevels: CareerLevel[];
	isLoading: boolean;
	loadingPercent: number;
	staffMembers: StaffMember[];
};


const StaffTeamOverviewView: FunctionComponent<Props> = ({ careerLevels, isLoading, loadingPercent, staffMembers }) => {

	const fteTotals: TeamFteTotals = useMemo(() => {
		let totalHeadCount = 0;
		let totalFte = 0;
		let totalHeadCountEmployees = 0;
		let totalFteEmployees = 0;
		let totalHeadCountStudents = 0;
		let totalFteStudents = 0;
		let totalHeadCountInterns = 0;
		let totalFteInterns = 0;

		staffMembers
			.filter((staffMember) => !!staffMember.currentEmploymentTypeId && ![EmploymentTypeId.Freelancer, EmploymentTypeId.Left].includes(staffMember.currentEmploymentTypeId as EmploymentTypeId))
			.forEach((staffMember) => {
				totalHeadCount++;
				totalFte += staffMember.currentFtePercentage ? parseInt(staffMember.currentFtePercentage) / 10000 : 0;

				if (staffMember.currentEmploymentTypeId === EmploymentTypeId.Employed) {
					totalHeadCountEmployees++;
					totalFteEmployees += staffMember.currentFtePercentage ? parseInt(staffMember.currentFtePercentage) / 10000 : 0;
				}

				if (staffMember.currentEmploymentTypeId === EmploymentTypeId.Student) {
					totalHeadCountStudents++;
					totalFteStudents += staffMember.currentFtePercentage ? parseInt(staffMember.currentFtePercentage) / 10000 : 0;
				}

				if (staffMember.currentEmploymentTypeId === EmploymentTypeId.Intern) {
					totalHeadCountInterns++;
					totalFteInterns += staffMember.currentFtePercentage ? parseInt(staffMember.currentFtePercentage) / 10000 : 0;
				}
			});
		return {
			totalHeadCount,
			totalFte,
			totalHeadCountEmployees,
			totalFteEmployees,
			totalHeadCountStudents,
			totalFteStudents,
			totalHeadCountInterns,
			totalFteInterns,
		};
	}, [staffMembers]);

	const teamColumns = useMemo(() => {
		const categoriesOrder = [
			CareerLevelId.ManagingDirector,
			CareerLevelId.SeniorManager,
			CareerLevelId.Manager,
			CareerLevelId.SeniorConsultant,
			CareerLevelId.Consultant,
			CareerLevelId.Analyst,
			CareerLevelId.IT,
			CareerLevelId.Student,
		];
		const staffMembersSorted = staffMembers.sort(byObjectProperty("fullName"));
		const elements = categoriesOrder.map((careerLevelId) => {
			const careerLevel = careerLevels.find((cl) => cl.id === careerLevelId);

			let careerLevelDisplayName = careerLevel?.displayName || "";
			let careerLevelIdsInCategory = [careerLevelId];

			switch (careerLevelId) {
				case CareerLevelId.ManagingDirector:
				case CareerLevelId.Partner:
					careerLevelDisplayName = "Partner";
					careerLevelIdsInCategory = [CareerLevelId.ManagingDirector, CareerLevelId.Partner];
					break;
				case CareerLevelId.IT:
				case CareerLevelId.Assistant:
					careerLevelDisplayName = "Office Support";
					careerLevelIdsInCategory = [CareerLevelId.IT, CareerLevelId.Assistant];
			}

			const staffMembersInCategory = staffMembersSorted.filter(staffMember => {
				if (careerLevelId === CareerLevelId.Student && staffMember.currentEmploymentTypeId === EmploymentTypeId.Intern) {
					return false;
				}
				return careerLevelIdsInCategory.includes(staffMember.currentCareerLevelId as CareerLevelId)
					&& ![EmploymentTypeId.Freelancer].includes(staffMember.currentEmploymentTypeId as EmploymentTypeId);
			});

			const { activeStaffMembersInCategory, leftStaffMembersInCategory, futureStaffMembersInCategory } = getStaffMembersSplitByEmploymentStatus(staffMembersInCategory);
			return <TeamCategory careerLevelDisplayName={careerLevelDisplayName}
								 activeStaffMembers={activeStaffMembersInCategory}
								 leftStaffMembers={leftStaffMembersInCategory}
								 futureStaffMembers={futureStaffMembersInCategory}
								 key={careerLevelId} />;
		});

		const interns = staffMembersSorted.filter((staffMember) => staffMember.currentEmploymentTypeId === EmploymentTypeId.Intern);
		if (interns.length > 0) {
			const { activeStaffMembersInCategory, leftStaffMembersInCategory, futureStaffMembersInCategory } = getStaffMembersSplitByEmploymentStatus(interns);
			elements.push(<TeamCategory careerLevelDisplayName={"Praktikanten"}
										activeStaffMembers={activeStaffMembersInCategory}
										leftStaffMembers={leftStaffMembersInCategory}
										futureStaffMembers={futureStaffMembersInCategory}
										key={"interns"} />);
		}
		return elements;
	}, [careerLevels, staffMembers]);

	return (
		<div className="grid h-screen grid-rows-[auto_auto_auto_1fr] overflow-y-auto">
			<BreadcrumbsSection pages={["HR", "Team"]}
								className="bg-white" />
			<Section className="z-50 bg-white pb-4 pt-2">
				<div className="flex w-full items-center justify-between gap-x-4">
					<Headline type="h2">Team {new Date().getFullYear()}</Headline>
				</div>
			</Section>
			<Section>
				<HrMainPageTabs selectedTabName="team-overview" />
			</Section>
			<div className={clsx("mx-auto size-full max-w-7xl overflow-hidden px-2 pb-10 pt-4 sm:px-6 lg:px-8", !isLoading && "grid grid-rows-[auto_1fr] gap-y-4")}>
				{isLoading && <LoadingPage pcent={loadingPercent} />}
				{!isLoading && <>
					<TeamFteTotalsSection fteTotals={fteTotals} />
					<div className="relative flex size-full flex-row overflow-hidden">
						<div className="relative mb-10 flex h-full flex-row gap-x-4 overflow-x-auto">
							{teamColumns}
						</div>
						<div className="absolute right-0 z-10 h-full min-w-5  bg-gradient-to-l from-gray-100 to-transparent">&nbsp;</div>
					</div>
				</>}
			</div>
		</div>
	);
};

export default StaffTeamOverviewView;