import { useMemo } from "react";

import { useAllProjectTags } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTag, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	tagTypeId: ProjectTagTypeId;
	excludedIds?: string[];
	valuePropertyName?: keyof ProjectTag;
}

export function useProjectTagInputOptions({ tagTypeId, valuePropertyName = "id", excludedIds = [] }: Props) {
	const { data: allProjectTags } = useAllProjectTags();

	return useMemo(() => {
		if (allProjectTags) {
			const filteredDeliverableTags = allProjectTags.filter((projectTag) => projectTag.tagTypeId === tagTypeId && !excludedIds.includes(projectTag.id));

			return mapDataToInputOptionsData(filteredDeliverableTags.sort(byObjectProperty("displayName")), valuePropertyName, "displayName");

		}
		return [];
	}, [allProjectTags, excludedIds, tagTypeId, valuePropertyName]);
}