import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_BENEFITS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitQueries.ts";
import type {
	StaffMemberBenefitWithDate,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitTypes.ts";
import UpdateBenefitEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection/components/UpdateBenefitEntrySidebar/components/UpdateBenefitEntryForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	benefitData: StaffMemberBenefitWithDate | null;
};

const UpdateBenefitEntrySidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	benefitData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!benefitData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_BENEFITS_QUERY_KEY(benefitData?.staffMemberId) });
		onClose();
	}, [benefitData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{benefitData && <UpdateBenefitEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				benefitData={benefitData} />}
		</Sidebar>
	);
};

export default UpdateBenefitEntrySidebar;
