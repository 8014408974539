import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils";
import { getAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeApiDispatchers.ts";

const ENTITY_QUERY_KEY = "timeTrackingType";

export const ALL_TIME_TRACKING_TYPES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllTimeTrackingTypes = (enabled: boolean = true) => useQuery({
	queryKey: ALL_TIME_TRACKING_TYPES_QUERY_KEY,
	queryFn: () => getAllTimeTrackingTypes().then(getDataFromResponse),
	enabled,
});