import type { QueriesOptions} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getInvoiceRecipient,
	indexInvoiceRecipientByClient,
} from "~/modules/billing/api/invoiceRecipient/invoiceRecipientApiDispatchers.ts";

const ENTITY_QUERY_KEY = "invoiceRecipient";
export const INVOICE_RECIPIENT_QUERY_KEY = (invoiceRecipientId: string) => [ENTITY_QUERY_KEY, {invoiceRecipientId}];
export const CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, {clientId}];

export const useInvoiceRecipient = (clientId: string, invoiceRecipientId: string, options?: QueriesOptions<any>) => useQuery({
    queryKey: INVOICE_RECIPIENT_QUERY_KEY(invoiceRecipientId),
    queryFn: () => getInvoiceRecipient(clientId, invoiceRecipientId).then(getDataFromResponse),
	...options,
});

export const useClientsInvoiceRecipients = (clientId: string) => useQuery({
    queryKey: CLIENTS_INVOICE_RECIPIENTS_QUERY_KEY(clientId),
    queryFn: () => indexInvoiceRecipientByClient(clientId).then(getDataFromResponse),
	enabled: !!clientId,
});