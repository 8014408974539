import { yupResolver } from "@hookform/resolvers/yup";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import ProjectTag from "~/components/projectTags/ProjectTag";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateProjectsCuratedTags,
	updateProjectsUserTags,
} from "~/modules/project/api/project/projectApiDispatchers.ts";
import { useProjectTagInputOptions } from "~/modules/project/api/projectTag/hooks/useProjectTagInputOptions.ts";
import { useProjectTagsByType } from "~/modules/project/api/projectTag/hooks/useProjectTagsByType.ts";
import type { ProjectTagPivot, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import {
	getProjectTagTypeDisplayNameById,
	getProjectTagTypeThemeById,
	isCuratedTagType,
} from "~/modules/project/utils/projectTagTypeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateProductTagsFormData {
	projectTags: { originalId: string, displayName: string }[];
}

type UpdateProductTagsFormProps = {
	onSuccess: () => void;
	onClose: () => void;
	projectTagIds: ProjectTagPivot[];
	projectId: string;
	tagTypeId: ProjectTagTypeId;
};

const UpdateProjectTagsForm: FunctionComponent<UpdateProductTagsFormProps> = ({
	onSuccess,
	onClose,
	projectTagIds,
	projectId,
	tagTypeId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const allProductTags = useProjectTagsByType(tagTypeId);

	const tagTypeIsCurated = isCuratedTagType(tagTypeId);
	const valuePropertyName = tagTypeIsCurated ? "id" : "displayName";

	const productTagOptions = useProjectTagInputOptions({ tagTypeId, valuePropertyName });

	const schema = useMemo(() => {
		return yup.object({
			projectTags: yup.array().of(yup.object({
				originalId: yup.string().required(),
				displayName: yup.string().default(""),
			})).required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const productTagDisplayNames: UpdateProductTagsFormData["projectTags"] = [];
		if (allProductTags) {
			projectTagIds.forEach(productTagId => {
				const productTag = allProductTags.find(productTag => productTag.id === productTagId.tagId);
				if (productTag) {
					productTagDisplayNames.push({ originalId: productTag.id, displayName: productTag.displayName });
				}
			});
		}

		return { projectTags: productTagDisplayNames.sort() };
	}, [projectTagIds, allProductTags]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateProductTagsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateProductTagsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "projectTags",
	});

	const handleAddTag = useCallback((value: string | null) => {

		if (tagTypeIsCurated) {
			const existingTag = productTagOptions.find(productTagOption => productTagOption.value === value);

			if (existingTag && !fields.find(field => field.originalId === existingTag.value)) {
				append({ originalId: existingTag.value, displayName: existingTag.label });
			}
		} else if (value && !fields.find(field => field.displayName === value)) {
			append({ originalId: (new Date).toDateString(), displayName: value });
		}

	}, [productTagOptions, fields, append, tagTypeIsCurated]);

	const onSubmit = useCallback(async (data: UpdateProductTagsFormData) => {
		try {
			setIsBusy(true);
			const updateData = data.projectTags.map(tag => tagTypeIsCurated ? tag.originalId : tag.displayName);
			if (tagTypeIsCurated) {
				await updateProjectsCuratedTags({ projectId, tagIds: updateData, tagTypeId });
			} else {
				await updateProjectsUserTags({ projectId, tagDisplayNames: updateData, tagTypeId });
			}

			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten");
		}
	}, [onSuccess, projectId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">{`${getProjectTagTypeDisplayNameById(tagTypeId)} Tags bearbeiten`}</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<ComboBoxPlain value=""
							   autoFocus={true}
							   allowNew={!tagTypeIsCurated}
							   immediate={true}
							   placeholder="Tag hinzufügen"
							   optionsData={productTagOptions}
							   onChange={(value) => handleAddTag(value as string | null)}
				/>
				<div className="mt-4 flex flex-wrap gap-2 rounded-lg border p-2">
					{fields.length === 0 && <ProjectTag displayName="Keine Tags ausgewählt"
														theme="neutral" />}
					{fields.map((field, index) => {
						return <ProjectTag key={index}
										   theme={getProjectTagTypeThemeById(tagTypeId)}
										   displayName={field.displayName}
										   onDeleteClick={() => remove(index)} />;
					})}
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onClose}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateProjectTagsForm;
