import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getYearlyRevenueReport } from "~/modules/reports/api/revenueReport/revenueReportApiDispatchers.ts";

export const REVENUE_REPORT_BASE_BATH = "revenue-report";

export const YEARLY_REVENUE_REPORT_QUERY_KEY = (year: string) => [REVENUE_REPORT_BASE_BATH, year];

export const useYearlyRevenueReport = (year:string) => useQuery({
    queryKey: YEARLY_REVENUE_REPORT_QUERY_KEY(year),
    queryFn: () => getYearlyRevenueReport(year).then(getDataFromResponse),
	enabled: !!year,
});