import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getSickDaysReport } from "~/modules/humanResources/api/sickdaysReport/sickdaysReportApiDispatchers.ts";

export const SICK_DAYS_REPORT_QUERY_KEY = ['sick-days-report'];

export const useSickDaysReport = () => useQuery({
    queryKey: SICK_DAYS_REPORT_QUERY_KEY,
    queryFn: () => getSickDaysReport().then(getDataFromResponse)
});