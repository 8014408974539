import type { FunctionComponent } from "react";
import { useMemo } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import { useYearlyDailyRateReport } from "~/modules/reports/api/dailyRateReport/dailyRateReportQueries.ts";
import type {
	DailyRatesByCareerLevelBreakdownEnriched,
} from "~/modules/reports/api/dailyRateReport/dailyRateReportTypes.ts";
import DailyRateReportChartSection
	from "~/modules/reports/components/GlobalReportsView/components/DailyRateReportSection/components/DailyRateReportChartSection";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { sortArray } from "~/utils/sortFunctions.ts";

type Props = {
	year: string;
};
const DailyRateReportSection: FunctionComponent<Props> = ({ year }) => {
	const { data, options } = useAggregatedDataV2({
		careerLevels: useAllCareerLevels(),
		clients: useAllClients(),
		dailyRateReport: useYearlyDailyRateReport(year || ""),
		projects: useAllProjects(),
		users: useAllUsers(),
	});

	const {
		careerLevels,
		clients,
		dailyRateReport,
		projects,
		users,
	} = data;

	const { isLoading, loadingPercent } = options;

	const enrichedDailyRateBreakdown: DailyRatesByCareerLevelBreakdownEnriched[] = useMemo(() => {
		if (careerLevels && dailyRateReport && projects) {
			return dailyRateReport.dailyRateBreakdown.map((item) => {
				const careerLevelDisplayName = item.careerLevelId === CareerLevelId.ManagingDirector ? "Partner" : careerLevels.find(cl => cl.id === item.careerLevelId)?.displayName;
				return {
					...item,
					careerLevelDisplayName: careerLevelDisplayName || "Unbekannt",
					dailyRateBreakdown: item.dailyRateBreakdown.map(breakdown => {
						const projectTitles = sortArray(breakdown.projectIds.map(projectId => {
							const project = projects.find(project => project.id === projectId);
							return !project ? "Unbekannt" : project.title + " (" + project.projectNumber + ")";
						}));
						return {
							...breakdown,
							projectTitles,
						};
					}),
				};
			});
		}
		return [];
	}, [careerLevels, dailyRateReport, projects]);

	if (!year) return null;
	return <div className="grid size-full grid-rows-[auto_1fr] gap-y-8 overflow-y-scroll">
		{isLoading && <LoadingPage pcent={loadingPercent} />}
		{!isLoading && <><DailyRateReportChartSection
			averageDailyRateCents={dailyRateReport.averageDailyRateCents}
			careerLevels={careerLevels}
			careerLevelBreakdown={dailyRateReport.careerLevelBreakdown}
			clients={clients}
			clientBreakdown={dailyRateReport.clientBreakdown}
			dailyRateBreakdown={enrichedDailyRateBreakdown}
			users={users}
			year={year} />
		</>}
	</div>;
};

export default DailyRateReportSection;