import type { FunctionComponent, PropsWithChildren } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Avatar from "~/components/Avatar";
import Card from "~/components/Card";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { formatDateRange, formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	careerLevelDisplayName: string;
	activeStaffMembers: StaffMember[];
	leftStaffMembers: StaffMember[];
	futureStaffMembers: StaffMember[];
};

const TeamMember = ({ children, staffMember }: PropsWithChildren & {
	staffMember: StaffMember
}) => {
	return <NavLink to={generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, { staffMemberId: staffMember.id, tabId: StaffMemberDetailsTabId.CoreData })}
					key={staffMember.id}
					className="group flex items-center gap-x-3">
		<Avatar firstName={staffMember.firstName}
				lastName={staffMember.lastName}
				image={staffMember.avatarImage} />
		<div className="flex flex-col text-primary-900">
			<div className="font-medium leading-none group-hover:text-secondary-500">{staffMember.fullName}</div>
			<div className="mt-1 text-xs font-light group-hover:text-secondary-500">{children}</div>
		</div>
	</NavLink>;
};

const TeamCategory: FunctionComponent<Props> = ({ careerLevelDisplayName, activeStaffMembers, futureStaffMembers, leftStaffMembers }) => {
	const showNextStaffMembers = futureStaffMembers.length > 0;
	const showLeftStaffMembers = leftStaffMembers.length > 0;
	return <div className="h-full min-w-64 shrink-0 last:mr-4"><Card title={careerLevelDisplayName}>
		<div className="flex h-full flex-col gap-y-6">
			{activeStaffMembers.map((staffMember) => {
				return <TeamMember key={staffMember.id}
								   staffMember={staffMember}>
					{staffMember.currentFtePercentage ? formatNumberWithComma(parseInt(staffMember.currentFtePercentage) / 10000, 2, true) : "--"} FTE
				</TeamMember>;
			})}
			{showNextStaffMembers && <>
				<div className="-mb-2 mt-2 font-bold text-primary-900">Next 2024</div>
				{futureStaffMembers.map((staffMember) => {
					return <TeamMember key={staffMember.id}
									   staffMember={staffMember}>
						{staffMember.employmentStartDate ? formatDateWithGermanMonth(new Date(staffMember.employmentStartDate)) : ""}
					</TeamMember>;
				})}
			</>}
			{showLeftStaffMembers && <>
				<div className="-mb-2 mt-2 font-bold text-primary-900">Ausgeschieden 2024</div>
				{leftStaffMembers.map((staffMember) => {
					return <TeamMember key={staffMember.id}
									   staffMember={staffMember}>
						{staffMember.employmentStartDate && staffMember.employmentEndDate ? formatDateRange(new Date(staffMember.employmentStartDate), new Date(staffMember.employmentEndDate)) : ""}
					</TeamMember>;
				})}
			</>}
		</div>

	</Card>
		<div className="h-10" />
	</div>;
};

export default TeamCategory;