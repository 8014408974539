import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersTotalCompensations,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationApiDispatchers.ts";

export const STAFF_MEMBER_TOTAL_COMPENSATION_BASE_QUERY_KEY = "staffMembersSalaries";

export const STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_TOTAL_COMPENSATION_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersTotalCompensations = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY(staffMemberId),
    queryFn: () => indexStaffMembersTotalCompensations(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});