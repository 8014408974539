import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { FunctionComponent } from "react";
import { Chart } from "react-chartjs-2";

import type {
	DailyRateAndDaysTrackedWithProjectTitles,
} from "~/modules/reports/api/dailyRateReport/dailyRateReportTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
);

type Props = {
	data: DailyRateAndDaysTrackedWithProjectTitles[];
};

const DailyRateByClientHorizontalBarChart: FunctionComponent<Props> = ({ data }) => {
	const sortedData = data.sort((a, b) => parseFloat(b.dailyRateCents) - parseFloat(a.dailyRateCents));
	const labels = sortedData.map(item => {
		return formatCentsToCurrency(parseFloat(item.dailyRateCents));
	});

	const chartData = {
		labels,
		datasets: [
			{
				label: "Erfasste Tage",
				data: sortedData.map(item => parseFloat(item.minutesTracked)/480),
				backgroundColor: "rgba(67, 152, 238)",
				borderColor: "rgba(67, 152, 238)",
				borderWidth: 0,
			},
		],
	};

	const options = {
		indexAxis: "y" as const,
		responsive: true,
		maintainAspectRatio: false,
		animation: false,
		scales: {
			x: {
				beginAtZero: true,
				title: {
					display: false,
					text: "Average Daily Rate (€)",
				},
				ticks: {
					callback: (value: number) => `${formatNumberWithComma(value, 2)}`,
				},
			},
			y: {
				offset: true,
				ticks: {
					autoSkip: false,
					maxRotation: 0,
					minRotation: 0,
				},
			},
		},
		plugins: {
			legend: {
				display: true,
			},
			tooltip: {
				callbacks: {
					title: (tooltipItems: any) => {
						const index = tooltipItems[0].dataIndex;
						return sortedData[index].projectTitles.join("\n");
					},
					label: () => "",
				},
			},
			datalabels: {
				color: "black",
				font: {
					weight: "bold",
				},
				anchor: "end",
				align: "right",
				clamp: true,
				formatter: (value: number) => `${formatNumberWithComma(value, 2)}`,
			},
		},
	};


	return (
		<div style={{ height: `${Math.max(130, data.length * 40)}px`, width: "100%" }}>
			<Chart type="bar"
				   data={chartData}
				   options={options as any} />
		</div>
	);
};

export default DailyRateByClientHorizontalBarChart;