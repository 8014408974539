import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

interface TimeTrackingFormTagProps {
	onClick?: (any: any) => void;
	selected?: boolean;
	toBeDeleted?: boolean;
	isNew?: boolean;
	showDeleteButton?: boolean;
}

const TimeTrackingFormTag: FunctionComponent<PropsWithChildren<TimeTrackingFormTagProps>> = ({
	children,
	selected,
	onClick,
	toBeDeleted,
	isNew,
	showDeleteButton = false,
}) => (
	<button
		onClick={onClick}
		className={clsx(
			"inline-flex items-center p-2 ring-1 ring-offset-1",
			"rounded-md text-sm font-medium",
			"ring-gray-500/10",
			"transition-colors duration-200",
			"focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white",
			!selected && !toBeDeleted && !isNew ? "bg-gray-50 text-gray-600 " : "",
			selected || isNew ? "bg-accent-500 text-white hover:bg-accent-600 hover:text-white" : "",
			toBeDeleted ? "bg-red-500 text-white hover:bg-red-600 hover:text-white" : "",
			{
				"cursor-pointer hover:ring-gray-500/30 hover:text-gray-800": undefined !== onClick,
			},
		)}
	>
		{children}
		{showDeleteButton && <div className="ml-2 flex items-center justify-center border-l border-gray-200 pl-2">x</div>}
	</button>
);

export default TimeTrackingFormTag;
