import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_BONUSES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusQueries.ts";
import type { StaffMemberBonusWithDate } from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusTypes.ts";
import UpdateBonusEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection/components/UpdateBonusEntrySidebar/components/UpdateBonusEntryForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	bonusData: StaffMemberBonusWithDate | null;
};

const UpdateBonusEntrySidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	bonusData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!bonusData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_BONUSES_QUERY_KEY(bonusData?.staffMemberId) });
		onClose();
	}, [bonusData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{bonusData && <UpdateBonusEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				bonusData={bonusData} />}
		</Sidebar>
	);
};

export default UpdateBonusEntrySidebar;
