import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getReportByMonth } from "~/modules/humanResources/api/staffMonthlyReport/staffMonthlyReportApiDispatchers.ts";

export const MONTHLY_COMPENSATION_REPORTS_BASE_QUERY_KEY = "monthly-compensation-reports";
export const MONTHLY_REPORT_QUERY_KEY = (startOfMonth: string) => [MONTHLY_COMPENSATION_REPORTS_BASE_QUERY_KEY, startOfMonth];

export const useMonthlyReport = (startOfMonth:string, enabled=true ) => useQuery({
    queryKey: MONTHLY_REPORT_QUERY_KEY(startOfMonth),
    queryFn: () => getReportByMonth(startOfMonth).then(getDataFromResponse),
	enabled
});