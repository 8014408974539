import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import TableHeaderCell from "~/components/Table/components/TableHeaderCell";
import TableWrapper from "~/components/Table/components/TableWrapper";
import { useStaffMembersBenefits } from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitQueries.ts";
import type {
	StaffMemberBenefitWithDate,
} from "~/modules/humanResources/api/staffMemberBenefit/staffMemberBenefitTypes.ts";
import BenefitsTableRow
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection/components/BenefitsTableRow";
import CreateBenefitEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection/components/CreateBenefitEntrySidebar";
import DeleteBenefitEntryModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection/components/DeleteBenefitEntryModal";
import UpdateBenefitEntrySidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection/components/UpdateBenefitEntrySidebar";
import StaffMemberTimeline
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberTimeline";
import TableEmptyMessage from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableEmptyMessage";
import TableRowLoadingSpinner
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableRowLoadingSpinner";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { staffMemberId: string };

const BenefitsSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateBenefitSidebar, setShowCreateBenefitSidebar] = useState<boolean>(false);
	const [benefitsDataToEdit, setBenefitsDataToEdit] = useState<StaffMemberBenefitWithDate | null>(null);
	const [benefitsDataToDelete, setBenefitsDataToDelete] = useState<StaffMemberBenefitWithDate | null>(null);
	const {
		data: benefitsData,
		isLoading: benefitsIsLoading,
	} = useStaffMembersBenefits(staffMemberId);

	const tableRows = useMemo(() => {
		if (benefitsData !== undefined) {
			if (benefitsData.length === 0) {
				return [];
			}
			const benefitsWithDate = benefitsData.map(benefit => {
				return {
					...benefit,
					startDate: new Date(benefit.startDate),
					endDate: benefit.endDate ? new Date(benefit.endDate) : null,
				};
			});
			return benefitsWithDate.sort(byObjectProperty("startDate", "desc"))
				.map(benefit => {
					return <BenefitsTableRow
						key={benefit.id}
						amountCents={benefit.amountCents}
						description={benefit.description}
						endDate={benefit.endDate}
						onDeleteClick={() => {
							setBenefitsDataToDelete(benefit);
						}}
						onEditClick={() => {
							setBenefitsDataToEdit(benefit);
						}}
						startDate={benefit.startDate}
					/>;
				});
		}
		return [];
	}, [benefitsData]);

	return <><Card title="Benefits"
				   padding="none"
				   onHeaderButtonClick={() => setShowCreateBenefitSidebar(true)}
				   headerButtonText="Neuer Eintrag">
		<StaffMemberTimeline>
			<TableWrapper rounded={false}>
				<thead>
				<tr>
					<TableHeaderCell widthPercentage={20} rounded={false}>
						Startdatum
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={20} rounded={false}>
						Enddatum
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={20} rounded={false}>
						Monatl. Wert
					</TableHeaderCell>
					<TableHeaderCell widthPercentage={40} rounded={false}>
						Beschreibung
					</TableHeaderCell>
					<TableHeaderCell  />
				</tr>
				</thead>
				<tbody>
				{benefitsIsLoading && <TableRowLoadingSpinner />}
				{!benefitsIsLoading && tableRows.length === 0 ? <TableEmptyMessage /> : tableRows}
				</tbody>
			</TableWrapper>
		</StaffMemberTimeline>
	</Card>
		<CreateBenefitEntrySidebar
			isOpen={showCreateBenefitSidebar}
			onClose={() => {
				setShowCreateBenefitSidebar(false);
			}}
			staffMemberId={staffMemberId}
		/>
		<DeleteBenefitEntryModal benefitData={benefitsDataToDelete}
								 onCloseClick={() => setBenefitsDataToDelete(null)}
								 isOpen={!!benefitsDataToDelete} />
		<UpdateBenefitEntrySidebar isOpen={!!benefitsDataToEdit}
								   onClose={() => setBenefitsDataToEdit(null)}
								   benefitData={benefitsDataToEdit} />
	</>;
};

export default BenefitsSection;