import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelApiDispatchers.ts";

export const GET_ALL_CAREER_LEVELS_QUERY_KEY = ["careerLevels"];

export const useAllCareerLevels = (enabled: boolean = true) => useQuery({
	queryKey: GET_ALL_CAREER_LEVELS_QUERY_KEY,
	queryFn: () => getAllCareerLevels().then(getDataFromResponse),
	enabled,
});