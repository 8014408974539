import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	STAFF_MEMBER_QUERY_KEY,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import {
	STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelQueries.ts";
import type {
	StaffMemberCareerLevelWithDate,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelTypes.ts";
import UpdateCareerLevelForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection/components/UpdateCareerLevelSidebar/components/UpdateCareerLevelForm";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";

type CreateEmploymentSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	careerLevelData: StaffMemberCareerLevelWithDate | null;
};

const UpdateCareerLevelSidebar: FunctionComponent<CreateEmploymentSidebarProps> = ({
	isOpen,
	onClose,
	careerLevelData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!careerLevelData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY(careerLevelData.staffMemberId) });
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBER_QUERY_KEY(careerLevelData.staffMemberId) });
		queryClient.invalidateQueries({ queryKey: ALL_STAFF_MEMBERS_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
		onClose();
	}, [careerLevelData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{careerLevelData && <UpdateCareerLevelForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				careerLevelData={careerLevelData} />}
		</Sidebar>
	);
};

export default UpdateCareerLevelSidebar;
