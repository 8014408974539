import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getStaffMembersCompensationReportNotes,
} from "~/modules/humanResources/api/staffMemberCompensationReportNote/staffMemberCompensationReportNoteApiDispatchers.ts";

export const STAFF_MEMBER_COMPENSATION_REPORT_NOTE_BASE_QUERY_KEY = "staffMemberCompensationReportNote";

export const STAFF_MEMBERS_COMPENSATION_REPORT_NOTES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_COMPENSATION_REPORT_NOTE_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersCompensationReportNotes = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_COMPENSATION_REPORT_NOTES_QUERY_KEY(staffMemberId),
    queryFn: () => getStaffMembersCompensationReportNotes(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});
