import type { FunctionComponent, PropsWithChildren } from "react";

import Card from "~/components/Card";
import type { TeamFteTotals } from "~/modules/humanResources/types/teamOverviewTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	fteTotals: TeamFteTotals;
};

const FteTotalsSection: FunctionComponent<PropsWithChildren & {
	title: string
}> = ({ children, title }) => {
	return <Card><div className="flex flex-col px-4 py-2 font-bold text-primary-900">
		<div className="text-sm font-medium">{title}</div>
		<div className="whitespace-nowrap text-lg">
			{children}
		</div>
	</div></Card>;
};

const TeamFteTotalsSection: FunctionComponent<Props> = ({ fteTotals }) => {
	return <div className="grid w-full grid-cols-4 gap-x-4">
		<FteTotalsSection title="Summe">
			{formatNumberWithComma(fteTotals.totalFte, 2, true)} FTE und&nbsp;
			{formatNumberWithComma(fteTotals.totalHeadCount, 2, true)} HC
		</FteTotalsSection>

		<FteTotalsSection title="Festangestellt">
			{formatNumberWithComma(fteTotals.totalFteEmployees, 2, true)} FTE und&nbsp;
			{formatNumberWithComma(fteTotals.totalHeadCountEmployees, 2, true)} HC
		</FteTotalsSection>

		<FteTotalsSection title="Werkstduenten">
			{formatNumberWithComma(fteTotals.totalFteStudents, 2, true)} FTE und&nbsp;
			{formatNumberWithComma(fteTotals.totalHeadCountStudents, 2, true)} HC
		</FteTotalsSection>

		<FteTotalsSection title="Praktikanten">
			{formatNumberWithComma(fteTotals.totalFteInterns, 2, true)} FTE und&nbsp;
			{formatNumberWithComma(fteTotals.totalHeadCountInterns, 2, true)} HC
		</FteTotalsSection>
	</div>;
};

export default TeamFteTotalsSection;