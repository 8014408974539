import { getYear } from "date-fns";
import CompensationReportNotes
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationReportNotes";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import Card from "~/components/Card";
import CardValueWithLabel from "~/components/CardValueWithLabel";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import Section from "~/components/sections/Section";
import { COMPENSATION_VISIBILITY_CHANNEL_NAME } from "~/constants/broadcastChannelNames.ts";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import useBroadcastChannel from "~/hooks/useBroadcastChannel";
import { useAllBonusTypes } from "~/modules/humanResources/api/bonusType/bonusTypeQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import {
	useStaffMembersFtePercentages,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageQueries.ts";
import { useStaffMembersTotalCompensations } from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationQueries.ts";
import {
	useStaffMembersVariableCompensationPercentages,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageQueries.ts";
import BenefitsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BenefitsSection";
import BonusesSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/BonusesSection";
import CompensationComponentsOverview
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview";
import { getEntryValidAtDate } from "~/utils/arrayUtils.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	staffMemberCoreData: StaffMember
};

const StaffMemberPaymentsSection: FunctionComponent<Props> = ({ staffMemberCoreData }) => {
	const broadcastVisibility = useBroadcastChannel(COMPENSATION_VISIBILITY_CHANNEL_NAME);
	const {
		data: totalCompensationsData,
		isLoading: totalCompensationsIsLoading,
	} = useStaffMembersTotalCompensations(staffMemberCoreData.id);

	const {
		data: fteData,
		isLoading: fteIsLoading,
	} = useStaffMembersFtePercentages(staffMemberCoreData.id);

	const {
		data: variableCompensationPercentagesData,
		isLoading: variableCompensationPercentagesIsLoading,
	} = useStaffMembersVariableCompensationPercentages(staffMemberCoreData.id);

	useAllBonusTypes();

	const currentCompensationData = useMemo(() => {
		let currentFullTotalCompensationCents: number | undefined = undefined;
		let currentActualTotalCompensationCents: number | undefined = undefined;
		let currentBaseSalaryCents: number | undefined = undefined;
		let currentFtePercentage: number | undefined = undefined;
		let currentMonthlySalaryCents: number | undefined = undefined;
		let currentVariableCompensationCents: number | undefined = undefined;
		let currentVariableCompensationPercentage: number | undefined = undefined;
		const currentDate = new Date();

		if (totalCompensationsData !== undefined && fteData !== undefined && variableCompensationPercentagesData !== undefined) {
			currentFtePercentage = getEntryValidAtDate(fteData.map(fte => ({
				...fte,
				validFrom: new Date(fte.validFrom),
			})), currentDate)?.percentage;

			currentVariableCompensationPercentage = getEntryValidAtDate(variableCompensationPercentagesData.map(variableCompensation => ({
				...variableCompensation,
				validFrom: new Date(variableCompensation.validFrom),
			})), currentDate)?.percentage;

			currentFullTotalCompensationCents = getEntryValidAtDate(totalCompensationsData.map(compensation => ({
				...compensation,
				validFrom: new Date(compensation.validFrom),
			})), currentDate)?.totalCompensationCents;

			if (currentFullTotalCompensationCents !== undefined && currentFtePercentage !== undefined && currentVariableCompensationPercentage !== undefined) {
				currentActualTotalCompensationCents = currentFullTotalCompensationCents * (currentFtePercentage / 10000);
				currentVariableCompensationCents = currentActualTotalCompensationCents * (currentVariableCompensationPercentage / 10000);
				currentBaseSalaryCents = currentActualTotalCompensationCents - currentVariableCompensationCents;
				currentMonthlySalaryCents = currentBaseSalaryCents / 12;
			}
		}
		return {
			fullTotalCompensationCents: currentFullTotalCompensationCents,
			actualTotalCompensationCents: currentActualTotalCompensationCents,
			baseSalaryCents: currentBaseSalaryCents,
			ftePercentage: currentFtePercentage,
			monthlySalaryCents: currentMonthlySalaryCents,
			variableCompensationPercentage: currentVariableCompensationPercentage,
			variableCompensationCents: currentVariableCompensationCents,
		};
	}, [totalCompensationsData, fteData, variableCompensationPercentagesData]);

	const isLoading = totalCompensationsIsLoading || fteIsLoading || variableCompensationPercentagesIsLoading;
	const summaryWasCalculated = currentCompensationData.ftePercentage !== undefined && currentCompensationData.monthlySalaryCents !== undefined && currentCompensationData.variableCompensationPercentage !== undefined && currentCompensationData.variableCompensationCents !== undefined;
	const currentFtePercentageFormatted = currentCompensationData.ftePercentage ? formatNumberWithComma(currentCompensationData.ftePercentage / 100, 2, true) : EMPTY_VALUE_PLACEHOLDER;
	const currentVariableCompensationPercentageFormatted = currentCompensationData.variableCompensationPercentage ? formatNumberWithComma(currentCompensationData.variableCompensationPercentage / 100, 2, true) : EMPTY_VALUE_PLACEHOLDER;

	const averageAnnualTotalCompensationIncreasePercentage = useMemo(() => {
		if (totalCompensationsData) {
			if (totalCompensationsData.length < 2) return undefined;

			const totalCompensationsSorted = totalCompensationsData.map(compensation => ({
				...compensation,
				validFrom: new Date(compensation.validFrom),
			})).sort(byObjectProperty("validFrom", "asc"));
			const startTotalCompensation = totalCompensationsSorted[0];
			const currentTotalCompensation = totalCompensationsSorted[totalCompensationsData.length - 1];

			const years = getYear(currentTotalCompensation.validFrom) - getYear(startTotalCompensation.validFrom);
			if (years <= 0) return undefined;
			const startTotalCompensationCents = startTotalCompensation.totalCompensationCents;
			const currentTotalCompensationCents = currentTotalCompensation.totalCompensationCents;
			const totalIncrease = currentTotalCompensationCents - startTotalCompensationCents;
			const averageIncrease = totalIncrease / years;
			return averageIncrease / startTotalCompensationCents * 100;

		}
		return undefined;
	}, [totalCompensationsData]);

	return <Section scrollable={true}
					className="pb-10">
		<div className="grid w-full grid-cols-3 gap-4">
			<div className="col-span-3">
				<Card padding="none"
					  title="Aktuelle Gehaltsdaten"
					  theme="highlight"
					  headerButtonText="Gehaltsdaten verbergen"
					  onHeaderButtonClick={() => broadcastVisibility("hide")}
				>
					<div className="flex justify-between">
						{isLoading && <div className="flex h-16 w-full items-center justify-center"><LoadingSpinnerFull theme="neutral" /></div>}
						{!isLoading && !summaryWasCalculated &&
							<div className="flex h-16 w-full items-center justify-center text-sm"><span>Zur Anzeige der aktuellen Gehaltsdaten müssen aktuell gültige Werte für Gehalt, FTE und Variablen Gehaltsanteil vorhanden sein.</span>
							</div>}
						{!isLoading && summaryWasCalculated && <>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label="Total compensation (100%)">
								{currentCompensationData.fullTotalCompensationCents ? formatCentsToCurrency(currentCompensationData.fullTotalCompensationCents) : EMPTY_VALUE_PLACEHOLDER}
							</CardValueWithLabel>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label={`Total compensation (${currentFtePercentageFormatted}%)`}>
								{currentCompensationData.actualTotalCompensationCents ? formatCentsToCurrency(currentCompensationData.actualTotalCompensationCents) : EMPTY_VALUE_PLACEHOLDER}
							</CardValueWithLabel>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label={`Grundgehalt`}>
								{currentCompensationData.baseSalaryCents ? formatCentsToCurrency(currentCompensationData.baseSalaryCents) : EMPTY_VALUE_PLACEHOLDER}
							</CardValueWithLabel>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label={`Variabler Gehaltsanteil (${currentVariableCompensationPercentageFormatted}%)`}>
								{currentCompensationData.variableCompensationCents ? formatCentsToCurrency(currentCompensationData.variableCompensationCents) : EMPTY_VALUE_PLACEHOLDER}
							</CardValueWithLabel>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label="Monatliches Fixgehalt">
								{currentCompensationData.monthlySalaryCents ? formatCentsToCurrency(currentCompensationData.monthlySalaryCents) : EMPTY_VALUE_PLACEHOLDER}
							</CardValueWithLabel>
							<CardValueWithLabel theme="dark"
												hasBottomBorder={false}
												label="Ø Gehaltssteigerung p.a.">
								{averageAnnualTotalCompensationIncreasePercentage ? formatNumberWithComma(averageAnnualTotalCompensationIncreasePercentage, 2) + " %" : "n/a"}
							</CardValueWithLabel>
						</>}
					</div>
				</Card>
			</div>
			<div className="col-span-3 space-y-4">
				<CompensationComponentsOverview staffMemberId={staffMemberCoreData.id} />
				<BenefitsSection staffMemberId={staffMemberCoreData.id} />
				<BonusesSection staffMemberId={staffMemberCoreData.id} />
				<CompensationReportNotes staffMemberId={staffMemberCoreData.id}/>
			</div>
		</div>
	</Section>;
};

export default StaffMemberPaymentsSection;