import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import type { VacationLedgerEntryType } from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import UpdateVacationLedgerEntryForm
	from "~/modules/absence/components/VacationLedgerView/components/UpdateVacationLedgerEntryForm";

type CreateVacationLedgerEntrySidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	vacationLedgerEntryData?: VacationLedgerEntryType | null,
};

const UpdateVacationLedgerEntrySidebar: React.FunctionComponent<CreateVacationLedgerEntrySidebarProps> = ({
	isOpen,
	onClose,
	vacationLedgerEntryData,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			if (vacationLedgerEntryData) {
				await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(vacationLedgerEntryData.staffMemberId) });
				await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(vacationLedgerEntryData.staffMemberId) });
				onClose();
			}
		},
		[onClose, queryClient, vacationLedgerEntryData],
	);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{vacationLedgerEntryData && <UpdateVacationLedgerEntryForm
				onSuccess={handleSuccess}
				onCancel={onClose}
				vacationLedgerEntryData={vacationLedgerEntryData}
			/>}
		</Sidebar>
	);
};

export default UpdateVacationLedgerEntrySidebar;
