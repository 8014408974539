import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getUsersMonthlyClosings } from "~/modules/timeTracking/api/monthlyClosing/monthlyClosingApiDispatchers.ts";

const ENTITY_QUERY_KEY = "monthlyClosing";

export const USERS_MONTHLY_CLOSINGS_QUERY_KEY = (userId: string) => [ENTITY_QUERY_KEY, userId];

export const useUsersMonthlyClosings = (userId:string) => useQuery({
    queryKey: USERS_MONTHLY_CLOSINGS_QUERY_KEY(userId),
    queryFn: () => getUsersMonthlyClosings(userId).then(getDataFromResponse),
	enabled: !!userId,
});
