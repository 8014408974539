import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import CreateVacationLedgerEntryForm
	from "~/modules/absence/components/VacationLedgerView/components/CreateVacationLedgerEntryForm";

type CreateVacationLedgerEntrySidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberId: string,
};

const CreateVacationLedgerEntrySidebar: React.FunctionComponent<CreateVacationLedgerEntrySidebarProps> = ({
	isOpen,
	onClose,
	staffMemberId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId) });
			onClose();
		},
		[onClose, queryClient, staffMemberId],
	);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			<CreateVacationLedgerEntryForm
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberId={staffMemberId}
			/>
		</Sidebar>
	);
};

export default CreateVacationLedgerEntrySidebar;
