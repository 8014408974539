import { useMemo } from "react";

import { useAllProjectTags } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";

export function useProjectTagValidationComparisonData(tagTypeId:ProjectTagTypeId) {
	const { data: allProjectTags } = useAllProjectTags();

	return useMemo(() => {
		if (allProjectTags) {
			return allProjectTags.filter(projectTag=>projectTag.tagTypeId === tagTypeId).map((projectTag) => {
				return {
					id: projectTag.id,
					value: sanitizeStringForComparison(projectTag.displayName),
				};
			});

		}
		return [];
	}, [allProjectTags, tagTypeId]);


}