import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersSpecialAgreements,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementApiDispatchers.ts";

export const STAFF_MEMBER_SPECIAL_AGREEMENT_BASE_QUERY_KEY = "staffMembersSpecialAgreements";

export const STAFF_MEMBERS_SPECIAL_AGREEMENTS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_SPECIAL_AGREEMENT_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersSpecialAgreements = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_SPECIAL_AGREEMENTS_QUERY_KEY(staffMemberId),
    queryFn: () => indexStaffMembersSpecialAgreements(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});