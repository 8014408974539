export type ExcelSheetData = {
	id: string;
	bonusCents: number | null;
	bonusCentsWasUpdated: boolean;
	careerLevelDisplayName: string | null;
	careerLevelWasUpdated: boolean;
	compensationReportNotes: string | null;
	currentPeriodStartDate: string | null;
	currentPeriodStartedInCurrentMonth: boolean;
	currentPeriodStartTotalCompensationCents: number | null;
	employmentEndDate: Date | null;
	ftePercentage: number | null;
	ftePercentageWasUpdated: boolean;
	monthlySalaryCents: number | null;
	personnelNumber: string | null;
	staffMemberId: string;
	staffMemberFullName: string;
	totalCompensationCents: number | null;
	totalCompensationFteCents: number | null;
	totalCompensationWasUpdated: boolean;
	totalPaymentCents: number;
	variableCompensationCents: number | null;
	variableCompensationPercentage: number | null;
	variableCompensationPercentageWasUpdated: boolean;
	yearlySalaryCents: number | null;
}


export const monthlyReportTableColumnDefs = [
	{
		accessor: "personnelNumber",
		header: "PSN",
		type: "string",
	},
	{
		accessor: "staffMemberFullName",
		header: "Mitarbeiter/in",
		highlightCondition: (row: ExcelSheetData) =>  row.currentPeriodStartedInCurrentMonth || !!row.employmentEndDate,
		type: "string",
	},
	{
		accessor: "careerLevelDisplayName",
		header: "Karrierestufe",
		highlightCondition: (row: ExcelSheetData) => row.currentPeriodStartedInCurrentMonth,
		type: "string",
	},
	{
		accessor: "currentPeriodStartDate",
		header: "Vetragsbeginn\naktuelle\nAnstellungsperiode",
		highlightCondition: (row: ExcelSheetData) => row.currentPeriodStartedInCurrentMonth,
		type: "date",
	},
	{
		accessor: "employmentEndDate",
		header: "Vetragsende\n(aktueller\nMonat)",
		highlightCondition: (row: ExcelSheetData) => !!row.employmentEndDate,
		type: "date",
	},
	{
		accessor: "currentPeriodStartTotalCompensationCents",
		header: "Einstigesgehalt\naktuelle\nAnstellungsperiode",
		highlightCondition: (row: ExcelSheetData) => row.currentPeriodStartedInCurrentMonth,
		type: "currency",
	},
	{
		accessor: "ftePercentage",
		header: "FTE\nProzentsatz",
		highlightCondition: (row: ExcelSheetData) => row.ftePercentageWasUpdated,
		type: "percentage",

	},
	{
		accessor: "totalCompensationFteCents",
		header: "Total Compensation\n100%",
		highlightCondition: (row: ExcelSheetData) => row.totalCompensationWasUpdated,
		type: "currency",
	},
	{
		accessor: "totalCompensationCents",
		header: "Total Compensation\nFTE-basiert",
		highlightCondition: undefined,
		type: "currency",

	},
	{
		accessor: "variableCompensationPercentage",
		header: "Variabler\nAnteil",
		highlightCondition: (row: ExcelSheetData) => row.variableCompensationPercentageWasUpdated,
		type: "percentage",
	},
	{
		accessor: "yearlySalaryCents",
		header: "Grundgehalt\nFTE-basiert",
		highlightCondition: undefined,
		type: "currency",
	},
	{
		accessor: "variableCompensationCents",
		header: "Variabler Anteil\nFTE-basiert",
		highlightCondition: undefined,
		type: "currency",

	},
	{
		accessor: "monthlySalaryCents",
		header: "Monatliches\nGrundgehalt\nFTE-basiert",
		highlightCondition: undefined,
		type: "currency",
	},
	{
		accessor: "monthlyBonusPaymentCents",
		header: "Monatliche\nBonus-\nAbschlagszahlung",
		highlightCondition: undefined,
		type: "currency",
	},
	{
		accessor: "bonusCents",
		header: "Bonuszahlungen",
		highlightCondition: (row: ExcelSheetData) => row.bonusCentsWasUpdated,
		type: "currency",

	},
	{
		accessor: "totalPaymentCents",
		header: "Auszahlungsbetrag",
		highlightCondition: undefined,
		type: "currency",
	},
	{
		accessor: "compensationReportNotes",
		header: "Bemerkungen",
		highlightCondition: undefined,
		type: "currency",
	},
];