export const getAllProgramsEndpoint = "programs";

export function indexProgramsByClientIdEndpoint(clientId: string) {
	return `clients/${clientId}/programs`;
}

export function getProgramEndpoint(clientId: string, programId: string) {
	return `clients/${clientId}/programs/${programId}`;
}

export function createProgramEndpoint(clientId: string) {
	return `clients/${clientId}/programs`;
}

export function updateProgramEndpoint(clientId: string, programId: string) {
	return `clients/${clientId}/programs/${programId}`;
}
