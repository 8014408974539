import type {
	ProjectDescriptionCardData,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { byMultipleObjectProperties } from "~/utils/sortFunctions.ts";

export function getTagFilterResults(selectedTags: ProjectTagComboboxOption[],
	projects: ProjectDescriptionCardData[]): ProjectDescriptionCardData[] {
	const result: ProjectDescriptionCardData[] = [];

	projects.forEach(project => {
		const matchedTags = selectedTags.filter(tag => project.projectTagIds.includes(tag.value))
			.sort(byMultipleObjectProperties([{ property: "tagTypeId", direction: "asc" }, {
			property: "label",
			direction: "asc",
		}]));
		if (matchedTags.length === 0) return;

		result.push({
			...project,
			matchedTags,
			totalMatches: matchedTags.length
		});
	});
	return result.sort(byMultipleObjectProperties([{ property: "totalMatches", direction: "desc" }, {
		property: "title",
		direction: "asc",
	}]));

}

export function getSelectedTagsFromIds(tags: ProjectTagComboboxOption[], ids: string[]): ProjectTagComboboxOption[] {
	return tags.filter(tag => ids.includes(tag.value));
}