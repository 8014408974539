import { useMemo } from "react";

import { useProjectsOrders } from "~/modules/project/api/order/orderQueries.ts";
import { sanitizeStringForComparison } from "~/utils/form/formUtils.ts";
import type { UniqueValidatorArrayObject } from "~/utils/form/validationRules.ts";

type UseOrderTitleValidationComparisonDataProps = {
	projectId: string;
	projectPhaseId: string;
};

export const useOrderTitleValidationComparisonData = ({
														  projectId,
														  projectPhaseId,
													  }: UseOrderTitleValidationComparisonDataProps): UniqueValidatorArrayObject[] => {
	const {
		data: projectsOrdersData,
	} = useProjectsOrders(projectId);

	return useMemo(() => {
		if (projectsOrdersData) {
			return projectsOrdersData.filter(order => order.projectPhaseId === projectPhaseId).map((order) => {
				return {
					id: order.id,
					value: sanitizeStringForComparison(order.title),
				};
			});
		}

		return [];
	}, [projectsOrdersData, projectPhaseId]);
};