import { yupResolver } from "@hookform/resolvers/yup";
import ProjectTag from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import Hint from "~/components/Hint";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useProjectTagInputOptions } from "~/modules/project/api/projectTag/hooks/useProjectTagInputOptions.ts";
import { mergeProjectTags } from "~/modules/project/api/projectTag/projectTagApiDispatchers.ts";
import type {
	ProjectTag as ProjectTagType,
	ProjectTagTypeId,
} from "~/modules/project/api/projectTag/projectTagTypes.ts";
import {
	getProjectTagTypeDisplayNameById,
	getProjectTagTypeThemeById,
} from "~/modules/project/utils/projectTagTypeUtils.ts";
import type { FormInputOption } from "~/types/form.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface MergeDeliverableTagsFormData {
	projectTags: { originalId: string; displayName: string }[];
}

type MergeDeliverableTagsFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	projectTagData: ProjectTagType;
	tagTypeId: ProjectTagTypeId;
};

const MergeDeliverableTagsForm: FunctionComponent<MergeDeliverableTagsFormProps> = ({
	onSuccess,
	onCancel,
	projectTagData,
	tagTypeId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const deliverableTagOptions = useProjectTagInputOptions({ excludedIds: [projectTagData.id], tagTypeId });

	const schema = useMemo(() => {
		return yup.object({
			projectTags: yup.array().of(yup.object({
				originalId: yup.string().required(),
				displayName: yup.string().default(""),
			})).required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			projectTags: [],
		};
	}, []);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<MergeDeliverableTagsFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<MergeDeliverableTagsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
		enableInitially: false,
	});

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "projectTags",
	});

	const handleAddTag = useCallback((value: string | null) => {
		const existingTag = deliverableTagOptions.find(deliverableTagOption => deliverableTagOption.value === value);

		if (existingTag && !fields.find(field => field.originalId === existingTag.value)) {
			append({ originalId: existingTag.value, displayName: existingTag.label });
		}
	}, [deliverableTagOptions, fields, append]);

	const onSubmit = useCallback(async (data: MergeDeliverableTagsFormData) => {
		try {
			setIsBusy(true);
			const tagIdsToMerge = data.projectTags.map(tag => tag.originalId);
			await mergeProjectTags({ tagId: projectTagData.id, tagIdsToMerge, tagTypeId });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.");
		}
	}, [projectTagData.id, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">{getProjectTagTypeDisplayNameById(tagTypeId)} Tags zusammenführen</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="mb-8 max-w-fit">
					<ProjectTag theme={getProjectTagTypeThemeById(tagTypeId)}
								size="md"
								displayName={projectTagData.displayName} />
				</div>

				<ComboBoxPlain value=""
							   autoFocus={true}
							   allowNew={false}
							   placeholder="Tags zum Zusammenführen auswählen"
							   optionsData={deliverableTagOptions}
							   onChange={handleAddTag as (value: string | FormInputOption | null) => void}
				/>
				<div className="mb-8 mt-4 flex flex-wrap gap-x-2 rounded-lg border p-2">
					{fields.length === 0 && <ProjectTag displayName="Keine Tags ausgewählt"
														theme="neutral" />}
					{fields.map((field, index) => {
						return <ProjectTag key={index}
										   theme={getProjectTagTypeThemeById(tagTypeId)}
										   displayName={field.displayName}
										   onDeleteClick={() => remove(index)} />;
					})}
				</div>
				<Hint theme="warning"
					  size="sm">
					<p>Die ausgewählten Tags werden durch den bestehenden Tag ersetzt und <strong>gelöscht</strong>.</p>
					<p><strong>Dieser Vorgang kann nicht rückgängig gemacht werden.</strong></p>
				</Hint>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Tags zusammenführen
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default MergeDeliverableTagsForm;
