import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateProjectPhaseData,
	PhaseStaffUtilisationReport,
	ProjectPhase,
	UpdateProjectPhaseData,
	UpdateProjectPhaseStatusData,
} from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";

export function indexProjectPhasesByProjectId(projectId: string): AxiosApiResponsePromise<ProjectPhase[]> {
	return axiosApi.get(`/projects/${projectId}/project-phases`);
}

export function createProjectPhase(projectId: string,
	data: CreateProjectPhaseData): AxiosApiResponsePromise<ProjectPhase> {
	return axiosApi.post(`/projects/${projectId}/project-phases`, { ...data });
}

export function updateProjectPhase({ projectId, projectPhaseId, phaseData }: {
	projectId: string,
	projectPhaseId: string,
	phaseData: UpdateProjectPhaseData
}): AxiosApiResponsePromise<ProjectPhase> {
	return axiosApi.put(`/projects/${projectId}/project-phases/${projectPhaseId}`, { ...phaseData });
}

export function deleteProjectPhase({ projectPhaseId, projectId }: {
	projectId: string,
	projectPhaseId: string
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`/prosajects/${projectId}/project-phases/${projectPhaseId}`);
}

export function updateProjectPhaseStatus({ projectId, projectPhaseId, data }: {
	projectId: string,
	projectPhaseId: string,
	data: UpdateProjectPhaseStatusData
}): AxiosApiResponsePromise<ProjectPhase> {
	return axiosApi.patch(`/projects/${projectId}/project-phases/${projectPhaseId}/status`, data);
}

export function getStaffUtilisation(projectId: string,
	phaseId: string): AxiosApiResponsePromise<PhaseStaffUtilisationReport[]> {
	return axiosApi.get(`/projects/${projectId}/project-phases/${phaseId}/staff-utilisation`);
}
