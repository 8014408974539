import { PencilIcon, ShareIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import ProjectTag from "components/projectTags/ProjectTag";
import ProjectTagWithUsage from "components/projectTags/ProjectTagWithUsage";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardWrapper from "~/components/Card/components/CardWrapper";
import ContentWrapper from "~/components/ContentWrapper";
import SearchInput from "~/components/formElements/SearchInput";
import CreateProjectTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection/components/CreateProjectTagSidebar";
import DeleteProjectTagModal
	from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection/components/DeleteProjectTagModal";
import MergeProjectTagsSidebar
	from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection/components/MergeProjectTagsSidebar";
import UpdateProjectTagSidebar
	from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection/components/UpdateProjectTagSidebar";
import TaggedProjectListItem from "~/modules/admin/components/AdminView/components/TaggedProjectListItem";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import type {
	ProjectTag as ProjectTagType,
	ProjectTagTypeId,
	ProjectTagUsageReport,
} from "~/modules/project/api/projectTag/projectTagTypes.ts";
import type { ProjectTagWithProjectId } from "~/modules/project/types/adminProjectTagsViewTypes.ts";
import {
	getProjectTagTypeThemeById,
} from "~/modules/project/utils/projectTagTypeUtils.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	loadingPercentage: number;
	tagTypeId: ProjectTagTypeId;
	allTagsData: ProjectTagType[];
	usageReport: ProjectTagUsageReport[];
}

const AdminProjectTagsSection: FunctionComponent<Props> = ({
	allTagsData,
	loadingPercentage,
	tagTypeId,
	usageReport,
}) => {
	const [search, setSearch] = useState<string>("");
	const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
	const [selectedTagData, setSelectedTagData] = useState<ProjectTagWithProjectId | null>(null);
	const [showCreateSidebar, setShowCreateSidebar] = useState<boolean>(false);
	const [projectTagDataToUpdate, setProjectTagDataToUpdate] = useState<ProjectTagType | null>(null);
	const [projectTagDataToDelete, setProjectTagDataToDelete] = useState<ProjectTagType | null>(null);
	const [projectTagDataToMerge, setProjectTagDataToMerge] = useState<ProjectTagType | null>(null);

	const { data: allClients } = useAllClients();
	const { data: allProjects } = useAllProjects();

	const enhancedSortedTags: ProjectTagWithProjectId[] = useMemo(() => {
		if (allTagsData && usageReport) {
			return allTagsData.filter(projectTag => projectTag.tagTypeId === tagTypeId)?.map((tag) => {
				const projectIds: string[] = usageReport?.find((report) => report.projectTagId === tag.id)?.projectIds || [];
				return {
					...tag,
					projectIds,
				};
			});
		}
		return [];
	}, [allTagsData, tagTypeId, usageReport]);

	const filteredTags = useMemo(() => {
		if (enhancedSortedTags) {
			const deliverableTagsSorted = enhancedSortedTags.sort(byObjectProperty("displayName"));
			if (search) {
				return deliverableTagsSorted.filter((tag) => tag.displayName.toLowerCase().includes(search.toLowerCase()));
			}
			return deliverableTagsSorted;
		}
		return undefined;
	}, [enhancedSortedTags, search]);

	// we're doing this in use effect to automatically update the selectedTagData when enhancedSortedTags updates
	useEffect(() => {
		if (enhancedSortedTags && selectedTagId) {
			const projectTagData = enhancedSortedTags.find((tag) => tag.id === selectedTagId);

			if (projectTagData) {
				setSelectedTagData(projectTagData);
			}
		}

		if (!selectedTagId) {
			setSelectedTagData(null);
		}
	}, [selectedTagId, enhancedSortedTags]);

	if (loadingPercentage !== 100) {
		return <LoadingPage pcent={loadingPercentage} />;
	}
	return <ContentWrapper className="size-full overflow-hidden pb-10 pt-4">
		<div className="grid h-full grid-cols-5 gap-x-8">
			<div className="col-span-2 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max"
							 className="flex flex-col">
					<CardHeader>
						<div className="mr-2 max-w-full grow">
							<SearchInput value={search}
										 onChange={setSearch} />
						</div>
						<Button onClick={() => setShowCreateSidebar(true)}>Neuer Tag</Button>
					</CardHeader>
					<CardContent hasTitle={true}
								 className="grow overflow-y-auto">
						<div className="flex flex-col gap-y-2 ">
							{filteredTags?.map((tag) => {
								return <div key={tag.id}><ProjectTagWithUsage
									onClick={() => setSelectedTagId(tag.id)}
									size="sm"
									theme={getProjectTagTypeThemeById(tag.tagTypeId)}
									selected={selectedTagData?.id === tag.id}
									displayName={tag.displayName}
									numberUsages={tag.projectIds.length} /></div>;

							})}
							{enhancedSortedTags.length > 0 && filteredTags?.length === 0 && <div className="text-gray-500">Es wurde keine Tags gefunden.</div>}
							{enhancedSortedTags.length === 0 && <div className="text-gray-500">Es exisiteren noch keine Tags dieses Typs.</div>}
						</div>
					</CardContent>
				</CardWrapper>
			</div>
			<div className="col-span-3 flex max-h-full flex-col overflow-hidden">
				<CardWrapper height="max"
							 className="flex flex-col">
					<CardHeader>
						<div className="max-w-40 grow-0">
							{selectedTagData ? <ProjectTag theme={getProjectTagTypeThemeById(tagTypeId)}
														   size="md"
														   displayName={selectedTagData.displayName} /> :
								<ProjectTag size="md"
											displayName="Kein Tag ausgewählt"
											theme="neutral" />}
						</div>
						<div className="ml-2 flex shrink-0 justify-end gap-x-2 self-end">
							<Button theme="secondary"
									disabled={!selectedTagData}
									onClick={() => setProjectTagDataToMerge(selectedTagData)}
							>
								<ShareIcon className="size-4 rotate-180" />
								Tags zusammenführen</Button>
							<Button theme="danger"
									disabled={!selectedTagData || selectedTagData.projectIds.length > 0}
									onClick={() => setProjectTagDataToDelete(selectedTagData)}
							>
								<TrashIcon className="size-4" />
								Löschen</Button>
							<Button theme="primary"
									disabled={!selectedTagData}
									onClick={() => setProjectTagDataToUpdate(selectedTagData)}>
								<PencilIcon className="size-4" />Bearbeiten</Button>
						</div>
					</CardHeader>
					<CardContent hasTitle={true}
								 className="grow overflow-y-auto">
						<div className="flex flex-col gap-y-2">
							{selectedTagData && selectedTagData.projectIds.map((projectId) => {
								const projectData = allProjects?.find((project) => project.id === projectId);
								let clientDisplayName = "";
								if (projectData && allClients) {
									clientDisplayName = allClients.find((client) => client.id === projectData.clientId)?.displayName || "";
								}

								return projectData ? <TaggedProjectListItem key={projectId}
																			projectData={projectData}
																			clientDisplayName={clientDisplayName} /> : null;
							})}
							{selectedTagData && selectedTagData.projectIds.length === 0 &&
								<div className="text-gray-500">Dieser Tag ist keinem Projekt zugeordnet.</div>}
							{!selectedTagData &&
								<div className="text-gray-500">Tag auswählen, um dessen Details anzuzeigen</div>}
						</div>
					</CardContent>

				</CardWrapper>

			</div>
		</div>
		<CreateProjectTagSidebar isOpen={showCreateSidebar}
								 onClose={() => setShowCreateSidebar(false)}
								 tagTypeId={tagTypeId}
		/>
		<UpdateProjectTagSidebar isOpen={!!projectTagDataToUpdate}
								 onClose={() => setProjectTagDataToUpdate(null)}
								 projectTagData={projectTagDataToUpdate}
								 tagTypeId={tagTypeId}
		/>
		<MergeProjectTagsSidebar isOpen={!!projectTagDataToMerge}
								 onClose={() => setProjectTagDataToMerge(null)}
								 projectTagData={projectTagDataToMerge}
								 tagTypeId={tagTypeId}
		/>
		<DeleteProjectTagModal isOpen={!!projectTagDataToDelete}
							   onClose={() => setProjectTagDataToDelete(null)}
							   projectTagData={projectTagDataToDelete}
							   setSelectedTagId={setSelectedTagId}
							   tagTypeId={tagTypeId}
		/>
	</ContentWrapper>;
};

export default AdminProjectTagsSection;