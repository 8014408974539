export default {
	buttonTexts: {
		save: "Speichern",
		cancel: "Abbrechen",
		update: "Aktualisieren",
	},
	select: {
		defaultOption: "-- Bitte auswählen --",
	},
	validationErrors: {
		mixed: {
			required: "Dieses Feld darf nicht leer sein.",
		},
		string: {
			email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
		},
		number:{
			requiredTypeError: "Dieses Feld darf nicht leer sein."
		}
	},
};