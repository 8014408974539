import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersCareerLevels,
} from "~/modules/humanResources/api/staffMembersCareerLevel/staffMembersCareerLevelApiDispatchers.ts";

export const STAFF_MEMBERS_CAREER_LEVELS_BASE_QUERY_KEY = "staffMembersCareerLevels";

export const STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBERS_CAREER_LEVELS_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersCareerLevels = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_CAREER_LEVELS_QUERY_KEY(staffMemberId),
    queryFn: () => indexStaffMembersCareerLevels(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});