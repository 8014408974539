import {ReactComponent as Icon} from "./calendarIcon.svg"

type StopwatchIconProps = {
	className?: string;
};

const CalendarIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default CalendarIcon;