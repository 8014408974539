import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCountries } from "~/modules/country/api/country/countryApiDispatchers.ts";

const ENTITY_QUERY_KEY = "country";

export const ALL_COUNTRIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCountries = (enabled: boolean = true) => useQuery({
	queryKey: ALL_COUNTRIES_QUERY_KEY,
	queryFn: () => getAllCountries().then(getDataFromResponse),
	enabled,
});