import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { deleteProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import { PROJECTS_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";

type DeleteProjectPhaseModalProps = {
	isOpen: boolean;
	onClose: () => void;
	projectId: string;
	projectPhaseId: string;
	projectPhaseTitle: string;
};

const DeleteProjectPhaseModal: React.FunctionComponent<DeleteProjectPhaseModalProps> = ({
	isOpen,
	projectId,
	projectPhaseId,
	projectPhaseTitle,
	onClose,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (!isOpen) {
			setError("");
		}
	}, [isOpen]);
	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteProjectPhase({ projectPhaseId, projectId });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(projectId) });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_PHASES_QUERY_KEY(projectId) });
			await queryClient.invalidateQueries({ queryKey: PROJECT_QUERY_KEY(projectId) });

			onClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectPhaseId.find(
						(validationError) => validationError.rule === "projectPhaseIsDeletableRule",
					)
				) {
					setError("Die Phase kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Phase "${projectPhaseTitle}" löschen`,
		bodyText: `Möchtest Du diese Phase wirklich löschen?`,
		deleteButtonText: `Phase löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteProjectPhaseModal;
