import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllClients, getClient } from "~/modules/client/api/client/clientApiDispatchers.ts";

const ENTITY_QUERY_KEY = "client";

export const CLIENT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];
export const ALL_CLIENTS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useClient = (clientId: string) => useQuery({
	queryKey: CLIENT_QUERY_KEY(clientId),
	queryFn: () => getClient(clientId).then(getDataFromResponse),
	enabled: !!clientId,
});

export const useAllClients = (enabled: boolean = true) => useQuery({
	queryKey: ALL_CLIENTS_QUERY_KEY,
	queryFn: () => getAllClients().then(getDataFromResponse),
	enabled
});