import type { VisibilityState} from "@tanstack/react-table";
import {
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import Table from "~/components/Table";
import {
	monthlyUtilisationColumnDefs,
} from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationTableSection/components/MonthlyUtilisationTable/monthlyUtilisationTableColumnDefs.tsx";
import type { MonthlyUtilisationTableData } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { UtilisationReportDeductiblesProperty } from "~/modules/reports/types/utilistationReportViewTypes.ts";

type Props = {
	selectedDeductibles: UtilisationReportDeductiblesProperty[],
	tableData: MonthlyUtilisationTableData[];
	search: string;
};

const MonthlyUtilisationTable: FunctionComponent<Props> = ({ search, selectedDeductibles, tableData }) => {
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

	useEffect(() => {
		const newColumnVisibility: VisibilityState = {};

		Object.values(UtilisationReportDeductiblesProperty).forEach((deductible) => {
			const deductibleName = deductible as UtilisationReportDeductiblesProperty;
			if(!selectedDeductibles.includes(deductibleName)){
				newColumnVisibility[deductibleName] = false;
			}
		});
		setColumnVisibility(newColumnVisibility);
	}, [selectedDeductibles]);

	const table = useReactTable({
		data: tableData,
		state: {
			globalFilter: search,
			columnVisibility
		},
		columns: monthlyUtilisationColumnDefs,
		globalFilterFn: "includesString",
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	});

	return <Table table={table}
				  fixedWidth={true} />;
};

export default MonthlyUtilisationTable;