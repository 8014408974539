import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembers,
	indexStaffMembersMentees,
	showStaffMember,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";

export const STAFF_MEMBER_BASE_QUERY_KEY = "staffMembers";

export const ALL_STAFF_MEMBERS_QUERY_KEY = [STAFF_MEMBER_BASE_QUERY_KEY, "all"];

export const useAllStaffMembers = (enabled: boolean = true) => useQuery({
	queryKey: ALL_STAFF_MEMBERS_QUERY_KEY,
	queryFn: () => indexStaffMembers().then(getDataFromResponse),
	enabled,
});

export const STAFF_MEMBER_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BASE_QUERY_KEY, staffMemberId];

export const useStaffMember = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBER_QUERY_KEY(staffMemberId),
	queryFn: () => showStaffMember(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});

export const STAFF_MEMBERS_MENTEES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BASE_QUERY_KEY, "mentees", staffMemberId];

export const useStaffMembersMentees = (staffMemberId:string) => useQuery({
	queryKey: STAFF_MEMBERS_MENTEES_QUERY_KEY(staffMemberId),
	queryFn: () => indexStaffMembersMentees(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});