import type { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";

type ErrorInfo = {
    name: string;
    error: unknown;
};

export type AggregatedDataOptions<T> = {
    isLoading: boolean;
    loadingPercent: number;
    status: Array<"pending" | "error" | "success">;
    errors: ErrorInfo[];
    disabledQueries: string[];
    loadingStates: { [K in keyof T]: boolean };
};

type InferHookResult<T> = T extends UseQueryResult<infer R, any> ? R : never;

export function useAggregatedDataV2<T extends Record<string, UseQueryResult<any, unknown>>>(
    hookResults: T
): {
    data: { [K in keyof T]: InferHookResult<T[K]> };
    options: AggregatedDataOptions<T>;
} {
    return useMemo(() => {
        const data = {} as { [K in keyof T]: InferHookResult<T[K]> };
        const statusArray: AggregatedDataOptions<T>["status"] = [];
        const errors: ErrorInfo[] = [];
        const disabledQueries: string[] = [];
        const loadingStates = {} as { [K in keyof T]: boolean };

        Object.entries(hookResults).forEach(([name, query]) => {
            (data as any)[name] = query.data;
            statusArray.push(query.status);
            if (query.error) errors.push({ name, error: query.error });
            if (query.isLoading === undefined) disabledQueries.push(name);
            loadingStates[name as keyof T] = query.isPending;
        });

        const totalEnabledQueries = Object.keys(hookResults).length - disabledQueries.length;
        const successCount = statusArray.filter((s) => s === "success").length;
        const loadingPercent = totalEnabledQueries > 0
            ? (successCount / totalEnabledQueries) * 100
            : 100;

        return {
            data,
            options: {
                isLoading: statusArray.some((s) => s === "pending"),
                loadingPercent,
                status: statusArray,
                errors,
                disabledQueries,
                loadingStates,
            },
        };
    }, [hookResults]);
}