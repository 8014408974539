import { Tooltip as MantineTooltip } from "@mantine/core";
import clsx from "clsx";
import type { PropsWithChildren } from "react";

type TooltipProps = PropsWithChildren & {
	className?: string;
	text: string;
	portalId?: string;
	width?: number;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({
	className,
	children, portalId, text,
	width,
}) => {

	return <MantineTooltip label={text}
						   withArrow={true}
						   style={{ width }}
						   withinPortal={!!portalId}
						   portalProps={portalId ? { target: "#" + portalId } : undefined}
						   arrowSize={6}
						   classNames={{
							   tooltip: clsx("absolute rounded-md bg-gray-500 px-2 py-1 text-sm text-white shadow-md",
								   className),
							   arrow: "bg-inherit",
						   }}>
		{children}
	</MantineTooltip>;

};

export default Tooltip;