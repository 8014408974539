import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Popover } from "@mantine/core";
import { t } from "i18next";

import Status from "~/components/Status";
import type { BillingReportStaffedUserType } from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportTypes.ts";
import BillableProjectCardListElement
	from "~/modules/billing/components/MonthlyBillingReportView/components/BillableProjectCard/components/BillableProjectCardListElement";
import DownloadInvoiceButton
	from "~/modules/billing/components/MonthlyBillingReportView/components/DownloadInvoiceButton";
import type { OrderInvoicingStatus } from "~/modules/billing/types/billingEnums.ts";
import { getInvoicingStatusTheme } from "~/modules/billing/utils/monthlyInvoicingUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

type BillableProjectPhaseProps = {
	allUsersData: User[];
	comment: string | null;
	companyName: string;
	hasFreelancers: string;
	manager: string;
	monthlyClosing: string;
	orderId: string;
	orderIsOverbooked: boolean;
	orderTitle: string;
	phaseTitle: string;
	projectTitle: string;
	projectNumber: string;
	setSelectedOrderId: (orderId: string) => void;
	staffedUsers: BillingReportStaffedUserType[];
	month: string;
	status: OrderInvoicingStatus;
	total: string;
}


const BillableProjectPhase: React.FunctionComponent<BillableProjectPhaseProps> = ({
	allUsersData,
	comment,
	companyName,
	hasFreelancers,
	manager,
	monthlyClosing,
	orderId,
	orderIsOverbooked,
	orderTitle,
	phaseTitle,
	setSelectedOrderId,
	staffedUsers,
	month,
	status,
	total,
}) => {
	const statusTheme = getInvoicingStatusTheme(status);
	const statusDisplayName = t(normalizeTKey(`entities:orderInvoicingStatus.${status}`));

	const staffedUsersContent = (<div>{staffedUsers.map((staffedUser) => {
		const user = allUsersData.find((user) => user.id === staffedUser.userId);
		if (user) {
			return (
				<div className="flex flex-row items-center gap-x-2 p-1"
					 key={"staffedUser" + staffedUser.userId}>
					{staffedUser.hasClosedMonth ? <CheckCircleIcon className="size-5 text-success-500" /> :
						<XCircleIcon className="size-5 text-danger-500" />}
					<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{user.fullName}</dd>
				</div>
			);
		}
		return null;
	})}</div>);

	return (
		<div className="w-full">
			<div className="flex flex-row py-2 pr-4 text-gray-600">
				<span className="mr-2">Phase: {phaseTitle}</span>
				<button onClick={() => setSelectedOrderId(orderId)}>
					<Status theme={statusTheme}>{statusDisplayName}</Status></button>
			</div>
			<div className="grid grid-cols-4 gap-x-5 rounded bg-white px-4 py-2">
				{orderIsOverbooked && <div className="col-span-4 mb-2 flex items-center gap-x-2 bg-yellow-50 p-2 text-yellow-800">
					<ExclamationTriangleIcon className="mt-0.5 size-5" />
					<span className="font-medium">Das Kostenvolumen der Bestellung ist überbucht.</span>
				</div>}
				<dl className="col-span-2 flex-none divide-y divide-gray-100">
					<BillableProjectCardListElement label="Bestellung"
								 value={orderTitle} />
					<BillableProjectCardListElement label="Firma"
								 value={companyName} />
					<BillableProjectCardListElement label="Manager"
								 value={manager} />
				</dl>
				<dl className="flex-none divide-y divide-gray-100">
					<BillableProjectCardListElement label="EUR (netto)"
								 value={total} />
					<div className="flex flex-row gap-x-5 py-2">
						<dt className="w-32 text-sm font-medium leading-6 text-gray-900">Monatsabschluss</dt>
						<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
							<Popover position="bottom-start"
									 shadow="md"
									 offset={2}
									 classNames={{
										 dropdown: "absolute z-50 border border-gray-200 bg-white rounded-md shadow-lg pl-1.5 pr-3 py-1 text-gray-800 min-w-[5rem]",
									 }}>
								<Popover.Target>
									<button className="w-full hover:text-gray-500">{monthlyClosing}</button>
								</Popover.Target>
								<Popover.Dropdown>
									{staffedUsersContent}
								</Popover.Dropdown>
							</Popover>
						</dd>
					</div>
					<BillableProjectCardListElement label="Freelancer"
								 value={hasFreelancers} />
				</dl>
				<dl className="flex-none divide-y divide-gray-100">
					<DownloadInvoiceButton orderId={orderId}
										   month={month} />
					<BillableProjectCardListElement label="Besonderheiten"
								 value={comment || "Keine"} />
				</dl>
			</div>
		</div>);
};

export default BillableProjectPhase;