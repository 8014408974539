import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllLanguages } from "~/modules/country/api/language/languageApiDispatchers.ts";

const ENTITY_QUERY_KEY = "language";

export const ALL_LANGUAGES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllLanguages = (enabled: boolean = true) => useQuery({
    queryKey: ALL_LANGUAGES_QUERY_KEY,
    queryFn: () => getAllLanguages().then(getDataFromResponse),
    enabled,
});