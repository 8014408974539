import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { FunctionComponent } from "react";
import { Chart } from "react-chartjs-2";

import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { DailyRateReportClientBreakdown } from "~/modules/reports/api/dailyRateReport/dailyRateReportTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
);

type Props = {
	data: DailyRateReportClientBreakdown[];
	clients: Client[];
};

const DailyRateByClientHorizontalBarChart: FunctionComponent<Props> = ({ data, clients }) => {
	const sortedData = data.sort((a, b) => parseFloat(b.averageDailyRateCents) - parseFloat(a.averageDailyRateCents));
	const labels = sortedData.map(item => {
		const client = clients.find(c => c.id === item.clientId);
		return client ? client.displayName : item.clientId;
	});

	const chartData = {
		labels,
		datasets: [
			{
				data: sortedData.map(item => parseFloat(item.averageDailyRateCents)),
				backgroundColor: "rgb(30,53,96)",
				borderColor: "rgb(30,53,96)",
				borderWidth: 0,
			},
		],
	};

	const options = {
		indexAxis: 'y' as const,
		responsive: true,
		maintainAspectRatio: false,
		animation:false,
		scales: {
			x: {
				beginAtZero: true,
				title: {
					display: false,
					text: "Average Daily Rate (€)",
				},
				ticks: {
					callback: (value: number) => `${formatCentsToCurrency(value)}`,
				},
			},
			y: {
				offset: true,
				ticks: {
					autoSkip: false,
					maxRotation: 0,
					minRotation: 0,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context: any) => `${formatCentsToCurrency(context.parsed.x)}`,
				},
			},
			datalabels: {
				color: 'white',
				font: {
					weight: 'bold'
				},
				anchor: 'end',
				align: 'left',
				clamp:true,
				formatter: (value: number) => `${formatCentsToCurrency(value)}`,
			},
		},
	};


	return (
		<div style={{ height: `${Math.max(400, data.length * 40)}px`, width: "100%" }}>
			{/*@ts-expect-error library type definition is wrong*/}
			<Chart type="bar" data={chartData} options={options} />
		</div>
	);
};

export default DailyRateByClientHorizontalBarChart;