import clsx from "clsx";
import type { FunctionComponent } from "react";

type Props = {
	colorClassName?: string;
	highlightColorClassName?: string;
	isHighlighted?: boolean
	label: string;
	value: string;
};

const HorizontalStatDetail: FunctionComponent<Props> = ({
	colorClassName = "text-gray-700",
	highlightColorClassName = "text-red-600",
	isHighlighted = false,
	label,
	value,
}) => {

	return <div className="flex grow flex-col items-center justify-center px-2">
		<div className="text-xs font-medium text-gray-500">
			{label}
		</div>
		<div className={clsx("text-lg font-bold", isHighlighted ? highlightColorClassName : colorClassName)}>
			{value}
		</div>
	</div>;
};

export default HorizontalStatDetail;