import { useCallback, useEffect } from "react";

import { useUpdateCheckStore } from "~/stores/useUpdateCheckStore.ts";

const UPDATE_INTERVAL = 60 * 1000;

function UpdateChecker() {
	const {
		setUpdateAvailable,
	} = useUpdateCheckStore((state) => state);

	const checkForUpdates = useCallback(async () => {
		try {
			// Fetch the latest manifest
			const manifestResponse = await fetch("/manifest.json", {
				cache: "no-store",
				headers: { "Cache-Control": "no-cache" },
			});

			if (!manifestResponse.ok) {
				console.warn("Manifest file not accessible. Skipping update check.");
				return;
			}

			const manifest = await manifestResponse.json();
			let latestJsFile = manifest["index.html"]?.file;

			if (!latestJsFile) {
				console.warn("Unable to find main JavaScript file in manifest.");
				return;
			}
			latestJsFile = latestJsFile.replace("assets/", "");

			// Get the current script filename from the DOM
			const scripts = document.getElementsByTagName("script");
			const currentScript = Array.from(scripts).find(script =>
				script.src.includes("assets/index-") && script.src.endsWith(".js"),
			);

			if (!currentScript) {
				console.warn("Unable to find current script in the DOM.");
				return;
			}

			const currentJsFile = currentScript.src.split("/").pop();

			// Compare the filenames
			if (currentJsFile !== latestJsFile) {
				setUpdateAvailable(true);
			}

		} catch (error) {
			console.error("Error checking for updates:", error);
		}
	}, [setUpdateAvailable]);

	useEffect(() => {
		if(process.env.NODE_ENV === "development") return;
		checkForUpdates(); // Check immediately when component mounts

		const intervalId = setInterval(checkForUpdates, UPDATE_INTERVAL);

		return () => clearInterval(intervalId); // Clean up on unmount
	}, [checkForUpdates]);

/*	if (updateAvailable) {
		return (
			<div className="fixed bottom-10 right-10 flex items-center gap-x-2 rounded-lg bg-white px-4 py-3 text-sm shadow-lg">
				Ein App-Update ist verfügbar. Bitte lade die Seite neu.
				<Button onClick={reloadApp}>
					Jetzt neu laden
				</Button>
			</div>
		);
	}*/

	return null;
}

export default UpdateChecker;