import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { All_COMPANIES_QUERY_KEY, COMPANY_QUERY_KEY, useCompany } from "~/modules/client/api/company/companyQueries.ts";
import UpdateCompanyForm from "~/modules/client/components/forms/UpdateCompanyForm";
import LoadingPage from "~/pages/LoadingPage.tsx";

type UpdateCompanySidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	companyId: string;
};

const UpdateCompanySidebar: React.FunctionComponent<UpdateCompanySidebarProps> = ({ isOpen, setOpen, companyId }) => {
	const queryClient = useQueryClient();
	const {
		data: companyData,
		isLoading: companyIsLoading,
	} = useCompany(companyId, isOpen && !!companyId);


	const handleSuccess = useCallback(async () => {
		queryClient.invalidateQueries({ queryKey: COMPANY_QUERY_KEY(companyId) });
		await queryClient.invalidateQueries({ queryKey: All_COMPANIES_QUERY_KEY });
		setOpen(false);
	}, [queryClient, companyId, setOpen]);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<>
				{companyIsLoading || !companyData ? <LoadingPage /> :
					<UpdateCompanyForm companyData={companyData}
									   onSuccess={handleSuccess}
									   onCancel={() => setOpen(false)} />}
			</>
		</Sidebar>
	);
};

export default UpdateCompanySidebar;
