import type { MouseEvent } from "react";
import { Fragment } from "react";

import TimeTrackingFormTag from "~/modules/timeTracking/components/components/TimeTrackingFormTag";

interface LocationsProps {
	data: {displayName:string}[]
	onClick: (locationName: string, event: MouseEvent) => void;
}

const Locations: React.FC<LocationsProps> = ({ data, onClick }) => (
	<Fragment>
		{data.map((locationData, index) =>
				!!locationData && (
					<TimeTrackingFormTag
						key={`location-${index}`}
						onClick={(event) => onClick(locationData.displayName, event)}
					>
						{locationData.displayName}
					</TimeTrackingFormTag>
				)
		)}
	</Fragment>
);

export default Locations;