import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type React from "react";
import { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";

import type { UtilisationChartData } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { TimeTrackingTypeId } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);


type ChartProps = {
	chartData: UtilisationChartData[][];
	projectUtilisationOnly: boolean;
	selectedMonth: number;
	setSelectedMonth: (month: string) => void;
};

const MonthlyUtilizationChart: React.FC<ChartProps> = ({ chartData, projectUtilisationOnly, selectedMonth, setSelectedMonth }) => {
	const chartRef = useRef<ChartJS>(null);
	const months = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

	const prepareDatasets = (reportData: UtilisationChartData[][], projectUtilisationOnly: boolean) => {
		let typeIds = [TimeTrackingTypeId.Project];
		if (!projectUtilisationOnly) {
			typeIds = [TimeTrackingTypeId.Project, TimeTrackingTypeId.Sales, TimeTrackingTypeId.Internal];
		}
		return Array.from(typeIds).map((typeId) => {
			let label = "";
			let backgroundColor = "";
			switch (typeId) {
				case TimeTrackingTypeId.Project:
					label = "Projekt";
					backgroundColor = "rgb(30,53,96)";
					break;
				case TimeTrackingTypeId.Sales:
					label = "Sales";
					backgroundColor = "rgba(67, 152, 238)";
					break;
				case TimeTrackingTypeId.Internal:
					label = "Intern";
					backgroundColor = "rgba(151,209,249)";
					break;
			}
			return {
				label,
				data: reportData.map(monthData => {
					return monthData.find(typeData => typeData.typeId === typeId)?.utilisationPercentage.toNumber() || 0;
				}),
				backgroundColor
			};
		}).filter(Boolean);
	};

	const processedData = {
		labels: months,
		datasets: prepareDatasets(chartData, projectUtilisationOnly),
	};

	const options = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		scales: {
			x: { stacked: true,ticks: {
					font: (context: any) => {
						const index = context.index;
						return {
							weight: index + 1 === selectedMonth ? 'bold' : 'normal'
						};
					}
				} },

			y: {
				stacked: true,
				suggestedMax: 100,
				ticks: {
					callback: (value: number) => `${value} %`,
				},
			},
		},
		plugins: {
			tooltip: {
				enabled:false,
				callbacks: {
					label: (context: any) => `${context.dataset.label}: ${context.parsed.y.toFixed(2)}%`,
				},
			},
			legend: {
				position: "top" as const,
			},
			datalabels: {
				color: "white",
				font: {
					weight: "bold",
				},
				formatter: (value: number) => {
					return `${formatNumberWithComma(value, 1)} %`;
				},
				display: (context: any) => {
					const dataset = context.dataset;
					const value = dataset.data[context.dataIndex];
					return value > 5; // Only display label if value is greater than 5%
				},
				anchor: "center",
				align: "center",
			},
		},
		onHover: (event: any, elements: any[]) => {
			if (elements.length > 0) {
				event.native.target.style.cursor = "pointer";
			} else {
				event.native.target.style.cursor = "default";
			}
		},
	};

	useEffect(() => {
		const chart = chartRef.current;

		if (chart) {
			chart.canvas.onclick = (evt) => {
				const points = chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);

				if (points.length) {
					const firstPoint = points[0];
					const index = firstPoint.index;
					setSelectedMonth((index + 1).toString());
				}
			};
		}
	}, [setSelectedMonth]);


	return (
		<Bar data={processedData}
			/*@ts-expect-error libary type definition is wrong*/
			 ref={chartRef}
			/*@ts-expect-error libary type definition is wrong*/
			 options={options} />
	);
};

export default MonthlyUtilizationChart;