import Button from "components/buttons/Button";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';
import { generatePath } from "react-router-dom";

import Sidebar from "~/components/Sidebar";
import { appRoutes } from "~/constants/appRoute.ts";
import { ALL_CLIENTS_QUERY_KEY, useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import CreateClientForm from "~/modules/client/components/forms/CreateClientForm";

type ClientViewSidebarProps = {
	isOpen: boolean;
	selectedCompanyId: string | null;
	onClose: () => void;
};

const CreateClientSidebar: React.FunctionComponent<ClientViewSidebarProps> = ({
	isOpen,
	onClose,
	selectedCompanyId,
}) => {
	const [newClientId, setNewClientId] = useState<string | null>(null);
	const [newClientData, setNewClientData] = useState<Client | null>(null);
	const queryClient = useQueryClient();
	const { data: allClientsData, isLoading: allClientsLoading } = useAllClients();

	useEffect(() => {
		if (newClientId && allClientsData && !allClientsLoading) {
			const newClient = allClientsData?.find((client) => client.companyId === newClientId);
			if (newClient) {
				setNewClientData(newClient);
			}
		}
	}, [newClientId, allClientsData, allClientsLoading]);

	useEffect(() => {
		if (!selectedCompanyId) {
			setNewClientId(null);
			setNewClientData(null);
		}
	}, [selectedCompanyId]);

	const handleClose = useCallback(() => {
		setNewClientId(null);
		setNewClientData(null);
		onClose();
	}, [onClose]);

	const handleSuccess = useCallback(
		async (clientId: string) => {

			await queryClient.refetchQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
			await queryClient.refetchQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
			setNewClientId(clientId);
			handleClose();
		},
		[handleClose, queryClient],
	);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={handleClose}>
			{isOpen && !newClientId && !newClientData && selectedCompanyId && (
				<CreateClientForm companyId={selectedCompanyId}
								  onSuccess={handleSuccess} />
			)}
			{isOpen && newClientData && (
				<div className="px-8 py-6">
					<div className="mb-4 text-center text-xl font-bold text-gray-800">
						Neue Firma &quot;{newClientData.displayName}&quot; erstellt
					</div>
					<div className="mb-4 text-center text-gray-800">Was möchtest Du als nächstes tun?</div>
					<div className="flex flex-col space-y-4">
						<Button
							className="justify-center"
							to={generatePath(appRoutes.client("").path, { clientId: newClientData.id })}
						>
							Firmenseite öffnen
						</Button>
					</div>
				</div>
			)}
		</Sidebar>
	);
};

export default CreateClientSidebar;
