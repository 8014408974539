import { isAfter, isBefore, isSameYear } from "date-fns";

import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";

export function getStaffMembersSplitByEmploymentStatus(staffMembers: StaffMember[]): {
	activeStaffMembersInCategory: StaffMember[],
	leftStaffMembersInCategory: StaffMember[],
	futureStaffMembersInCategory: StaffMember[],
} {
	const activeStaffMembersInCategory: StaffMember[] = [];
	const leftStaffMembersInCategory: StaffMember[] = [];
	const futureStaffMembersInCategory: StaffMember[] = [];
	const today = new Date();
	staffMembers
		.forEach((staffMember) => {
			const employmentStartDate = staffMember.employmentStartDate ? new Date(staffMember.employmentStartDate) : null;
			const employmentEndDate = staffMember.employmentEndDate ? new Date(staffMember.employmentEndDate) : null;
/*			if(staffMember.id === "9"){
				debugger;
			}*/
			if(staffMember.currentEmploymentTypeId && staffMember.currentEmploymentTypeId !== EmploymentTypeId.Left){
				activeStaffMembersInCategory.push(staffMember);
			}
			else if (employmentEndDate && isSameYear(employmentEndDate, today) && isBefore(employmentEndDate, today)) {
				leftStaffMembersInCategory.push(staffMember);
			} else if (employmentStartDate && isSameYear(employmentStartDate, today) && isAfter(employmentStartDate, today)) {
				futureStaffMembersInCategory.push(staffMember);
			}
		});
	return {
		activeStaffMembersInCategory,
		leftStaffMembersInCategory,
		futureStaffMembersInCategory,
	};
}