import Sidebar from "~/components/Sidebar";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import {
	useAllProjectReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import ProjectReferenceTextCard from "~/modules/project/components/ProjectReferenceTextCard";

type ReferenceTextSidebarProps = {
	isOpen: boolean;
	close: () => void;
	referenceTextId: string | null;
};

const ReferenceTextSidebar: React.FunctionComponent<ReferenceTextSidebarProps> = ({
	isOpen,
	close,
	referenceTextId,
}) => {
	const { data: referenceTextData } = useAllProjectReferenceTexts();

	const referenceText = referenceTextId ? referenceTextData?.find((referenceText) => referenceText.id === referenceTextId) : null;

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 theme="medium"
				 setOpen={() => close()}>
			<SidebarContent>

				{referenceText && <ProjectReferenceTextCard referenceText={referenceText}/>}
			</SidebarContent>
		</Sidebar>
	);
};

export default ReferenceTextSidebar;
