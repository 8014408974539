import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexAllStaffMembersEmployments,
	indexStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsApiDispatchers.ts";

export const EMPLOYMENT_BASE_QUERY_KEY = "staffMembersEmployments";

export const STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY = (staffMemberId: string) => [EMPLOYMENT_BASE_QUERY_KEY, staffMemberId];

export const ALL_STAFF_MEMBER_EMPLOYMENTS_QUERY_KEY = [EMPLOYMENT_BASE_QUERY_KEY];

export const useAllStaffMemberEmployments = () => useQuery({
	queryKey: ALL_STAFF_MEMBER_EMPLOYMENTS_QUERY_KEY,
	queryFn: () => indexAllStaffMembersEmployments().then(getDataFromResponse),
});

export const useStaffMembersEmployments = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBERS_EMPLOYMENTS_QUERY_KEY(staffMemberId),
	queryFn: () => indexStaffMembersEmployments(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});