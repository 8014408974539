import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import DatePickerInput from "~/components/formElements/DatePickerInput";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import useGenderSelectOptions from "~/hooks/form/formOptionsData/useGenderSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMembersPersonalDetails,
} from "~/modules/humanResources/api/staffMember/staffMemberApiDispatchers.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type UpdatePersonalDetailsFormData = {
	firstName: string;
	lastName: string;
	nickName: string | null;
	genderId: string;
	dateOfBirth: Date | null;
	personnelNumber: string | null;
}

type UpdatePersonalDetailsFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdatePersonalDetailsForm: React.FunctionComponent<UpdatePersonalDetailsFormProps> = ({
	onSuccess,
	onCancel,
	staffMemberCoreData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const genderSelectionOptions = useGenderSelectOptions();
	const schema = useMemo(() => {
		return yup.object({
			firstName: yup.string().required("First name is required"),
			lastName: yup.string().required("Last name is required"),
			nickName: yup.string().nullable().default(null),
			genderId: yup.string().required(),
			dateOfBirth: yup.date().nullable().default(null),
			personnelNumber: yup.string().nullable().default(null),
		});
	}, []);
	const defaultValues = useMemo(() => {
		if (staffMemberCoreData) {
			return {
				firstName: staffMemberCoreData.firstName,
				lastName: staffMemberCoreData.lastName,
				nickName: staffMemberCoreData.nickName,
				genderId: staffMemberCoreData.genderId,
				dateOfBirth: staffMemberCoreData.dateOfBirth ? new Date(staffMemberCoreData.dateOfBirth) : null,
				personnelNumber: staffMemberCoreData.personnelNumber,
			};
		}
		return {
			firstName: "",
			lastName: "",
			nickName: null,
			genderId: "",
			dateOfBirth: null,
			personnelNumber: null,
		};
	}, [staffMemberCoreData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdatePersonalDetailsFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdatePersonalDetailsFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: UpdatePersonalDetailsFormData) => {
		const updateData = {
			...data,
			dateOfBirth: data.dateOfBirth ? formatDateToYYYYMMDD(data.dateOfBirth) : null,
		};
		try {
			setIsBusy(true);
			await updateStaffMembersPersonalDetails(staffMemberCoreData.id, updateData);
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
		}
	}, [onSuccess, staffMemberCoreData.id]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Persönliche Daten
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Update fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="grid grid-cols-2 gap-4">
					<Input control={control}
						   name="firstName"
						   label="Vorname"
						   placeholder="Vorname"
					/>
					<Input control={control}
						   name="lastName"
						   label="Nachname"
						   placeholder="Nachname"
					/>
					<Input control={control}
						   name="nickName"
						   label="Spitzname"
						   placeholder="Spitzname"
					/>
					<Select optionsData={genderSelectionOptions}
							name="genderId"
							control={control}
							label="Geschlecht" />
					<DatePickerInput control={control}
									 name="dateOfBirth"
									 label="Geburtsdatum" />
					<Input control={control}
						   name="personnelNumber"
						   label="Personalnummer"
						   placeholder="99"
					/>
				</div>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdatePersonalDetailsForm;
