import { generatePath } from "react-router-dom";

import { AdminViewTabName } from "~/modules/admin/types/adminViewTypes.ts";
import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";

export interface AppRoute {
	path: string;
	displayName: string;
}

export const COMPANY_DETAILS_PATH = '/company/:companyId';

export const USER_BASE_ROUTE = "/user/:userId";

export const HR_BASE_PATH = "/hr";

export const HR_STAFF_MEMBERS_INDEX_PATH = HR_BASE_PATH + "/staff-members";

export const HR_STAFF_MONTHLY_REPORT_PATH = HR_BASE_PATH + "/monthly-report";

export const HR_STATISTICS_PATH = HR_BASE_PATH + "/statistics";

export const HR_TEAM_OVERVIEW_PATH = HR_BASE_PATH + "/team";

export const STAFF_MEMBER_BASE_PATH = "/staff-members/:staffMemberId";

export const STAFF_MEMBER_ABSENCES_PATH = STAFF_MEMBER_BASE_PATH + "/absences";

export const STAFF_MEMBER_VACATION_LEDGER_PATH = STAFF_MEMBER_BASE_PATH + "/vacation-ledger";

export const STAFF_MEMBER_WORKING_SCHEDULES_PATH = STAFF_MEMBER_BASE_PATH + "/working-schedules";

export const HR_STAFF_MEMBER_DETAILS_PATH = HR_STAFF_MEMBERS_INDEX_PATH + "/:staffMemberId";
export const HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID = HR_STAFF_MEMBER_DETAILS_PATH + "/:tabId";

export const PROJECT_DESCRIPTIONS_INDEX_PATH = "descriptions";

export const PROJECT_DETAILS_BASE_PATH = "/projects/:projectId";

export const PROJECT_DETAILS_PATH_WITH_TAB_ID = PROJECT_DETAILS_BASE_PATH + "/:tabId";

export const PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID = PROJECT_DETAILS_BASE_PATH + `/${ProjectDetailsTabName.Overview}`;

export const ADMIN_BASE_PATH = "/admin";

export const ADMIN_PATH_WITH_TAB_ID = ADMIN_BASE_PATH + "/:tabId";

export const ADMIN_PATH_WITH_DEFAULT_TAB_ID = generatePath(ADMIN_PATH_WITH_TAB_ID, { tabId: AdminViewTabName.Users });

export const TIME_TRACKINGS_PATH = "/user/:userId/time-trackings";

export const REPORTS_BASE_PATH = "/reports";

export const REPORTS_PATH_WITH_TAB_ID = REPORTS_BASE_PATH + "/:tabId";

export const appRoutes = {
	absenceOverview: () => ({ path: "/absence-overview", displayName: "Übersicht Abwesenheiten" }),
	base: () => ({ path: "/", displayName: "Home" }),
	client: (displayName?: string) => ({ path: "/clients/:clientId", displayName }),
	clients: () => ({ path: "/clients", displayName: "Kunden" }),
	contact: (displayName?: string) => ({ path: "/contacts/:contactId", displayName }),
	contacts: () => ({ path: "/contacts", displayName: "Kontakte" }),
	devError: () => ({ path: "/dev-error", displayName: "Dev Error" }),
	landing: () => ({ path: "/", displayName: "Home" }),
	login: () => ({ path: "/login", displayName: "Login" }),
	logout: () => ({ path: "/logout", displayName: "Logout" }),
	monthlyBillingReport: () => ({ path: "/billing/monthly-report/", displayName: "Abrechnung" }),
	passwordResetRequest: () => ({ path: "/request-password-reset", displayName: "Passwort zurücksetzen" }),
	passwordReset: () => ({ path: "/reset-password/:token", displayName: "Passwort zurücksetzen" }),
	projectsTimeTrackings: (displayName?: string) => ({ path: "/projects/:projectId/time-trackings", displayName }),
	projects: () => ({ path: "/projects", displayName: "Projekte" }),
	userProfile: () => ({ path: "/profile", displayName: "Profil" }),
};
