import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkQueries.ts";
import type {
	StaffMemberDocumentLink,
} from "~/modules/humanResources/api/staffMemberDocumentLink/staffMemberDocumentLinkTypes.ts";
import UpdateDocumentLinkEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection/components/DocumentLinksSection/components/UpdateDocumentLinkEntrySidebar/components/UpdateDocumentLinkEntryForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	documentLinkData: StaffMemberDocumentLink | null;
};

const UpdateDocumentLinkEntrySidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	documentLinkData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!documentLinkData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_DOCUMENT_LINKS_QUERY_KEY(documentLinkData?.staffMemberId) });
		onClose();
	}, [documentLinkData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{documentLinkData && <UpdateDocumentLinkEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				documentLinkData={documentLinkData} />}
		</Sidebar>
	);
};

export default UpdateDocumentLinkEntrySidebar;
