import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import MergeProjectTagsForm
	from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection/components/MergeProjectTagsSidebar/components/MergeProjectTagsForm";
import { PROJECT_BASE_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import {
	ALL_PROJECT_TAGS_QUERY_KEY,
	PROJECT_TAG_USAGE_REPORT_QUERY_KEY,
} from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTag, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";

type MergeDeliverableTagsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	projectTagData: ProjectTag | null;
	tagTypeId: ProjectTagTypeId;
};

const MergeProjectTagsSidebar: FunctionComponent<MergeDeliverableTagsSidebarProps> = ({
	isOpen,
	onClose,
	projectTagData,
	tagTypeId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: ALL_PROJECT_TAGS_QUERY_KEY });
		await queryClient.refetchQueries({ queryKey: PROJECT_TAG_USAGE_REPORT_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: [PROJECT_BASE_QUERY_KEY] });
		onClose();
	}, [onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && projectTagData && <MergeProjectTagsForm onSuccess={handleSuccess}
																   onCancel={onClose}
																   projectTagData={projectTagData}
																   tagTypeId={tagTypeId}
			/>}
		</Sidebar>
	);
};

export default MergeProjectTagsSidebar;
