import ProjectDetailsView from "modules/project/components/ProjectDetailsView";
import type { FC } from "react";
import { useEffect, useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID } from "~/constants/appRoute.ts";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useCompany } from "~/modules/client/api/company/companyQueries.ts";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import { useProjectsOrders } from "~/modules/project/api/order/orderQueries";
import { useProject } from "~/modules/project/api/project/projectQueries";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries";
import { useAllProjectTags } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";
import { getPhaseIdFromTabId, isPhaseTabId } from "~/modules/project/utils/projectDetailsViewUtils.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";


const ProjectDetailsPage: FC = () => {
		const guard = userViewGuard(PermissionNames.CanManageProjects);
		if (guard) return guard;

		const { projectId, tabId } = useParams();
		if (undefined === projectId || undefined === tabId) return <div>error</div>;

		const navigate = useNavigate();
		const { data, options } = useAggregatedDataV2({
			companyAvatarImages: useAllCompanyAvatarImages(),
			clientContactPersons: useAllClientContactPersons(),
			clients: useAllClients(),
			countries: useAllCountries(),
			locations: useAllLocations(),
			orders: useProjectsOrders(projectId),
			phases: useProjectsProjectPhases(projectId),
			project: useProject(projectId),
			projectTags: useAllProjectTags(),
			projectRoles: useProjectProjectRoles(projectId),
			staffings: useProjectsStaffings(projectId),
			users: useAllUsers(),
			workingStatuses: useAllWorkingStatuses(),
		});
		const {
			clients,
			companyAvatarImages,
			phases,
			project,
			staffings,
			users,
		} = useMemo(() => data, [data]);

		const client = useMemo(() => {
			if (project && clients) {
				const client = clients.find((client: Client) => client.id === project.clientId);
				if (client) return client;
			}
			return undefined;
		}, [project, clients]);


		const {
			data: companyData,
			isLoading: companyIsLoading,
		} = useCompany(client?.companyId || "", !!client?.companyId);

		useEffect(() => {
			if (phases === undefined) return;
			if (isPhaseTabId(tabId)) {
				const phaseId = getPhaseIdFromTabId(tabId);

				const phase = phases.find((phase) => phase.phaseNumber === phaseId);
				if (!phase) {
					navigate(generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId }));
				}
			} else if (![ProjectDetailsTabName.Overview, ProjectDetailsTabName.Reports, ProjectDetailsTabName.ProjectTags, ProjectDetailsTabName.ReferenceTexts].includes(tabId as ProjectDetailsTabName)) {
				navigate(generatePath(PROJECT_DETAILS_PATH_WITH_DEFAULT_TAB_ID, { projectId }));
			}
		}, [navigate, phases, projectId, tabId]);


		if (options.loadingPercent < 100 || companyIsLoading || !companyData) return <LoadingPage pcent={options.loadingPercent} />;

		const projectHasStaffings = staffings && staffings.length > 0;

		return (
			<ProjectDetailsView
				allCompanyAvatarImages={companyAvatarImages}
				allUsers={users}
				client={client!}
				company={companyData}
				phases={phases}
				projectData={project}
				projectHasStaffings={projectHasStaffings}
				activeTabId={tabId}
			/>
		);
	}
;

export default ProjectDetailsPage;

