import { endOfDay, startOfDay } from "date-fns";
import ProjectsTimeTrackingsTable
	from "modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection/components/ProjectsTimeTrackingsTable";
import type { FC } from "react";
import { useMemo } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import { useCompany } from "~/modules/client/api/company/companyQueries.ts";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import type { Location } from "~/modules/location/api/location/locationTypes.ts";
import { useProjectsOrders } from "~/modules/project/api/order/orderQueries.ts";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import { useProject } from "~/modules/project/api/project/projectQueries.ts";
import { useProjectsProjectPhases } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries.ts";
import { useProjectsTimeTrackings } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import type { TimeTracking } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


interface Props {
	projectId: string;
}

const ProjectTimeTrackingsSection: FC<Props> = ({ projectId }) => {

		const { data, options } = useAggregatedDataV2({
			clientContactPersons: useAllClientContactPersons(),
			clients: useAllClients(),
			locations: useAllLocations(),
			orders: useProjectsOrders(projectId),
			phases: useProjectsProjectPhases(projectId),
			project: useProject(projectId),
			projectRoles: useProjectProjectRoles(projectId),
			staffings: useProjectsStaffings(projectId),
			timeTrackings: useProjectsTimeTrackings(projectId),
			users: useAllUsers(),
			workingStatuses: useAllWorkingStatuses(),
		});

		const {
			clients,
			locations,
			orders,
			phases,
			project,
			projectRoles,
			staffings,
			timeTrackings,
			users,
		} = data;

		const client = useMemo(() => {
			if (project && clients) {
				const client = clients.find((client: Client) => client.id === project.clientId);
				if (client) return client;
			}
			return null;
		}, [project, clients]);


		const {
			data: companyData,
			isLoading: companyIsLoading,
		} = useCompany(client?.companyId || "", !!client?.companyId);

		const [tableData, dateFirstTimeTracking, dateLastTimeTracking] = useMemo(() => {
			if (timeTrackings && phases && orders && users && staffings && projectRoles && locations) {
				const tableData = timeTrackings.map((timeTracking: TimeTracking) => {
					const staffing = staffings.find((staffing: Staffing) => staffing.id === timeTracking.staffingId)!;
					const location = locations.find((location: Location) => location.id === timeTracking.locationId)!;
					const order = orders.find((order: Order) => order.id === staffing.orderId);
					const phase = phases.find((phase: ProjectPhase) => phase.id === staffing.projectPhaseId);
					const projectRole = projectRoles.find((projectRole: ProjectRole) => projectRole.id === staffing.projectRoleId);
					const user = users.find((user: User) => user.id === staffing.userId);
					if (!staffing || !projectRole || !order || !user || !phase) {
						throw new Error("Missing relation for time Tracking: " + timeTracking.id);
					}

					const totalAmountCents = Math.round(staffing.dailyRateCents / 480 * timeTracking.minutes);

					return {
						date: new Date(timeTracking.date),
						phaseId: staffing.projectPhaseId,
						phaseTitle: phase.phaseNumber.toString().padStart(2, "0") + " - " + phase.title,
						projectRoleDisplayName: projectRole.displayName,
						userId: staffing.userId,
						userFullName: user.fullName,
						location: location.displayName,
						orderId: order.id,
						orderTitle: order.title,
						minutesTracked: timeTracking.minutes,
						dailyRateCents: staffing.dailyRateCents,
						totalAmountCents,
						deliverablesText: timeTracking.text || "",
					};
				});
				let dateFirstTimeTracking = null;
				let dateLastTimeTracking = null;
				let timeTrackingsSorted = timeTrackings;

				if (timeTrackings.length > 0) {
					timeTrackingsSorted = timeTrackings.sort(byObjectProperty("date"));
					dateFirstTimeTracking = timeTrackingsSorted[0].date ? startOfDay(new Date(timeTrackingsSorted[0].date)) : null;
					dateLastTimeTracking = timeTrackingsSorted[timeTrackingsSorted.length - 1].date ? endOfDay(new Date(timeTrackingsSorted[timeTrackingsSorted.length - 1].date)) : null;
				}
				return [tableData, dateFirstTimeTracking, dateLastTimeTracking];
			}
			return [[], null, null];
		}, [timeTrackings, phases, orders, users, staffings, projectRoles, locations]);

		const timeTrackingUserIds = useMemo(() => {
			if (timeTrackings) {
				return timeTrackings.reduce((userIds: string[], timeTracking: TimeTracking) => {
					if (!userIds.includes(timeTracking.userId)) {
						userIds.push(timeTracking.userId);
					}
					return userIds;
				}, []);
			}
			return [];
		}, [timeTrackings]);


		if (options.loadingPercent < 100 || companyIsLoading || !companyData) return <LoadingPage pcent={options.loadingPercent} />;

		return (
			<ProjectsTimeTrackingsTable
				availableUserIds={timeTrackingUserIds}
				maxDate={dateLastTimeTracking}
				minDate={dateFirstTimeTracking}
				orders={orders}
				phases={phases}
				projectData={project}
				tableData={tableData}
				users={users}
			/>
		);
	}
;

export default ProjectTimeTrackingsSection;

