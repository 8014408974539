import { DocumentTextIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import ProjectTag from "components/projectTags/ProjectTag";
import type { FunctionComponent } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Card from "~/components/Card";
import CompanyLogo from "~/components/CompanyLogo";
import { PROJECT_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import type { ProjectDescriptionCardData } from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";
import { getProjectTagTypeThemeById } from "~/modules/project/utils/projectTagTypeUtils.ts";
import { formatManDays } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";

type Props = {
	projectData: ProjectDescriptionCardData;
	selectedReferenceTextId: string | null;
	setSelectedReferenceTextId: (id: string) => void;
};

const ReferenceTextSearchResultCard: FunctionComponent<Props> = ({
	projectData,
	setSelectedReferenceTextId,
	selectedReferenceTextId,
}) => {
	return (
		<Card>
			<div className="flex gap-x-4">

				<div className="flex items-start pt-1">
					<CompanyLogo image={projectData.companyAvatarImage}
								 displayName={projectData.clientDisplayName} />
				</div>
				<div className="flex flex-col">
					<div className="flex flex-col">
						<NavLink className="text-[1.375rem] font-bold text-primary-500 transition-colors duration-200 hover:text-secondary-500"
								 to={generatePath(PROJECT_DETAILS_PATH_WITH_TAB_ID, {
									 projectId: projectData.id,
									 tabId: ProjectDetailsTabName.ReferenceTexts,
								 })}>{projectData.title}</NavLink>
						<div className="text-sm text-primary-500">
							{projectData.clientDisplayName} | {projectData.projectNumber} | {formatManDays(parseInt(projectData.manDaysPlanned))} PT
						</div>
					</div>
					<div className="mt-2 flex flex-col">
						<div className="flex flex-wrap gap-2">
							{projectData.matchedTags.map((tag) => {
								return <ProjectTag key={tag.tagTypeId + "_" + tag.value}
												   size="xxs"
												   displayName={tag.label}
												   theme={getProjectTagTypeThemeById(tag.tagTypeId)} />;
							})}
						</div>
						<div className="mt-2 flex items-center gap-x-2">
							{projectData.referenceTexts.map((referenceText) => {
								return <button key={referenceText.id}
											   onClick={() => setSelectedReferenceTextId(referenceText.id)}
											   className={clsx(
												   "rounded-md px-3 py-2 text-sm shadow",
												   "font-semibold",
												   "flex items-center",
												   "border",
												   selectedReferenceTextId === referenceText.id ? "bg-primary-700 text-white" : "cursor-pointer bg-white hover:text-secondary-600",
											   )}>
									<DocumentTextIcon className="mr-1 size-4 shrink-0" />
									<div className="grow-0 truncate">{referenceText.title}</div>
								</button>;
							})}
						</div>
					</div>
				</div>
			</div>
		</Card>);

};

export default ReferenceTextSearchResultCard;