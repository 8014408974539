import {ReactComponent as Icon} from "./globeIcon.svg"

type StopwatchIconProps = {
	className?: string;
};

const GlobeIcon: React.FunctionComponent<StopwatchIconProps> = ({className}) => {
	return <Icon className={className}/>;
};

export default GlobeIcon;