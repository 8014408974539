import { useMemo } from "react";

import { useAllClientContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useClientsContactPersonsSelectOptions = (clientId: string, additionalContactPersonId?: string): FormInputOption[] => {
	const { data: allClientContactPersons } = useAllClientContactPersons(!!clientId);
	return useMemo(() => {
		if (allClientContactPersons && clientId) {
			const filteredClientContactPersons = allClientContactPersons.filter((contactPerson) => contactPerson.clientId === clientId);
			const sortedContactsData = filteredClientContactPersons.sort(byObjectProperty("fullName"));
			if (additionalContactPersonId && filteredClientContactPersons.find(contactPerson => contactPerson.id === additionalContactPersonId) === undefined) {
				const additionalContactPerson = allClientContactPersons.find((contactPerson) => contactPerson.id === additionalContactPersonId);
				if (additionalContactPerson) {
					sortedContactsData.splice(0, 0, additionalContactPerson);
				}
			}
			return mapDataToInputOptionsData(sortedContactsData, "id", "fullName");
		}
		return [];
	}, [additionalContactPersonId, allClientContactPersons, clientId]);
};

export default useClientsContactPersonsSelectOptions;

