import clsx from "clsx";
import Button from "components/buttons/Button";
import CreateProjectSidebar from "modules/project/components/ProjectsIndexView/components/CreateProjectSidebar";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import Headline from "~/components/Headline";
import NoEntriesFound from "~/components/NoEntriesFound";
import { appRoutes } from "~/constants/appRoute.ts";
import { PROJECTS_INDEX_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import { useAuth } from "~/contexts/AuthContext";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import { TOOL_TIP_PORTAL_CONTAINER_ID } from "~/modules/humanResources/utils/projectsIndexUtils.ts";
import type { ProjectSummary } from "~/modules/project/api/project/projectTypes.ts";
import type { WorkingStatusType } from "~/modules/project/api/workingStatus/workingStatusTypes.ts";
import ActiveFilters from "~/modules/project/components/ProjectsIndexView/components/ActiveFilters";
import ProjectsIndexProjectSummary
	from "~/modules/project/components/ProjectsIndexView/components/ProjectsIndexProject";
import SearchAndFilterBar
	from "~/modules/project/components/ProjectsIndexView/components/SearchAndFilterBar/SearchAndFilterBar.tsx";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { WorkingStatusId } from "~/types/entityIds.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


interface ProjectsIndexViewProps {
	projects: ProjectSummary[];
	clients: Client[];
	users: User[];
	workingStatuses: WorkingStatusType[];
}

type PageState = {
	searchValue: string,
	searchValues: string[];
	statusFilter: string[];
	sortOrder: "asc" | "desc";
	myProjectsFilter: boolean;
};

const defaultPageState: PageState = {
	searchValue: "",
	searchValues: [],
	sortOrder: "asc",
	statusFilter: ["1", "2"],
	myProjectsFilter: true,
};

const ProjectsIndexView: FunctionComponent<ProjectsIndexViewProps> = ({
	projects,
	clients,
	users,
	workingStatuses,
}) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: PROJECTS_INDEX_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});
	const [showCreateProjectSidebar, setShowCreateProjectSidebar] = useState(false);

	const [searchValue, setSearchValue] = useState<string>(pageState.searchValue);
	const [searchValues, setSearchValues] = useState<string[]>(pageState.searchValues);
	const [statusFilter, setStatusFilter] = useState<string[]>(pageState.statusFilter);
	const [sortOrder, setSortOrder] = useState<PageState["sortOrder"]>(pageState.sortOrder);
	const [myProjectsFilter, setMyProjectsFilter] = useState(pageState.myProjectsFilter);

	useEffect(() => {
		setPageState({ searchValue, searchValues, statusFilter, sortOrder, myProjectsFilter });
	}, [statusFilter, setPageState, searchValue, sortOrder, myProjectsFilter, searchValues]);

	const onChangeStatus = (value: string | null) => {
		if (null === value) {
			setStatusFilter([]);
		} else {
			setStatusFilter((prevState) => {
				return prevState.includes(value)
					? prevState.filter((optionValue) => optionValue !== value)
					: [...prevState, value];
			});
		}
	};

	const visibleProjectIds = projects
		.filter((project) => {
			if (!myProjectsFilter || !user) return true;
			return (
				project.managedBy === user.id ||
				project.staffedUserIds.includes(user.id) ||
				project.phaseManagerIds.includes(user.id)
			);
		})
		.filter((project) => {
			if (!statusFilter.length) return true;
			let closedFilter = false;
			const statusIdsWithoutClosed = statusFilter.filter(item => item !== WorkingStatusId.Closed);
			if (statusFilter.includes(WorkingStatusId.Closed)) {
				closedFilter = project.projectPhaseStatusesIds.every(item => item === WorkingStatusId.Closed);
			}

			return closedFilter || statusIdsWithoutClosed.some(item => project.projectPhaseStatusesIds.includes(item));
		})
		.filter((project: ProjectSummary) => {
			if (searchValues.length === 0 && !searchValue) return true;
			const allSearchValues = searchValue ? [searchValue, ...searchValues] : searchValues;
			return allSearchValues.every((searchValue) => {
				return project.searchIndex.includes(searchValue.toLowerCase());
			});
		}).map((project) => project.id);

	const sortedProjects = useMemo(() => {
		return projects.sort(byObjectProperty("projectCounter", sortOrder));
	}, [projects, sortOrder]);

	return (<>
			<div className="grid h-screen grid-rows-[auto_1fr] overflow-hidden">
				<div className="sticky bg-white">
					<BreadcrumbsSection pages={[appRoutes.projects()]}
										className="bg-white" />

					<ContentWrapper className={clsx("flex w-full justify-between gap-4 pb-4 pt-2")}>
						<Headline type="h2">{t("projects.title", "Projekte")}</Headline>
						<SearchAndFilterBar
							myProjectsFilter={myProjectsFilter}
							onChangeMyProjectsFilter={setMyProjectsFilter}
							onChangeSearch={setSearchValue}
							onChangeStatus={onChangeStatus}
							searchValue={searchValue}
							setSearchValues={setSearchValues}
							sortOrder={sortOrder}
							setSortOrder={setSortOrder}
							statusFilter={statusFilter}
						/>
						<Button onClick={() => setShowCreateProjectSidebar(true)}>
							{t("projects.btnNewProject", "Neues Projekt")}
						</Button>
					</ContentWrapper>

					<ActiveFilters statusFilter={statusFilter}
								   onChangeMyProjectsFilter={setMyProjectsFilter}
								   onChangeSearch={setSearchValue}
								   onChangeStatus={onChangeStatus}
								   displayedProjectsCount={visibleProjectIds.length}
								   myProjectsFilter={myProjectsFilter}
								   searchValue={searchValue}
								   searchValues={searchValues}
								   setSearchValues={setSearchValues}
								   totalProjectsCount={projects.length}
					/>

				</div>

				<div className="size-full overflow-y-auto pb-10 pt-4">
					<ContentWrapper className="flex w-full flex-col gap-y-[2.3rem]">
						{visibleProjectIds.length === 0 && <NoEntriesFound />}
						{sortedProjects.map((project: ProjectSummary) => {
							const hidden = !visibleProjectIds.includes(project.id);
							return <ProjectsIndexProjectSummary
								hidden={hidden}
								key={project.id}
								project={project}
								clients={clients}
								users={users}
								user={user}
								workingStatuses={workingStatuses}
							/>;
						})}

					</ContentWrapper>
				</div>
				{showCreateProjectSidebar && (
					<CreateProjectSidebar isOpen={showCreateProjectSidebar}
										  setOpen={setShowCreateProjectSidebar} />
				)}
			</div>
			<div id={TOOL_TIP_PORTAL_CONTAINER_ID} />
		</>
	);
};

export default ProjectsIndexView;
