import ClientDetailsView from "modules/client/components/ClientDetailsView";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import { useClient } from "~/modules/client/api/client/clientQueries";
import { useClientsContactPersons } from "~/modules/client/api/clientContactPerson/clientContactPersonQueries";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import { useAllCountries } from "~/modules/country/api/country/countryQueries";
import { useAllCurrencies } from "~/modules/country/api/currency/currencyQueries";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import { useClientsProjects } from "~/modules/project/api/project/projectQueries";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

const ClientDetailsPage: React.FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if (guard) return guard;
	const { clientId } = useParams();
	if (undefined === clientId) return null;


	const { data, options } = useAggregatedDataV2({
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		allCompanies: useAllCompanies(),
		allCountries: useAllCountries(),
		allCurrencies: useAllCurrencies(),
		client: useClient(clientId),
		contacts: useClientsContactPersons(clientId),
		invoiceRecipients: useClientsInvoiceRecipients(clientId),
		projects: useClientsProjects(clientId),
		workingStatuses: useAllWorkingStatuses(),
	});

	const {
		allCompanies,
		allCompanyAvatarImages,
		client,
		projects,
		contacts,
		workingStatuses,
		invoiceRecipients,
	} = data;

	const company = useMemo(() => {
		if (allCompanies && client) {
			return allCompanies.find(company => company.id === client.companyId);
		}
		return undefined;
	}, [allCompanies, client]);

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

	return (
		<ClientDetailsView
			allCompanyAvatarImages={allCompanyAvatarImages}
			clientData={client}
			company={company!}
			projects={projects}
			invoiceRecipients={invoiceRecipients}
			contacts={contacts}
			workingStatuses={workingStatuses}

		/>
	);
};

export default ClientDetailsPage;
