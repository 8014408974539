import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { ALL_PROJECTS_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import { PROJECTS_PHASES_QUERY_KEY } from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import { deleteTimeTracking } from "~/modules/timeTracking/api/timeTracking/timeTrackingApiDispatchers.ts";
import {
	PROJECTS_TIME_TRACKINGS_QUERY_KEY,
	TIME_TRACKINGS_BY_USER_QUERY_KEY,
} from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import type { TimeTrackingExtendedType } from "~/modules/timeTracking/api/timeTracking/timeTrackingTypes.ts";
import { getStartAndEndOfMonthFormatted } from "~/modules/timeTracking/utils/timeTrackingUtils.ts";
import { TimeTrackingTypeId } from "~/types/entityIds.ts";
import { formatDateTimeToDate } from "~/utils/dateAndTimeUtils.ts";

type DeleteTimeTrackingModalProps = {
	isOpen: boolean;
	onClose: () => void;
	timeTrackingData: TimeTrackingExtendedType | null;
};

const ListLabelValueElement: React.FC<{ label: string; value: string }> = ({ label, value }) => (
	<div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
		<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">{value}</dd>
	</div>
);

const DeleteTimeTrackingModal: React.FunctionComponent<DeleteTimeTrackingModalProps> = ({
	isOpen,
	onClose,
	timeTrackingData,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();
	const isProject = timeTrackingData?.timeTrackingTypeId === TimeTrackingTypeId.Project || false;

	const handleConfirmationClick = async () => {
		if (!timeTrackingData) return;
		try {
			setBusy(true);
			await deleteTimeTracking({ userId: timeTrackingData.userId, timeTrackingId: timeTrackingData.id });
			const timeTrackingFilterData = getStartAndEndOfMonthFormatted(new Date(timeTrackingData.date));

			const userId = timeTrackingData.userId;
			await queryClient.refetchQueries({ queryKey: TIME_TRACKINGS_BY_USER_QUERY_KEY(userId, timeTrackingFilterData) });
			await queryClient.refetchQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(userId) });
			queryClient.invalidateQueries({ queryKey: ALL_PROJECTS_QUERY_KEY });

			if (timeTrackingData.projectId) {
				queryClient.invalidateQueries({ queryKey: PROJECTS_TIME_TRACKINGS_QUERY_KEY(timeTrackingData.projectId) });
				queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(timeTrackingData.projectId) });
				queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(timeTrackingData.projectId) });
				queryClient.invalidateQueries({ queryKey: PROJECTS_PHASES_QUERY_KEY(timeTrackingData.projectId) });
			}

			onClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.timeTrackingId.find(
						(validationError) => validationError.rule === "timeTrackingIsEditableRule",
					)
				) {
					setError("Diese Erfassung kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Zeiterfassung löschen`,
		bodyText: `Möchtest Du diese Zeiterfassung wirklich löschen?`,
		deleteButtonText: `Zeiterfassung löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{timeTrackingData && <dl className="mb-10 divide-y divide-gray-300">
				{isProject && timeTrackingData.date && (
					<ListLabelValueElement label="Datum"
										   value={formatDateTimeToDate(timeTrackingData.date)!} />
				)}
				{isProject && <ListLabelValueElement label="Projekt"
													 value={timeTrackingData.projectTitle} />}
				{isProject && <ListLabelValueElement label="Phase"
													 value={timeTrackingData.projectPhaseTitle} />}
				{isProject && <ListLabelValueElement label="Bestellung"
													 value={timeTrackingData.orderTitle} />}
				<ListLabelValueElement label="Ort"
									   value={timeTrackingData.locationName} />
				<ListLabelValueElement label="Buchungstext"
									   value={timeTrackingData.text || "-"} />
			</dl>}
		</DeleteEntityView>
	);
};

export default DeleteTimeTrackingModal;
