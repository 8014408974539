import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import ContentWrapper from "~/components/ContentWrapper";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import { ADMIN_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import AdminProjectTagsSection from "~/modules/admin/components/AdminView/components/AdminProjectTagsSection";
import AdminUsersSection from "~/modules/admin/components/AdminView/components/AdminUsersSection";
import { AdminViewTabName } from "~/modules/admin/types/adminViewTypes.ts";
import {
	useProjectTagTypeSelectOptions,
} from "~/modules/project/api/projectTag/hooks/useProjectTagTypeSelectOptions.ts";
import type {
	ProjectTag as ProjectTagType,
	ProjectTagUsageReport,
} from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { getProjectTagTypeDisplayNameById } from "~/modules/project/utils/projectTagTypeUtils.ts";
import { PermissionNames } from "~/types/entityNames.ts";
import type { SectionHeadingTab } from "~/types/tabsTypes.ts";

type Props = {
	activeTabId: AdminViewTabName;
	allTagsData: ProjectTagType[];
	usageReport: ProjectTagUsageReport[];
	tagsLoadingPercentage: number;
}

const AdminView: FunctionComponent<Props> = ({
	activeTabId,
	allTagsData,
	usageReport,
	tagsLoadingPercentage,
}) => {
	const { hasAnyPermission } = useAuth();
	const [selectedTagTypeId, setSelectedTagTypeId] = useState<ProjectTagTypeId>(ProjectTagTypeId.Products);
	const navigate = useNavigate();
	let pageTitle = "";
	const breadcrumbs = ["Admin"];
	const tagTypeSelectOptions = useProjectTagTypeSelectOptions();

	switch (activeTabId) {
		case AdminViewTabName.Users:
			pageTitle = "Admin Users";
			breadcrumbs.push("Users");
			break;
		case AdminViewTabName.ProjectTags:
			pageTitle = `Admin Project Tags: ${getProjectTagTypeDisplayNameById(selectedTagTypeId)}`;
			breadcrumbs.push("Project Tags");
			breadcrumbs.push(getProjectTagTypeDisplayNameById(selectedTagTypeId));
			break;

	}

	const userCanAdminUsers = hasAnyPermission(PermissionNames.CanManageUsers);
	const useCanAdminProjectTags = hasAnyPermission(PermissionNames.CanManageProjectTags);


	const tabs = useMemo(() => {
		const tabs: SectionHeadingTab[] = [];

		if (userCanAdminUsers) {
			tabs.push({
				name: "Users",
				value: AdminViewTabName.Users,
				active: activeTabId === AdminViewTabName.Users,
			});
		}

		if (useCanAdminProjectTags) {
			tabs.push({
				name: "Project Tags",
				value: AdminViewTabName.ProjectTags,
				active: activeTabId === AdminViewTabName.ProjectTags,
			});
		}
		return tabs;
	}, [activeTabId, useCanAdminProjectTags, userCanAdminUsers]);

	useEffect(() => {
		if (tabs.find((tab) => tab.active) === undefined) {
			navigate(generatePath(ADMIN_PATH_WITH_TAB_ID, { tabId: tabs[0].value }));
		}
	}, [activeTabId, navigate, tabs]);


	return <div className="grid h-screen grid-rows-[auto_auto_1fr]">
		<BreadcrumbsSection pages={breadcrumbs}
							className="bg-white" />

		<div className="flex w-full flex-col bg-white ">
			<ContentWrapper className="w-full">
				<div className={clsx("min-w-0 flex-1", tabs.length === 1 && "pb-4")}>
					<div className="flex items-center justify-between">
						<Headline type="h2"
								  className="pt-2">{pageTitle}</Headline>
						{activeTabId === AdminViewTabName.ProjectTags && useCanAdminProjectTags &&
							<div className="flex items-center gap-x-2">
								<div>Tag Typ:</div>
								<div className="w-64">
									<SelectPlain optionsData={tagTypeSelectOptions}

												 onChange={(value) => setSelectedTagTypeId(value)}
												 value={selectedTagTypeId} />
								</div>
							</div>
						}
					</div>
				</div>
				{tabs.length > 1 && <SectionHeadingTabs tabs={tabs}
														onTabClick={(value) => navigate(generatePath(ADMIN_PATH_WITH_TAB_ID, { tabId: value }))} />}
			</ContentWrapper>
		</div>

		{activeTabId === AdminViewTabName.ProjectTags && useCanAdminProjectTags &&
			<AdminProjectTagsSection loadingPercentage={tagsLoadingPercentage}
									 key={selectedTagTypeId}
									 allTagsData={allTagsData}
									 usageReport={usageReport}
									 tagTypeId={selectedTagTypeId} />}

		{activeTabId === AdminViewTabName.Users && userCanAdminUsers && <AdminUsersSection />}
	</div>;
};

export default AdminView;