import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { CreateUserDeliverableDataType, Deliverable } from "~/modules/timeTracking/api/deliverable/deliverableTypes.ts";

export const getUsersDeliverablesForAvailableStaffings = ({ userId }: {
	userId: string
}): AxiosApiResponsePromise<Deliverable[]> => axiosApi.get(`users/${userId}/staffings/deliverables`);

export function createUserDeliverable({ userId, deliverableData }: {
	userId: string,
	deliverableData: CreateUserDeliverableDataType
}): AxiosApiResponsePromise<void> {
	return axiosApi.post(`users/${userId}/deliverables`, deliverableData);
}

export function syncUserDeliverables({ userId, deliverableData }: {
	userId: string,
	deliverableData: CreateUserDeliverableDataType
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`users/${userId}/deliverables`, deliverableData);
}
