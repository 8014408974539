import { date, dateTime, time, weekday } from './formatters/datetime';

export type CustomFormatter<T = any> = (value: T, lng?: string) => string;

export const customFormatters = new Map<string, CustomFormatter>([
	['date', date],
	['time', time],
	['weekday', weekday],
	['datetime', dateTime],
]);
