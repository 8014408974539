import type Decimal from "decimal.js-light";

import type {
	UtilisationCalculationProperties,
} from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";

export type MonthlyUtilisationTableData = Omit<UtilisationCalculationProperties, "minutesTracked"> & {
	staffMemberDisplayName: string;
	ftePercentages: number[];
	regularWorkDays: string;
	month: number;
	utilisationPercentage: Decimal;
	workDaysTargetFte: Decimal;
};

export type UtilisationChartData = {
	typeId: string;
	utilisationPercentage: Decimal;
}

export enum UtilisationReportDeductiblesProperty {
	LongTermAbsenceDays = "longTermAbsenceDaysFte",
	SickDays = "sickAbsenceDaysFte",
	TrainingAndEventAbsenceDays = "trainingAndEventAbsenceDaysFte",
	VacationDays = "vacationDaysFte",
}

export enum UtilisationReportDeductibleDisplayNames {
	longTermAbsenceDaysFte = "Langzeitabwesenheiten",
	sickAbsenceDaysFte = "Krankheitstage",
	trainingAndEventAbsenceDaysFte = "Training & Interne Events",
	vacationDaysFte = "Urlaubstage",
}