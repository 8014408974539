import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllSalesTypes } from "~/modules/client/api/salesType/salesTypeApiDispatchers.ts";

const ENTITY_QUERY_KEY = "salesType";

export const ALL_SALES_TYPES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllSalesTypes = (enabled: boolean = true) => useQuery({
    queryKey: ALL_SALES_TYPES_QUERY_KEY,
    queryFn: () => getAllSalesTypes().then(getDataFromResponse),
    enabled
});