import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllStaffMemberChildren,
} from "~/modules/humanResources/api/staffMemberChild/staffMemberChildApiDispatchers.ts";

export const STAFF_MEMBER_CHILD_BASE_QUERY_KEY = "staffMemberChild";

export const STAFF_MEMBERS_CHILDREN_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_CHILD_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersChildren = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_CHILDREN_QUERY_KEY(staffMemberId),
    queryFn: () => getAllStaffMemberChildren(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});