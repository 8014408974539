export const getAllQuotasEndpoint = "quotas";

export function indexQuotasByClientIdEndpoint(clientId: string) {
	return `clients/${clientId}/quotas`;
}

export function getQuotaEndpoint(clientId: string, quotaId: string) {
	return `clients/${clientId}/quotas/${quotaId}`;
}

export function createQuotaEndpoint(clientId: string) {
	return `clients/${clientId}/quotas`;
}

export function updateQuotaEndpoint(clientId: string, quotaId: string) {
	return `clients/${clientId}/quotas/${quotaId}`;
}

