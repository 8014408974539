import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllLocations,
	getUsersLocationsForAvailableStaffings,
} from "~/modules/location/api/location/locationApiDispatchers.ts";

export const LOCATION_BASE_QUERY_KEY = "location";

export const All_LOCATIONS_QUERY_KEY = [LOCATION_BASE_QUERY_KEY, "all"];
export const USER_LOCATIONS_QUERY_KEY = (userId: string) => [LOCATION_BASE_QUERY_KEY, { userId }];

export const useAllLocations = (enabled: boolean = true) =>
	useQuery({
		queryKey: All_LOCATIONS_QUERY_KEY,
		queryFn: () => getAllLocations().then(getDataFromResponse),
		enabled,
	});

export const useUsersAvailableLocations = (userId:string) =>
	useQuery({
		queryKey: USER_LOCATIONS_QUERY_KEY(userId),
		queryFn: () => getUsersLocationsForAvailableStaffings({ userId }).then(getDataFromResponse),
		enabled: !!userId,
	});
