import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { endOfMonth, isAfter, isBefore, startOfMonth, subMonths } from "date-fns";
import Decimal from "decimal.js-light";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import { MonthPickerInputPlain } from "~/components/formElements/MonthPickerInput/MonthPickerInput.tsx";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import { useAllLocations } from "~/modules/location/api/location/locationQueries.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import MonthlyTimeTrackingReportListItem
	from "~/modules/project/components/ProjectDetailsView/components/OrderDetails/components/MonthlyTimeTrackingReport/components/MonthlyTimeTrackingReportListItem";
import type { MonthlyReportData, TimeTrackingListEntry } from "~/modules/project/types/projectViewTypes.ts";
import { useProjectsTimeTrackings } from "~/modules/timeTracking/api/timeTracking/timeTrackingQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { orderId: string, projectId: string, ordersStaffings: Staffing[], };


const MonthlyTimeTrackingReport: FunctionComponent<Props> = ({ ordersStaffings, projectId }) => {
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [showTimeTrackings, setShowTimeTrackings] = useState<boolean>(false);
	const { data: projectsTimeTrackings, isLoading } = useProjectsTimeTrackings(projectId);

	const { data: allLocationsData } = useAllLocations();
	const { data: allUsersData } = useAllUsers();

	const [minDate, maxDate] = useMemo(() => {
		if (projectsTimeTrackings && projectsTimeTrackings.length > 0) {
			if (projectsTimeTrackings.length === 1) {
				return [startOfMonth(new Date(projectsTimeTrackings[0].date)), endOfMonth(new Date(projectsTimeTrackings[0].date))];
			} else {
				return [startOfMonth(new Date(projectsTimeTrackings[0].date)), endOfMonth(new Date(projectsTimeTrackings[projectsTimeTrackings.length - 1].date))];
			}
		}

		return [startOfMonth(startDate), endOfMonth(startDate)];
	}, [projectsTimeTrackings, startDate]);

	useEffect(() => {
		if (isAfter(startDate, maxDate) || isBefore(startDate, minDate)) {
			setStartDate(maxDate);
		}
	}, [minDate, maxDate, startDate]);

	const handleGoToNextMonthClick = useCallback(() => {
		setStartDate(prevState => {
			const nextMonth = new Date(prevState);
			nextMonth.setMonth(nextMonth.getMonth() + 1);
			return nextMonth;
		});
	}, []);

	const handleGoToPrevMonthClick = useCallback(() => {
		setStartDate(prevState => {
			const prevMonth = new Date(prevState);
			prevMonth.setMonth(prevMonth.getMonth() - 1);
			return prevMonth;
		});
	}, []);

	const prevButtonDisabled = minDate ? subMonths(startDate, 1) <= minDate : true;
	const nextButtonDisabled = maxDate ? endOfMonth(startDate) >= maxDate : true;

	const ordersTimeTrackings = useMemo(() => {
		if (projectsTimeTrackings && ordersStaffings) {
			const ordersStaffingIds = ordersStaffings.map(staffing => staffing.id);

			return projectsTimeTrackings.filter(timeTracking => timeTracking.staffingId && ordersStaffingIds.includes(timeTracking.staffingId));
		}
		return [];
	}, [projectsTimeTrackings, ordersStaffings]);

	const [monthlyTimeTrackingReportData, totalDays, totalCents] = useMemo(() => {
		if (ordersTimeTrackings && allLocationsData && allUsersData) {
			const usersById = (allUsersData as User[]).reduce((acc, user) => {
				acc[user.id] = user.fullName;
				return acc;
			}, {} as Record<string, string>);

			const locationsById = allLocationsData.reduce((acc, location) => {
				acc[location.id] = location.displayName;
				return acc;
			}, {} as Record<string, string>);


			const timeTrackingsPerUser: Record<string, TimeTrackingListEntry[]> = ordersTimeTrackings.filter((timeTracking) => {
				return new Date(timeTracking.date).getMonth() === startDate.getMonth() && new Date(timeTracking.date).getFullYear() === startDate.getFullYear();
			}).reduce((acc, timeTracking) => {
				if (!acc[timeTracking.userId]) {
					acc[timeTracking.userId] = [];
				}
				const locationDisplayName = locationsById[timeTracking.locationId] || "";
				const hours = new Decimal(timeTracking.minutes / 60).toDecimalPlaces(2);

				acc[timeTracking.userId].push({
					locationDisplayName,
					hours,
					text: timeTracking.text || "",
					date: new Date(timeTracking.date),
				});
				return acc;
			}, {} as Record<string, TimeTrackingListEntry[]>);

			const monthlyReportData: MonthlyReportData[] = [];
			let monthTotalDays = 0;
			let monthTotalCents = 0;

			Object.keys(timeTrackingsPerUser).forEach(userId => {
				const totalHours = timeTrackingsPerUser[userId].reduce((acc, listEntry) => {
					return acc.add(listEntry.hours);
				}, new Decimal(0));

				const totalDays = totalHours.dividedBy(8).toDecimalPlaces(2);
				const dailyRateCents = ordersStaffings.find(staffing => staffing.userId === userId)!.dailyRateCents;
				const dailyRate = new Decimal(dailyRateCents).dividedBy(100);
				const totalCents = totalDays.mul(dailyRate).toDecimalPlaces(2).mul(100);
				const userDisplayName = usersById[userId];

				monthlyReportData.push({
					userDisplayName,
					dailyRateCents,
					totalCents,
					totalDays,
					totalHours,
					timeTrackings: timeTrackingsPerUser[userId],
				});
				monthTotalDays += totalDays.toNumber();
				monthTotalCents += totalCents.toNumber();

			});
			return [monthlyReportData.sort(byObjectProperty("userDisplayName")), monthTotalDays, monthTotalCents];
		}
		return [[], 0, 0];
	}, [ordersTimeTrackings, allLocationsData, allUsersData, startDate, ordersStaffings]);

	return <div>
		{isLoading && <div className="py-20"><LoadingPage fullscreen={false} /></div>}
		{!isLoading && ordersTimeTrackings.length === 0 &&
			<div className="m-4 rounded-lg bg-white px-4 py-3 text-center text-sm">Für diese Bestellung sind keine
				Erfassungen vorhanden</div>}
		{!isLoading && ordersTimeTrackings.length > 0 && <div className="mb-4 px-4">
			<div className="flex justify-center py-4">
				<div className="mr-2 flex max-w-fit items-center justify-between gap-1">
					<Button size="sm"
							theme="none"
							onClick={handleGoToPrevMonthClick}
							disabled={prevButtonDisabled}>
						<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
					</Button>
					<div className="flex min-w-32 items-center justify-center">
						<MonthPickerInputPlain value={startDate}
											   inputSize="sm"
											   clearable={false}
											   maxDate={maxDate || undefined}
											   minDate={minDate || undefined}
											   name="date"
											   onChange={(date) => setStartDate(date!)} />
					</div>
					<Button size="sm"
							theme="none"
							onClick={handleGoToNextMonthClick}
							disabled={nextButtonDisabled}>
						<ChevronRightIcon className="size-5 hover:fill-accent-600" />
					</Button>
				</div>
				<SwitchPlain checked={showTimeTrackings}
							 inputSize="sm"
							 onChange={() => setShowTimeTrackings(!showTimeTrackings)}
							 labelOn="Erfassungen sichtbar"
							 labelOff="Erfassungen verborgen" />
			</div>
			<div className="grid grid-cols-4 rounded-lg bg-white px-4 py-3 text-sm">
				{monthlyTimeTrackingReportData.length === 0 &&
					<div className="col-span-4 text-center text-sm">Für den ausgewählten Monat sind keine Erfassungen
						vorhanden</div>}
				{monthlyTimeTrackingReportData.length > 0 &&
					<>
						<div className="mb-1 text-left font-bold">
							Mitarbeiter:in
						</div>
						<div className="text-right font-bold">
							Tagessatz
						</div>
						<div className="text-right font-bold">
							PT
						</div>
						<div className="text-right font-bold">
							Summe
						</div>

					</>}
				{monthlyTimeTrackingReportData.map(itemData => {
					return <MonthlyTimeTrackingReportListItem key={itemData.userDisplayName}
															  itemData={itemData}
															  showTimeTrackings={showTimeTrackings}
					/>;
				})}

				{monthlyTimeTrackingReportData.length > 0 &&
					<>
						<div className="col-span-3 mt-1 text-right font-bold">
							∑ {formatNumberWithComma(totalDays, 2)}
						</div>
						<div className="text-right font-bold">
							∑ {formatCentsToCurrency(totalCents, 2)}
						</div>
					</>}

			</div>
		</div>}
	</div>;
};

export default MonthlyTimeTrackingReport;