import clsx from "clsx";
import type { FunctionComponent } from "react";

import type { ButtonSizeVariants, ButtonThemeVariants } from "~/components/buttons/Button/buttonVariants.ts";
import { buttonVariants } from "~/components/buttons/Button/buttonVariants.ts";
import ExcelIcon from "~/components/icons/ExcelIcon";

type DownloadExcelButtonProps = {
	buttonText?: string
	disabled?: boolean
	onClick: () => void
	size?: ButtonSizeVariants
	theme?:ButtonThemeVariants
};

const DownloadExcelButton: FunctionComponent<DownloadExcelButtonProps> = ({ buttonText="Download", disabled = false, onClick, size, theme="secondary" }) => {
	return (
		<button type="button"
				disabled={disabled}
				className={clsx(buttonVariants({ theme, size, disabled }),!!buttonText &&  "min-w-24")}
				onClick={onClick}><ExcelIcon className={clsx("size-5 fill-white",!!buttonText && "mr-0.5",)} />{buttonText}{!!buttonText && <span className="w-0.5"/>}
		</button>
	);
};

export default DownloadExcelButton;