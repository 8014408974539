import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";

import Card from "~/components/Card";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import MonthlyUtilisationBarChart
	from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationChartSection/MonthlyUtilisationBarChart";
import { UtilisationReportDeductiblesProperty } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import {
	calculateUtilisationPercentageFromMinutes,
	calculateUtilisationPercentageFromReportData,
	calculateWorkDaysTargetFte,
} from "~/modules/reports/utils/utilisationReportUtils.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = {
	selectedDeductibles: UtilisationReportDeductiblesProperty[],
	selectedMonth: string;
	setSelectedDeductibles: DispatchStateAction<UtilisationReportDeductiblesProperty[]>
	setSelectedMonth: (month: string) => void;
	utilisationReportData: YearlyUtilisationReport,
	year: string
};

const TotalUtilisationChartSection: FunctionComponent<Props> = ({ selectedDeductibles, selectedMonth, setSelectedDeductibles, setSelectedMonth,
	utilisationReportData,
	year
}) =>
{
	const [projectUtilisationOnly, setProjectUtilisationOnly] = useState<boolean>(true);

	const utilisationFormatted = formatNumberWithComma(calculateUtilisationPercentageFromReportData(utilisationReportData, selectedDeductibles, projectUtilisationOnly).toNumber(), 2);

	const chartData = useMemo(() => {
		return utilisationReportData.monthlyBreakdown.map((monthData) => {
			const workDaysTargetFTE = calculateWorkDaysTargetFte(monthData, selectedDeductibles);
			return monthData.timeTrackingTypeBreakdown.map((typeId) => {
				return {
					typeId: typeId.timeTrackingTypeId,
					utilisationPercentage: calculateUtilisationPercentageFromMinutes(typeId.minutesTracked, workDaysTargetFTE),
				};
			});

		});
	}, [selectedDeductibles, utilisationReportData]);

	const handleSwitchChange = useCallback((value: UtilisationReportDeductiblesProperty) => {
		setSelectedDeductibles((prev) => {
			if (prev.includes(value)) {
				return prev.filter((deductible) => deductible !== value);
			}
			return [...prev, value];
		});
	}, [setSelectedDeductibles]);

	return <div className="grid grid-cols-5 gap-x-2 pt-4">
		<div className="col-span-4">
			<Card>
				<div className="relative flex gap-x-4">
					<div className="absolute right-0">
						<SwitchPlain checked={projectUtilisationOnly}
									 onChange={setProjectUtilisationOnly}
									 labelOn="Projektauslastung"
									 labelOff="Gesamtauslastung" />
					</div>
					<div className="flex min-w-24 shrink-0 flex-col items-center justify-center">
						<div className="flex items-center text-xl font-bold text-gray-600">
							{utilisationFormatted} %
						</div>
						<div className="flex items-center text-sm font-light text-gray-500">
							Ø {year}
						</div>
					</div>
					<div className="min-h-40 w-full">
						<MonthlyUtilisationBarChart chartData={chartData}
													selectedMonth={parseInt(selectedMonth)}
													setSelectedMonth={setSelectedMonth}
													projectUtilisationOnly={projectUtilisationOnly}
						/>
					</div>
				</div>
			</Card>
		</div>
		<div className="col-span-1">
			<Card title="Berechnungsmodell"
				  height="full">
				<div className="flex flex-col gap-y-2">
					<SwitchPlain
						inputSize="xs"
						value={UtilisationReportDeductiblesProperty.LongTermAbsenceDays}
						checked={selectedDeductibles.includes(UtilisationReportDeductiblesProperty.LongTermAbsenceDays)}
						label={"Abzug Langzeitabwesenheit"}
						onChange={() => handleSwitchChange(UtilisationReportDeductiblesProperty.LongTermAbsenceDays)}
					/>
					<SwitchPlain
						inputSize="xs"
						value={UtilisationReportDeductiblesProperty.TrainingAndEventAbsenceDays}
						checked={selectedDeductibles.includes(UtilisationReportDeductiblesProperty.TrainingAndEventAbsenceDays)}
						label={"Abzug Training & Events"}
						onChange={() => handleSwitchChange(UtilisationReportDeductiblesProperty.TrainingAndEventAbsenceDays)}
					/>
					<SwitchPlain
						inputSize="xs"
						value={UtilisationReportDeductiblesProperty.VacationDays}
						checked={selectedDeductibles.includes(UtilisationReportDeductiblesProperty.VacationDays)}
						label={"Abzug Urlaub"}
						onChange={() => handleSwitchChange(UtilisationReportDeductiblesProperty.VacationDays)}
					/>
					<SwitchPlain
						inputSize="xs"
						value={UtilisationReportDeductiblesProperty.SickDays}
						checked={selectedDeductibles.includes(UtilisationReportDeductiblesProperty.SickDays)}
						label={"Abzug Krankheit"}
						onChange={() => handleSwitchChange(UtilisationReportDeductiblesProperty.SickDays)}
					/>
				</div>
			</Card>
		</div>
	</div>;
}
;

export default TotalUtilisationChartSection;