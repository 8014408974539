import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import RichTextEditor from "~/components/RichTextEditor";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	createProjectReferenceText,
	deleteProjectReferenceText,
	updateProjectReferenceText,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsApiDispatchers.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface CreateOrUpdateReferenceTextFormData {
	summaryText: string | null;
	title: string;
	text: string;
}

type CreateOrUpdateReferenceTextFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	referenceText?: ProjectReferenceText,
	projectId: string;
};

const CreateOrUpdateReferenceTextForm: React.FunctionComponent<CreateOrUpdateReferenceTextFormProps> = ({
	onSuccess,
	onCancel,
	projectId,
	referenceText,
}) => {
	const [isDeletion, setIsDeletion] = useState<boolean>(false);
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const isUpdate = !!referenceText;

	const schema = useMemo(() => {
		return yup.object({
			summaryText: yup.string().nullable().default(null),
			title: yup.string().required(),
			text: yup.string().required(),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			summaryText: referenceText?.summaryText || "",
			title: referenceText?.title || "",
			text: referenceText?.text || "",
		};
	}, [referenceText]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<CreateOrUpdateReferenceTextFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<CreateOrUpdateReferenceTextFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: CreateOrUpdateReferenceTextFormData) => {
		setIsBusy(true);
		try {
			if (isUpdate) {
				await updateProjectReferenceText({
					projectId,
					referenceTextId: referenceText.id,
					summaryText: data.summaryText,
					text: data.text,
					title: data.title,
				});
			} else {
				await createProjectReferenceText({
					projectId,
					summaryText: data.summaryText,
					text: data.text,
					title: data.title,
				});
			}
			onSuccess();
		} catch (error) {
			setServerErrorMessage("Ein unbekannter Fehler ist aufgetreten.");
		}
	}, [isUpdate, onSuccess, projectId, referenceText?.id]);

	const handleDeletion = useCallback(async () => {
		if (!referenceText) return;
		setIsBusy(true);
		try {
			await deleteProjectReferenceText({ projectId, referenceTextId: referenceText!.id });
			onSuccess();
		} catch (error) {
			setServerErrorMessage("Ein unbekannter Fehler ist aufgetreten.");
		}
	}, [onSuccess, projectId, referenceText]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start overflow-hidden"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">{isUpdate ? "Referenz bearbeiten" : "Neue Referenz"}</Headline>

			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<div className="flex flex-col gap-y-4">
					<Input control={control}
						   name="title"
						   label="Titel" />
					<RichTextEditor name="summaryText"
									placeholder="Ein Satz, der die Projektleistung beschreibt."
									label="Kurzreferenz"
									control={control} />
					<RichTextEditor name="text"
									label="Langreferenz"
									control={control} />
				</div>
			</SidebarContent>
			<SidebarFooter>
				{isUpdate && !isDeletion && <Button className="mr-auto"
													onClick={() => setIsDeletion(true)}
													theme="danger">Referenz löschen</Button>}
				{isUpdate && isDeletion && <Button className="mr-auto"
												   theme="none"
												   onClick={() => setIsDeletion(false)}>
					<ChevronLeftIcon className="size-6" /><span>Zurück</span>

				</Button>}

				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				{!isDeletion && <SubmitButton busy={isBusy}
											  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>}
				{isUpdate && isDeletion && <Button theme="danger"
												   onClick={handleDeletion}>
					Referenz endgültig löschen</Button>}
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateOrUpdateReferenceTextForm;
