// Helper function to clean invisible characters and normalize whitespace
import { Extension } from "@tiptap/react";
import type { Node as ProseMirrorNode} from "prosemirror-model";
import { Fragment, Slice } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";

export const cleanInvisibleCharacters = (text: string): string => {
	return text
		// Remove zero-width spaces and other invisible unicode characters
		.replace(/[\u200B-\u200D\uFEFF]/g, "")
		// Remove non-breaking spaces
		.replace(/\u00A0/g, " ")
		/*	// Normalize whitespace (replace multiple spaces with single space)
			.replace(/\s+/g, ' ')*/
		// Remove control characters except newlines and tabs
		// eslint-disable-next-line no-control-regex
		.replace(/[\x00-\x09\x0B-\x1F\x7F]/g, "")
		// Trim whitespace at start and end
		.trim();
};

// Helper function to clean HTML
export const cleanHtml = (html: string): string => {
	// Create a new DOMParser
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, "text/html");

	// Remove unwanted elements (script, style, etc.)
	const unwantedElements = doc.querySelectorAll(
		"script, style, iframe, object, embed, form, input, textarea, button");
	unwantedElements.forEach(element => element.remove());

	// Remove all HTML comments
	const removeComments = (node: Node) => {
		const walker = document.createTreeWalker(
			node,
			NodeFilter.SHOW_COMMENT,
			null,
		);
		const comments: Comment[] = [];
		let comment;
		while ((comment = walker.nextNode() as Comment | null) !== null) {
			comments.push(comment);
		}
		comments.forEach(comment => comment.remove());
	};
	removeComments(doc.body);

	// Clean text content in all elements
	const cleanTextNodes = (node: Node) => {
		if (node.nodeType === Node.TEXT_NODE) {
			node.textContent = cleanInvisibleCharacters(node.textContent || "");
		}
		node.childNodes.forEach(child => cleanTextNodes(child));
	};
	cleanTextNodes(doc.body);

	// Return cleaned HTML
	return doc.body.innerHTML;
};

export const isEmptyContent = (html: string): boolean => {
	// Check for empty string
	if (!html) return true;

	// Check for just whitespace
	if (html.trim() === "") return true;

	// Check for empty paragraph tags (with potential whitespace)
	return !!html.replace(/\s/g, "").match(/^<p>(<\/p>|><\/p>|>\s*<\/p>)$/);
};

export const PasteHandler = Extension.create({
	name: "pasteHandler",

	addProseMirrorPlugins() {
		return [
			new Plugin({
				key: new PluginKey("pasteHandler"),
				props: {
					transformPasted: (slice: Slice) => {
						const cleanNode = (node: ProseMirrorNode): ProseMirrorNode => {
							if (node.isText) {
								return node.type.schema.text(cleanInvisibleCharacters(node.text || ""));
							}

							if (node.content.size > 0) {
								const content: ProseMirrorNode[] = [];
								node.content.forEach((child: ProseMirrorNode) => {
									content.push(cleanNode(child));
								});
								return node.copy(Fragment.from(content));
							}

							return node;
						};

						const content: ProseMirrorNode[] = [];
						slice.content.forEach((node: ProseMirrorNode) => {
							content.push(cleanNode(node));
						});

						return new Slice(
							Fragment.from(content),
							slice.openStart,
							slice.openEnd,
						);
					},
				},
			}),
		];
	},
});

