import GlobalReportsView from "modules/reports/components/GlobalReportsView";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { generatePath, Navigate, useParams, useSearchParams } from "react-router-dom";

import { REPORTS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAvailableReportingYears } from "~/modules/reports/api/availableYears/availableYearsQueries.ts";
import { GlobalReportsViewTabId } from "~/modules/reports/types/globalReportsViewTypes.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const GlobalReportsPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanViewReports);
	if (guard) return guard;
	const { tabId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	const year = searchParams.get("year");

	const { data, options } = useAggregatedDataV2({
		availableYears: useAvailableReportingYears(),
	});

	const { availableYears } = data;

	useEffect(() => {
		if (!year || isNaN(parseInt(year))) {
			setSearchParams({ year: new Date().getFullYear().toString() });
		}
	}, [setSearchParams, year]);

	useEffect(() => {
		if (availableYears && year) {
			if (!availableYears.includes(parseInt(year))) {
				setSearchParams({ year: availableYears[0].toString() });
			}
		}
	}, [availableYears, year, setSearchParams]);

	if (!tabId) return <Navigate to={generatePath(REPORTS_PATH_WITH_TAB_ID, { tabId: GlobalReportsViewTabId.Utilisation })} />;
	if (!year) return null;
	return <GlobalReportsView isLoading={options.isLoading}
							  activeTabId={tabId as GlobalReportsViewTabId}
							  year={year} />;
};

export default GlobalReportsPage;