import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import CompensationSectionLockedMessage
	from "modules/humanResources/components/StaffMemberDetailsView/components/CompensationSectionLockedMessage";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import Avatar from "~/components/Avatar";
import AvatarEditButton from "~/components/AvatarEditButton";
import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import MainContent from "~/components/mainContent/MainContent";
import Section from "~/components/sections/Section";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, HR_STAFF_MEMBERS_INDEX_PATH } from "~/constants/appRoute.ts";
import { useAuth } from "~/contexts/AuthContext";
import useStaffMemberSelectOptions from "~/hooks/form/formOptionsData/useStaffMemberSelectOptions.ts";
import type { Country } from "~/modules/country/api/country/countryTypes.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type {
	EmploymentType,
	EmploymentTypeId,
} from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import DeleteAvatarImageModal
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/DeleteAvatarImageModal";
import StaffMemberAvatarUploadSidebar
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberAvatarUploadSidebar";
import StaffMemberCoreDataSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection";
import StaffMemberDocumentsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberDocumentsSection";
import StaffMemberEmploymentDataSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection";
import StaffMemberPaymentsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection";
import StaffMemberWorkingSchedulesSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberWorkingSchedulesSection";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { useStaffMemberCompensationVisibilityStore } from "~/stores/useStaffMemberCompensationVisibilityStore.ts";
import { PermissionNames } from "~/types/entityNames.ts";

type Props = {
	countries: Country[];
	employmentTypes: EmploymentType[];
	activeTabId: StaffMemberDetailsTabId;
	staffMemberCoreData: StaffMember;
};

const StaffMemberDetailsView: FunctionComponent<Props> = ({
	activeTabId,
	staffMemberCoreData,
}) => {
	const navigate = useNavigate();
	const { hasAnyPermission } = useAuth();
	const userCanUseCompensationSection = hasAnyPermission([PermissionNames.CanManagesStaffSalaries]);

	const allStaffMemberSelectOption = useStaffMemberSelectOptions();
	const {
		isVisible: totalCompensationSectionIsVisible,
		setVisibility: setCompensationSectionVisibility,
	} = useStaffMemberCompensationVisibilityStore((state) => state);

	const [showImageUploadModal, setShowImageUploadModal] = useState<boolean>(false);

	//@ToDo only load and display totalCompensation data if user has permission to see it
	const headerEmploymentAndCareerLevel = useMemo(() => {
		const elements: string[] = [];

		if (staffMemberCoreData.currentEmploymentTypeId) {
			elements.push(getEmploymentTypeDisplayNameById(staffMemberCoreData.currentEmploymentTypeId as EmploymentTypeId));
		}
		if (staffMemberCoreData.currentCareerLevelId) {
			elements.push(getCareerLevelDisplayNameById(staffMemberCoreData.currentCareerLevelId as CareerLevelId));
		}

		if (elements.length === 0) return null;


		return elements.join(" | ");
	}, [staffMemberCoreData]);

	const showCompensationSection = activeTabId === StaffMemberDetailsTabId.Compensation && userCanUseCompensationSection && totalCompensationSectionIsVisible;
	const showCompensationSectionLockScreen = activeTabId === StaffMemberDetailsTabId.Compensation && userCanUseCompensationSection && !totalCompensationSectionIsVisible;
	const showCompensationSectionMissingPermissionWarning = activeTabId === StaffMemberDetailsTabId.Compensation && !userCanUseCompensationSection;
	const [showDeleteAvatarImageModal, setShowDeleteAvatarImageModal] = useState<boolean>(false);
	return <MainContent className="grid h-screen w-full grid-rows-[auto_1fr] overflow-hidden"
						withBottomPadding={false}>
		<div>
			<nav className={clsx("flex bg-white py-4")}
				 aria-label="Breadcrumbs">
				<Section>
					<Breadcrumbs pages={[{path:generatePath(HR_STAFF_MEMBERS_INDEX_PATH), displayName:"HR"},"Mitarbeiter"]} />
				</Section>
			</nav>
			<Section className="bg-white">
				<div className="flex items-center justify-between pb-8">
					<div className="flex items-center gap-x-4">
						<AvatarEditButton onEditClick={() => setShowImageUploadModal(true)}
										  onDeleteClick={staffMemberCoreData.avatarImage ? () => setShowDeleteAvatarImageModal(true): undefined}
										  imageType="avatar">
							<Avatar width="w-12"
									height="h-12"
									firstName={staffMemberCoreData.firstName}
									lastName={staffMemberCoreData.lastName}
									image={staffMemberCoreData.avatarImage}
							/>
						</AvatarEditButton>

						<div className="flex flex-col">
							<h1 className="text-3xl font-bold">{staffMemberCoreData.fullName}</h1>
							{headerEmploymentAndCareerLevel &&
								<div className="text-sm text-gray-500">{headerEmploymentAndCareerLevel}</div>}
						</div>
					</div>

					<div className="ml-auto flex items-center gap-x-5">
						<div>Mitarbeiter auswählen:</div>
						<div className="w-56">
							<ComboBoxPlain
								allowNew={false}
								optionsData={allStaffMemberSelectOption}
								onChange={(staffMemberId) => navigate(generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
									staffMemberId,
									tabId: activeTabId,
								}))}
								value={staffMemberCoreData.id} />
						</div>
					</div>
				</div>
			</Section>
			<Section>
				<SectionHeadingTabs
					tabs={[
						{
							name: "Basisdaten",
							value: StaffMemberDetailsTabId.CoreData,
							active: activeTabId === StaffMemberDetailsTabId.CoreData,
						},
						{
							name: "Beschäftigungsdetails",
							value: StaffMemberDetailsTabId.EmploymentDetails,
							active: activeTabId === StaffMemberDetailsTabId.EmploymentDetails,
						},
						{
							name: "Gehalt, Boni & Benefits",
							value: StaffMemberDetailsTabId.Compensation,
							active: activeTabId === StaffMemberDetailsTabId.Compensation,
							icon: !totalCompensationSectionIsVisible ? <EyeSlashIcon className="size-5 opacity-60" /> :
								<EyeIcon className="size-5" />,
						},
						{
							name: "Dokumente & Sondervereinbarungen",
							value: StaffMemberDetailsTabId.Documents,
							active: activeTabId === StaffMemberDetailsTabId.Documents,
						},
						{
							name: "Arbeitszeitmodell & Urlaubsanspruch",
							value: StaffMemberDetailsTabId.WorkingSchedules,
							active: activeTabId === StaffMemberDetailsTabId.WorkingSchedules,
						},
					]}
					onTabClick={(tabId) => {
						navigate(generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
							staffMemberId: staffMemberCoreData.id,
							tabId,
						}), { replace: true });
					}}
				/>
			</Section>
			<Section>
				<div className="mb-4 border-b border-gray-200" />
			</Section>
		</div>
		{activeTabId === StaffMemberDetailsTabId.CoreData &&
			<StaffMemberCoreDataSection staffMemberCoreData={staffMemberCoreData} />}
		{activeTabId === StaffMemberDetailsTabId.EmploymentDetails &&
			<StaffMemberEmploymentDataSection staffMemberCoreData={staffMemberCoreData} />}

		{showCompensationSection && <StaffMemberPaymentsSection staffMemberCoreData={staffMemberCoreData} />}
		{showCompensationSectionLockScreen &&
			<CompensationSectionLockedMessage setVisibility={setCompensationSectionVisibility} />}
		{showCompensationSectionMissingPermissionWarning && "Missing Permission"}

		{activeTabId === StaffMemberDetailsTabId.Documents &&
			<StaffMemberDocumentsSection staffMemberId={staffMemberCoreData.id} />}
		{activeTabId === StaffMemberDetailsTabId.WorkingSchedules &&
			<StaffMemberWorkingSchedulesSection staffMemberCoreData={staffMemberCoreData} />}
		<StaffMemberAvatarUploadSidebar isOpen={showImageUploadModal}
										originalImage={staffMemberCoreData.avatarImage?.original}
										staffMemberId={staffMemberCoreData.id}
										onClose={() => setShowImageUploadModal(false)} />
		<DeleteAvatarImageModal isOpen={showDeleteAvatarImageModal}
								onCloseClick={() => setShowDeleteAvatarImageModal(false)}
								staffMemberId={staffMemberCoreData.id} />
	</MainContent>;
};

export default StaffMemberDetailsView;