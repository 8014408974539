import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback } from "react";

import UpdateWorkingScheduleForm from "~/components/forms/UpdateWorkingScheduleForm";
import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import {
	STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY,
} from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";

type UpdateWorkingScheduleSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	workingScheduleData?: WorkingSchedule | null;
	workingSchedulesMinDate: Date;
};

const UpdateWorkingScheduleSidebar: React.FunctionComponent<UpdateWorkingScheduleSidebarProps> = ({
	onClose,
	isOpen,
	workingScheduleData,
	workingSchedulesMinDate,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(
		async () => {
			if (workingScheduleData) {
				const staffMemberId = workingScheduleData.staffMemberId;
				queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId) });
				await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId) });
				await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId) });
				await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId) });
				onClose();
			}
		},
		[onClose, queryClient, workingScheduleData],
	);

	const workingScheduleDataWithDate = workingScheduleData ? {
		...workingScheduleData,
		validFrom: new Date(workingScheduleData.validFrom),
	} : null;
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{!!workingScheduleDataWithDate && <UpdateWorkingScheduleForm
				onCancel={onClose}
				onSuccess={handleSuccess}
				workingScheduleData={workingScheduleDataWithDate}
				workingSchedulesMinDate={workingSchedulesMinDate}
			/>}
		</Sidebar>
	);
};

export default UpdateWorkingScheduleSidebar;
