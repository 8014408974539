import Decimal from "decimal.js-light";

import type {
	UtilisationCalculationProperties,
	WorkDayTargetProperties,
} from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import { UtilisationReportDeductiblesProperty } from "~/modules/reports/types/utilistationReportViewTypes.ts";

export const defaultDeductibles: UtilisationReportDeductiblesProperty[] = [
	UtilisationReportDeductiblesProperty.LongTermAbsenceDays,
	UtilisationReportDeductiblesProperty.VacationDays,
	UtilisationReportDeductiblesProperty.TrainingAndEventAbsenceDays,
];

export function calculateWorkDaysTargetFte<T extends WorkDayTargetProperties>(reportData: T,
	deductibles: UtilisationReportDeductiblesProperty[]): Decimal {

	return deductibles.reduce((acc, deductible) => {
		return acc.sub(reportData[deductible]);
	}, new Decimal(reportData.regularWorkDaysFte));
}

export function calculateUtilisationPercentageFromReportData<T extends UtilisationCalculationProperties>(reportData: T,
	deductibles: UtilisationReportDeductiblesProperty[],
	projectOnly: boolean): Decimal {
	let minutes = reportData.minutesTracked;
	if (projectOnly) {
		minutes = reportData.projectMinutesTracked;
	}
	const workDaysTargetFte = calculateWorkDaysTargetFte(reportData, deductibles);

	return calculateUtilisationPercentageFromMinutes(minutes, workDaysTargetFte);
}

export function calculateUtilisationPercentageFromMinutes(minutes: number, workDaysTargetFte: Decimal): Decimal {
	if (workDaysTargetFte.eq(0)) {
		return new Decimal(0);
	}
	const daysTracked = new Decimal(minutes).div(480);

	return daysTracked.div(workDaysTargetFte).mul(100);
}