import { yupResolver } from "@hookform/resolvers/yup";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useProjectTagTypeSelectOptions,
} from "~/modules/project/api/projectTag/hooks/useProjectTagTypeSelectOptions.ts";
import {
	useProjectTagValidationComparisonData,
} from "~/modules/project/api/projectTag/hooks/useProjectTagValidationComparisonData.ts";
import { updateProjectTag } from "~/modules/project/api/projectTag/projectTagApiDispatchers.ts";
import type { ProjectTag, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { getProjectTagTypeDisplayNameById } from "~/modules/project/utils/projectTagTypeUtils.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface UpdateDeliverableTagFormData {
	displayName: string;
	tagTypeId:string
}

type UpdateDeliverableTagFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	projectTagData: ProjectTag;
	tagTypeId: ProjectTagTypeId;
};

const UpdateProjectTagForm: FunctionComponent<UpdateDeliverableTagFormProps> = ({
	onSuccess,
	onCancel,
	projectTagData,
	tagTypeId,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const validationComparisonData = useProjectTagValidationComparisonData(tagTypeId);
	const tagTypeSelectOptions = useProjectTagTypeSelectOptions();
	const schema = useMemo(() => {
		return yup.object({
			displayName: yup.string().required().unique(validationComparisonData, projectTagData.id),
			tagTypeId: yup.string().required()
		});
	}, [projectTagData.id, validationComparisonData]);

	const defaultValues = useMemo(() => {
		return {
			displayName: projectTagData.displayName,
			tagTypeId: projectTagData.tagTypeId,
		};
	}, [projectTagData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<UpdateDeliverableTagFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateDeliverableTagFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
		enableInitially: false,
	});

	const onSubmit = useCallback(async (data: UpdateDeliverableTagFormData) => {
		try {
			setIsBusy(true);
			await updateProjectTag({ id: projectTagData.id, displayName: data.displayName, tagTypeId:data.tagTypeId as ProjectTagTypeId });
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			console.log(apiError);
			setServerErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.");
		}
	}, [projectTagData.id, tagTypeId, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">{getProjectTagTypeDisplayNameById(tagTypeId)} Tag bearbeiten</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}
				<Input control={control}
					   name="displayName"
					   label="Name"
				/>
				<div className="h-4" />
				<Select optionsData={tagTypeSelectOptions}
						name="tagTypeId"
						control={control}
						label="Tag Typ" />
			</SidebarContent>
			<SidebarFooter>
			<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					abbrechen
				</Button>
			</SidebarFooter>
		</form>);
};

export default UpdateProjectTagForm;
