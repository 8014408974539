import clsx from "clsx";
import UtilisationReportSection from "modules/reports/components/GlobalReportsView/components/UtilisationReportSection";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import Breadcrumbs from "~/components/Breadcrumbs/components/Breadcrumbs";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import Headline from "~/components/Headline";
import MainContent from "~/components/mainContent/MainContent";
import Section from "~/components/sections/Section";
import { REPORTS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import DailyRateReportSection from "~/modules/reports/components/GlobalReportsView/components/DailyRateReportSection";
import RevenueReportSection from "~/modules/reports/components/GlobalReportsView/components/RevenueReportSection";
import YearSelect from "~/modules/reports/components/GlobalReportsView/components/YearSelect";
import {
	UTILISATION_REPORT_YEAR_THRESHOLD
} from "~/modules/reports/components/GlobalReportsView/globalReportsConfig.ts";
import useAvailableReportingYearsSelectOptions
	from "~/modules/reports/hooks/useAvailableReportingYearsSelectOptions.ts";
import {
	GlobalReportsViewTabDisplayNames,
	GlobalReportsViewTabId,
} from "~/modules/reports/types/globalReportsViewTypes.ts";

type Props = {
	activeTabId: GlobalReportsViewTabId;
	isLoading: boolean;
	year: string;
};

const GlobalReportsView: FunctionComponent<Props> = ({
	activeTabId,
	isLoading,
	year,
}) => {
	const tabDisplayName = GlobalReportsViewTabDisplayNames[activeTabId];
	const navigate = useNavigate();

	const yearSelectOptions = useAvailableReportingYearsSelectOptions(activeTabId === GlobalReportsViewTabId.Utilisation);

	const handleYearChange = useCallback((year: string) => {
		navigate(generatePath(REPORTS_PATH_WITH_TAB_ID, { tabId: activeTabId }) + "?year=" + year);
	}, [activeTabId, navigate]);

	const selectedYear = activeTabId === GlobalReportsViewTabId.Utilisation && parseInt(year) < UTILISATION_REPORT_YEAR_THRESHOLD ? UTILISATION_REPORT_YEAR_THRESHOLD.toString() : year;

	return <MainContent className="grid h-screen w-full grid-rows-[auto_auto_auto_1fr] overflow-hidden"
						withBottomPadding={false}>
		<nav className={clsx("flex bg-white py-4")}
			 aria-label="Breadcrumbs">
			<Section>
				<Breadcrumbs pages={["Reports", tabDisplayName]} />
			</Section>
		</nav>
		<Section className="bg-white">
			<div className="flex items-center justify-between pb-8">
				<Headline type="h2">Reports: {tabDisplayName}</Headline>
				{!isLoading && <YearSelect disabled={isLoading }
							selectedYear={selectedYear}
							setSelectedYear={handleYearChange}
							yearSelectOptions={yearSelectOptions} />}
			</div>

		</Section>
		<Section>
			<SectionHeadingTabs
				tabs={[
					{
						name: GlobalReportsViewTabDisplayNames.utilisation,
						value: GlobalReportsViewTabId.Utilisation,
						active: activeTabId === GlobalReportsViewTabId.Utilisation,
					},
					{
						name: GlobalReportsViewTabDisplayNames.dailyRate,
						value: GlobalReportsViewTabId.DailyRate,
						active: activeTabId === GlobalReportsViewTabId.DailyRate,
					},
					{
						name: GlobalReportsViewTabDisplayNames.revenue,
						value: GlobalReportsViewTabId.Revenue,
						active: activeTabId === GlobalReportsViewTabId.Revenue,
					},

				]}
				onTabClick={(tabId: string) => navigate(generatePath(REPORTS_PATH_WITH_TAB_ID, { tabId }) + "?year=" + selectedYear)}
			/>
		</Section>
		<div className="mx-auto size-full max-w-7xl overflow-hidden px-2 sm:px-6 lg:px-8">
			{activeTabId === GlobalReportsViewTabId.Utilisation && <UtilisationReportSection year={selectedYear} />}
			{activeTabId === GlobalReportsViewTabId.DailyRate && <DailyRateReportSection year={year} />}
			{activeTabId === GlobalReportsViewTabId.Revenue && <RevenueReportSection year={year} />}
		</div>
	</MainContent>;
};

export default GlobalReportsView;