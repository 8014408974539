import { useMemo } from "react";

import { useStaffMembersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import type { UniqueDateValidatorArrayObject } from "~/utils/form/validationRules.ts";


export const useUsersWorkingSchedulesValidationComparisonData = (staffMemberId: string): UniqueDateValidatorArrayObject[] => {
	const {
		data: workingSchedules,
	} = useStaffMembersWorkingSchedules(staffMemberId);

	return useMemo(() => {
		if (workingSchedules) {
			return workingSchedules.map((workingSchedule) => {
				return {
					id: workingSchedule.id,
					value: new Date(workingSchedule.validFrom),
				};
			});
		}

		return [];
	}, [workingSchedules]);
};