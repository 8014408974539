import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import ContentWrapper from "~/components/ContentWrapper";
import SectionHeadingTabs from "~/components/headings/SectionHeadingTabbar";
import ProjectTimeTrackingsSection
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection";
import StaffUtilisationSection
	from "~/modules/project/components/ProjectDetailsView/components/StaffUtilisationSection";
import type { SectionHeadingTab } from "~/types/tabsTypes.ts";

type ProjectReportingSectionProps = {
	projectId: string;
};
export const TabName = {
	TimeTrackings: "time-trackings",
	Utilization: "utilization",
} as const;

export type TabName = typeof TabName[keyof typeof TabName];

const ProjectReportingSection: FunctionComponent<ProjectReportingSectionProps> = ({ projectId }) => {
	const [activeTabId, setActiveTabId] = useState<TabName>(TabName.Utilization);
	const tabs = useMemo(() => {
		const tabs: SectionHeadingTab[] = [
			{
				name: "Erfassungen",
				value: TabName.TimeTrackings,
				active: activeTabId === TabName.TimeTrackings,
			},
			{
				name: "Auslastung	",
				value: TabName.Utilization,
				active: activeTabId === TabName.Utilization,
			}];
		return tabs;
	}, [activeTabId]);

	return <ContentWrapper className="grid size-full grid-rows-[auto_1fr] overflow-hidden">
		<SectionHeadingTabs tabs={tabs}
							onTabClick={(value) => setActiveTabId(value)} />
		{activeTabId === TabName.TimeTrackings && <ProjectTimeTrackingsSection projectId={projectId} />}
		{activeTabId === TabName.Utilization && <StaffUtilisationSection projectId={projectId} />}

	</ContentWrapper>;
};

export default ProjectReportingSection;