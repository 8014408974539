import { format } from "date-fns";
import Decimal from "decimal.js-light";

import type {
	CreateOrUpdateVacationLedgerEntryDataType
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryTypes.ts";
import { EntryType } from "~/modules/absence/types/vacationLedgerEnums.ts";
import type { VacationLedgerFormData } from "~/modules/absence/types/vacationLedgerFormTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

export function formatVacationDays(vacationDays: Decimal): string {
	return formatNumberWithComma(vacationDays.toNumber(), 2);
}

export const convertVacationLedgerEntryFormDataToApiData = (formData: VacationLedgerFormData): CreateOrUpdateVacationLedgerEntryDataType => {
	const validFromFormatted = format(formData.validFrom, "yyyy-MM-dd");
	let workDays = new Decimal(formData.workDays / 100);
	if (formData.entryTypeId === EntryType.Deduction) {
		workDays = workDays.negated();
	}

	return {
		...formData, validFrom: validFromFormatted, workDays: workDays.toNumber(),
	};
};