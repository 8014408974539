import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCompanyLocations } from "~/modules/client/api/companyLocation/companyLocationApiDispatchers.ts";

const ENTITY_QUERY_KEY = "companyLocation";

export const ALL_COMPANY_LOCATIONS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCompanyLocations = () => useQuery({
    queryKey: ALL_COMPANY_LOCATIONS_QUERY_KEY,
    queryFn: () => getAllCompanyLocations().then(getDataFromResponse),
});