import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback, useState } from "react";

import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteCompanyLogo } from "~/modules/client/api/company/companyApiDispatchers.ts";
import { COMPANY_QUERY_KEY } from "~/modules/client/api/company/companyQueries.ts";

type DeleteStaffingModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	companyId: string;
};

const DeleteCompanyLogoModal: React.FunctionComponent<DeleteStaffingModalProps> = ({
	isOpen,
	onCloseClick,
	companyId,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const handleCloseClick = useCallback(() => {
		setBusy(false);
		setError("");
		onCloseClick();
	}, [onCloseClick]);

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteCompanyLogo(companyId);
			await queryClient.refetchQueries({ queryKey: COMPANY_QUERY_KEY(companyId) });

			handleCloseClick();
		} catch (error) {
			console.error("Delete failed:", error);
			setError("Ein unbekannter Fehler ist aufgetreten.");
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: "Firmenlogo löschen",
		bodyText: `Möchtest Du das Firmenlogo wirklich löschen?`,
		deleteButtonText: `Firmenlogo löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={handleCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteCompanyLogoModal;
