import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getAllProjectRoles,
	getProjectsProjectRoles,
} from "~/modules/project/api/projectRole/projectRoleApiDispatchers.ts";

const ENTITY_QUERY_KEY = "projectRole";

export const ALL_PROJECT_ROLES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const PROJECT_ROLES_BY_PROJECT_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];


export const useAllProjectRoles = () => useQuery({
	queryKey: ALL_PROJECT_ROLES_QUERY_KEY,
	queryFn: () => getAllProjectRoles().then(getDataFromResponse),
});

export const useProjectProjectRoles = (projectId: string) => useQuery({
	queryKey: PROJECT_ROLES_BY_PROJECT_QUERY_KEY(projectId),
	queryFn: () => getProjectsProjectRoles(projectId).then(getDataFromResponse),
	enabled: !!projectId,
});