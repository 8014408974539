import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllUsers } from "~/modules/user/api/user/userApiDispatchers.ts";

const ENTITY_QUERY_KEY = "user";

export const ALL_USERS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllUsers = () => useQuery({
    queryKey: ALL_USERS_QUERY_KEY,
    queryFn: () => getAllUsers().then(getDataFromResponse)
});