import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageQueries.ts";
import type {
	StaffMemberVariableCompensationPercentageWithDate,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageTypes.ts";
import UpdateFtePercentageEntryForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateFtePercentageEntrySidebar.tsx/components/UpdateFtePercentageEntryForm";

type CreateEmploymentSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	ftePercentageData: StaffMemberVariableCompensationPercentageWithDate | null;
};

const UpdateFtePercentageEntrySidebar: FunctionComponent<CreateEmploymentSidebarProps> = ({
	isOpen,
	onClose,
	ftePercentageData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!ftePercentageData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY(ftePercentageData.staffMemberId) });
		onClose();
	}, [ftePercentageData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{ftePercentageData && <UpdateFtePercentageEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				ftePercentageData={ftePercentageData} />}
		</Sidebar>
	);
};

export default UpdateFtePercentageEntrySidebar;
