import ProjectReferenceTextsSearchView from "modules/project/components/ProjectReferenceTextsSearchView";
import type { FC } from "react";
import { useMemo } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries";
import {
	useAllProjectReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import { useAllProjectTags, useProjectTagUsageReport } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type {
	ProjectDescriptionCardData,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";
import { byMultipleObjectProperties, byObjectProperty } from "~/utils/sortFunctions.ts";


const ProjectReferenceTextsSearchPage: FC = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if (guard) return guard;

	const { data, options } = useAggregatedDataV2({
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		clients: useAllClients(),
		projects: useAllProjects(),
		projectTags: useAllProjectTags(),
		projectTagUsageReport: useProjectTagUsageReport(),
		referenceTexts: useAllProjectReferenceTexts(),
	});
	const {
		allCompanyAvatarImages,
		clients,
		projects,
		projectTags,
		projectTagUsageReport,
		referenceTexts,
	} = data;

	const projectData: ProjectDescriptionCardData[] = useMemo(() => {
		if (projects && allCompanyAvatarImages && clients && referenceTexts) {
			return projects.filter(project => project.tags.length > 0).sort(byObjectProperty("title")).map((project) => {
				const client = clients.find((client) => client.id === project.clientId);
				const projectsReferenceTexts = referenceTexts.filter((referenceText) => referenceText.projectId === project.id);
				const avatarImage = allCompanyAvatarImages.find(image => image.clientIds.includes(project.clientId));
				return {
					id: project.id,
					referenceTexts:projectsReferenceTexts,
					companyAvatarImage: avatarImage || null,
					clientDisplayName: client?.displayName || "",
					isInternal: project.isInternal,
					manDaysPlanned: project.manDaysPlanned,
					matchedTags: [],
					projectNumber: project.projectNumber,
					projectTagIds: project.tags.map(tagId => tagId.tagId),
					title: project.title,
					totalMatches: 0,
				};
			});
		}
		return [];
	}, [allCompanyAvatarImages, clients, projects, referenceTexts]);

	const enrichedProjectTags: ProjectTagComboboxOption[] = useMemo(() => {
		if (projectTags && projectTagUsageReport) {
			return projectTags.map(tag => {
				const usages = projectTagUsageReport.find(usage => usage.projectTagId === tag.id)?.projectIds.length || 0;
				return {
					label: tag.displayName,
					tagTypeId: tag.tagTypeId,
					value: tag.id,
					usages,
				};
			}).sort(byMultipleObjectProperties([{ property: "tagTypeId", direction: "asc" }, {
				property: "label",
				direction: "asc",
			}]));
		}
		return [];
	}, [projectTagUsageReport, projectTags]);

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

	return (
		<ProjectReferenceTextsSearchView
			projectData={projectData}
			projectTags={enrichedProjectTags} />
	);
};


export default ProjectReferenceTextsSearchPage;

