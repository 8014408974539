import { TrashIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent, PropsWithChildren } from "react";
import { useState } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Button from "~/components/buttons/Button";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardTitle from "~/components/Card/components/CardTitle";
import CardWrapper from "~/components/Card/components/CardWrapper";
import { appRoutes } from "~/constants/appRoute.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import DeleteProjectModal from "~/modules/project/components/ProjectDetailsView/components/DeleteProjectModal";
import UpdateProjectSidebar from "~/modules/project/components/ProjectDetailsView/components/UpdateProjectSidebar";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	companyDisplayName: string;
	clientDisplayName: string;
	clientId: string;
	endDate?: string;
	projectData: Project;
	projectHasStaffings: boolean;
	projectLead: string;
	startDate?: string;
};
const ProjectOverviewDetail: FunctionComponent<{ label: string } & PropsWithChildren> = ({ children, label }) => {
	return <div className="grid grid-cols-3 gap-4 py-4">
		<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
		<dd className="col-span-2 text-sm leading-6 text-gray-700">{children}</dd>
	</div>;
};

const ProjectDetailsSection: FunctionComponent<Props> = ({
	companyDisplayName,
	clientDisplayName,
	clientId,
	endDate,
	projectData,
	projectHasStaffings,
	projectLead,
	startDate,
}) => {
	const [showUpdateProjectSidebar, setShowUpdateProjectSidebar] = useState<boolean>(false);
	const [showDeleteProjectSidebar, setShowDeleteProjectSidebar] = useState<boolean>(false);
	return <>
		<CardWrapper hasTitle={true}>
			<CardHeader>
				<CardTitle>Basisdaten</CardTitle>
				<div className="flex gap-x-2">
					<Button size="auto"
							theme="plain"
							className="px-2 py-1 text-xs"
							onClick={() => setShowUpdateProjectSidebar(true)}>Bearbeiten</Button>
					{!projectHasStaffings && <Button size="auto"
													 theme="danger"
													 className="px-2 py-1 text-xs"
													 onClick={() => setShowDeleteProjectSidebar(true)}><TrashIcon className="size-3" />Projekt
						löschen</Button>}
				</div>
			</CardHeader>
			<CardContent hasTitle={true}
						 padding="none">
				<dl className="divide-y divide-gray-100 px-5">
					<ProjectOverviewDetail label="Projekttitel">
						{projectData.title}
					</ProjectOverviewDetail>
					<ProjectOverviewDetail label="Projektnummer">
						{projectData.projectNumber}
					</ProjectOverviewDetail>
					<ProjectOverviewDetail label="Kunde">
						<NavLink
							to={generatePath(appRoutes.client("").path, { clientId })}
							className="flex flex-col transition-colors duration-200 hover:text-secondary-500"
						>
							<div>{companyDisplayName}</div>
							<div className="text-xs italic">{clientDisplayName}</div>
						</NavLink>
					</ProjectOverviewDetail>
					<ProjectOverviewDetail label="Timing">
						{!startDate && !endDate && "n/a"}
						{startDate && <span>{formatDateWithGermanMonth(new Date(startDate), false)} - </span>}
						{endDate && <span>{formatDateWithGermanMonth(new Date(endDate), false)}</span>}
					</ProjectOverviewDetail>
					<ProjectOverviewDetail label="Ansprechpartner">
						{projectLead}
					</ProjectOverviewDetail>
				</dl>
			</CardContent>
		</CardWrapper>
		<UpdateProjectSidebar
			isOpen={showUpdateProjectSidebar}
			setOpen={setShowUpdateProjectSidebar}
			projectId={projectData.id}
		/>
		<DeleteProjectModal
			isOpen={showDeleteProjectSidebar}
			projectId={projectData.id}
			projectTitle={projectData.title}
			onCloseClick={() => setShowDeleteProjectSidebar(false)}
		/>
	</>;
};

export default ProjectDetailsSection;