import Button from "~/components/buttons/Button";
import Headline from "~/components/Headline/Headline.tsx";
import Timeline from "~/components/Timeline/Timeline.tsx";
import type { Order } from "~/modules/project/api/order/orderTypes.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import type { Staffing } from "~/modules/project/api/staffing/staffingTypes.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";

import OrderDetails from "../OrderDetails";

interface OrdersProps {
	allUsers: User[];
	clientId: string;
	onNewOrderClick: () => void;
	phasesOrders: Order[];
	projectPhaseId: string;
	projectRoles: ProjectRole[];
	staffings: Staffing[];
	showBudget: boolean;
}

const Orders: React.FC<OrdersProps> = ({
	allUsers,
	clientId,
	onNewOrderClick,
	phasesOrders,
	projectRoles,
	staffings,
	showBudget,
}) => {
	return (
		<>
			<div className="flex items-center justify-between">
				<Headline type="h3">Bestellungen & Staffing</Headline>
				<Button size="sm" theme="secondary" onClick={onNewOrderClick}>Neue Bestellung</Button>
			</div>

			<Timeline>
				{phasesOrders.map((order) => {
					const orderStaffing = staffings.filter((s) => order.id === s.orderId);
					return (
						<OrderDetails
							allUsers={allUsers}
							clientId={clientId}
							key={order.id}
							orderData={order}
							projectRoles={projectRoles}
							showBudget={showBudget}
							staffings={orderStaffing}
							totalOrders={phasesOrders.length}
						/>
					);
				})}
			</Timeline>
		</>
	);
};

export default Orders;
