import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getUsersVacationLedgerReport,
	indexUsersVacationLedgerEntries,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryApiDispatchers.ts";

export const VACATION_LEDGER_ENTRY_BASE_QUERY_KEY = "vacationLedgerEntries";

export const STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY = (staffMemberId: string) => [VACATION_LEDGER_ENTRY_BASE_QUERY_KEY, { staffMemberId }];
export const STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY = (staffMemberId: string) => ["vacationLedgerReport", { staffMemberId }];
export const useStaffMembersVacationLedgerEntries = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId),
	queryFn: () => indexUsersVacationLedgerEntries({ staffMemberId }).then(getDataFromResponse),
	enabled: !!staffMemberId,
});


export const useStaffMembersVacationLedgerReport = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId),
	queryFn: () => getUsersVacationLedgerReport({ staffMemberId }).then(getDataFromResponse),
	enabled: !!staffMemberId,
});