import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { generatePath, NavLink } from "react-router-dom";

import Card from "~/components/Card";
import LoadingSpinnerFull from "~/components/LoadingSpinners/LoadingSpinnerFull";
import { HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID } from "~/constants/appRoute.ts";
import {
	useAllStaffMembers,
	useStaffMembersMentees,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = { staffMemberId: string }

const MenteesSection: FunctionComponent<Props> = ({ staffMemberId }) => {
	const {
		data: allStaffMembers,
		isLoading: staffMembersIsLoading,
	} = useAllStaffMembers();

	const {
		data: menteesIds,
		isLoading: menteesIsLoading,
	} = useStaffMembersMentees(staffMemberId);

	const menteesList = useMemo(() => {
		if (menteesIds && menteesIds.length > 0 && allStaffMembers) {
			const mentees: StaffMember[] = [];
			menteesIds.forEach((menteeId) => {
				const staffMember = allStaffMembers.find((staffMember) => staffMember.id === menteeId);
				if (staffMember) {
					mentees.push(staffMember);
				}
			});

			return <ul>{mentees.sort(byObjectProperty("firstName")).map((staffMember) => {
				const linkPath = generatePath(HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID, {
					staffMemberId: staffMember.id,
					tabId: StaffMemberDetailsTabId.EmploymentDetails,
				});
				return <li key={staffMember.id}>
					<NavLink to={linkPath}
							 className="hover:text-danger-500">{staffMember.fullName || ""}</NavLink></li>;
			})}</ul>;
		}
		return "Keine";
	}, [menteesIds, allStaffMembers]);

	const showLoader = staffMembersIsLoading || menteesIsLoading;
	const showMentees = !showLoader;

	return <div className="col-span-2">
		<Card title="Mentees"
			  height="full"
			  padding="none">
			{showLoader && <LoadingSpinnerFull theme="neutral" />}
			<div className="px-4 py-3">
				{showMentees && (menteesList ? menteesList : "Keine")}
			</div>
		</Card>
	</div>;
};

export default MenteesSection;