import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";
import { isAfter, isSameDay } from "date-fns";
import CreateFtePercentageEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/CreateFtePercentageEntrySidebar";
import CreateTotalCompensationEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/CreateTotalCompensationEntrySidebar";
import CreateVariableCompensationPercentageEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/CreateVariableCompensationPercentageEntrySidebar";
import DeleteFtePercentageEntryModal
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/DeleteFtePercentageEntryModal";
import DeleteTotalCompensationModal
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/DeleteTotalCompensationModal";
import DeleteVariableCompensationPercentageEntryModal
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/DeleteVariableCompensationPercentageEntryModal";
import TableTimelineItem
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/TableTimelineItem";
import UpdateFtePercentageEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateFtePercentageEntrySidebar.tsx";
import UpdateTotalCompensationEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateTotalCompensationEntrySidebar";
import UpdateVariableCompensationPercentageEntrySidebar
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateVariableCompensationPercentageEntrySidebar";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import Card from "~/components/Card";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import {
	useStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import type {
	StaffMemberEmploymentWithDate,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";
import {
	useStaffMembersFtePercentages,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageQueries.ts";
import type {
	StaffMemberFtePercentageWithDate,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageTypes.ts";
import { useStaffMembersTotalCompensations } from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationQueries.ts";
import type {
	StaffMemberTotalCompensationWithDate,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffTotalCompensationTypes.ts";
import {
	useStaffMembersVariableCompensationPercentages,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageQueries.ts";
import type {
	StaffMemberVariableCompensationPercentageWithDate,
} from "~/modules/humanResources/api/staffMemberVariableCompensationPercentage/staffMemberVariableCompensationPercentageTypes.ts";
import TableTimelineHeaderCell
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/TableTimelineHeaderCell";
import TableEmptyMessage from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableEmptyMessage";
import TableRowLoadingSpinner
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/TableRowLoadingSpinner";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

type Props = { staffMemberId: string };

type TimelineEventType = "fte" | "variableCompensation" | "compensation" | "left";

type TimelineEventDataType = StaffMemberFtePercentageWithDate
	| StaffMemberVariableCompensationPercentageWithDate
	| StaffMemberTotalCompensationWithDate
	| StaffMemberEmploymentWithDate

type TimelineEvent<T extends TimelineEventDataType> = {
	type: TimelineEventType;
	data: {
		validFrom: Date;
		value: number;
	}
	originalData: T
}

type TimelineElementData = {
	formattedValue: string;
	originalData: TimelineEventDataType;
}

type TimelineRowData = {
	date: Date;
	isLeftEntry: boolean;
	totalCompensation: TimelineElementData | null;
	fte: TimelineElementData | null;
	variableCompensation: TimelineElementData | null;
	baseSalary: string | null;
};

function getTimelineEventValidAtDate<TimelineEvent extends {
	type: TimelineEventType,
	data: { validFrom: Date }
}>(entries: TimelineEvent[],
	date: Date, type: TimelineEventType): TimelineEvent | undefined {
	if (entries.length === 0) {
		return undefined;
	}
	let validEntry: TimelineEvent | undefined = undefined;

	entries.forEach((entry) => {
		if (type === entry.type && !isAfter(entry.data.validFrom, date)) {
			validEntry = entry;
		}
	});

	return validEntry;
}

const EmptyTableCell = () => <td className="pr-2 text-right text-gray-500">--</td>;

const CompensationComponentsOverview: FunctionComponent<Props> = ({ staffMemberId }) => {
	const [showCreateTotalCompensationEntrySidebar, setShowCreateTotalCompensationEntrySidebar] = useState(false);
	const [totalCompensationEntryDataToDelete, setTotalCompensationEntryDataToDelete] = useState<StaffMemberTotalCompensationWithDate | null>(null);
	const [totalCompensationEntryDataToUpdate, setTotalCompensationEntryDataToUpdate] = useState<StaffMemberTotalCompensationWithDate | null>(null);
	const [showCreateFteEntrySidebar, setShowCreateFteEntrySidebar] = useState(false);
	const [fteEntryDataToDelete, setFteEntryDataToDelete] = useState<StaffMemberFtePercentageWithDate | null>(null);
	const [fteEntryDataToUpdate, setFteEntryDataToUpdate] = useState<StaffMemberFtePercentageWithDate | null>(null);
	const [showCreateVariableCompensationEntrySidebar, setShowCreateVariableCompensationEntrySidebar] = useState(false);
	const [variableCompensationEntryDataToDelete, setVariableCompensationEntryDataToDelete] = useState<StaffMemberVariableCompensationPercentageWithDate | null>(null);
	const [variableCompensationEntryDataToUpdate, setVariableCompensationEntryDataToUpdate] = useState<StaffMemberVariableCompensationPercentageWithDate | null>(null);

	const {
		data: employmentsData,
		isLoading: employmentsIsLoading,
	} = useStaffMembersEmployments(staffMemberId);

	const {
		data: totalCompensationsData,
		isLoading: totalCompensationsIsLoading,
	} = useStaffMembersTotalCompensations(staffMemberId);

	const {
		data: variableCompensationPercentagesData,
		isLoading: variableCompensationPercentagesIsLoading,
	} = useStaffMembersVariableCompensationPercentages(staffMemberId);

	const {
		data: ftePercentagesData,
		isLoading: ftePercentagesIsLoading,
	} = useStaffMembersFtePercentages(staffMemberId);

	const dataIsLoading = employmentsIsLoading || totalCompensationsIsLoading || variableCompensationPercentagesIsLoading || ftePercentagesIsLoading;

	const timelineElements = useMemo(() => {
		if (!(employmentsIsLoading || totalCompensationsIsLoading || variableCompensationPercentagesIsLoading || ftePercentagesIsLoading) && (employmentsData &&
			totalCompensationsData &&
			variableCompensationPercentagesData &&
			ftePercentagesData)) {

			const leaveEvents: TimelineEvent<StaffMemberEmploymentWithDate>[] = employmentsData
				.filter(employment => employment.employmentTypeId === EmploymentTypeId.Left)
				.map(employment => ({
					type: "left",
					data: { value: 0, validFrom: new Date(employment.validFrom) },
					originalData: { ...employment, validFrom: new Date(employment.validFrom) },
				}));

			const ftePercentageEvents: TimelineEvent<StaffMemberFtePercentageWithDate>[] = ftePercentagesData
				.map(fte => ({
					type: "fte",
					data: { value: fte.percentage, validFrom: new Date(fte.validFrom) },
					originalData: { ...fte, validFrom: new Date(fte.validFrom) },
				}));

			const variableCompensationPercentageEvents: TimelineEvent<StaffMemberVariableCompensationPercentageWithDate>[] = variableCompensationPercentagesData
				.map(variableCompensation => ({
					type: "variableCompensation",
					data: { value: variableCompensation.percentage, validFrom: new Date(variableCompensation.validFrom) },
					originalData: { ...variableCompensation, validFrom: new Date(variableCompensation.validFrom) },
				}));

			const totalCompensationEvents: TimelineEvent<StaffMemberTotalCompensationWithDate>[] = totalCompensationsData
				.map(compensation => ({
					type: "compensation",
					data: { value: compensation.totalCompensationCents, validFrom: new Date(compensation.validFrom) },
					originalData: { ...compensation, validFrom: new Date(compensation.validFrom) },
				}));

			const entriesSortedDesc = [...ftePercentageEvents, ...variableCompensationPercentageEvents, ...totalCompensationEvents, ...leaveEvents].sort((a,
				b) => b.data.validFrom.getTime() - a.data.validFrom.getTime());

			const entriesSortedAsc = [...entriesSortedDesc].reverse();

			const timelineRowsData: TimelineRowData[] = [];

			entriesSortedDesc.forEach((entry) => {
				if (timelineRowsData.find((row) => isSameDay(row.date, entry.data.validFrom))) {
					return;
				}

				const timelineRowData: TimelineRowData = {
					date: entry.data.validFrom,
					isLeftEntry: entry.type === "left",
					totalCompensation: null,
					fte: null,
					variableCompensation: null,
					baseSalary: "n/a",
				};

				const currentlyValidTotalCompensation = getTimelineEventValidAtDate(entriesSortedAsc, entry.data.validFrom, "compensation");
				const currentlyValidFte = getTimelineEventValidAtDate(entriesSortedAsc, entry.data.validFrom, "fte");
				const currentlyValidVariableCompensation = getTimelineEventValidAtDate(entriesSortedAsc, entry.data.validFrom, "variableCompensation");

				if (currentlyValidTotalCompensation !== undefined && currentlyValidFte !== undefined && currentlyValidVariableCompensation !== undefined) {
					const totalCompensationCents = currentlyValidTotalCompensation.data.value * (currentlyValidFte.data.value / 10000);
					const variableCompensation = totalCompensationCents * (currentlyValidVariableCompensation.data.value / 10000);
					timelineRowData.baseSalary = formatCentsToCurrency(totalCompensationCents - variableCompensation);
				}
				if (currentlyValidTotalCompensation !== undefined && isSameDay(currentlyValidTotalCompensation.data.validFrom, entry.data.validFrom)) {
					timelineRowData.totalCompensation = {
						formattedValue: formatCentsToCurrency(currentlyValidTotalCompensation.data.value),
						originalData: currentlyValidTotalCompensation.originalData,
					};
				}

				if (currentlyValidFte !== undefined && isSameDay(currentlyValidFte.data.validFrom, entry.data.validFrom)) {
					timelineRowData.fte = {
						formattedValue: formatNumberWithComma(currentlyValidFte.data.value / 100, 2) + " %",
						originalData: currentlyValidFte.originalData,
					};
				}

				if (currentlyValidVariableCompensation !== undefined && isSameDay(currentlyValidVariableCompensation.data.validFrom, entry.data.validFrom)) {
					timelineRowData.variableCompensation = {
						formattedValue: formatNumberWithComma(currentlyValidVariableCompensation.data.value / 100, 2) + " %",
						originalData: currentlyValidVariableCompensation.originalData,
					};
				}

				timelineRowsData.push(timelineRowData);
			});

			return timelineRowsData.map((row, index) => {
				const nextElement = timelineRowsData[index + 1];
				const nextElementIsLeftEntry = nextElement?.isLeftEntry;
				const isStartItem = !nextElement || nextElementIsLeftEntry;


				let dotTheme: "success" | "danger" | "neutral" = "neutral";
				if (row.isLeftEntry) {
					dotTheme = "danger";
				} else if (isStartItem) {
					dotTheme = "success";
				}

				const totalCompensationCell = row.totalCompensation ? (
					<td>
						<div className="flex items-center justify-end">
							<span>{row.totalCompensation.formattedValue}</span>
							<ButtonWithPopover theme="dark"
											   items={
												   [
													   {
														   label: "Bearbeiten",
														   onClick: () => setTotalCompensationEntryDataToUpdate(row.totalCompensation!.originalData as StaffMemberTotalCompensationWithDate),
														   icon: PencilIcon,
													   },
													   {
														   label: "Löschen",
														   onClick: () => setTotalCompensationEntryDataToDelete(row.totalCompensation!.originalData as StaffMemberTotalCompensationWithDate),
														   icon: TrashIcon,
													   },

												   ]
											   } />
						</div>
					</td>
				) : <td />;

				const fteCell = row.fte ? (
					<td>
						<div className="flex items-center justify-end">
							<span>{row.fte.formattedValue}</span>
							<ButtonWithPopover theme="dark"
											   items={
												   [
													   {
														   label: "Bearbeiten",
														   onClick: () => setFteEntryDataToUpdate(row.fte!.originalData as StaffMemberFtePercentageWithDate),
														   icon: PencilIcon,
													   },
													   {
														   label: "Löschen",
														   onClick: () => setFteEntryDataToDelete(row.fte!.originalData as StaffMemberFtePercentageWithDate),
														   icon: TrashIcon,
													   },

												   ]
											   } />
						</div>
					</td>
				) : <td />;

				const variableCompensationCell = row.variableCompensation ? (
					<td>
						<div className="flex items-center justify-end">
							<span>{row.variableCompensation.formattedValue}</span>
							<ButtonWithPopover theme="dark"
											   items={
												   [
													   {
														   label: "Bearbeiten",
														   onClick: () => setVariableCompensationEntryDataToUpdate(row.variableCompensation!.originalData as StaffMemberVariableCompensationPercentageWithDate),
														   icon: PencilIcon,
													   },
													   {
														   label: "Löschen",
														   onClick: () => setVariableCompensationEntryDataToDelete(row.variableCompensation!.originalData as StaffMemberVariableCompensationPercentageWithDate),
														   icon: TrashIcon,
													   },

												   ]
											   } />
						</div>
					</td>
				) : <td />;

				return <TableTimelineItem key={index}
										  dotTheme={dotTheme}
										  isOdd={index % 2 === 0}
										  isStartItem={isStartItem}
										  isStopItem={row.isLeftEntry || index === 0}
										  date={row.date}>
					{row.isLeftEntry ? <td className="py-3 text-center text-danger-500"
										   colSpan={100}>Ausgeschieden</td> :
						<>
							{row.totalCompensation ? totalCompensationCell : <EmptyTableCell />}
							{row.fte ? fteCell : <EmptyTableCell />}
							{row.variableCompensation ? variableCompensationCell : <EmptyTableCell />}
							<td className="pr-4 text-right"> {row.baseSalary}</td>
						</>}

				</TableTimelineItem>;
			});
		}
		return [];
	}, [employmentsIsLoading, totalCompensationsIsLoading, variableCompensationPercentagesIsLoading, ftePercentagesIsLoading, employmentsData, totalCompensationsData, variableCompensationPercentagesData, ftePercentagesData]);

	return <><Card padding="none">
		<table className="w-full border-collapse">
			<thead className="border-b">
			<tr>
				<th className="px-2 py-4 text-sm font-normal">Gültig ab</th>
				<TableTimelineHeaderCell onAddItemClick={() => setShowCreateTotalCompensationEntrySidebar(true)}
										 title="Total compensation" />
				<TableTimelineHeaderCell title="FTE Prozentsatz"
										 onAddItemClick={() => setShowCreateFteEntrySidebar(true)} />
				<TableTimelineHeaderCell title="Variabler Gehaltsanteil"
										 onAddItemClick={() => setShowCreateVariableCompensationEntrySidebar(true)} />
				<TableTimelineHeaderCell title="Grundgehalt" />
			</tr>
			</thead>
			<tbody>
			{dataIsLoading ? <TableRowLoadingSpinner /> :
				timelineElements.length > 0 ? timelineElements : <TableEmptyMessage />}
			</tbody>
		</table>
	</Card>
		<CreateTotalCompensationEntrySidebar isOpen={showCreateTotalCompensationEntrySidebar}
											 onClose={() => setShowCreateTotalCompensationEntrySidebar(false)}
											 staffMemberId={staffMemberId} />

		<UpdateTotalCompensationEntrySidebar isOpen={!!totalCompensationEntryDataToUpdate}
											 onClose={() => setTotalCompensationEntryDataToUpdate(null)}
											 totalCompensationEntryData={totalCompensationEntryDataToUpdate} />

		<DeleteTotalCompensationModal isOpen={!!totalCompensationEntryDataToDelete}
									  onCloseClick={() => setTotalCompensationEntryDataToDelete(null)}
									  totalCompensationData={totalCompensationEntryDataToDelete}
		/>

		<CreateFtePercentageEntrySidebar isOpen={showCreateFteEntrySidebar}
										 onClose={() => setShowCreateFteEntrySidebar(false)}
										 staffMemberId={staffMemberId} />

		<UpdateFtePercentageEntrySidebar isOpen={!!fteEntryDataToUpdate}
										 onClose={() => setFteEntryDataToUpdate(null)}
										 ftePercentageData={fteEntryDataToUpdate} />

		<DeleteFtePercentageEntryModal isOpen={!!fteEntryDataToDelete}
									   onCloseClick={() => setFteEntryDataToDelete(null)}
									   ftePercentage={fteEntryDataToDelete}
		/>

		<CreateVariableCompensationPercentageEntrySidebar isOpen={showCreateVariableCompensationEntrySidebar}
														  onClose={() => setShowCreateVariableCompensationEntrySidebar(false)}
														  staffMemberId={staffMemberId} />

		<UpdateVariableCompensationPercentageEntrySidebar isOpen={!!variableCompensationEntryDataToUpdate}
														  onClose={() => setVariableCompensationEntryDataToUpdate(null)}
														  variableCompensationPercentageData={variableCompensationEntryDataToUpdate} />

		<DeleteVariableCompensationPercentageEntryModal isOpen={!!variableCompensationEntryDataToDelete}
														onCloseClick={() => setVariableCompensationEntryDataToDelete(null)}
														variableCompensationPercentageData={variableCompensationEntryDataToDelete}
		/>
	</>;
};

export default CompensationComponentsOverview;