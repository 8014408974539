import { format } from "date-fns";
import { de } from "date-fns/locale";
import ExcelJS from "exceljs";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";

type TableData = {
	label: string,
	value: string
};

function DownloadYearlyRevenueReportExcelButton({ data, type, year }: {
	data: TableData[],
	type: "project" | "client",
	year: number
}) {
	const generateExcel = async () => {
		const typeDisplayName = type === "project" ? "Projekt" : "Kunde";
		const workBookTitle = "Umsatz nach " + typeDisplayName + " " + format(new Date(year, 0), "yyyy", { locale: de });
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(workBookTitle);

		const columnDefs = [
			{ accessor: "label", header: typeDisplayName, type: "string" },
			{ accessor: "value", header: "Umsatz", type: "currency" },
		];

		// Add headers
		const headers = columnDefs.map(col => col.header);
		const headerRow = worksheet.addRow(headers);

		// Style header row
		headerRow.font = { bold: true };
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
		headerRow.height = 45;

		// Add data and apply formatting
		data.forEach((row) => {
			const excelRow = worksheet.addRow(
				columnDefs.map(col => {
					const value = row[col.accessor as keyof TableData];

					if (value === null) return null;

					if (col.accessor === "value") {
						return parseFloat(value) / 100;
					}
					return value;
				}),
			);

			// Apply cell formatting
			columnDefs.forEach((col, colIndex) => {
				const cell = excelRow.getCell(colIndex + 1);

				switch (col.type) {
					case "currency":
						cell.numFmt = "#,##0.00 \"€\"";
						break;
				}

				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;
			// @ts-expect-error - `eachCell` is not in the type definitions
			column.eachCell({ includeEmpty: true }, (cell) => {
				const cellLength = cell.value ? cell.value.toString().length : 10;
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});
			column.width = Math.min(maxLength + 2, 30);
		});

		// Freeze top row
		worksheet.views = [
			{ state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2", activeCell: "A2" },
		];

		// Set German locale for the workbook
		workbook.views = [
			{
				x: 0, y: 0, width: 10000, height: 20000,
				firstSheet: 0, activeTab: 1, visibility: "visible",
			},
		];
		workbook.calcProperties.fullCalcOnLoad = true;

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = workBookTitle + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton
		theme="white"
		buttonText=""
		onClick={generateExcel}
	/>;
}

export default DownloadYearlyRevenueReportExcelButton;