import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { KeyboardEvent } from "react";
import { forwardRef } from "react";

import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";

type SearchInputProps = FormInputBaseVariants & {
	value: string;
	onChange: (value: string) => void;
	onKeydown?:(event:KeyboardEvent<HTMLInputElement>) => void;
	placeholder?: string;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
	{ inputSize, onChange, onKeydown=()=>{}, placeholder, theme, value },
	ref,
) {
	return (
		<div className="relative focus-within:z-10">
			<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
				<MagnifyingGlassIcon className="size-5 text-gray-400"
									 aria-hidden="true" />
			</div>

			<input
				ref={ref}
				type="text"
				name="searchInput"
				className={clsx(formInputBaseVariants({ inputSize, theme }), "pl-10")}
				placeholder={undefined === placeholder ? "Suche ..." : placeholder}
				value={value}
				autoComplete={"off"}
				onKeyDown={onKeydown}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			/>
		</div>
	);
});
export default SearchInput;
