export const getAllProjectRolesEndpoint = "project-roles";

export function getProjectsProjectRolesEndpoint(projectId: string) {
	return `/projects/${projectId}/project-roles`;
}

export function addProjectRoleToProjectEndpoint(projectId: string) {
	return `/projects/${projectId}/project-roles`;
}

export function removeProjectRoleFromProjectEndpoint(projectId: string, projectRoleId: string) {
	return `/projects/${projectId}/project-roles/${projectRoleId}`;
}

