import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersBonuses,
} from "~/modules/humanResources/api/staffMemberBonus/staffMemberBonusApiDispatchers.ts";

export const STAFF_MEMBER_BONUS_BASE_QUERY_KEY = "staffMembersBonus";

export const STAFF_MEMBERS_BONUSES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_BONUS_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersBonuses = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBERS_BONUSES_QUERY_KEY(staffMemberId),
	queryFn: () => indexStaffMembersBonuses(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});