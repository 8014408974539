import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import ProjectTag from "~/components/projectTags/ProjectTag";
import { useProjectTagsByType } from "~/modules/project/api/projectTag/hooks/useProjectTagsByType.ts";
import type { ProjectTagPivot, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import UpdateProjectTagsSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectTaggingContent/components/ProjectTags/components/UpdateProjectTagsSidebar";
import {
	getProjectTagTypeDisplayNameById,
	getProjectTagTypeThemeById,
} from "~/modules/project/utils/projectTagTypeUtils.ts";

type Props = { productTagIds: ProjectTagPivot[], projectId: string, tagTypeId: ProjectTagTypeId };

const ProjectTags: FunctionComponent<Props> = ({ productTagIds, projectId, tagTypeId }) => {
	const [showUpdateSidebar, setShowUpdateSidebar] = useState<boolean>(false);
	const allTags = useProjectTagsByType(tagTypeId);

	const tagPills = useMemo(() => {
		if (productTagIds && allTags) {
			return productTagIds.map((productTagId) => {
				const productTag = allTags.find((tag) => tag.id === productTagId.tagId);
				if (productTag) {
					return <ProjectTag key={productTag.id}
									   displayName={productTag.displayName}
									   theme={getProjectTagTypeThemeById(tagTypeId)} />;
				}

			}).filter(Boolean);
		}
		return [];
	}, [allTags, productTagIds, tagTypeId]);

	return <>
		<Card title={getProjectTagTypeDisplayNameById(tagTypeId)}
			  onHeaderButtonClick={() => setShowUpdateSidebar(!showUpdateSidebar)}
			  headerButtonText="Bearbeiten">
			<div className="flex cursor-pointer flex-wrap gap-2" onClick={()=>setShowUpdateSidebar(true)}>
				{/*{tagPills?.length === 0 && <span className="text-xs text-gray-400">Keine Tags zugewiesen</span>}*/}
				{tagPills}
			</div>
		</Card>
		<UpdateProjectTagsSidebar
			isOpen={showUpdateSidebar}
			onClose={() => setShowUpdateSidebar(false)}
			projectTagIds={productTagIds}
			projectId={projectId}
			tagTypeId={tagTypeId}
		/>
	</>;
};


export default ProjectTags;