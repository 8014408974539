import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/buttons/Button";
import { format, isSameDay, startOfDay } from "date-fns";
import type Decimal from "decimal.js-light";
import { t } from "i18next";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { createAbsence } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";
import { AbsenceTypeId } from "~/modules/absence/api/absence/absenceTypes.ts";
import { LONG_TERM_ABSENCE_TYPE_IDS } from "~/modules/absence/api/config/absenceConfig.ts";
import type { WorkingSchedule } from "~/modules/absence/api/workingSchedule/workingScheduleTypes.ts";
import CreateOrUpdateAbsenceFormSection
	from "~/modules/absence/components/formSections/CreateOrUpdateAbsenceFormSection";
import type { FormInputOption } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

interface CreateAbsenceFormData {
	absenceTypeId: string;
	dayCheck?: string;
	startDate: Date;
	endDate: Date;
	comment: string | null;
	firstDayIsHalf: boolean;
	lastDayIsHalf: boolean;
}

type CreateAbsenceFormProps = {
	absenceTypeSelectOptions: FormInputOption[];
	remainingVacationDays: Decimal;
	onSuccess: () => void;
	onCancel: () => void;
	workingSchedules: WorkingSchedule[];
	staffMemberId: string,
	userCanManageLongTermAbsences: boolean;
};

const CreateAbsenceForm: FunctionComponent<CreateAbsenceFormProps> = ({
	absenceTypeSelectOptions,
	onSuccess,
	onCancel,
	remainingVacationDays,
	workingSchedules,
	staffMemberId,
	userCanManageLongTermAbsences,
}) => {
	const [busy, setBusy] = useState(false);
	const [overlapError, setOverlapError] = useState(false);
	const [showLongTermAbsenceWarning, setShowLongTermAbsenceWarning] = useState<boolean>(false);
	const schema = useMemo(() => {
		return yup.object({
			absenceTypeId: yup.string().required(),
			startDate: yup.date().required(),
			endDate: yup
				.date()
				.nullable()
				.required()
				.min(yup.ref("startDate"), "Das Startdatum muss vor dem Enddatum liegen."),
			comment: yup.string().nullable().default(""),
			firstDayIsHalf: yup.boolean().default(false),
			lastDayIsHalf: yup.boolean().default(false),
			dayCheck: yup.string().test({
				name: "not-both-half-days",
				message: "Es können nicht beide Tage halbe Tage sein, wenn Start- und Enddatum gleich sind",
				test: function(_, context) {
					return !(isSameDay(context.parent.startDate, context.parent.endDate) && context.parent.firstDayIsHalf && context.parent.lastDayIsHalf);

				},
			}),
		});
	}, []);
	const defaultValues = useMemo(() => {
		return {
			absenceTypeId: absenceTypeSelectOptions[0].value,
			startDate: startOfDay(new Date()),
			endDate: startOfDay(new Date()),
			comment: "Urlaub",
			firstDayIsHalf: false,
			lastDayIsHalf: false,
		};
	}, [absenceTypeSelectOptions]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid, errors },
		watch,
		trigger,
	} = useForm<CreateAbsenceFormData>({
		mode: "onChange",
		defaultValues: defaultValues,
		resolver: yupResolver<CreateAbsenceFormData>(schema),
	});
	const startDate = watch("startDate");
	const endDate = watch("endDate");
	const absenceTypeId = watch("absenceTypeId");

	useEffect(() => {
		if (LONG_TERM_ABSENCE_TYPE_IDS.includes(absenceTypeId as AbsenceTypeId) && !userCanManageLongTermAbsences) {
			setShowLongTermAbsenceWarning(true);
		} else {
			setShowLongTermAbsenceWarning(false);
		}
	}, [absenceTypeId, userCanManageLongTermAbsences]);


	useEffect(() => {
		trigger();
		setOverlapError(false);
	}, [startDate, endDate, trigger]);

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: busy,
	});

	const onSubmit = useCallback(async (data: CreateAbsenceFormData) => {
		const startDateFormatted = format(data.startDate, "yyyy-MM-dd");
		const endDateFormatted = format(data.endDate, "yyyy-MM-dd");

		try {
			setBusy(true);
			await createAbsence({
				staffMemberId,
				data: { ...data, startDate: startDateFormatted, endDate: endDateFormatted },
			});
			onSuccess();
		} catch (error) {
			const apiError = handleApiError(error);
			if (apiError.type === ApiResponseErrorType.VALIDATION) {
				if (apiError.messages.endDate.find((message) => message.rule === "absencesOverlapRule")) {
					setOverlapError(true);
					setBusy(false);
				}
			}
		}
	}, [onSuccess, staffMemberId]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Neue Abwesenheit erstellen</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<CreateOrUpdateAbsenceFormSection
					absenceTypeSelectOptions={absenceTypeSelectOptions}
					dayCheckErrorMessage={errors.dayCheck?.message}
					control={control}
					overlapError={overlapError}
					remainingVacationDays={remainingVacationDays}
					showLongTermAbsenceWarning={showLongTermAbsenceWarning}
					showSicknessGuideline={absenceTypeId === AbsenceTypeId.Sick}
					watch={watch}
					workingSchedules={workingSchedules} />
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable || showLongTermAbsenceWarning}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>);
};

export default CreateAbsenceForm;
