import { useMemo } from "react";

import { useClientsInvoiceRecipients } from "~/modules/billing/api/invoiceRecipient/invoiceRecipientQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { mapDataToInputOptionsData } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";


const useClientsInvoiceRecipientSelectOptions = (clientId: string): FormInputOption[] => {
	const { data: clientsInvoiceRecipientsData } = useClientsInvoiceRecipients(clientId);
	return useMemo(() => {
		if (clientsInvoiceRecipientsData && clientId) {
			const sortedContactsData = clientsInvoiceRecipientsData.sort(byObjectProperty("displayName"));
			return mapDataToInputOptionsData(sortedContactsData, "id", "displayName");
		}
		return [];
	}, [clientsInvoiceRecipientsData, clientId]);
};

export default useClientsInvoiceRecipientSelectOptions;

