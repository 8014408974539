import { t } from "i18next";

import type { ProjectTagVariantsProps } from "~/components/projectTags/ProjectTag/projectTagVariants.ts";
import { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import type { WorkingStatusNames } from "~/types/entityNames.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";

export function getProjectTagTypeDisplayNameById(tagTypeId: ProjectTagTypeId): WorkingStatusNames {

	return t(normalizeTKey("entities:projectTagTypes." + tagTypeId));
}

export function getProjectTagTypeThemeById(tagTypeId: ProjectTagTypeId): ProjectTagVariantsProps["theme"] {

	switch (tagTypeId) {
		case ProjectTagTypeId.Products:
			return "primary"
		case ProjectTagTypeId.SubProducts:
			return "primary-light";
		default:
			return "default";
	}
}

export function isCuratedTagType(tagTypeId: ProjectTagTypeId): boolean {
	return tagTypeId === ProjectTagTypeId.Products || tagTypeId === ProjectTagTypeId.SubProducts;
}
