import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import { deleteAbsence } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";
import {
	ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY,
	STAFF_MEMBERS_ABSENCES_QUERY_KEY,
} from "~/modules/absence/api/absence/absenceQueries.ts";
import type { Absence } from "~/modules/absence/api/absence/absenceTypes.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { formatDateRange } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	absenceData: Absence & { typeName: string } | null;
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteAbsenceModal: React.FunctionComponent<Props> = ({
	absenceData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const { t } = useTranslation();
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!absenceData) return;
		const staffMemberId = absenceData.staffMemberId;
		try {
			setBusy(true);
			await deleteAbsence({ staffMemberId, absenceId: absenceData.id });
			queryClient.removeQueries({ queryKey: [ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY] });
			queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId) });

			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId) });

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Die Abwesenheit kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Abwesenheit löschen`,
		bodyText: `Möchtest Du diese Abwesenheit wirklich löschen?`,
		deleteButtonText: `Abwesenheit löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!absenceData && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>Typ</Label>
					<span className="text-base font-bold">{t(normalizeTKey(`entities:absenceType.${absenceData.typeName}`))}</span>
				</div>
				<div>
					<Label>Zeitraum</Label>
					<span className="text-base font-bold">{formatDateRange(new Date(absenceData.startDate), new Date(absenceData.endDate))}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteAbsenceModal;