import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback, useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { deleteProjectTag } from "~/modules/project/api/projectTag/projectTagApiDispatchers.ts";
import { ALL_PROJECT_TAGS_QUERY_KEY } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTag, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { getProjectTagTypeDisplayNameById } from "~/modules/project/utils/projectTagTypeUtils.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";

type DeleteProductTagModalProps = {
	isOpen: boolean;
	onClose: () => void;
	projectTagData: ProjectTag | null;
	setSelectedTagId: DispatchStateAction<string | null>
	tagTypeId: ProjectTagTypeId;
};

const DeleteProjectTagModal: React.FunctionComponent<DeleteProductTagModalProps> = ({
	isOpen,
	onClose,
	projectTagData,
	setSelectedTagId,
	tagTypeId,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	const handleClose = useCallback(() => {
		setBusy(false);
		setError("");
		onClose();
	}, [onClose]);

	const handleConfirmationClick = async () => {
		if (!projectTagData) return;
		try {
			setBusy(true);
			await deleteProjectTag({ id: projectTagData.id, tagTypeId });
			await queryClient.refetchQueries({ queryKey: ALL_PROJECT_TAGS_QUERY_KEY });
			setSelectedTagId(null);
			handleClose();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectDeliverableTagId.find(
						(validationError) => validationError.rule === "projectDeliverableTagIsDeletableRule",
					)
				) {
					setError("Der Tag kann nicht gelöscht werden, da er noch Projekten zugeordnet ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};
	const tagDisplayName = getProjectTagTypeDisplayNameById(tagTypeId);
	const texts: DeleteEntityTextElements = {
		headerText: `${tagDisplayName} Tag "${projectTagData?.displayName}" löschen`,
		bodyText: `Möchtest Du den Tag wirklich löschen?`,
		deleteButtonText: `${tagDisplayName} Tag löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={handleClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteProjectTagModal;
