import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexAllProjectReferenceTexts,
	indexProjectsReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsApiDispatchers.ts";

const REFERENCE_TEXTS_BASE_QUERY_KEY = "referenceTexts";

export const ALL_PROJECT_REFERENCES_QUERY_KEY = [REFERENCE_TEXTS_BASE_QUERY_KEY, "all"];
export const PROJECTS_REFERENCE_TEXTS_QUERY_KEY = (projectId: string) => [REFERENCE_TEXTS_BASE_QUERY_KEY, { projectId }];
export const useAllProjectReferenceTexts = () => useQuery({
	queryKey: ALL_PROJECT_REFERENCES_QUERY_KEY,
	queryFn: () => indexAllProjectReferenceTexts().then(getDataFromResponse),
});

export const useProjectsReferenceTexts = (projectId: string) => useQuery({
	queryKey: PROJECTS_REFERENCE_TEXTS_QUERY_KEY(projectId),
	queryFn: () => indexProjectsReferenceTexts(projectId).then(getDataFromResponse),
});