import type { RefObject } from "react";
import { useCallback, useRef, useState } from "react";

interface UseCopyStyledOptions {
	/** Duration in milliseconds before the copied state resets */
	timeout?: number;
}

interface UseCopyStyledReturn {
	/** Function to trigger the copy operation */
	copyToClipboard: () => Promise<boolean>;
	/** Whether the content was successfully copied */
	copied: boolean;
	/** Ref to attach to the element containing the content to copy */
	targetRef: RefObject<HTMLDivElement>;
}

export const useCopyStyled = (options: UseCopyStyledOptions = {}): UseCopyStyledReturn => {
	const { timeout = 2000 } = options;
	const [copied, setCopied] = useState(false);
	const targetRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout>();

	const copyToClipboard = useCallback(async (): Promise<boolean> => {
		if (!targetRef.current) return false;

		try {
			const element = targetRef.current;
			const htmlContent = element.innerHTML;
			const plainText = element.innerText;

			const clipboardData = new ClipboardItem({
				'text/html': new Blob([htmlContent], { type: 'text/html' }),
				'text/plain': new Blob([plainText], { type: 'text/plain' })
			});

			await navigator.clipboard.write([clipboardData]);
			setCopied(true);

			// Clear any existing timeout
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			// Set new timeout
			timeoutRef.current = setTimeout(() => {
				setCopied(false);
			}, timeout);

			return true;
		} catch (err) {
			console.error('Copy failed:', err);
			return false;
		}
	}, [timeout]);

	return { copyToClipboard, copied, targetRef };
};