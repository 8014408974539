import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import Button from "components/buttons/Button";
import type { FunctionComponent, KeyboardEvent } from "react";
import { Fragment, useCallback } from "react";

import SearchInput from "~/components/formElements/SearchInput";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import { WorkingStatus } from "~/components/Status";
import useWorkingStatusOptions from "~/hooks/form/formOptionsData/useWorkingStatusOptions.ts";
import type { DispatchStateAction } from "~/types/hookTypes.ts";

interface SearchAndFilterBarProps {
	myProjectsFilter: boolean;
	onChangeMyProjectsFilter: (state: any) => void;
	onChangeSearch: (state: any) => void;
	onChangeStatus: (state: any) => void;
	searchValue: string | undefined;
	setSearchValues: DispatchStateAction<string[]>;
	setSortOrder: (state: "asc" | "desc") => void;
	sortOrder: string;
	statusFilter: string[];
}

const SearchAndFilterBar: FunctionComponent<SearchAndFilterBarProps> = ({
	myProjectsFilter,
	onChangeMyProjectsFilter,
	onChangeSearch,
	onChangeStatus,
	searchValue,
	setSortOrder,
	setSearchValues,
	sortOrder,
	statusFilter,
}) => {
	const workingStatusOptions = useWorkingStatusOptions();
	const handleSearchInputKeydown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			const value = (event.target as HTMLInputElement).value;
			if (value) {
				setSearchValues(prevState => {
					if(prevState.includes(value)) return prevState;
					return [value.trim(),...prevState];
				});
				onChangeSearch("");
			}
		}
	}, [onChangeSearch, setSearchValues]);


	return (
		<section aria-labelledby="filter-heading"
				 className="ml-4 flex w-full grow">
			<div className="w-full">
				<div className="mx-auto flex max-w-7xl items-center gap-x-5">
					<Menu as="div"
						  className="relative inline-block text-left">
						<SearchInput onChange={onChangeSearch}
									 onKeydown={handleSearchInputKeydown}
									 value={searchValue || ""} />
					</Menu>

					<div className="flex items-center">
						<SwitchPlain checked={myProjectsFilter}
									 onChange={onChangeMyProjectsFilter}
									 labelOn="Nur meine Projekte"
									 labelOff="Alle Projekte" />
					</div>
					<Button
						className="ml-auto"
						theme="white"
						onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
					>
						{sortOrder === "desc" ? (
							<>
								<span>Neueste zuerst</span>
								<ChevronDownIcon className="size-5 text-gray-400" />
							</>
						) : (
							<>
								<span>Älteste zuerst</span>
								<ChevronUpIcon className="size-5 text-gray-400" />

							</>
						)}
					</Button>
					<div className="flow-root">
						<Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">

							<Popover className="relative inline-block px-4 text-left">
								<Popover.Button
									className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
									<span>Status Filter</span>
									{statusFilter.length > 0 && (
										<span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
														{statusFilter.length}
													</span>
									)}
									<ChevronDownIcon
										className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true"
									/>
								</Popover.Button>

								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Popover.Panel
										className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black/5 focus:outline-none">
										<form className="space-y-4">
											{workingStatusOptions.map((option, index) => (
												<div key={option.value}
													 className="flex items-center">
													<input
														id={`statusFilter-${index}`}
														name={`statusFilter[]`}
														defaultValue={option.value}
														type="checkbox"
														defaultChecked={statusFilter.includes(option.value)}
														className="size-4 cursor-pointer rounded border-gray-300 text-accent-600 accent-accent-500 focus:ring-accent-500"
														onChange={() => onChangeStatus(option.value)}
													/>
													<label
														htmlFor={`statusFilter-${index}`}
														className="ml-3 cursor-pointer whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
													>
														<WorkingStatus id={option.value} />
													</label>
												</div>
											))}
										</form>
									</Popover.Panel>
								</Transition>
							</Popover>
						</Popover.Group>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SearchAndFilterBar;
