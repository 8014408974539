import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useMemo, useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { useAuth } from "~/contexts/AuthContext";
import { deleteStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	useProjectsStaffings,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";

type DeleteStaffingModalProps = {
	isOpen: boolean;
	onCloseClick: () => void;
	projectId: string;
	staffingId: string | null;
};

const DeleteStaffingModal: React.FunctionComponent<DeleteStaffingModalProps> = ({
	isOpen,
	onCloseClick,
	projectId,
	staffingId,
}) => {
	const { user } = useAuth();
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();

	const { data: projectsStaffingsData } = useProjectsStaffings(projectId);
	const { data: allUsersData } = useAllUsers();

	const userName = useMemo(() => {
		if (projectsStaffingsData && allUsersData && staffingId) {
			const staffingData = projectsStaffingsData?.find((staffing) => staffing.id === staffingId);

			if (staffingData) {
				console.log();
				return allUsersData?.find((user) => user.id === staffingData.userId)?.fullName;
			}
		}
		return null;
	}, [projectsStaffingsData, allUsersData, staffingId]);

	const handleConfirmationClick = async () => {
		if (!staffingId) return;
		try {
			setBusy(true);
			await deleteStaffing({ staffingId, projectId });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
			if (user && userName === user?.fullName) {
				queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user.id) });
			}

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.staffingId.find(
						(validationError) => validationError.rule === "staffingIsDeletableRule",
					)
				) {
					setError("Das Staffing kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Staffing "${userName}" löschen`,
		bodyText: `Möchtest Du das Staffing wirklich löschen?`,
		deleteButtonText: `Staffing löschen`,
	};

	return (
		<DeleteEntityView
			isOpen={isOpen}
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteStaffingModal;
