import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	ProjectTag,
	ProjectTagTypeId,
	ProjectTagUsageReport,
} from "~/modules/project/api/projectTag/projectTagTypes.ts";


const basePath = "project-tags";

const tagTypeBasePath = (tagTypeId: string) => `${basePath}/${tagTypeId}`;

export function indexProjectTags(): AxiosApiResponsePromise<ProjectTag[]> {
	return axiosApi.get(basePath);
}

export function getProjectTagsUsageReport(): AxiosApiResponsePromise<ProjectTagUsageReport[]> {
	return axiosApi.get(`${basePath}/usage-report`);
}

export function createProjectTag({ displayName, tagTypeId }: {
	displayName: string,
	tagTypeId: ProjectTagTypeId
}): AxiosApiResponsePromise<ProjectTag> {
	return axiosApi.post(tagTypeBasePath(tagTypeId), { displayName });
}

export function updateProjectTag({ id, displayName, tagTypeId }: {
	id: string,
	displayName: string,
	tagTypeId: ProjectTagTypeId
}): AxiosApiResponsePromise<ProjectTag> {
	return axiosApi.put(`${tagTypeBasePath(tagTypeId)}/${id}`, { displayName });
}

export function deleteProjectTag({ id, tagTypeId }: { id: string, tagTypeId: string }): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${tagTypeBasePath(tagTypeId)}/${id}`);
}

export function mergeProjectTags({ tagId, tagIdsToMerge, tagTypeId }: {
	tagId: string,
	tagIdsToMerge: string[];
	tagTypeId: string;
}): AxiosApiResponsePromise<ProjectTag> {
	return axiosApi.post(`${tagTypeBasePath(tagTypeId)}/${tagId}`, { tagIdsToMerge });
}