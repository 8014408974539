import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedData } from "~/hooks/useAggregatedData.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import {
	useStaffMembersVacationEntitlements,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import {
	useStaffMembersVacationLedgerReport,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { useStaffMembersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleQueries.ts";
import WorkingSchedulesView from "~/modules/absence/components/WorkingSchedulesView";
import { useAllSettings } from "~/modules/settings/api/settings/settingsQueries.ts";
import type { Setting } from "~/modules/settings/api/settings/settingsTypes.ts";
import { SettingKey } from "~/modules/settings/api/settings/settingsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { PermissionNames } from "~/types/entityNames.ts";

const WorkingSchedulesPage: React.FunctionComponent = () => {
	const viewGuard = userViewGuard(null, PermissionNames.CanManageAbsences, "staffMember");
	if (viewGuard) return viewGuard;

	const staffMemberId = useParams().staffMemberId;

	const { data, options } = useAggregatedData([
		{
			hook: () => useStaffMembersWorkingSchedules(staffMemberId || ""),
			name: "workingSchedules",
		},
		{
			hook: () => useStaffMembersVacationEntitlements(staffMemberId || ""),
			name: "vacationEntitlements",
		},
		{
			hook: () => useStaffMembersVacationLedgerReport(staffMemberId || ""),
			name: "vacationLedgerReport",
		},
		{
			hook: () => useAllSettings(),
			name: "allSettings",
		},
	]);
	const {
		allSettings,
		workingSchedules,
		vacationEntitlements,
		vacationLedgerReport,
	} = useMemo(() => data, [data]);

	if (options.loadingPcent < 100) return <LoadingPage pcent={options.loadingPcent} />;

	const workingSchedulesMinDateSetting = allSettings.find((setting: Setting) => setting.key === SettingKey.EmploymentEntitiesMinDate);
	const workingSchedulesMinDate = workingSchedulesMinDateSetting ? new Date(workingSchedulesMinDateSetting.value) : new Date();

	if (!staffMemberId) return null;

	return <WorkingSchedulesView
		staffMemberId={staffMemberId}
		vacationEntitlements={vacationEntitlements}
		vacationLedgerReport={vacationLedgerReport}
		workingSchedules={workingSchedules}
		workingSchedulesMinDate={workingSchedulesMinDate}
	/>;
};

export default WorkingSchedulesPage;