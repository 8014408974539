import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getYearlyDailyRateReport } from "~/modules/reports/api/dailyRateReport/dailyRateReportApiDispatchers.ts";

export const DAILY_RATE_REPORT_BASE_BATH = "daily-rate-report";

export const YEARLY_DAILY_RATE_REPORT_QUERY_KEY = (year: string) => [DAILY_RATE_REPORT_BASE_BATH, year];

export const useYearlyDailyRateReport = (year:string) => useQuery({
    queryKey: YEARLY_DAILY_RATE_REPORT_QUERY_KEY(year),
    queryFn: () => getYearlyDailyRateReport(year).then(getDataFromResponse),
	enabled: !!year,
});