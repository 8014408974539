import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";

import type { CareerLevel } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { DailyRateReportCareerLevelBreakdown } from "~/modules/reports/api/dailyRateReport/dailyRateReportTypes.ts";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
);


type Props = {
	data: DailyRateReportCareerLevelBreakdown[];
	careerLevels: CareerLevel[];
	setSelectedCareerLevelId: (careerLevelId: string) => void;
	selectedCareerLevelId: string; // New prop for the selected career level
};

const DailyRateByCareerLevelBarChart: FunctionComponent<Props> = ({
	data,
	careerLevels,
	setSelectedCareerLevelId,
	selectedCareerLevelId
}) => {
	const chartRef = useRef<ChartJS>(null);
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	const sortedData = data.sort((a, b) => parseFloat(b.averageDailyRateCents) - parseFloat(a.averageDailyRateCents));
	const labels = sortedData.map(item => {
		const careerLevel = careerLevels.find(cl => cl.id === item.careerLevelId);
		if (item.careerLevelId === CareerLevelId.ManagingDirector) {
			return "Partner";
		}
		return careerLevel ? careerLevel.displayName : item.careerLevelId;
	});

	const getBarColor = (index: number) => {
		if (sortedData[index].careerLevelId === selectedCareerLevelId) {
			return "rgba(67, 152, 238)"; // Highlight color (pink)
		}
		if (index === hoveredIndex) {
			return "rgb(43,80,145)"; // Hover color (teal)
		}
		return "rgb(30,53,96)"; // Default color (dark blue)
	};

	const chartData = {
		labels,
		datasets: [
			{
				type: "bar" as const,
				label: "Average Daily Rate",
				data: sortedData.map(item => parseFloat(item.averageDailyRateCents) / 100),
				backgroundColor: sortedData.map((_, index) => getBarColor(index)),
				borderColor: "rgb(30,53,96)",
				borderWidth: 0,
			},
		],
	};

	const options = {
		responsive: true,
		animation: false,
		maintainAspectRatio: false,
		scales: {
			x: {
				ticks: {
					font: (context: any) => {
						const index = context.index;
						return {
							weight: sortedData[index].careerLevelId === selectedCareerLevelId ? 'bold' : 'normal'
						};
					}
				}
			},
			y: {
				beginAtZero: true,
				title: {
					display: false,
					text: "Average Daily Rate (€)",
				},
				ticks: {
					callback: (value: number) => `${value.toFixed(2)} €`,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				text: "Daily Rate by Career Level",
			},
			tooltip: {
				enabled: false,
				callbacks: {
					label: (context: any) => `${context.parsed.y.toFixed(2)} €`,
				},
			},
			datalabels: {
				color: "white",
				font: {
					weight: "bold",
				},
				anchor: "center",
				align: "center",
				formatter: (value: number) => `€ ${value.toFixed(2)}`,
			},
		},
		onHover: (event: any, elements: any[]) => {
			if (elements.length > 0) {
				const index = elements[0].index;
				setHoveredIndex(index);
				event.native.target.style.cursor = "pointer";
			} else {
				setHoveredIndex(null);
				event.native.target.style.cursor = "default";
			}
		},
	};

	useEffect(() => {
		const chart = chartRef.current;

		if (chart) {
			chart.canvas.onclick = (evt) => {
				const points = chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);

				if (points.length) {
					const firstPoint = points[0];
					const index = firstPoint.index;
					setSelectedCareerLevelId(sortedData[index].careerLevelId);
				}
			};
		}
	}, [sortedData, setSelectedCareerLevelId]);

	return (
		<Chart type="bar"
			   ref={chartRef}
			   data={chartData}
			   options={options as any} />
	);
};

export default DailyRateByCareerLevelBarChart;