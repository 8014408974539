import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersVacationEntitlements,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementApiDispatchers.ts";

const VACATION_ENTITLEMENT_BASE_QUERY_KEY = "vacationEntitlements";

export const STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY = (staffMemberId: string) => [VACATION_ENTITLEMENT_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersVacationEntitlements = (staffMemberId:string) => useQuery({
	queryKey: STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY(staffMemberId),
	queryFn: () => indexStaffMembersVacationEntitlements(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});
