import { DocumentTextIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import ContentWrapper from "~/components/ContentWrapper";
import {
	useProjectsReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";
import CreateReferenceTextSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/CreateReferenceTextSidebar";
import NoReferencesExistMessage
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/NoReferencesExistMessage";
import UpdateReferenceTextSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/UpdateReferenceTextSidebar";
import ProjectReferenceTextCard from "~/modules/project/components/ProjectReferenceTextCard";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type ProjectReferenceTextsContentProps = {
	projectId: string;
};

const ProjectReferenceTextsContent: FunctionComponent<ProjectReferenceTextsContentProps> = ({ projectId }) => {
	const [selectedReferenceTextId, setSelectedReferenceTextId] = useState<string | null>(null);
	const [showCreateSidebar, setShowCreateSidebar] = useState<boolean>(false);
	const [referenceTextToUpdate, setReferenceTextToUpdate] = useState<ProjectReferenceText | null>(null);
	const { data: projectReferenceTexts, isLoading } = useProjectsReferenceTexts(projectId);

	useEffect(() => {
		if (!selectedReferenceTextId && projectReferenceTexts) {
			setSelectedReferenceTextId(projectReferenceTexts[0]?.id);
		}
	}, [projectReferenceTexts, selectedReferenceTextId]);

	const referenceTextCards = projectReferenceTexts?.sort(byObjectProperty("title")).map((referenceText) => {
		return <button key={referenceText.id}
					   onClick={() => setSelectedReferenceTextId(referenceText.id)}
					   className={clsx(
						   "rounded-md px-3 py-2 text-sm shadow",
						   "font-semibold",
						   "flex items-center",
						   selectedReferenceTextId === referenceText.id ? "bg-primary-700 text-white" : "cursor-pointer bg-white hover:text-secondary-600",
					   )}>
			<DocumentTextIcon className="mr-1 size-4 shrink-0" />
			<div className="grow-0 truncate">{referenceText.title}</div>
		</button>;
	});
	const selectedReferenceText = projectReferenceTexts?.find((referenceText) => referenceText.id === selectedReferenceTextId);
	return <ContentWrapper className="grid size-full grid-cols-[20%_1fr] gap-x-8 overflow-hidden pb-10 pt-4">
		{isLoading && <div className="col-span-2 size-full"><LoadingPage /></div>}
		{!isLoading && projectReferenceTexts && projectReferenceTexts.length === 0 &&
			<NoReferencesExistMessage onCreateReferenceClick={() => setShowCreateSidebar(true)} />}
		{!isLoading && projectReferenceTexts && projectReferenceTexts?.length > 0 && <>
			<div className="flex flex-col gap-y-2">
				{referenceTextCards}
				<ButtonNewItem theme="inline"
							   size="sm"
							   onClick={() => setShowCreateSidebar(true)}>Neue Referenz</ButtonNewItem>
			</div>
			<div className="max-h-full overflow-hidden">
				{selectedReferenceText && <ProjectReferenceTextCard referenceText={selectedReferenceText}
																	onEditClick={() => setReferenceTextToUpdate(selectedReferenceText)} />}
			</div>
		</>}

		<CreateReferenceTextSidebar isOpen={showCreateSidebar}
									projectId={projectId}
									close={() => setShowCreateSidebar(false)} />
		<UpdateReferenceTextSidebar referenceText={referenceTextToUpdate}
									isOpen={!!referenceTextToUpdate}
									close={() => setReferenceTextToUpdate(null)} />
	</ContentWrapper>;
};

export default ProjectReferenceTextsContent;