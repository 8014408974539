import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type { InputHTMLAttributes, MouseEvent } from "react";
import { forwardRef, useCallback, useState } from "react";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";

import FormInput from "~/components/formElements/FormInput";
import type { FormInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import { formInputBaseVariants } from "~/components/formElements/formInputBaseVariant.ts";
import Label from "~/components/formElements/Label";
import useAutoFocus from "~/hooks/useAutofocus.ts";


type SharedProps = InputHTMLAttributes<HTMLInputElement> & FormInputBaseVariants & {
	autoFocus?: boolean;
	className?: string;
	error?: string;
	errorIconOnly?: boolean;
	label?: string;
	name: string;
	placeholder?: string;
	type?: string;
}

type InputPlainProps = SharedProps;


export const InputPlain = forwardRef<HTMLInputElement, InputPlainProps>(
	({
		autoFocus,
		disabled,
		className,
		error,
		inputSize,
		label,
		name,
		onBlur,
		onChange,
		placeholder,
		theme,
		type = "text",
		value,
	}, ref) => {
		const [selectedType, setSelectedType] = useState(type);
		const togglePasswordVisibility = useCallback((event: MouseEvent) => {
			event.preventDefault();
			setSelectedType(state => state === "password" ? "text" : "password");
		}, []);

		const autoFocusRef = useAutoFocus(autoFocus === true, true, ref);
		if (type === "number" && value === 0) {
			value = "0";
		}
		return (
			<FormInput>
				{label && <Label htmlFor={name}>{label}</Label>}
				<div className="relative rounded-md shadow-sm">
					<input
						className={clsx(
							formInputBaseVariants({ disabled, inputSize, theme }),
							className,
						)}
						disabled={disabled}
						value={value || ""}
						name={name}
						type={selectedType}
						id={name}
						placeholder={placeholder}
						ref={autoFocusRef}
						onChange={onChange}
						onBlur={onBlur}
						autoComplete="off"
					/>
					{type === "password" && <button
						className="absolute right-0 top-0 mr-2 flex h-full items-center"
						onClick={togglePasswordVisibility}
					>
						{selectedType === "password" ? <EyeSlashIcon className="size-5 fill-gray-500" /> :
							<EyeIcon className="size-5 fill-gray-600" />}
					</button>}
					{error && (
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
							<ExclamationCircleIcon className="size-5 text-red-500"
												   aria-hidden="true" />
						</div>
					)}
				</div>
				{error && (
					<p className="mt-2 text-sm text-red-600"
					   id="email-error">
						{error}
					</p>
				)}
			</FormInput>
		);
	});

InputPlain.displayName = "InputPlain";

type InputProps = SharedProps & {
	name: string;
	control: Control<any>;
	defaultValue?: number;
	useControllerError?: boolean;
	rules?: any;
}

const Input: React.FC<InputProps> = ({
	name,
	control,
	useControllerError = true,
	error,
	rules,
	...rest
}) => {
	const {
		field,
		fieldState: { error: errorFromRHF },
	} = useController({
		name,
		control,
		rules,
	});

	const errorMessage = useControllerError ? errorFromRHF?.message : error;
	return <InputPlain {...field} {...rest} error={errorMessage} />;
};


export default Input;
