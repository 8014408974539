import type { FunctionComponent } from "react";

import ContentWrapper from "~/components/ContentWrapper";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import ProjectTags
	from "~/modules/project/components/ProjectDetailsView/components/ProjectTaggingContent/components/ProjectTags";

type ProjectTagsSectionProps = {
	projectData: Project;
};

const ProjectTaggingContent: FunctionComponent<ProjectTagsSectionProps> = ({ projectData }) => {
	return <ContentWrapper className="grid w-full grid-cols-3 gap-x-8 pb-10 pt-4">
		<div className="flex flex-col gap-y-4">
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.Products} />
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.SubProducts} />
		</div>
		<div className="flex flex-col gap-y-4">
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.MethodsAndFrameworks} />
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.Apps} />
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.Partners} />

		</div>
		<div className="flex flex-col gap-y-4">
			<ProjectTags productTagIds={projectData.tags}
						 projectId={projectData.id}
						 tagTypeId={ProjectTagTypeId.ProjectKeyDeliverables} />
		</div>

	</ContentWrapper>
};

export default ProjectTaggingContent;