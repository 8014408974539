import { useMemo } from "react";

import { useAvailableReportingYears } from "~/modules/reports/api/availableYears/availableYearsQueries.ts";
import {
	UTILISATION_REPORT_YEAR_THRESHOLD,
} from "~/modules/reports/components/GlobalReportsView/globalReportsConfig.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { sortArray } from "~/utils/sortFunctions.ts";


const useAvailableReportingYearsSelectOptions = (isUtilisationReport = false): FormInputOption[] => {
	const { data: yearsData } = useAvailableReportingYears();
	return useMemo(() => {
		if (yearsData) {
			const filteredYearsData = isUtilisationReport ? yearsData.filter((year) => year >= UTILISATION_REPORT_YEAR_THRESHOLD) : yearsData;

			return sortArray(filteredYearsData, "desc").map((year) => {
				const yearString = year.toString();
				return createInputOptionData(yearString, yearString);
			});

		}
		return [];
	}, [yearsData, isUtilisationReport]);
};

export default useAvailableReportingYearsSelectOptions;

