export type ProjectTag = {
	id: string;
	displayName: string;
	tagTypeId: ProjectTagTypeId;
	createdAt: string;
	updatedAt: string;
}

export type ProjectTagPivot = {
	tagId: string;
	tagTypeId: ProjectTagTypeId;
}

export const ProjectTagTypeId = {
	Products: "1",
	SubProducts: "2",
	MethodsAndFrameworks: "3",
	Apps: "4",
	Partners: "5",
	ProjectKeyDeliverables: "6",
} as const;

export type ProjectTagTypeId = typeof ProjectTagTypeId[keyof typeof ProjectTagTypeId];

export type ProjectTagUsageReport = {
	projectTagId: string;
	projectIds: string[];
};