import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import Label from "~/components/formElements/Label";
import {
	deleteStaffMemberTotalCompensation,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationApiDispatchers.ts";
import {
	STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationQueries.ts";
import type {
	StaffMemberTotalCompensationWithDate,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffTotalCompensationTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	totalCompensationData: StaffMemberTotalCompensationWithDate | null
	onCloseClick: () => void;
	isOpen: boolean;
};

const DeleteTotalCompensationModal: React.FunctionComponent<Props> = ({
	totalCompensationData,
	onCloseClick,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();


	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		if (!totalCompensationData) return;
		try {
			setBusy(true);
			await deleteStaffMemberTotalCompensation(totalCompensationData.staffMemberId, totalCompensationData.id);
			await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY(totalCompensationData.staffMemberId) });

			onCloseClick();
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.projectId.find(
						(validationError) => validationError.rule === "absenceIsDeletableOrUpdatableRule",
					)
				) {
					setError("Der Eintrag kann nicht gelöscht werden.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: "Total Compensation: Eintrag löschen",
		bodyText: "Möchtest Du diesen Eintrag wirklich löschen?",
		deleteButtonText: "Eintrag löschen",
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			onCloseClick={onCloseClick}
			isOpen={isOpen}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		>
			{!!totalCompensationData && <div className="mb-4 flex flex-col gap-y-2">
				<div>
					<Label>Total compensation Betrag</Label>
					<span className="text-base">{formatCentsToCurrency(totalCompensationData.totalCompensationCents)}</span>
				</div>
				<div>
					<Label>Gültig ab</Label>
					<span className="text-base">{formatDateWithGermanMonth(totalCompensationData.validFrom)}</span>
				</div>
			</div>}

		</DeleteEntityView>
	);
};

export default DeleteTotalCompensationModal;
