import StaffTeamOverviewView from "modules/humanResources/components/StaffTeamOverviewView";
import type { FunctionComponent } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";


const StaffTeamOverviewPage: FunctionComponent = () => {
	const guard = userViewGuard(PermissionNames.CanManageStaff);
	if (guard) return guard;
	const { data, options } = useAggregatedDataV2({
		careerLevels: useAllCareerLevels(),
		staffMembers: useAllStaffMembers(),
	});

	const {
		careerLevels,
		staffMembers,
	} = data;


	const { isLoading, loadingPercent } = options;

	if (!careerLevels || !staffMembers) return null;

	return <StaffTeamOverviewView careerLevels={careerLevels}
								  loadingPercent={loadingPercent}
								  isLoading={isLoading}
								  staffMembers={staffMembers} />;
};

export default StaffTeamOverviewPage;