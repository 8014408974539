import { yupResolver } from "@hookform/resolvers/yup";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import SubmitButton from "~/components/formElements/SubmitButton";
import Headline from "~/components/Headline";
import RichTextEditor from "~/components/RichTextEditor";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarErrorOverlay from "~/components/Sidebar/components/SidebarErrorOverlay";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	updateStaffMemberSpecialAgreement,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementApiDispatchers.ts";
import type {
	StaffMemberSpecialAgreement,
} from "~/modules/humanResources/api/staffMemberSpecialAgreement/staffMemberSpecialAgreementTypes.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type FormData = {
	text: string;
}

type Props = {
	onSuccess: () => void;
	onCancel: () => void;
	specialAgreementsData: StaffMemberSpecialAgreement;
};

const UpdateSpecialAgreementsForm: React.FunctionComponent<Props> = ({
	onSuccess,
	onCancel,
	specialAgreementsData,
}) => {
	const [isBusy, setIsBusy] = useState(false);
	const [serverErrorMessage, setServerErrorMessage] = useState("");

	const schema = useMemo(() => {
		return yup.object({
			text: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		return {
			text: specialAgreementsData.text || "",
		};
	}, [specialAgreementsData]);

	const {
		handleSubmit,
		control,
		formState: { isDirty, isSubmitted, isValid },
	} = useForm<FormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<FormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({
		isSubmitted,
		isDirty,
		isValid,
		isLoading: isBusy,
	});

	const onSubmit = useCallback(async (data: FormData) => {

			try {
				setIsBusy(true);
				await updateStaffMemberSpecialAgreement({
					staffMemberId: specialAgreementsData.staffMemberId, specialAgreementId: specialAgreementsData.id,
					updateData: data,
				});
				onSuccess();
			} catch (error) {
				const apiError = handleApiError(error);
				console.log(apiError);
				setServerErrorMessage("Ein unerwarteter Fehler ist aufgetreten.");
			}
		},
		[specialAgreementsData.id, specialAgreementsData.staffMemberId, onSuccess]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}
			  onKeyDown={preventSubmitOnEnter}
			  className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<Headline type="h4"
						  color="muted">
					Sonderverinbarungen bearbeiten
				</Headline>
			</SidebarHeader>
			<SidebarContent>
				{isBusy && <SidebarBusyOverlay />}
				{!!serverErrorMessage &&
					<SidebarErrorOverlay title="Speichern fehlgeschlagen">{serverErrorMessage}</SidebarErrorOverlay>}

					<RichTextEditor control={control}
									name="text" />
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={isBusy}
							  disabled={!formIsSubmittable}>
					Speichern
				</SubmitButton>
				<Button theme="white"
						onClick={onCancel}>
					Abbrechen
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateSpecialAgreementsForm;
