import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";

import Button from "~/components/buttons/Button";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import type { FormInputOption } from "~/types/form.ts";

type Props = {
	disabled?: boolean;
	selectedYear: string;
	setSelectedYear: (year: string) => void;
	yearSelectOptions: FormInputOption[];
};

const YearSelect: FunctionComponent<Props> = ({ disabled = false, selectedYear, setSelectedYear, yearSelectOptions }) => {
	const [minYear, maxYear] = useMemo(() => {
		return yearSelectOptions.reduce((minAndMaxValues, option) => {
			const min = Math.min(minAndMaxValues[0], parseInt(option.value));
			const max = Math.max(minAndMaxValues[1], parseInt(option.value));

			return [min, max];

		}, [9999, 0]);
	}, [yearSelectOptions]);
	const handleGoToPrevYearClick = useCallback(() => {
		const prevYearNumber = parseInt(selectedYear) - 1;
		if (prevYearNumber >= minYear) {
			setSelectedYear((prevYearNumber).toString());
		}
	}, [minYear, selectedYear, setSelectedYear]);


	const handleGoToNextYearClick = useCallback(() => {
		const nextYearNumber = parseInt(selectedYear) + 1;
		if (nextYearNumber <= maxYear) {
			setSelectedYear(nextYearNumber.toString());
		}
	}, [maxYear, selectedYear, setSelectedYear]);

	const prevButtonDisabled = disabled || parseInt(selectedYear) === minYear;
	const nextButtonDisabled = disabled || parseInt(selectedYear) === maxYear;

	return <div className="flex items-center gap-x-2">
		<Button size="sm"
				theme="none"
				onClick={handleGoToPrevYearClick}
				disabled={prevButtonDisabled}>
			<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
		</Button>
		<div className="w-28">
			<SelectPlain optionsData={yearSelectOptions}
						 disabled={disabled}
						 onChange={setSelectedYear}
						 value={selectedYear} />
		</div>
		<Button size="sm"
				theme="none"
				onClick={handleGoToNextYearClick}
				disabled={nextButtonDisabled}>
			<ChevronRightIcon className="size-5 hover:fill-accent-600" />
		</Button>
	</div>;
};

export default YearSelect;