import type { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import AdminView from "~/modules/admin/components/AdminView";
import { AdminViewTabName } from "~/modules/admin/types/adminViewTypes.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import { useAllProjectTags, useProjectTagUsageReport } from "~/modules/project/api/projectTag/projectTagQueries.ts";
import { PermissionNames } from "~/types/entityNames.ts";

const AdminPage: FunctionComponent = () => {
	const guard = userViewGuard([PermissionNames.CanManageProjectTags, PermissionNames.CanManageUsers]);
	if (guard) return guard;

	const { tabId } = useParams();

	const sanitizedTabId = tabId as AdminViewTabName || AdminViewTabName.Users;

	const tagsLoadingEnabled = sanitizedTabId !== AdminViewTabName.Users;


	const { data, options: { loadingPercent: tagsLoadingPercentage } } = useAggregatedDataV2({
		allClients: useAllClients(tagsLoadingEnabled),
		allProjectsIsLoading: useAllProjects(tagsLoadingEnabled),
		allProductTags: useAllProjectTags(tagsLoadingEnabled),
		productTagUsageReport: useProjectTagUsageReport(tagsLoadingEnabled),
	});

	const {
		allProductTags,
		productTagUsageReport,
	} = data;

	return <AdminView activeTabId={sanitizedTabId}
					  allTagsData={allProductTags}
					  usageReport={productTagUsageReport}
					  tagsLoadingPercentage={tagsLoadingPercentage}
	/>;
};

export default AdminPage;