import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexAbsences, indexUsersAbsences } from "~/modules/absence/api/absence/absenceApiDispatchers.ts";

export const ABSENCE_BASE_QUERY_KEY = "absences";
export const ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY = ABSENCE_BASE_QUERY_KEY + "-timespan";

export const STAFF_MEMBERS_ABSENCES_QUERY_KEY = (staffMemberId: string) => [ABSENCE_BASE_QUERY_KEY, { staffMemberId }];

export const ABSENCES_IN_TIMESPAN_QUERY_KEY = (startDate: string,
	endDate: string) => [ABSENCE_IN_TIMESPAN_BASE_QUERY_KEY, startDate, endDate];

export const useAbsencesInTimespan = ({ startDate, endDate }: {
	startDate: string,
	endDate: string,
}) => useQuery({
	queryKey: ABSENCES_IN_TIMESPAN_QUERY_KEY(startDate, endDate),
	queryFn: () => indexAbsences({ startDate, endDate }).then(getDataFromResponse),
	enabled: !!startDate && !!endDate,
});

export const useStaffMembersAbsences = (staffMemberId: string) => useQuery({
	queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId),
	queryFn: () => indexUsersAbsences({ staffMemberId }).then(getDataFromResponse),
	enabled: !!staffMemberId,
});


