import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import { STAFF_MEMBER_QUERY_KEY } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { useAllTaxClasses } from "~/modules/humanResources/api/taxClass/taxClassQueries.ts";
import UpdateTaxDetailsForm
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberCoreDataSection/components/UpdateTaxDetailsSidebar/components/UpdateTaxDetailsForm";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	staffMemberCoreData: StaffMember;
};

const UpdateTaxDetailsSidebar: FunctionComponent<Props> = ({
	isOpen,
	onClose,
	staffMemberCoreData,
}) => {

	const {
		isLoading: taxClassesIsLoading,
	} = useAllTaxClasses();

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBER_QUERY_KEY(staffMemberCoreData.id) });
		onClose();
	}, [onClose, queryClient, staffMemberCoreData.id]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{taxClassesIsLoading && <SidebarBusyOverlay />}
			{!taxClassesIsLoading && <UpdateTaxDetailsForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				staffMemberCoreData={staffMemberCoreData} />}
		</Sidebar>
	);
};

export default UpdateTaxDetailsSidebar;
