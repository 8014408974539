import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "~/components/buttons/Button/Button.tsx";
import ComboBox from "~/components/formElements/ComboBox";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import Switch from "~/components/formElements/Switch";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useProjectsProjectRolesOptions from "~/hooks/form/formOptionsData/useProjectsProjectRolesOptions.ts";
import useUserSelectOptions from "~/hooks/form/formOptionsData/useUsersSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import { useProjectProjectRoles } from "~/modules/project/api/projectRole/projectRoleQueries.ts";
import { updateStaffing } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import type { Staffing, UpdateStaffingData } from "~/modules/project/api/staffing/staffingTypes.ts";
import StaffingBudgetFormSection from "~/modules/project/components/forms/formSections/StaffingBudgetFormSection";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";

type CreateStaffingFormProps = {
	onSuccess: () => void;
	onCancel: () => void;
	staffingData: Staffing;
	totalBudgetUnassigned: number;
	totalManDaysUnassigned: number;
};

interface UpdateStaffingFormData extends Omit<UpdateStaffingData, "orderId" | "projectId" | "projectPhaseId"> {
}

const UpdateStaffingForm: React.FC<CreateStaffingFormProps> = ({
	onSuccess,
	onCancel,
	staffingData,
	totalManDaysUnassigned,
	totalBudgetUnassigned,
}) => {
	//const { t } = useTranslation();
	const [busy, setBusy] = useState(false);

	const userSelectOptions = useUserSelectOptions({
		/*filterFunction: staffableUsersFilterFn,*/
		ignoreIds: [staffingData.userId].filter(Boolean),
	});

	const projectRoleSelectOptions = useProjectsProjectRolesOptions({
		projectId: staffingData.projectId,
		valueProperty: "displayName",
	});
	const { data: projectRolesData } = useProjectProjectRoles(staffingData.projectId);

	const schema = useMemo(() => {
		return yup.object({
			isActive: yup.boolean().required(),
			dailyRateCents: yup.number().required(),
			manDays: yup.number().typeError(t("form:validationErrors.number.requiredTypeError")).required(),
			projectRoleName: yup.string().required(),
			userId: yup.string().required(),
		});
	}, []);

	const defaultValues = useMemo(() => {
		const projectRoleDisplayName = projectRolesData?.find((projectRole) => projectRole.id === staffingData.projectRoleId)?.displayName || "";

		return {
			isActive: staffingData.isActive,
			dailyRateCents: staffingData.dailyRateCents,
			manDays: staffingData.manDays,
			projectRoleName: projectRoleDisplayName,
			userId: staffingData.userId,
		};
	}, [staffingData, projectRolesData]);

	const originalAssignedBudget = staffingData.dailyRateCents * staffingData.manDays;

	const totalBudgetUnassignedWithCurrentStaffing = totalBudgetUnassigned + originalAssignedBudget;
	const totalManDaysUnassignedWithCurrentStaffing = totalManDaysUnassigned + staffingData.manDays;

	console.log({ totalBudgetUnassigned, totalBudgetUnassignedWithCurrentStaffing, originalAssignedBudget });

	const {
		handleSubmit,
		control,
		formState: { errors, isValid, isDirty, isSubmitted },
		watch,
	} = useForm<UpdateStaffingFormData>({
		defaultValues: defaultValues,
		resolver: yupResolver<UpdateStaffingFormData>(schema),
	});


	const formIsSubmittable = useFormIsSubmittable({
		enableInitially: false,
		isSubmitted,
		isValid,
		isLoading: busy,
		isDirty,
	});

	const onSubmit: SubmitHandler<UpdateStaffingFormData> = async (data: UpdateStaffingFormData) => {
		setBusy(true);
		const staffingUpdateData = {
			...data,
			projectId: staffingData.projectId,
			projectPhaseId: staffingData.projectPhaseId,
			orderId: staffingData.orderId,
		};

		try {
			await updateStaffing({
				projectId: staffingData.projectId,
				staffingId: staffingData.id,
				staffingData: staffingUpdateData,
			});
			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Staffing bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				{busy && <SidebarBusyOverlay />}
				<FormSectionGroup>
					<FormSection title="Mitarbeiter">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<Select
									name={"userId"}
									disabled={true}
									control={control}
									optionsData={userSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<FormSection title="Zeiterfassung">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<Switch name={"isActive"}
										control={control}
										labelOn={"Aktiv"}
										labelOff={"Inaktiv"} />
							</div>
						</div>
					</FormSection>
					<FormSection title="Rolle im Projekt">
						<div className="grid grid-cols-6 gap-x-6 gap-y-2">
							<div className="col-span-6">
								<ComboBox
									placeholder="Name der Rolle"
									name={"projectRoleName"}
									control={control}
									optionsData={projectRoleSelectOptions}
								/>
							</div>
						</div>
					</FormSection>
					<StaffingBudgetFormSection control={control}
											   errors={errors}
											   totalBudgetUnassigned={totalBudgetUnassignedWithCurrentStaffing}
											   totalManDaysUnassigned={totalManDaysUnassignedWithCurrentStaffing}
											   watch={watch} />
				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateStaffingForm;
