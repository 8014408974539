import type { FunctionComponent } from "react";
import { useState } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import { useAllCareerLevels } from "~/modules/humanResources/api/careerLevel/careerLevelQueries.ts";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import { useYearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportQueries.ts";
import TotalUtilisationChartSection
	from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationChartSection";
import TotalUtilisationTableSection
	from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationTableSection";
import type { UtilisationReportDeductiblesProperty } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { defaultDeductibles } from "~/modules/reports/utils/utilisationReportUtils.ts";
import { useAllTimeTrackingTypes } from "~/modules/timeTracking/api/timeTrackingType/timeTrackingTypeQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

type Props = {
	year: string;
};

const currentMonth = (new Date().getMonth() + 1);
const monthToDisplay = currentMonth === 1 ? "" : (currentMonth - 1).toString();

const UtilisationReportSection: FunctionComponent<Props> = ({ year }) => {
	const [selectedDeductibles, setSelectedDeductibles] = useState<UtilisationReportDeductiblesProperty[]>(defaultDeductibles);
	const [selectedMonth, setSelectedMonth] = useState<string>(monthToDisplay);
	const { data, options } = useAggregatedDataV2({
		careerLevels: useAllCareerLevels(),
		staffMembers: useAllStaffMembers(),
		timeTrackingTypes: useAllTimeTrackingTypes(),
		utilisationReport: useYearlyUtilisationReport(year || ""),
	});

	const {
		utilisationReport,
	} = data;

	const { isLoading, loadingPercent } = options;

	return <div className="grid size-full grid-rows-[auto_1fr] gap-y-8 overflow-hidden pb-8">
		{isLoading && <LoadingPage pcent={loadingPercent} />}
		{!isLoading && <><TotalUtilisationChartSection utilisationReportData={utilisationReport}
													   selectedDeductibles={selectedDeductibles}
													   selectedMonth={selectedMonth}
													   setSelectedMonth={setSelectedMonth}
													   setSelectedDeductibles={setSelectedDeductibles}
													   year={year} />
			<TotalUtilisationTableSection utilisationReportData={utilisationReport}
										  selectedDeductibles={selectedDeductibles}
										  selectedMonth={selectedMonth}
										  setSelectedMonth={setSelectedMonth}
										  year={year} />
		</>}
	</div>;
};

export default UtilisationReportSection;