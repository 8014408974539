import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexSettings } from "~/modules/settings/api/settings/settingsApiDispatchers.ts";

export const ALL_SETTINGS_QUERY_KEY = ["settings"];

export const useAllSettings = (enabled: boolean = true) => useQuery({
    queryKey: ALL_SETTINGS_QUERY_KEY,
    queryFn: () => indexSettings().then(getDataFromResponse),
    enabled,
});