import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useCallback, useState } from "react";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import Button from "~/components/buttons/Button";
import Hint from "~/components/Hint";
import Sidebar from "~/components/Sidebar";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import {
	ALL_STAFF_MEMBERS_QUERY_KEY,
	useAllStaffMembers,
} from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import { updateUserStatus } from "~/modules/user/api/user/userApiDispatchers.ts";
import { ALL_USERS_QUERY_KEY } from "~/modules/user/api/user/userQueries.ts";
import type { UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import StatusUpdateSuccessMessage
	from "~/modules/user/components/components/UpdateUserStatusSidebar/components/StatusUpdateSuccessMessage";

type UpdateUserStatusSidebarProps = {
	isOpen: boolean;
	closeSidebar: () => void;
	userData: UserWithConfidentialInformation;
};

function eligibleStaffMemberToConnectExists(allStaffMembersData: StaffMember[] | undefined,
	userData: UserWithConfidentialInformation | undefined): boolean {
	if (!allStaffMembersData || !userData) return false;
	return allStaffMembersData.some(staffMember => staffMember.emailCompany === userData.email
		&& !staffMember.userId
		&& !!staffMember.currentEmploymentTypeId
		&& !!staffMember.currentCareerLevelId);
}

const UpdateUserStatusSidebar: React.FunctionComponent<UpdateUserStatusSidebarProps> = ({
	isOpen,
	closeSidebar,
	userData,
}) => {
	const queryClient = useQueryClient();
	const [isBusy, setIsBusy] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const isFreelanceUser = userData?.employmentTypeId === EmploymentTypeId.Freelancer;

	const [error, setError] = useState<string | null>(null);
	const {
		data: allStaffMembersData,
		isLoading: allStaffMembersIsLoading,
	} = useAllStaffMembers(!!userData && !isFreelanceUser);


	if (!userData) return null;
	const internalUserHasStaffMember = !isFreelanceUser && allStaffMembersData?.some(staffMember => staffMember.userId === userData?.id);
	const staffMemberToConnectExists = !internalUserHasStaffMember && eligibleStaffMemberToConnectExists(allStaffMembersData, userData);
	const showUpdateSection = !isSuccess && !allStaffMembersIsLoading && (isFreelanceUser || internalUserHasStaffMember || staffMemberToConnectExists);
	const [showWarningMessage, setShowWarningMessage] = useState(!isSuccess && !allStaffMembersIsLoading && !showUpdateSection);
	const updateStatus = useCallback(
		async (isActive: boolean) => {
			setIsBusy(true);
			try {
				await updateUserStatus(userData.id, isActive);
				queryClient.invalidateQueries({ queryKey: ALL_USERS_QUERY_KEY });
				queryClient.invalidateQueries({ queryKey: ALL_STAFF_MEMBERS_QUERY_KEY });
				setIsBusy(false);
				setIsSuccess(true);
			} catch (error) {
				const errorData = handleApiError(error);
				if (errorData.type === ApiResponseErrorType.VALIDATION) {
					if (
						errorData.messages.userId.find(
							(validationError) => validationError.rule === "userCanBeActivatedRules",
						)
					) {
						setShowWarningMessage(true);
					}
					else{
						setError("Ein unbekannter Fehler ist aufgetreten.");
					}
				} else {
					setError("Ein unbekannter Fehler ist aufgetreten.");
				}
				setIsBusy(false);
			}
		},
		[queryClient, userData?.id],
	);
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={closeSidebar}>
			<div className="flex min-h-full w-full flex-col justify-start">
				<SidebarHeader>
					<SidebarHeaderHeadline>
						Account-Status von &quot;{userData?.fullName}&quot; ändern
					</SidebarHeaderHeadline>
				</SidebarHeader>
				<SidebarContent>
					{error && <Hint theme="error">{error}</Hint>}
					{allStaffMembersIsLoading || isBusy && <SidebarBusyOverlay showSpinner={true} />}
					{showUpdateSection && <div className="px-8 py-6">
						<Button
							className="w-full justify-center"
							theme={userData?.isActive ? "danger" : "primary"}
							onClick={() => updateStatus(!userData?.isActive)}
						>
							User {userData?.isActive ? "deaktiveren" : "aktivieren"}
						</Button>
					</div>}
					{showWarningMessage && <Hint theme="warning"
												 title="Fehlende Verknüpfung zu Mitarbeiterprofil">
						<p className="mt-4">User-Accounts interner Mitarbeiter:innen müssen mit einem Mitarbeiterprofil
							verknüpft sein,
							damit sie aktiviert werden können. </p>

						<p className="mt-4">Damit ein Mitarbeiterprofil mit dem User-Account verknüpft werden kann,
							müssen im Profil folgende Informationen hinterlegt sein:</p>
						<ul className="ml-4 mt-4 list-disc">
							<li>Übereinstimmende E-Mail Adresse</li>
							<li>Anstellungsart</li>
							<li>Karrierestufe</li>
							<li>Keine bestehende Verknüpfung mit einem User-Account</li>
						</ul>
						<p className="mt-4 font-bold">Es wurden keine Mitarbeiterprofile gefunden, die diese
							Voraussetzungen erfüllen. Bitte wende Dich an das HR-Department.</p>
					</Hint>}
					{isSuccess && <StatusUpdateSuccessMessage onClose={closeSidebar} />}
				</SidebarContent>
			</div>
		</Sidebar>
	);
};

export default UpdateUserStatusSidebar;
