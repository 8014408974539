import ProjectsIndexView from "modules/project/components/ProjectsIndexView";
import { useMemo } from "react";

import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import userViewGuard from "~/hooks/useViewPermssionsCheck";
import { useAllClients } from "~/modules/client/api/client/clientQueries";
import { useAllCompanies } from "~/modules/client/api/company/companyQueries.ts";
import { useAllCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageQueries.ts";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries";
import type { ProjectSummary } from "~/modules/project/api/project/projectTypes.ts";
import { useAllWorkingStatuses } from "~/modules/project/api/workingStatus/workingStatusQueries";
import { useAllUsers } from "~/modules/user/api/user/userQueries";
import LoadingPage from "~/pages/LoadingPage";
import { PermissionNames } from "~/types/entityNames.ts";

interface Props {
}

const ProjectsIndexPage: React.FC<Props> = () => {
	const guard = userViewGuard(PermissionNames.CanManageProjects);
	if (guard) return guard;

	const { data, options } = useAggregatedDataV2({
		allCompanies: useAllCompanies(),
		allCompanyAvatarImages: useAllCompanyAvatarImages(),
		clients: useAllClients(),
		projects: useAllProjects(),
		users: useAllUsers(),
		workingStatuses: useAllWorkingStatuses(),
	});

	const { allCompanies, allCompanyAvatarImages, projects, clients, users, workingStatuses } = data;

	const enrichedProjects: ProjectSummary[] = useMemo(() => {
		if (projects && allCompanies && clients && allCompanyAvatarImages && users) {
			const clientsById = new Map(clients.map((client) => [client.id, client]));

			const companyLogosById: Record<string, AvatarImage> = {};

			allCompanies.forEach((company) => {
				if (!companyLogosById[company.id]) {
					const companyLogo = allCompanyAvatarImages.find((image) => image.id === company.avatarImageId);
					if (companyLogo) {
						companyLogosById[company.id] = companyLogo;
					}
				}
			});

			return projects.map((project) => {
				const staffedUserNames = project.staffedUserIds.map((userId) => {
					const user = users.find((u) => u.id === userId);
					if (user) {
						return user.fullName.toLowerCase();
					}
					return "";
				}).join(" ");

				const client = clientsById.get(project.clientId);
				const companyLogo = client ? companyLogosById[client.companyId] || null : null;
				const projectNumberElements = project.projectNumber ? project.projectNumber.split("-"): [];
				const projectCounter = projectNumberElements.length > 1 ? parseInt(projectNumberElements[2]) : 0;
				const searchIndex = `${project.title.toLowerCase()} ${project.projectNumber?.toString().toLowerCase()} ${client?.displayName.toLowerCase()} ${staffedUserNames}`;
				return {
					...project,
					staffedUserNames,
					companyLogo: companyLogo,
					clientDisplayName: client?.displayName,
					projectCounter,
					searchIndex
				};
			});
		}
		return [];
	}, [allCompanyAvatarImages, allCompanies, clients, projects, users]);

	if (options.loadingPercent < 100) return <LoadingPage pcent={options.loadingPercent} />;

	return <ProjectsIndexView projects={enrichedProjects}
							  clients={clients}
							  users={users}
							  workingStatuses={workingStatuses} />;
};

export default ProjectsIndexPage;
