import type { FunctionComponent, PropsWithChildren } from "react";
import { useEffect, useMemo, useState } from "react";

import Avatar from "~/components/Avatar";
import Card from "~/components/Card";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { CareerLevel } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type {
	DailyRateReportCareerLevelBreakdown,
	DailyRateReportClientBreakdown,
	DailyRatesByCareerLevelBreakdownEnriched,
} from "~/modules/reports/api/dailyRateReport/dailyRateReportTypes.ts";
import DailyRateBreakdownByCareerLevelBarChart
	from "~/modules/reports/components/GlobalReportsView/components/DailyRateReportSection/components/DailyRateReportChartSection/DailyRateBreakdownByCareerLevelBarChart";
import DailyRateByCareerLevelBarChart
	from "~/modules/reports/components/GlobalReportsView/components/DailyRateReportSection/components/DailyRateReportChartSection/DailyRateByCareerLevelBarChart";
import DailyRateByClientBarChart
	from "~/modules/reports/components/GlobalReportsView/components/DailyRateReportSection/components/DailyRateReportChartSection/DailyRateByClientBarChart";
import type { User } from "~/modules/user/api/user/userTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	averageDailyRateCents: string;
	careerLevelBreakdown: DailyRateReportCareerLevelBreakdown[];
	careerLevels: CareerLevel[];
	clients: Client[],
	clientBreakdown: DailyRateReportClientBreakdown[];
	dailyRateBreakdown: DailyRatesByCareerLevelBreakdownEnriched[];
	users: User[],
	year: string
};

const LabelValue = ({ label, children }: PropsWithChildren & {
	label: string
}) => {
	return <div className="flex flex-col">
		<div className="text-xs text-gray-600">{label}</div>
		<div className="text-gray-600">{children}</div>
	</div>;
};


const DailyRateReportChartSection: FunctionComponent<Props> = ({
	averageDailyRateCents,
	careerLevels,
	careerLevelBreakdown,
	clients,
	clientBreakdown,
	dailyRateBreakdown,
	users,
	year,
}) => {
	const [selectedCareerLevelId, setSelectedCareerLevelId] = useState<string>(CareerLevelId.SeniorManager);

	useEffect(() => {
		if (selectedCareerLevelId && careerLevelBreakdown) {
			const careerLevelIdsInReport = careerLevelBreakdown.map((item) => item.careerLevelId);
			if (!careerLevelIdsInReport.includes(selectedCareerLevelId)) {
				const firstCareerLevelInReport = careerLevelBreakdown.sort(byObjectProperty("averageDailyRateCents", "desc"))[0];
				setSelectedCareerLevelId(firstCareerLevelInReport.careerLevelId);
			}
		}
	}, [selectedCareerLevelId, careerLevelBreakdown]);


	const selectedDailyRateBreakdown = useMemo(() => {
		return dailyRateBreakdown.find((item) => item.careerLevelId === selectedCareerLevelId);
	}, [dailyRateBreakdown, selectedCareerLevelId]);

	const dailyRateDetailsChartData = selectedDailyRateBreakdown?.dailyRateBreakdown;
	const selectedCareerLevelDisplayName = selectedDailyRateBreakdown?.careerLevelDisplayName || "";
	const selectedCareerLevelData = careerLevelBreakdown.find((item) => item.careerLevelId === selectedCareerLevelId);

	const selectedUserElements = selectedDailyRateBreakdown?.userIds.map((userId) => {
		return users.find((user) => user.id === userId)!;
	}).filter((user) => !!user).sort(byObjectProperty("fullName")).map(user => {
		return <div key={user.id}
					className="flex items-center gap-x-2">
			<Avatar width="w-6"
					height="h-6"
					firstName={user.firstName}
					lastName={user.lastName}
					image={user.avatarImage} />
			<span>{user.fullName}</span>
		</div>;
	});

	return <div className="flex flex-col gap-y-8 pt-4">
		<Card>
			<div className="relative flex gap-x-4">
				<div className="flex min-w-24 shrink-0 flex-col items-center justify-center">
					<div className="flex items-center text-xl font-bold text-gray-600">
						{formatCentsToCurrency(parseInt(averageDailyRateCents), 2)}
					</div>
					<div className="flex items-center text-sm font-light">
						Ø {year}
					</div>
				</div>
				<div className="h-40 w-full">
					<DailyRateByCareerLevelBarChart
						setSelectedCareerLevelId={setSelectedCareerLevelId}
						selectedCareerLevelId={selectedCareerLevelId}
						data={careerLevelBreakdown}
						careerLevels={careerLevels} />
				</div>
			</div>
		</Card>
		<Card title="Tagessätze / Erfasste Tage nach Karrierestufe">
			<div className="grid grid-cols-3">
				<div>
					<div className="grid grid-cols-2 gap-2">
						<div className="col-span-2">
							<LabelValue label="Karriere stufe">
								{selectedCareerLevelDisplayName}
							</LabelValue>
						</div>
						<LabelValue label="Ø Tagessatz">
							{selectedCareerLevelData ? formatCentsToCurrency(parseInt(selectedCareerLevelData.averageDailyRateCents), 2) : ""}
						</LabelValue>

						<LabelValue label="Erfasste Tage">
							{selectedCareerLevelData ? formatNumberWithComma(parseFloat(selectedCareerLevelData.daysTracked), 2) : ""}
						</LabelValue>
						<div className="col-span-2">
							<LabelValue label="Gestaffte User">
								<div className="mt-2 space-y-2">
									{selectedUserElements}
								</div>
							</LabelValue>
						</div>
					</div>
				</div>
				<div className="col-span-2">
					{dailyRateDetailsChartData &&
						<DailyRateBreakdownByCareerLevelBarChart data={dailyRateDetailsChartData} />}
				</div>
			</div>
		</Card>

		<Card title="Ø Tagessatz nach Kunde">
			<DailyRateByClientBarChart
				data={clientBreakdown}
				clients={clients} />
		</Card>
	</div>;
};

export default DailyRateReportChartSection;