import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCompanies, getCompany } from "~/modules/client/api/company/companyApiDispatchers.ts";

const ENTITY_QUERY_KEY = "company";

export const All_COMPANIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const COMPANY_QUERY_KEY = (companyId: string) => [ENTITY_QUERY_KEY, companyId];

export const useAllCompanies = () => useQuery({
	queryKey: All_COMPANIES_QUERY_KEY,
	queryFn: () => getAllCompanies().then(getDataFromResponse),
});

export const useCompany = (companyId: string, enabled: boolean = true) => useQuery({
	queryKey: COMPANY_QUERY_KEY(companyId),
	queryFn: () => getCompany(companyId).then(getDataFromResponse),
	enabled,
});