import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import type { PropsWithChildren } from "react";
import { Fragment, useCallback, useRef } from "react";

type ModalProps = PropsWithChildren & {
	open: boolean;
	setOpen: (bool: boolean) => void;
	closeOnOutsideClick?: boolean;
	widthClassName?: string;
}

const Modal: React.FC<ModalProps> = ({ open, setOpen, closeOnOutsideClick = true, children, widthClassName }) => {
	const outsideClickHandler = useCallback(() => {
		if (closeOnOutsideClick) {
			setOpen(false);
		}
	}, [closeOnOutsideClick, setOpen]);

	const cancelButtonRef = useRef(null);

	return (
		<Transition.Root show={open}
						 as={Fragment}>
			<Dialog as="div"
					className="relative z-50"
					initialFocus={cancelButtonRef}
					onClose={outsideClickHandler}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className={clsx("relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6", widthClassName || "sm:w-full sm:max-w-lg")}>
								{open && children}
							</Dialog.Panel>
						</Transition.Child>

					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default Modal;
