import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import type React from "react";
import { useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Avatar from "~/components/Avatar";
import Button from "~/components/buttons/Button";
import FormHasErrorsHint from "~/components/formElements/FormHasErrorsHint";
import FormSection, { FormSectionGroup } from "~/components/formElements/FormSection";
import Input from "~/components/formElements/Input";
import Select from "~/components/formElements/Select";
import SubmitButton from "~/components/formElements/SubmitButton";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import SidebarFooter from "~/components/Sidebar/components/SidebarFooter";
import SidebarHeader from "~/components/Sidebar/components/SidebarHeader";
import SidebarHeaderHeadline from "~/components/Sidebar/components/SidebarHeaderHeadline";
import useCompanyLocationSelectOptions from "~/hooks/form/formOptionsData/useCompanyLocationSelectOptions.ts";
import { useFormIsSubmittable } from "~/hooks/form/useFormIsSubmittable.ts";
import {
	useUserValidationComparisonData,
} from "~/hooks/form/validationComparisonData/useUserValidationComparisonData.ts";
import { useAllPermissions } from "~/modules/auth/api/auth/authQueries.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import type { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { updateInternalUser } from "~/modules/user/api/user/userApiDispatchers.ts";
import type { UpdateInternalUserData, UserWithConfidentialInformation } from "~/modules/user/api/user/userTypes.ts";
import UserPermissionsFormSection from "~/modules/user/components/forms/formSections/UserPermissionsFormSection";
import type { PermissionNames } from "~/types/entityNames.ts";
import type { FormDataPropertyObject } from "~/types/form.ts";
import { normalizeTKey } from "~/types/typeHelpers.ts";
import { preventSubmitOnEnter } from "~/utils/form/formUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	onCancel: () => void;
	onSuccess: () => void;
	userData: UserWithConfidentialInformation
};

type UpdateInternalUserFormData = Omit<UpdateInternalUserData, "permissions" | "staffMemberId"> & {
	permissions: FormDataPropertyObject;
}

const UpdateInternalUserForm: React.FC<Props> = ({ onSuccess, onCancel, userData }) => {
	const [busy, setBusy] = useState(false);

	const { emailComparisonData } = useUserValidationComparisonData();

	const schema = useMemo(() => {
		return yup.object({
			email: yup.string()
				.email()
				.required()
				.uniqueEmail(emailComparisonData, userData.id),
			locationId: yup.string().required(),
			permissions: yup.object(),
			shortName: yup.string().required(),
		});
	}, [emailComparisonData, userData.id]);

	const { data: allPermissionsData } = useAllPermissions();

	const permissions = useMemo(() => {
		if (allPermissionsData) {
			return allPermissionsData.sort(byObjectProperty("name")).map((permission) => {
				return permission.name;
			});
		}
	}, [allPermissionsData]);

	const defaultValues = useMemo(() => {
		const permissions: FormDataPropertyObject = {};
		if (allPermissionsData) {
			allPermissionsData.forEach((permission) => {
				const userHasPermission = userData.permissions.find(userPermission => userPermission.name === permission.name);
				permissions[permission.name] = userHasPermission !== undefined;
			});

			return {
				locationId: userData.locationId || "",
				email: userData.email,
				permissions,
				shortName: userData.shortName,
			};
		}
		return {};
	}, [userData, allPermissionsData]);


	const {
		handleSubmit,
		control,
		formState: { isDirty, isValid, isSubmitted },
	} = useForm<UpdateInternalUserFormData>({
		defaultValues,
		mode: "onSubmit",
		resolver: yupResolver<UpdateInternalUserFormData>(schema),
	});

	const formIsSubmittable = useFormIsSubmittable({ isDirty, isSubmitted, isValid, isLoading: busy });

	const companyLocationSelectOptions = useCompanyLocationSelectOptions();

	const onSubmit: SubmitHandler<UpdateInternalUserFormData> = async (data) => {
		setBusy(true);
		let selectedPermissions: PermissionNames[] = [];

		if (data.permissions) {
			selectedPermissions = Object.keys(data.permissions).filter((key) =>
				data.permissions ? data.permissions[key] : false,
			) as PermissionNames[];
		}

		const updateData = { ...data, permissions: selectedPermissions };

		try {
			await updateInternalUser(userData.id, updateData);

			onSuccess();
		} catch (error) {
			console.log(error);
		}
	};

	const careerLevel = userData.careerLevelId ? getCareerLevelDisplayNameById(userData.careerLevelId as CareerLevelId) : "Keine Karrierestufe";
	const employmentType = userData.employmentTypeId ? getEmploymentTypeDisplayNameById(userData.employmentTypeId as EmploymentTypeId) : "Kein Anstellungsverhältnis";

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			className="flex min-h-full w-full flex-col justify-start"
		>
			<SidebarHeader>
				<SidebarHeaderHeadline>Internen User &quot;{userData.fullName}&quot; bearbeiten</SidebarHeaderHeadline>
			</SidebarHeader>
			<SidebarContent>
				<FormSectionGroup>
					{busy && <SidebarBusyOverlay />}

					<FormSection title="NORD.WORK User Daten">
						<div className="rounded-md px-4 py-3 text-sm shadow-sm ring-1 ring-inset ring-gray-300">
							<div className="flex items-center">
								<Avatar firstName={userData.firstName}
										lastName={userData.lastName}
										image={userData.avatarImage}
								/>
								<div className="ml-4 flex flex-col">
									<div className="text-left font-medium text-gray-900 group-hover:text-white">{`${userData.firstName} ${userData.lastName}`}</div>
									<div className="mt-1 text-gray-500 group-hover:text-gray-300">{careerLevel + " / " + employmentType}</div>
								</div>
							</div>
						</div>
						<div className="mt-6 grid grid-cols-2 gap-6">
							<Input name="email"
								   control={control}
								   label="E-Mail" />
							<Input name="shortName"
								   control={control}
								   label="Kürzel"
								   placeholder="MM"
							/>
							<Select name="locationId"
									optionsData={companyLocationSelectOptions}
									control={control}
									label="Standort" />
						</div>
					</FormSection>
					<UserPermissionsFormSection<UpdateInternalUserFormData> control={control}
																			permissions={permissions} />

				</FormSectionGroup>
			</SidebarContent>
			<SidebarFooter>
				<FormHasErrorsHint show={isSubmitted && !isValid}
								   className="mr-2" />
				<SubmitButton busy={busy}
							  disabled={!formIsSubmittable}>
					{t(normalizeTKey("form:buttonTexts.save"))}
				</SubmitButton>
				<Button theme="none"
						onClick={onCancel}>
					{t(normalizeTKey("form:buttonTexts.cancel"))}
				</Button>
			</SidebarFooter>
		</form>
	);
};

export default UpdateInternalUserForm;
