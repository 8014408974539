import { useQueryClient } from "@tanstack/react-query";
import CreateVacationEntitlementForm from "components/forms/CreateVacationEntitlementForm";
import type React from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";
import { useAllSettings } from "~/modules/settings/api/settings/settingsQueries.ts";
import { SettingKey } from "~/modules/settings/api/settings/settingsTypes.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";

type CreateVacationEntitlementSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	staffMemberId: string,
	minDate: Date;
};

const CreateVacationEntitlementSidebar: React.FunctionComponent<CreateVacationEntitlementSidebarProps> = ({
	onClose,
	isOpen,
	staffMemberId,
	minDate,
}) => {
	const queryClient = useQueryClient();

	const {
		data: settingsData,
		isLoading: settingsIsLoading,
	} = useAllSettings();

	const defaultEntitlementDays = settingsData?.find(setting => setting.key === SettingKey.DefaultVacationEntitlementDays)?.value;

	const handleSuccess = useCallback(
		async () => {
			queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY(staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(staffMemberId) });
			onClose();
		},
		[onClose, queryClient, staffMemberId],
	);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{settingsIsLoading ? <LoadingPage /> : <CreateVacationEntitlementForm minDate={minDate}
																				  staffMemberId={staffMemberId}
																				  defaultEntitlementDays={parseInt(defaultEntitlementDays || "0") * 100}
																				  onSuccess={handleSuccess}
																				  onCancel={onClose} />}
		</Sidebar>
	);
};

export default CreateVacationEntitlementSidebar;
