import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getProjectTagsUsageReport,
	indexProjectTags,
} from "~/modules/project/api/projectTag/projectTagApiDispatchers.ts";

export const PROJECT_TAG_BASE_QUERY_KEY = "project-tags";

export const ALL_PROJECT_TAGS_QUERY_KEY = [PROJECT_TAG_BASE_QUERY_KEY, "all"];

export const useAllProjectTags = (enabled: boolean = true) => useQuery({
	queryKey: ALL_PROJECT_TAGS_QUERY_KEY,
	queryFn: () => indexProjectTags().then(getDataFromResponse),
	enabled,
});

export const PROJECT_TAG_USAGE_REPORT_QUERY_KEY = [PROJECT_TAG_BASE_QUERY_KEY, "usage-report"];

export const useProjectTagUsageReport = (enabled: boolean = true) => useQuery({
	queryKey: PROJECT_TAG_USAGE_REPORT_QUERY_KEY,
	queryFn: () => getProjectTagsUsageReport().then(getDataFromResponse),
	enabled,
});