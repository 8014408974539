import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import Card from "~/components/Card";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import { useAllClients } from "~/modules/client/api/client/clientQueries.ts";
import { useAllProjects } from "~/modules/project/api/project/projectQueries.ts";
import { useYearlyRevenueReport } from "~/modules/reports/api/revenueReport/revenueReportQueries.ts";
import DownloadYearlyRevenueReportExcelButton
	from "~/modules/reports/components/GlobalReportsView/components/RevenueReportSection/DownloadYearlyRevenueReportExcelButton";
import YearlyRevenueByProjectChart
	from "~/modules/reports/components/GlobalReportsView/components/RevenueReportSection/YearlyRevenueByProjectChart";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	year: string
};

const RevenueReportSection: FunctionComponent<Props> = ({ year }) => {
	const [selectedChartType, setSelectedChartType] = useState<"project" | "client">("client");
	const { data, options } = useAggregatedDataV2({
		clients: useAllClients(),
		projects: useAllProjects(),
		revenueReport: useYearlyRevenueReport(year || ""),
	});

	const {
		clients,
		projects,
		revenueReport,
	} = data;

	const { isLoading, loadingPercent } = options;

	const [projectChartData, clientChartData] = useMemo(() => {
		if (clients && projects && revenueReport) {
			const projectChartData = revenueReport.projectBreakdown.map((projectBreakdownData) => {
				const project = projects.find((project) => project.id === projectBreakdownData.projectId);
				return {
					label: project?.title || "",
					value: projectBreakdownData.revenueInYear,
				};
			});

			const clientChartData = revenueReport.clientBreakdown.map((clientBreakdownData) => {
				const client = clients.find((client) => client.id === clientBreakdownData?.clientId);
				return {
					label: client?.displayName || "",
					value: clientBreakdownData.revenueInYear,
				};
			});
			return [projectChartData, clientChartData];
		}
		return [];
	}, [clients, projects, revenueReport]);

	const chartData = selectedChartType === "project" ? projectChartData : clientChartData;

	return <div className="flex h-full flex-col gap-y-2 overflow-y-scroll pb-8 pt-4">
		{isLoading && <LoadingPage pcent={loadingPercent} />}
		{!isLoading && chartData && (<>
			<Card>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-2 text-lg font-bold">
						<span className="text-gray-500">Gesamt {year}: </span>
						<span className="font-bold">{formatCentsToCurrency(parseInt(revenueReport?.totalRevenueInYear || "0"))}</span>
						<span className="text-sm font-light">(Stand: {formatDateWithGermanMonth(new Date())})</span>
					</div>
					<div className="flex gap-x-2">
						<SwitchPlain checked={selectedChartType === "project"}
									 onChange={(checked) => setSelectedChartType(checked ? "project" : "client")}
									 labelOn="Nach Projekt"
									 labelOff="Nach Kunde"
									 showAllLabels={true}
						/>
						<DownloadYearlyRevenueReportExcelButton data={chartData}
																type={selectedChartType}
																year={parseInt(year)} />
					</div>
				</div>
			</Card>
			<Card>
				<YearlyRevenueByProjectChart data={chartData} />
			</Card>
		</>)}

	</div>;
};

export default RevenueReportSection;