import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
    getAllApprovalLikelihoods,
} from "~/modules/project/api/approvalLikelihood/approvalLikelihoodApiDispatchers.ts";

export const ALL_APPROVAL_LIKELIHOODS_QUERY_KEY = ["allApprovalLikelihoods"];

export const useAllApprovalLikelihoods= (enabled: boolean = true) => useQuery({
    queryKey: ALL_APPROVAL_LIKELIHOODS_QUERY_KEY,
    queryFn: () => getAllApprovalLikelihoods().then(getDataFromResponse),
    enabled,
});