import type { Cell} from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import type { Table as TableType } from "@tanstack/table-core/build/lib/types";
import clsx from "clsx";

import TableBodyCell from "~/components/Table/components/TableBodyCell";


type DefaultTableBodyContentProps<T> = {
	table: TableType<T>
	verticalBorders?: boolean
};

const DefaultTableBodyContent = <T, >({ table,verticalBorders }: DefaultTableBodyContentProps<T>) => {
	return table.getRowModel().rows.map(row => {
			const onRowClick = table.options.meta ? table.options.meta.onRowClick : undefined;
			return <tr className={clsx("group", onRowClick && "cursor-pointer", verticalBorders && "divide-x hover:divide-white")}
					   key={row.id}
					   onClick={onRowClick ? () => onRowClick(row) : undefined}
			>
				{row.getVisibleCells().map((cell: Cell<T, any>) => {
					return <TableBodyCell key={cell.id}
										  textAlign={cell.column.columnDef.meta?.cellTextAlign}
										  hasWidthRestriction={cell.column.columnDef.meta?.widthPercentage !== undefined}
										  hasPadding={cell.column.columnDef.meta?.hasPadding}
										  className={clsx("group-hover:bg-gray-200", cell.column.getIsPinned() && "sticky left-0 z-10 bg-white")}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</TableBodyCell>;
				})}
			</tr>;
		},
	);
};

export default DefaultTableBodyContent;