import type { FunctionComponent, PropsWithChildren } from "react";
import { useEffect, useRef } from "react";

type Props = PropsWithChildren & {
	arcColor?: string;
	backgroundColor?: string;
	overflowColor?: string;
	percentage: number;
	size?: number;
	thickness?: number;
}

const OverflowPercentageChart: FunctionComponent<Props> = ({
	children,
	percentage,
	size = 256,
	thickness = 0.25,
	backgroundColor = "#f3f4f6",
	arcColor = "#36af2d",
	overflowColor = "#f63b3b",
}) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		const color = percentage <= 100 ? arcColor : overflowColor;
		// Set the canvas size to be 2x larger than the display size for higher resolution
		const scale = 2;
		canvas.width = size * scale;
		canvas.height = size * scale;
		canvas.style.width = `${size}px`;
		canvas.style.height = `${size}px`;

		// Scale all dimensions
		const centerX = (size * scale) / 2;
		const centerY = (size * scale) / 2;
		const radius = (size * scale) / 2;
		const innerRadius = radius * (1 - thickness);
		const startAngle = -Math.PI / 2;
		const endAngle = startAngle + (percentage / 100) * Math.PI * 2;

		// Clear canvas
		ctx.clearRect(0, 0, size * scale, size * scale);

		// Enable anti-aliasing
		ctx.imageSmoothingEnabled = true;
		ctx.imageSmoothingQuality = "high";

		// Draw background circle
		ctx.beginPath();
		ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
		ctx.fillStyle = backgroundColor;
		ctx.fill();

		// Create gradient for main arc
		const gradient = ctx.createConicGradient(startAngle, centerX, centerY);
		gradient.addColorStop(0, color);
		if (percentage <= 100) {
			gradient.addColorStop(0.25, color);
			gradient.addColorStop(0.5, `${color}CC`);  // 80% opacity
			gradient.addColorStop(0.75, `${color}99`);  // 60% opacity
		}
		gradient.addColorStop(1, color);

		// Draw main arc
		ctx.beginPath();
		ctx.arc(centerX, centerY, radius, startAngle, endAngle);
		ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
		ctx.closePath();
		ctx.fillStyle = gradient;
		ctx.fill();

		// Draw shadow for overlap if percentage > 100%
		if (percentage > 100) {
			const shadowGradient = ctx.createConicGradient(endAngle, centerX, centerY);
			shadowGradient.addColorStop(0, "rgba(0, 0, 0, 0.3)");
			shadowGradient.addColorStop(0.1, "rgba(0, 0, 0, 0.15)");
			shadowGradient.addColorStop(0.25, "rgba(0, 0, 0, 0)");

			ctx.beginPath();
			ctx.arc(centerX, centerY, radius, startAngle, startAngle + 2 * Math.PI);
			ctx.arc(centerX, centerY, innerRadius, startAngle + 2 * Math.PI, startAngle, true);
			ctx.closePath();
			ctx.fillStyle = shadowGradient;
			ctx.fill();
		}

		// Draw inner circle (white background)
		ctx.beginPath();
		ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
		ctx.fillStyle = "white";
		ctx.fill();

		// Draw end cap
		if (percentage > 100) {
			const capThickness = radius - innerRadius;
			const capCenterRadius = radius - capThickness / 2;
			const capCenterX = centerX + Math.cos(endAngle) * capCenterRadius;
			const capCenterY = centerY + Math.sin(endAngle) * capCenterRadius;

			ctx.beginPath();
			ctx.arc(capCenterX, capCenterY, capThickness / 2, 0, Math.PI * 2);
			ctx.fillStyle = color;
			ctx.fill();
		}

	}, [percentage, size, thickness, backgroundColor, arcColor, overflowColor]);

	return (
		<div className="relative"
			 style={{ width: size, height: size }}>
			<canvas ref={canvasRef}
					style={{ width: size, height: size }} />
			<div className="absolute inset-0 flex items-center justify-center">
				{children}
			</div>
		</div>
	);
};

export default OverflowPercentageChart;