import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type {
	CreateStaffMemberEmploymentData,
	StaffMemberEmployment,
	UpdateStaffMemberEmploymentData,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsTypes.ts";

const staffMemberEmploymentBasePath = (staffMemberId: string) => `/staff-members/${staffMemberId}/employments`;

export function indexAllStaffMembersEmployments(): AxiosApiResponsePromise<StaffMemberEmployment[]> {
	return axiosApi.get("/staff-members/employments");
}

export function indexStaffMembersEmployments(staffMemberId: string): AxiosApiResponsePromise<StaffMemberEmployment[]> {
	return axiosApi.get(staffMemberEmploymentBasePath(staffMemberId));
}

export function createStaffMemberEmployment(staffMemberId: string,
	employmentData: CreateStaffMemberEmploymentData): AxiosApiResponsePromise<void> {
	return axiosApi.post(staffMemberEmploymentBasePath(staffMemberId), employmentData);
}

export function updateStaffMemberEmployment({ staffMemberId, employmentId, updateData }: {
	staffMemberId: string;
	employmentId: string;
	updateData: UpdateStaffMemberEmploymentData
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${staffMemberEmploymentBasePath(staffMemberId)}/${employmentId}`, updateData);
}

export function deleteStaffMemberEmployment(staffMemberId: string, employmentId: string): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${staffMemberEmploymentBasePath(staffMemberId)}/${employmentId}`);
}