import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import type React from 'react';

interface DotPaginationProps {
  activeIndex: number;
  totalDots: number;
  onDotClick: (index: number) => void;
}

const DotPagination: React.FC<DotPaginationProps> = ({
  activeIndex,
  totalDots,
  onDotClick,
}) => {
  const handlePrevClick = () => {
    if (activeIndex > 0) {
      onDotClick(activeIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (activeIndex < totalDots - 1) {
      onDotClick(activeIndex + 1);
    }
  };

  return (
      <div className="flex items-center justify-center gap-2">
        <button
            onClick={handlePrevClick}
            disabled={activeIndex === 0}
            className="rounded-full p-1 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label="Previous page"
        >
          <ChevronLeftIcon className="size-5" />
        </button>

        <div className="flex gap-2">
          {Array.from({ length: totalDots }).map((_, index) => (
              <button
                  key={index}
                  onClick={() => onDotClick(index)}
                  className={`size-2 rounded-full transition-all duration-300 ${
                      activeIndex === index
                          ? 'bg-primary-900'
                          : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  aria-label={`Go to page ${index + 1}`}
                  aria-current={activeIndex === index ? 'true' : 'false'}
              />
          ))}
        </div>

        <button
            onClick={handleNextClick}
            disabled={activeIndex === totalDots - 1}
            className="rounded-full p-1 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50"
            aria-label="Next page"
        >
          <ChevronRightIcon className="size-5" />
        </button>
      </div>
  );
};

export default DotPagination;