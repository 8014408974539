import type { QueriesOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllQuotas, getQuota, indexQuotasByClientId } from "~/modules/project/api/quota/quotaApiDispatchers.ts";

const ENTITY_QUERY_KEY = "quota";

export const QUOTA_QUERY_KEY = (quotaId: string) => [ENTITY_QUERY_KEY, quotaId];
export const ALL_QUOTAS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];
export const QUOTAS_BY_CLIEBT_QUERY_KEY = (clientId: string) => [ENTITY_QUERY_KEY, clientId];
export const useQuota = (clientId: string, quotaId: string, options?: QueriesOptions<any>) => useQuery({
    queryKey: QUOTA_QUERY_KEY(quotaId),
    queryFn: () => getQuota(clientId, quotaId).then(getDataFromResponse),
	...options,
});

export const useAllQuotas = () => useQuery({
    queryKey: ALL_QUOTAS_QUERY_KEY,
    queryFn: () => getAllQuotas().then(getDataFromResponse)
});

export const useClientsQuotas = (clientId: string) => useQuery({
    queryKey: QUOTAS_BY_CLIEBT_QUERY_KEY(clientId),
    queryFn: () => indexQuotasByClientId(clientId).then(getDataFromResponse),
	enabled: !!clientId,
});