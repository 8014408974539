import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY,
	CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY,
} from "~/modules/client/api/clientContactPerson/clientContactPersonQueries.ts";
import CreateClientContactPersonForm from "~/modules/client/components/contact/forms/CreateClientContactPersonForm";

type AddContactPersonToClientSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
	companyId: string;
};

const AddContactPersonToClientSidebar: FunctionComponent<AddContactPersonToClientSidebarProps> = ({
	isOpen,
	setOpen,
	clientId,
	companyId,
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: CLIENT_CONTACT_PERSONS_BY_CLIENT_QUERY_KEY(clientId) });
		queryClient.invalidateQueries({ queryKey: ALL_CLIENT_CONTACT_PERSONS_QUERY_KEY });
		setOpen(false);
	}, [queryClient, clientId, setOpen]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			<CreateClientContactPersonForm
				clientId={clientId}
				companyId={companyId}
				onSuccess={handleSuccess}
				onCancel={() => setOpen(false)}
			/>
		</Sidebar>
	);
};

export default AddContactPersonToClientSidebar;
