import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	indexStaffMembersFtePercentages,
} from "~/modules/humanResources/api/staffMemberFtePercentage/staffMemberFtePercentageApiDispatchers.ts";

export const STAFF_MEMBER_FTE_PERCENTAGE_BASE_QUERY_KEY = "staffMembersFtePercentage";

export const STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY = (staffMemberId: string) => [STAFF_MEMBER_FTE_PERCENTAGE_BASE_QUERY_KEY, staffMemberId];

export const useStaffMembersFtePercentages = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_FTE_PERCENTAGES_QUERY_KEY(staffMemberId),
    queryFn: () => indexStaffMembersFtePercentages(staffMemberId).then(getDataFromResponse),
	enabled: !!staffMemberId,
});