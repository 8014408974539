import type { FunctionComponent } from "react";
import { Chart } from "react-chartjs-2";

import type {
	YearlyRevenueChartData,
} from "~/modules/reports/types/revenueReportViewTypes.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type Props = {data:YearlyRevenueChartData[]};

const YearlyRevenueByProjectChart: FunctionComponent<Props> = ({data}) => {
	const sortedData = data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
	const labels = sortedData.map(item => {
		return item.label
	});

	const chartData = {
		labels,
		datasets: [
			{
				data: sortedData.map(item => parseFloat(item.value)),
				backgroundColor: "rgb(30,53,96)",
				borderColor: "rgb(30,53,96)",
				borderWidth: 0,
			},
		],
	};

	const options = {
		indexAxis: 'y' as const,
		responsive: true,
		maintainAspectRatio: false,
		animation:false,
		scales: {
			x: {
				beginAtZero: true,
				title: {
					display: false,
					text: "Average Daily Rate (€)",
				},
				ticks: {
					callback: (value: number) => `${formatCentsToCurrency(value)}`,
				},
			},
			y: {
				offset: true,
				ticks: {
					autoSkip: false,
					maxRotation: 0,
					minRotation: 0,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context: any) => `${formatCentsToCurrency(context.parsed.x)}`,
				},
			},
			datalabels: {
				color: 'black',
				font: {
					weight: 'bold'
				},
				anchor: 'end',
				align: 'right',
				clamp:true,
				formatter: (value: number) => `${formatCentsToCurrency(value)}`,
			},
		},
	};


	return (
		<div style={{ height: `${Math.max(400, data.length * 40)}px`, width: "100%" }}>
			{/*@ts-expect-error library type definition is wrong*/}
			<Chart type="bar" data={chartData} options={options} />
		</div>
	);
};

export default YearlyRevenueByProjectChart;