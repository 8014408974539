import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getMonthlyBillingReport,
} from "~/modules/billing/api/monthlyBillingReport/monthlyBillingReportApiDispatchers.ts";

export const MONTHLY_BILLING_REPORT_BASE_QUERY_KEY = "billableProjectPhase";

export const MONTHLY_BILLING_REPORT_QUERY_KEY = (month: string) => [MONTHLY_BILLING_REPORT_BASE_QUERY_KEY, { month }];

export const useMonthlyBillingReport = ( month:string ) => useQuery({
	queryKey: MONTHLY_BILLING_REPORT_QUERY_KEY(month),
	queryFn: () => getMonthlyBillingReport({ month }).then(getDataFromResponse),
	enabled: !!month,
});