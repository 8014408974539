import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexUsersWorkingSchedules } from "~/modules/absence/api/workingSchedule/workingScheduleApiDispatchers.ts";

export const WORKING_SCHEDULE_BASE_QUERY_KEY = "workingSchedules";

export const STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY = (staffMemberId: string) => [WORKING_SCHEDULE_BASE_QUERY_KEY, { staffMemberId }];

export const useStaffMembersWorkingSchedules = (staffMemberId:string) => useQuery({
    queryKey: STAFF_MEMBERS_WORKING_SCHEDULES_QUERY_KEY(staffMemberId),
    queryFn: () => indexUsersWorkingSchedules({ staffMemberId }).then(getDataFromResponse),
	enabled: !!staffMemberId,
});
