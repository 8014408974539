import { useQueryClient } from "@tanstack/react-query";
import UpdateTotalCompensationEntryForm
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberPaymentsSection/components/CompensationComponentsOverview/components/UpdateTotalCompensationEntrySidebar/components/UpdateTotalCompensationEntryForm";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffMemberTotalCompensationQueries.ts";
import type {
	StaffMemberTotalCompensationWithDate,
} from "~/modules/humanResources/api/staffMemberTotalCompensation/staffTotalCompensationTypes.ts";

type CreateEmploymentSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	totalCompensationEntryData: StaffMemberTotalCompensationWithDate | null;
};

const UpdateTotalCompensationEntrySidebar: FunctionComponent<CreateEmploymentSidebarProps> = ({
	isOpen,
	onClose,
	totalCompensationEntryData,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!totalCompensationEntryData) return;
		await queryClient.refetchQueries({ queryKey: STAFF_MEMBERS_TOTAL_COMPENSATIONS_QUERY_KEY(totalCompensationEntryData.staffMemberId) });
		onClose();
	}, [totalCompensationEntryData, onClose, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{totalCompensationEntryData && <UpdateTotalCompensationEntryForm
				key={isOpen ? "open" : "closed"}
				onSuccess={handleSuccess}
				onCancel={onClose}
				totalCompensationEntryData={totalCompensationEntryData} />}
		</Sidebar>
	);
};

export default UpdateTotalCompensationEntrySidebar;
