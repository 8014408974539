import type { FunctionComponent } from "react";

const HorizontalStatDivider: FunctionComponent = () => {
	return (
		<div className="relative w-0 shrink-0">
			<div className="absolute inset-y-0 left-1/2 flex items-center">
				<div className="h-4/5 w-px bg-gray-200" />
			</div>
		</div>
	);
};

export default HorizontalStatDivider;