import { createColumnHelper } from "@tanstack/react-table";
import clsx from "clsx";
import Decimal from "decimal.js-light";

import type { MonthlyUtilisationTableData } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { UtilisationReportDeductiblesProperty } from "~/modules/reports/types/utilistationReportViewTypes.ts";
import { formatNumberWithComma } from "~/utils/numberUtils.ts";

const columnHelper = createColumnHelper<MonthlyUtilisationTableData>();
/*const widthValueColumns = 8;*/

function staffMemberHasWorkdaysTargetFte(row: MonthlyUtilisationTableData): boolean {
	return !row.workDaysTargetFte.eq(0);
}

export const monthlyUtilisationColumnDefs = [
	columnHelper.accessor("staffMemberDisplayName", {
		header: "Mitarbeiter:in",
		meta: {
			cellTextAlign: "left",
		},
		cell: props => {
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{props.getValue()}</div>;
		},
	}),
	columnHelper.accessor("ftePercentages", {
		header: "FTE",
		cell: props => {
			const displayValue = props.getValue().map((percentage: number) => {
				return formatNumberWithComma(percentage / 10000, 2, true);
			}).join(" / ");
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{displayValue}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor("regularWorkDays", {
		header: "Arbeitstage Monat",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{value}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor("regularWorkDaysFte", {
		header: "Arbeitstage Monat\n(FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{value}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor(UtilisationReportDeductiblesProperty.LongTermAbsenceDays, {
		header: "Langzeit-\nabwesenheiten\n(FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 1, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{formattedValue}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor(UtilisationReportDeductiblesProperty.TrainingAndEventAbsenceDays, {
		header: "Schulungen,\nInterne Events\n(FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 2, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{formattedValue}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor(UtilisationReportDeductiblesProperty.VacationDays, {
		header: "Urlaubstage\n(FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 2, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{formattedValue}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),
	columnHelper.accessor(UtilisationReportDeductiblesProperty.SickDays, {
		header: "Krankheitstage\n(FTE)",
		cell: props => {
			const value = new Decimal(props.getValue()).toNumber();
			let formattedValue = formatNumberWithComma(value, 2, true);
			if (value > 0) {
				formattedValue = "-" + formattedValue;
			}
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx(muted && "text-gray-400")}>{formattedValue}</div>;
		},
	/*	meta: {
			widthPercentage: widthValueColumns,
		},*/
	}),

	columnHelper.accessor("workDaysTargetFte", {
		header: "Arbeitstage Soll (FTE)",
		cell: props => {
			const value =props.getValue().toNumber();
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);

			return <div className={clsx("px-4 py-3 ",
				muted ? "bg-white text-gray-400 group-hover:bg-gray-200"
					: "bg-gray-200 font-bold group-hover:bg-gray-300")}>
				{formatNumberWithComma(value, 2, true)}
			</div>;
		},
		footer: props => {
			const total = props.table.getCoreRowModel().rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("workDaysTargetFte") || 0));
			}, new Decimal(0));
			return "∑ " + formatNumberWithComma(total.toNumber(), 2, true);
		},
		meta: {
			hasPadding: false,
			widthPercentage:10
		},
	}),
	columnHelper.accessor("projectMinutesTracked", {
		header: "Tage erfasst (Projekte)",
		cell: props => {
			const value = new Decimal(props.getValue()).div(480).toNumber();
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx("px-4 py-3 ",
				muted ? "bg-white text-gray-400 group-hover:bg-gray-200"
					: "bg-gray-200 font-bold group-hover:bg-gray-300")}>
				{formatNumberWithComma(value, 2, true)}
			</div>;
		},
		footer: props => {
			const total = props.table.getCoreRowModel().rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("projectMinutesTracked") || 0));
			}, new Decimal(0));
			const value = total.div(480).toNumber();
			return "∑ " + formatNumberWithComma(value, 2, true);
		},
		meta: {
			hasPadding: false,
			widthPercentage:10
		},
	}),
	columnHelper.accessor("utilisationPercentage", {
		header: "Projektauslastung",
		cell: props => {
			const value = props.getValue().toNumber();
			const muted = !staffMemberHasWorkdaysTargetFte(props.row.original);
			return <div className={clsx("px-4 py-3 ",
				muted ? "bg-white text-gray-400 group-hover:bg-gray-200"
					: "bg-gray-200 font-bold group-hover:bg-gray-300")}>
				{formatNumberWithComma(value, 2, true)} %
			</div>;
		},
		meta: {
			cellTextAlign: "right",
			hasPadding: false,
			widthPercentage:10
		},
		footer: props => {
			const rows = props.table.getCoreRowModel().rows;
			const totalWorkDaysTargetFte = rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("workDaysTargetFte") || 0));
			}, new Decimal(0));
			const totalProjectMinutesTracked = rows.reduce((sum, row) => {
				return sum.add(new Decimal(row.getValue("projectMinutesTracked") || 0));
			}, new Decimal(0));

			// Calculate utilization from footer sums
			const utilizationFromSums = totalProjectMinutesTracked.div(480).div(totalWorkDaysTargetFte).mul(100);

			// Calculate average utilization
			/*			const validRows = rows.filter(row => !!row.getValue("workDaysTargetFte"));
						const averageUtilization = validRows.reduce((sum, row) => {
							return sum.add(new Decimal(row.getValue("projectUtilizationPercentage") || 0));
						}, new Decimal(0)).div(validRows.length);*/

			return <div>∑ {formatNumberWithComma(utilizationFromSums.toNumber(), 2, true)} %</div>;
		},
	}),
];