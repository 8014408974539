import { format } from "date-fns";
import { de } from "date-fns/locale";
import ExcelJS from "exceljs";

import DownloadExcelButton from "~/components/buttons/DownloadExcelButton";

type ExcelTableData = {
	ftePercentages: number[];
	longTermAbsenceDaysFte: string;
	month: number;
	projectMinutesTracked: number;
	regularWorkDays: string;
	regularWorkDaysFte: string;
	sickAbsenceDaysFte: string;
	staffMemberDisplayName: string;
	trainingAndEventAbsenceDaysFte: string;
	vacationDaysFte: string;
};

const tableHeaderMapping = {
	staffMemberDisplayName: "Mitarbeiter:in",
	ftePercentages: "FTE",
	month: "Monat",
	regularWorkDays: "Arbeitstage Monat",
	regularWorkDaysFte: "Arbeitstage Monat (FTE)",
	longTermAbsenceDaysFte: "Langzeitabwesenheiten (FTE)",
	sickAbsenceDaysFte: "Krankheitstage (FTE)",
	trainingAndEventAbsenceDaysFte: "Schulungen & Interne Events (FTE)",
	vacationDaysFte: "Urlaubstage (FTE)",
	workDaysTargetFte: "Arbeitstage Soll (FTE)",
	projectMinutesTracked: "Tage erfasst (Projekte)",
	utilisationPercentage: "Projektauslastung",
};

const columnDefs = Object.entries(tableHeaderMapping).map(([key, header]) => ({
	accessor: key,
	header,
	type: key === "projectMinutesTracked" ? "number" :
		key === "ftePercentages" ? "number" :
			key === "month" ? "date" : "string",
}));

function DownloadYearlyUtilisationReportExcelButton({ data, year }: {
	data: ExcelTableData[],
	year: number
}) {
	const generateExcel = async () => {
		const workBookTitle = "Auslastungsreport " + format(new Date(year, 0), "yyyy", { locale: de });
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet(workBookTitle);

		// Add headers
		const headers = columnDefs.map(col => col.header);
		const headerRow = worksheet.addRow(headers);

		// Style header row
		headerRow.font = { bold: true };
		headerRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
		headerRow.height = 45;

		// Add data and apply formatting
		data.forEach((row) => {
			const excelRow = worksheet.addRow(
				columnDefs.map(col => {
					const value = row[col.accessor as keyof ExcelTableData];

					if (value === null) return null;

					if (col.accessor === "projectMinutesTracked" && typeof value === "number") {
						return value / (60 * 8); // Convert minutes to days (assuming 8-hour workday)
					}

					if (col.accessor === "ftePercentages" && Array.isArray(value)) {
						return value[0] / 10000; // Convert percentage to decimal
					}

					if (col.accessor === "month" && typeof value === "number") {
						return new Date(year, value, 1);
					}

					return value;
				}),
			);

			// Apply cell formatting
			columnDefs.forEach((col, colIndex) => {
				const cell = excelRow.getCell(colIndex + 1);

				switch (col.type) {
					case "number":
						cell.numFmt = "0.00";
						break;
					case "date":
						cell.numFmt = "mmmm";
						break;
				}

				cell.alignment = { vertical: "top", wrapText: true };
			});
		});

		// Add formula for workDaysTargetFTE
		const workDaysTargetCol = columnDefs.findIndex(col => col.accessor === "workDaysTargetFte");
		const regularWorkDaysFteCol = columnDefs.findIndex(col => col.accessor === "regularWorkDaysFte");
		const longTermAbsenceCol = columnDefs.findIndex(col => col.accessor === "longTermAbsenceDaysFte");
		const sickDaysCol = columnDefs.findIndex(col => col.accessor === "sickAbsenceDaysFte");
		const trainingsEventsCol = columnDefs.findIndex(col => col.accessor === "trainingAndEventAbsenceDaysFte");
		const vacationDaysCol = columnDefs.findIndex(col => col.accessor === "vacationDaysFte");

		if (workDaysTargetCol !== -1 && regularWorkDaysFteCol !== -1 && longTermAbsenceCol !== -1 &&
			sickDaysCol !== -1 && trainingsEventsCol !== -1 && vacationDaysCol !== -1) {
			for (let rowIndex = 2; rowIndex <= data.length + 1; rowIndex++) {
				const cell = worksheet.getCell(rowIndex, workDaysTargetCol + 1);
				cell.value = {
					formula: `${String.fromCharCode(65 + regularWorkDaysFteCol)}${rowIndex} - ${String.fromCharCode(65 + longTermAbsenceCol)}${rowIndex} - ${String.fromCharCode(65 + sickDaysCol)}${rowIndex} - ${String.fromCharCode(65 + trainingsEventsCol)}${rowIndex} - ${String.fromCharCode(65 + vacationDaysCol)}${rowIndex}`,
				};
				cell.numFmt = "0.00";
			}
		}

		// Update formula for utilisationPercentage
		const utilisationCol = columnDefs.findIndex(col => col.accessor === "utilisationPercentage");
		const projectDaysCol = columnDefs.findIndex(col => col.accessor === "projectMinutesTracked");

		if (utilisationCol !== -1 && projectDaysCol !== -1 && workDaysTargetCol !== -1) {
			for (let rowIndex = 2; rowIndex <= data.length + 1; rowIndex++) {
				const cell = worksheet.getCell(rowIndex, utilisationCol + 1);
				cell.value = {
					formula: `IFERROR(${String.fromCharCode(65 + projectDaysCol)}${rowIndex}/${String.fromCharCode(65 + workDaysTargetCol)}${rowIndex}, 0)`,
				};
				cell.numFmt = "0.00%";
			}
		}

		// Auto-fit columns
		worksheet.columns.forEach((column) => {
			let maxLength = 0;
			// @ts-expect-error - `eachCell` is not in the type definitions
			column.eachCell({ includeEmpty: true }, (cell) => {
				const cellLength = cell.value ? cell.value.toString().length : 10;
				if (cellLength > maxLength) {
					maxLength = cellLength;
				}
			});
			column.width = Math.min(maxLength + 2, 30);
		});

		// Freeze top row
		worksheet.views = [
			{ state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2", activeCell: "A2" },
		];

		// Set German locale for the workbook
		workbook.views = [
			{
				x: 0, y: 0, width: 10000, height: 20000,
				firstSheet: 0, activeTab: 1, visibility: "visible",
			},
		];
		workbook.calcProperties.fullCalcOnLoad = true;

		// Generate and download the Excel file
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = workBookTitle + ".xlsx";
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return <DownloadExcelButton
		theme="white"
		buttonText=""
		onClick={generateExcel}
	/>;
}

export default DownloadYearlyUtilisationReportExcelButton;