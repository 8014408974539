import { useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "components/LoadingSpinners/LoadingSpinner";
import type React from "react";
import { useCallback, useMemo } from "react";

import Sidebar from "~/components/Sidebar";
import { useAuth } from "~/contexts/AuthContext";
import { useClient } from "~/modules/client/api/client/clientQueries.ts";
import { PROJECTS_ORDERS_QUERY_KEY } from "~/modules/project/api/order/orderQueries.ts";
import { USERS_ACTIVE_STAFFINGS_QUERY_KEY } from "~/modules/project/api/staffing/staffingQueries.ts";
import type { OrderDefaultValuesFromClientType } from "~/modules/project/components/forms/CreateOrderForm";
import CreateOrderForm from "~/modules/project/components/forms/CreateOrderForm";

type CreateOrderSidebarProps = {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	clientId: string;
	projectId: string;
	projectPhaseId: string | null;
};

const CreateOrderSidebar: React.FunctionComponent<CreateOrderSidebarProps> = ({
	isOpen,
	setOpen,
	clientId,
	projectId,
	projectPhaseId,
}) => {
	const { data: clientData } = useClient(clientId);
	const { user } = useAuth();


	const clientDefaultValues = useMemo((): OrderDefaultValuesFromClientType | undefined => {
		if (clientData) {
			return {
				invoiceByMail: clientData.invoiceByMail,
				paymentTargetDays: clientData.paymentTargetDays,
				isVatExempt: clientData.isVatExempt,
				travelExpensesIncluded: clientData.travelExpensesIncluded,
			};
		}
	}, [clientData]);

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.invalidateQueries({ queryKey: PROJECTS_ORDERS_QUERY_KEY(projectId) });
		queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user!.id) });
		setOpen(false);
	}, [projectId, queryClient, setOpen, user]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => setOpen(!isOpen)}>
			{!clientDefaultValues || !projectPhaseId ? (
				<LoadingSpinner />
			) : (
				<CreateOrderForm
					clientId={clientId}
					projectId={projectId}
					projectPhaseId={projectPhaseId}
					defaultValuesFromClient={clientDefaultValues}
					onSuccess={handleSuccess}
					onCancel={() => setOpen(false)}
				/>
			)}
		</Sidebar>
	);
};

export default CreateOrderSidebar;
