import { PencilIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useState } from "react";

import Avatar from "~/components/Avatar/Avatar.tsx";
import ButtonWithPopover from "~/components/buttons/ButtonWithPopover";
import ProjectStatsCompactBarChart from "~/components/charts/ProjectStatsCompactBarChart";
import SidebarBusyOverlay from "~/components/Sidebar/components/SidebarBusyOverlay";
import Status from "~/components/Status/Status.tsx";
import { useAuth } from "~/contexts/AuthContext";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import { USER_LOCATIONS_QUERY_KEY } from "~/modules/location/api/location/locationQueries.ts";
import type { ProjectRole } from "~/modules/project/api/projectRole/projectRoleTypes.ts";
import { updateStaffingActivity } from "~/modules/project/api/staffing/staffingApiDispatchers.ts";
import {
	PROJECTS_STAFFINGS_QUERY_KEY,
	USERS_ACTIVE_STAFFINGS_QUERY_KEY,
} from "~/modules/project/api/staffing/staffingQueries.ts";
import { USER_DELIVERABLES_QUERY_KEY } from "~/modules/timeTracking/api/deliverable/deliverableQueries.ts";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

interface StaffingProps {
	avatarImage: AvatarImage | null;
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	dailyRateCents: number;
	firstName: string;
	fullName: string;
	staffingId: string;
	lastName: string;
	manDaysPlanned: number;
	manDaysTracked: number;
	onEditClick: () => void;
	onEditRemove: () => void;
	projectId: string;
	projectRole?: ProjectRole;
	showBudget: boolean;
	staffedUserId: string;
	staffingIsActive: boolean;
	userIsActive: boolean;
}

const StaffingDetails: FunctionComponent<StaffingProps> = ({
	avatarImage,
	budgetCentsPlanned,
	budgetCentsTracked,
	dailyRateCents,
	firstName,
	fullName,
	lastName,
	manDaysPlanned,
	manDaysTracked,
	onEditClick,
	onEditRemove,
	projectId,
	projectRole,
	showBudget,
	staffingId,
	staffingIsActive,
	staffedUserId,
	userIsActive,
}) => {
	const { user } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [activityUpdateFailed, setActivityUpdateFailed] = useState(false);
	const queryClient = useQueryClient();
	const inactiveClass = !staffingIsActive ? "opacity-30" : "";
	const handleActivityClick = useCallback(async () => {
		setIsLoading(true);

		try {
			await updateStaffingActivity({ staffingId, projectId, isActive: !staffingIsActive });
			await queryClient.invalidateQueries({ queryKey: PROJECTS_STAFFINGS_QUERY_KEY(projectId) });
			if (staffedUserId === user!.id) {
				queryClient.invalidateQueries({ queryKey: USERS_ACTIVE_STAFFINGS_QUERY_KEY(user!.id) });
				queryClient.invalidateQueries({ queryKey: USER_DELIVERABLES_QUERY_KEY(user!.id) });
				queryClient.invalidateQueries({ queryKey: USER_LOCATIONS_QUERY_KEY(user!.id) });
			}

		} catch (error) {
			setActivityUpdateFailed(true);
		}

		setIsLoading(false);
	}, [projectId, queryClient, staffedUserId, staffingId, staffingIsActive, user]);

	useEffect(() => {
		if (activityUpdateFailed) {
			setTimeout(() => {
				setActivityUpdateFailed(false);
			}, 1500);
		}
	}, [activityUpdateFailed]);

	return (
		<div className={clsx("relative rounded-lg  px-4 pb-16 pt-4 shadow", !staffingIsActive ? "bg-white/30" : "bg-white")}>
			{isLoading && <SidebarBusyOverlay showSpinner={true}/>}
			<dt>
				<div className={clsx("absolute", inactiveClass)}>
					<Avatar
						firstName={firstName}
						lastName={lastName}
						image={avatarImage}
					/>
				</div>
				<div className={clsx("ml-14 mr-6", inactiveClass)}>
					<div className="font-bold">{fullName}</div>
					{projectRole && <div className="-mt-1 text-sm">{projectRole.displayName}</div>}
					<div className="mt-2 text-xs text-gray-500">
						Tagessatz:{" "}
						{formatCentsToCurrency(dailyRateCents, 0)}
					</div>
					<div className="text-xs text-gray-500">
						Personentage:{" "}
						{manDaysPlanned.toFixed(0)}
					</div>
					<div className="mt-1 flex grow gap-x-1">
						<Status onClick={handleActivityClick}
								as="button"
								theme={staffingIsActive ? "success" : "default"}>{staffingIsActive ? "Zeiterfassung aktiv" : "Zeiterfassung nicht aktiv"}</Status>
						{staffingIsActive && !userIsActive && <Status theme="error">User inaktiv</Status>}
					</div>
				</div>
				{activityUpdateFailed &&
					<div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-100/80">
						<div className="w-full bg-red-400 p-2 text-center text-sm text-white">Status
							Update fehlgeschlagen
						</div>
					</div>}
				<div className="absolute right-3 top-4">
					<ButtonWithPopover
						theme="dark"
						items={[
							{
								label: "bearbeiten",
								onClick: onEditClick,
								icon: PencilIcon,
							},
							{
								label: "entfernen",
								disabled: manDaysTracked > 0,
								onClick: onEditRemove,
								icon: XCircleIcon,
							},
						]}
					/>
				</div>
			</dt>
			<dd className={clsx("flex items-baseline pb-6 sm:pb-7", inactiveClass)}>
				<div className="absolute inset-x-0 bottom-0 rounded-b-lg bg-gray-100 p-4">
					<div
						className={clsx("mb-2 text-xs font-normal", {
							"text-gray-400": 0 === manDaysTracked,
						})}
					>
						{0 === manDaysTracked ? "Keine Trackings vorhanden" : "Trackings"}
					</div>
					<ProjectStatsCompactBarChart manDaysPlanned={manDaysPlanned}
												 manDaysTracked={manDaysTracked}
												 budgetCentsPlanned={budgetCentsPlanned}
												 budgetCentsTracked={budgetCentsTracked}
												 showBudget={showBudget}
												 valuesVisible={false}
					/>
				</div>
			</dd>
		</div>
	);
};

export default StaffingDetails;
