import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_PROJECT_REFERENCES_QUERY_KEY,
	PROJECTS_REFERENCE_TEXTS_QUERY_KEY,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";
import CreateOrUpdateReferenceTextForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/CreateOrUpdateReferenceTextForm";

type UpdateReferenceTextSidebarSidebarProps = {
	isOpen: boolean;
	close: () => void;
	referenceText: ProjectReferenceText|null;
};

const UpdateReferenceTextSidebar: React.FunctionComponent<UpdateReferenceTextSidebarSidebarProps> = ({
	isOpen,
	close,
	referenceText,
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		if (!referenceText) return;
		await queryClient.refetchQueries({ queryKey: PROJECTS_REFERENCE_TEXTS_QUERY_KEY(referenceText.projectId) });
		queryClient.invalidateQueries({ queryKey: ALL_PROJECT_REFERENCES_QUERY_KEY });
		close();
	}, [close, referenceText, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 width="xl"
				 open={isOpen}
				 setOpen={() => close()}>
			{isOpen && referenceText && <CreateOrUpdateReferenceTextForm onCancel={close}
																		 onSuccess={handleSuccess}
																		 projectId={referenceText?.projectId}
																		 referenceText={referenceText}
			/>}

		</Sidebar>
	);
};

export default UpdateReferenceTextSidebar;
