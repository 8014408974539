import type { FunctionComponent } from "react";

import FormSection from "~/components/formElements/FormSection";


const SicknessAbsenceGuideline: FunctionComponent = () => {
	return <FormSection title="Checkliste Krankmeldung">
		<ol className="list-decimal space-y-4">
			<li><span className="font-bold">Vor Arbeitsbeginn: Info an Suse & Ragna.</span><br/>Bei Krankschreibung: Meldung der genauen Daten
				(von...bis...)
			</li>
			<li>
				<span className="font-bold">Info an Projektleitung / Projektteam und Kunde</span><br/>(gemäß Absprache im Projekt)
			</li>
			<li>
				<span className="font-bold">Abwesenheit in Outlook Kalender eintragen</span><br/>Idealerweise / falls möglich: Termine aktiv absagen
			</li>
			<li>
				<span className="font-bold">Abwesenheitsnotiz in Outlook eintragen</span>
			</li>
		</ol>
	</FormSection>;
};

export default SicknessAbsenceGuideline;