import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexCompanyAvatarImages } from "~/modules/image/api/avatarImage/avatarImageApiDispatchers.ts";

export const COMPANY_AVATAR_IMAGES_BASE_QUERY_KEY = "company-avatar-images";

export const ALL_COMPANY_AVATAR_IMAGES_QUERY_KEY = [COMPANY_AVATAR_IMAGES_BASE_QUERY_KEY, "all"];

export const useAllCompanyAvatarImages = (enabled: boolean = true) => useQuery({
    queryKey: ALL_COMPANY_AVATAR_IMAGES_QUERY_KEY,
    queryFn: () => indexCompanyAvatarImages().then(getDataFromResponse),
    enabled
});