import CardValueWithLabel from "components/CardValueWithLabel";
import MentorSection
	from "modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/MentorSection";
import type { FunctionComponent } from "react";
import { useMemo } from "react";

import Card from "~/components/Card";
import Section from "~/components/sections/Section";
import { EMPTY_VALUE_PLACEHOLDER } from "~/constants/textConstants.ts";
import type { CareerLevelId } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { EmploymentTypeId } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import type { StaffMember } from "~/modules/humanResources/api/staffMember/staffMemberTypes.ts";
import {
	useStaffMembersEmployments,
} from "~/modules/humanResources/api/staffMemberEmployments/staffMemberEmploymentsQueries.ts";
import CareerLevelsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CareerLevelsSection";
import CompanyLocationSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/CompanyLocationSection";
import EmploymentsSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/EmploymentsSection";
import MenteesSection
	from "~/modules/humanResources/components/StaffMemberDetailsView/components/StaffMemberEmploymentDataSection/components/MenteesSection";
import { getCareerLevelDisplayNameById } from "~/modules/humanResources/utils/careerLevelUtils.ts";
import { getEmploymentTypeDisplayNameById } from "~/modules/humanResources/utils/employmentTypeUtils.ts";
import { formatDateWithGermanMonth } from "~/utils/dateAndTimeUtils.ts";

type Props = {
	staffMemberCoreData: StaffMember
};

const StaffMemberEmploymentDataSection: FunctionComponent<Props> = ({ staffMemberCoreData }) => {
	const {
		data: allEmploymentsData,
	} = useStaffMembersEmployments(staffMemberCoreData.id);

	const careerLevelDisplayName = staffMemberCoreData.currentCareerLevelId ? getCareerLevelDisplayNameById(staffMemberCoreData.currentCareerLevelId as CareerLevelId) : EMPTY_VALUE_PLACEHOLDER;
	const employmentTypeDisplayName = staffMemberCoreData.currentEmploymentTypeId ? getEmploymentTypeDisplayNameById(staffMemberCoreData.currentEmploymentTypeId as EmploymentTypeId) : EMPTY_VALUE_PLACEHOLDER;

	const [employmentStartDate, employmentEndDate] = useMemo(() => {
		let employmentStartDate = null;
		let employmentEndDate = null;
		if (allEmploymentsData !== undefined) {
			const employmentsSorted = allEmploymentsData.sort((a,
				b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime());

			employmentsSorted.forEach((employment, index) => {
				const previousElement = employmentsSorted[index - 1];
				const isStartItem = !previousElement || previousElement.employmentTypeId as EmploymentTypeId === EmploymentTypeId.Left;
				const isLeft = employment.employmentTypeId as EmploymentTypeId === EmploymentTypeId.Left;

				if (isStartItem) {
					employmentStartDate = employment.validFrom;
				} else if (isLeft) {
					employmentEndDate = employment.validFrom;
				}

				if (index === employmentsSorted.length - 1 && !isLeft) {
					employmentEndDate = null;
				}
			});
		}

		employmentStartDate = employmentStartDate ? formatDateWithGermanMonth(new Date(employmentStartDate)) : EMPTY_VALUE_PLACEHOLDER;
		employmentEndDate = employmentEndDate ? formatDateWithGermanMonth(new Date(employmentEndDate)) : EMPTY_VALUE_PLACEHOLDER;

		return [employmentStartDate, employmentEndDate];
	}, [allEmploymentsData]);

	return <Section scrollable={true}>
		<div className="mb-10 grid w-full grid-cols-6 gap-4">
			<div className="col-span-6">
				<Card padding="none"
					  title="Aktuelle Beschäftigungsperiode"
					  theme="highlight"
				>
					<div className="grid grid-cols-4">
						<CardValueWithLabel theme="dark" label="Beschäftigungsart">
							{employmentTypeDisplayName}
						</CardValueWithLabel>
						<CardValueWithLabel theme="dark" label="Karrierelevel">
							{careerLevelDisplayName}
						</CardValueWithLabel>
						<CardValueWithLabel theme="dark" label="(Wieder-) Eintrittsdatum">
							{employmentStartDate}
						</CardValueWithLabel>
						<CardValueWithLabel theme="dark" label="Austrittsdatum">
							{employmentEndDate}
						</CardValueWithLabel>
					</div>
				</Card>
			</div>
			<CompanyLocationSection staffMemberCoreData={staffMemberCoreData} />
			<MentorSection staffMemberCoreData={staffMemberCoreData} />
			<MenteesSection staffMemberId={staffMemberCoreData.id} />
			<EmploymentsSection staffMemberId={staffMemberCoreData.id} />
			<CareerLevelsSection staffMemberId={staffMemberCoreData.id} />
		</div>
	</Section>;
};

export default StaffMemberEmploymentDataSection;