export const getAllClientsEndpoint = "clients";
export const createClientEndpoint = "clients";

export function getClientEndpoint(clientId: string) {
	return `clients/${clientId}`;
}

export function updateClientEndpoint(clientId: string) {
	return `clients/${clientId}`;
}
