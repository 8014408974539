import { useQueryClient } from "@tanstack/react-query";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { ALL_PROJECTS_QUERY_KEY, PROJECT_QUERY_KEY } from "~/modules/project/api/project/projectQueries.ts";
import {
	ALL_PROJECT_TAGS_QUERY_KEY,
	PROJECT_TAG_USAGE_REPORT_QUERY_KEY,
} from "~/modules/project/api/projectTag/projectTagQueries.ts";
import type { ProjectTagPivot, ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import UpdateProjectTagsForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectTaggingContent/components/ProjectTags/components/UpdateProjectTagsSidebar/components/UpdateProjectTagsForm";

type UpdateProductTagsSidebarProps = {
	isOpen: boolean;
	onClose: () => void;
	projectTagIds: ProjectTagPivot[];
	projectId: string;
	tagTypeId: ProjectTagTypeId;
};

const UpdateProjectTagsSidebar: FunctionComponent<UpdateProductTagsSidebarProps> = ({
	isOpen,
	onClose,
	projectTagIds,
	projectId,
	tagTypeId
}) => {
	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({ queryKey: PROJECT_QUERY_KEY(projectId) });
		queryClient.refetchQueries({ queryKey: ALL_PROJECT_TAGS_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: PROJECT_TAG_USAGE_REPORT_QUERY_KEY });
		queryClient.invalidateQueries({ queryKey: ALL_PROJECTS_QUERY_KEY });
		onClose();
	}, [onClose, projectId, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={() => onClose()}>
			{isOpen && <UpdateProjectTagsForm onSuccess={handleSuccess}
											  onClose={onClose}
											  projectId={projectId}
											  projectTagIds={projectTagIds}
											  tagTypeId={tagTypeId}
			/>}
		</Sidebar>
	);
};

export default UpdateProjectTagsSidebar;
