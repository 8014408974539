import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllCurrencies } from "~/modules/country/api/currency/currencyApiDispatchers.ts";

const ENTITY_QUERY_KEY = "currency";
export const ALL_CURRENCIES_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllCurrencies = (enabled: boolean = true) => useQuery({
    queryKey: ALL_CURRENCIES_QUERY_KEY,
    queryFn: () => getAllCurrencies().then(getDataFromResponse),
    enabled
});