import { useQueryClient } from "@tanstack/react-query";
import UpdateVacationEntitlementForm
	from "components/forms/UpdateVacationEntitlementForm";
import type React from "react";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import { STAFF_MEMBERS_ABSENCES_QUERY_KEY } from "~/modules/absence/api/absence/absenceQueries.ts";
import {
	STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY,
} from "~/modules/absence/api/vacationEntitlement/vacationEntitlementQueries.ts";
import type { VacationEntitlement } from "~/modules/absence/api/vacationEntitlement/vacationEntitlementTypes.ts";
import {
	STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY,
	STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY,
} from "~/modules/absence/api/vacationLedgerEntry/vacationLedgerEntryQueries.ts";

type UpdateVacationEntitlementSidebarProps = {
	onClose: () => void;
	isOpen: boolean;
	minDate: Date;
	vacationEntitlementData: VacationEntitlement | null
};

const UpdateVacationEntitlementSidebar: React.FunctionComponent<UpdateVacationEntitlementSidebarProps> = ({
	onClose,
	isOpen,
	minDate,
	vacationEntitlementData,
}) => {
	const queryClient = useQueryClient();

	const handleSuccess = useCallback(
		async () => {
			if (!vacationEntitlementData) return;
			queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_ENTITLEMENTS_QUERY_KEY(vacationEntitlementData.staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_REPORT_QUERY_KEY(vacationEntitlementData.staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_ABSENCES_QUERY_KEY(vacationEntitlementData.staffMemberId) });
			await queryClient.invalidateQueries({ queryKey: STAFF_MEMBERS_VACATION_LEDGER_ENTRIES_QUERY_KEY(vacationEntitlementData.staffMemberId) });
			onClose();
		},
		[vacationEntitlementData, queryClient, onClose],
	);
	const vacationEntitlementDataWithDate = vacationEntitlementData ? {
		...vacationEntitlementData,
		validFrom: new Date(vacationEntitlementData.validFrom),
	} : null;
	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 setOpen={onClose}>
			{vacationEntitlementDataWithDate !== null && <UpdateVacationEntitlementForm
				onCancel={onClose}
				onSuccess={handleSuccess}
				vacationEntitlementData={vacationEntitlementDataWithDate}
				minDate={minDate}

			/>}
		</Sidebar>
	);
};

export default UpdateVacationEntitlementSidebar;
