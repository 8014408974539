type FormIsSubmittableProps = {
	isDirty: boolean;
	isValid: boolean;
	isLoading: boolean;
	isSubmitted: boolean;
	enableInitially?: boolean;
}

export const useFormIsSubmittable = ({
	isDirty,
	isValid,
	isLoading,
	isSubmitted,
	enableInitially = true,
}: FormIsSubmittableProps): boolean => {

	// Create forms are initially enabled and errors are triggered on submit.
	if (enableInitially) {
		if (!isSubmitted) {
			return true;
		}
		return !isLoading && isValid;
	}

	return isDirty && isValid && !isLoading;
};