import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import Sidebar from "~/components/Sidebar";
import {
	ALL_PROJECT_REFERENCES_QUERY_KEY,
	PROJECTS_REFERENCE_TEXTS_QUERY_KEY,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import CreateOrUpdateReferenceTextForm
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/CreateOrUpdateReferenceTextForm";

type CreateReferenceTextSidebarSidebarProps = {
	isOpen: boolean;
	close: () => void;
	projectId: string;
};

const CreateReferenceTextSidebar: React.FunctionComponent<CreateReferenceTextSidebarSidebarProps> = ({
	isOpen,
	close,
	projectId
}) => {

	const queryClient = useQueryClient();
	const handleSuccess = useCallback(async () => {
		await queryClient.refetchQueries({queryKey: PROJECTS_REFERENCE_TEXTS_QUERY_KEY(projectId)});
		queryClient.invalidateQueries({queryKey: ALL_PROJECT_REFERENCES_QUERY_KEY});
		close();
	}, [close, projectId, queryClient]);

	return (
		<Sidebar closeOnOutsideClick={false}
				 width="xl"
				 open={isOpen}
				 setOpen={() => close()}>
			{isOpen && <CreateOrUpdateReferenceTextForm onCancel={close}
														onSuccess={handleSuccess}
			projectId={projectId}
			/>}

		</Sidebar>
	);
};

export default CreateReferenceTextSidebar;
