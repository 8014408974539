import Headline from "~/components/Headline";

const NoEntriesFound: React.FC = () => {
	return (
		<div className="flex flex-col items-center">
			<p className="text-base font-semibold text-primary-500">Alles weg?</p>
			<Headline type="h1">Keine passenden Einträge gefunden</Headline>
			<p className="mt-1 text-base leading-7 text-gray-600">Bitte passe deine Suche an ...</p>
		</div>
	);
};

export default NoEntriesFound;
