import type { FunctionComponent } from "react";
import { useMemo } from "react";

import Card from "~/components/Card";
import HorizontalStats from "~/components/HorizontalStats";
import HorizontalStatDivider
	from "~/components/HorizontalStats/components/HorizontalStatDetail/HorizontalStatDivider/HorizontalStatDivider";
import type { HorizontalStat } from "~/components/HorizontalStats/horizontalStatsTypes.ts";
import OverflowPercentageChart from "~/components/OverflowPercentageChart/OverflowPercentageChart.tsx";
import Tooltip from "~/components/Tooltip";
import { formatCentsToCurrency } from "~/utils/currencyUtils.ts";

type Props = {
	budgetCentsPlanned: number;
	budgetCentsTracked: number;
	manDaysPlanned: number;
	manDaysTracked: number;
	showBudget?: boolean;
}

const PhaseBudgetStatsSection: FunctionComponent<Props> = ({
	budgetCentsPlanned,
	budgetCentsTracked,
	manDaysPlanned,
	manDaysTracked,
	showBudget = true,
}) => {
	const plannedValue = showBudget ? budgetCentsPlanned : manDaysPlanned;
	const trackedValue = showBudget ? budgetCentsTracked : manDaysTracked;
	const percentageTracked = (trackedValue / plannedValue) * 100;
	const rest = plannedValue - trackedValue;

	const plannedValueFormatted = showBudget ? formatCentsToCurrency(plannedValue) : plannedValue.toFixed(0) + " PT";
	const trackedValueFormatted = showBudget ? formatCentsToCurrency(trackedValue) : trackedValue > 0 && trackedValue < 1 ? "<" : "" + +trackedValue.toFixed(0) + " PT";
	const restFormatted = showBudget ? formatCentsToCurrency(rest) : rest.toFixed(0) + " PT";
	const percentageTrackedFormatted = percentageTracked === Infinity ? "n/a" : percentageTracked.toFixed(0) + "%";

	const stats: HorizontalStat[] = useMemo(() => {
		return [
			{
				label: "Verfügt",
				value: trackedValueFormatted,
			},
			{
				label: "Rest",
				value: restFormatted,
				highlight: rest < 0,
			},
		];
	}, [rest, restFormatted, trackedValueFormatted]);
	const tooltipText = "Das Gesamtbudget der Phase errechnet sich aus der Summe der Budgets aller bestätigten Bestellungen der Phase. Sind keine bestätigten Bestellungen vorhanden, wird hier der Planwert der Phase angezeigt.";
	return <Card>
		<HorizontalStats stats={stats}>
			<OverflowPercentageChart percentage={percentageTracked === Infinity ? 0 : percentageTracked}
									 size={90}
									 thickness={0.4}>
				<div className="text-center text-sm font-bold">
					{percentageTrackedFormatted}
				</div>
			</OverflowPercentageChart>
			<div className="flex grow flex-col items-center justify-center px-2">
				<div className="flex px-1 text-xs font-medium text-gray-500">
					<span className="mr-1">Gesamtbudget Phase</span>
					<Tooltip text={tooltipText}
							 width={280}
					>
						<div className="flex size-4 cursor-default select-none items-center justify-center rounded-full bg-gray-300 text-xs text-white">?</div>
					</Tooltip>
				</div>
				<div className="text-lg font-bold">
					{plannedValueFormatted}
				</div>
			</div>
			<HorizontalStatDivider />
		</HorizontalStats>
	</Card>;
};

export default PhaseBudgetStatsSection;