import AbsenceCalendarPage from "pages/absence/AbsenceCalendarPage";
import StaffStatisticsPage from "pages/humanResources/StaffStatisticsPage";
import GlobalReportsPage from "pages/reports/GlobalReportsPage";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import AppLayoutPlain from "~/components/layout/AppLayoutPlain.tsx";
import RootPage from "~/components/layout/RootPage.tsx";
import {
	ADMIN_PATH_WITH_TAB_ID,
	appRoutes,
	COMPANY_DETAILS_PATH,
	HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID,
	HR_STAFF_MEMBERS_INDEX_PATH,
	HR_STAFF_MONTHLY_REPORT_PATH,
	HR_STATISTICS_PATH,
	HR_TEAM_OVERVIEW_PATH,
	PROJECT_DESCRIPTIONS_INDEX_PATH,
	PROJECT_DETAILS_PATH_WITH_TAB_ID,
	REPORTS_PATH_WITH_TAB_ID,
	STAFF_MEMBER_ABSENCES_PATH,
	STAFF_MEMBER_VACATION_LEDGER_PATH,
	STAFF_MEMBER_WORKING_SCHEDULES_PATH,
	TIME_TRACKINGS_PATH,
} from "~/constants/appRoute.ts";
import { COMPENSATION_VISIBILITY_CHANNEL_NAME } from "~/constants/broadcastChannelNames.ts";
import { APP_MODE } from "~/constants/envConstants.ts";
import { useAuth } from "~/contexts/AuthContext";
import useBroadcastChannel from "~/hooks/useBroadcastChannel";
import { useCommonData } from "~/hooks/useCommonData/useCommonData";
import AbsencesPage from "~/pages/absence/AbsencesPage";
import VacationLedgerPage from "~/pages/absence/VacationLedgerPage";
import WorkingSchedulesPage from "~/pages/absence/WorkingSchedulesPage";
import AdminPage from "~/pages/admin/AdminPage";
import MonthlyBillingReportPage from "~/pages/billing/MonthlyInvoicingPage";
import ClientDetailsPage from "~/pages/client/ClientDetailsPage.tsx";
import ClientsIndexPage from "~/pages/client/ClientsIndexPage.tsx";
import CompanyDetailsPage from "~/pages/client/CompanyDetailsPage.tsx";
import ContactDetailsPage from "~/pages/contact/ContactDetailsPage.tsx";
import ContactsIndexPage from "~/pages/contact/ContactsIndexPage.tsx";
import DevPlayground from "~/pages/DevPlayground";
import Error404Page from "~/pages/Error404Page.tsx";
import StaffMemberDetailsPage from "~/pages/humanResources/StaffMemberDetailsPage";
import StaffMembersIndexPage from "~/pages/humanResources/StaffMembersIndexPage";
import StaffMonthlyReportPage from "~/pages/humanResources/StaffMonthlyReportPage";
import StaffTeamOverviewPage from "~/pages/humanResources/StaffTeamOverviewPage";
import LandingPage from "~/pages/landingPage";
import LoginPage from "~/pages/login/LoginPage.tsx";
import LogoutPage from "~/pages/logout/LogoutPage";
import ProtectedRoutes from "~/pages/mainRouter/ProtectedRoutes/ProtectedRoutes";
import ProjectDetailsPage from "~/pages/project/ProjectDetailsPage.tsx";
import ProjectReferenceTextsSearchPage from "~/pages/project/ProjectReferenceTextsSearchPage.tsx";
import ProjectsIndexPage from "~/pages/project/ProjectsIndexPage.tsx";
import TimeTrackingPage from "~/pages/timetracking/TimeTrackingPage";
import RequestPasswordReset from "~/pages/users/RequestPasswordReset";
import ResetPassword from "~/pages/users/ResetPassword";
import { setIntendedLocation } from "~/services/intendedLocation";
import { useStaffMemberCompensationVisibilityStore } from "~/stores/useStaffMemberCompensationVisibilityStore.ts";
import { PermissionNames } from "~/types/entityNames.ts";


const MainRouter: FunctionComponent = () => {
	const { user, isCheckingAuthStatus, hasAnyPermission } = useAuth();
	const location = useLocation();

	useCommonData(!!user);
	useEffect(() => {
		if (!isCheckingAuthStatus && !user && ![appRoutes.login().path].includes(location.pathname)) {
			setIntendedLocation();
		}
	}, [location.pathname, user, isCheckingAuthStatus]);

	const setVisibility = useStaffMemberCompensationVisibilityStore((state) => state.setVisibility);

	const { start } = useIdleTimer({
		onIdle: () => {
			setVisibility(false);
		},
		startManually: true,
		events: ["keydown", "mousedown", "scroll"],
		timeout: 60 * 1000,
		throttle: 500,
		crossTab: true,
	});


	useEffect(() => {
		if (user && hasAnyPermission([PermissionNames.CanManagesStaffSalaries])) {
			start();
		}
	}, [user, setVisibility, hasAnyPermission, start]);

	useBroadcastChannel(COMPENSATION_VISIBILITY_CHANNEL_NAME, (e) => {
		if (e.data === "show") {
			setVisibility(true);
		}

		if (e.data === "hide") {
			setVisibility(false);
		}
	});

	return (
		<>
			{!isCheckingAuthStatus && (
				<Routes>
					<Route element={<AppLayoutPlain />}>
						<Route element={<ProtectedRoutes />}>
							<Route path=""
								   element={<LandingPage />} />
							<Route path={appRoutes.absenceOverview().path}
								   element={<AbsenceCalendarPage />} />
							<Route path={appRoutes.clients().path}
								   element={<ClientsIndexPage />} />
							<Route path={appRoutes.client().path}
								   element={<ClientDetailsPage />} />
							<Route path={appRoutes.contacts().path}
								   element={<ContactsIndexPage />} />
							<Route path={appRoutes.contact().path}
								   element={<ContactDetailsPage />} />
							<Route path={appRoutes.monthlyBillingReport().path}
								   element={<MonthlyBillingReportPage />} />
							<Route path={appRoutes.projects().path}
								   element={<ProjectsIndexPage />} />
							<Route path={COMPANY_DETAILS_PATH}
								   element={<CompanyDetailsPage />} />
							<Route path={PROJECT_DESCRIPTIONS_INDEX_PATH}
								   element={<ProjectReferenceTextsSearchPage />} />
							<Route path={PROJECT_DETAILS_PATH_WITH_TAB_ID}
								   element={<ProjectDetailsPage />} />
							<Route path={STAFF_MEMBER_ABSENCES_PATH}
								   element={<AbsencesPage />} />
							<Route path={STAFF_MEMBER_VACATION_LEDGER_PATH}
								   element={<VacationLedgerPage />} />
							<Route path={STAFF_MEMBER_WORKING_SCHEDULES_PATH}
								   element={<WorkingSchedulesPage />} />

							<Route path={HR_STAFF_MEMBERS_INDEX_PATH}
								   element={<StaffMembersIndexPage />} />
							<Route path={HR_STAFF_MONTHLY_REPORT_PATH}
								   element={<StaffMonthlyReportPage />} />
							<Route path={HR_STATISTICS_PATH}
								   element={<StaffStatisticsPage />} />
							<Route path={HR_TEAM_OVERVIEW_PATH}
								   element={<StaffTeamOverviewPage />} />
							<Route path={HR_STAFF_MEMBER_DETAILS_PATH_WITH_TAB_ID}
								   element={<StaffMemberDetailsPage />} />
							<Route path={appRoutes.logout().path}
								   element={<LogoutPage />} />
							<Route path={TIME_TRACKINGS_PATH}
								   element={<TimeTrackingPage />} />
							<Route path={REPORTS_PATH_WITH_TAB_ID}
								   element={<GlobalReportsPage />} />
							<Route path={TIME_TRACKINGS_PATH}
								   element={<TimeTrackingPage />} />
							<Route path={ADMIN_PATH_WITH_TAB_ID}
								   element={<AdminPage />} />
							{APP_MODE === "development" && <Route path={"test"}
																  element={<DevPlayground />} />}
							<Route path="*"
								   element={<Error404Page />} />
						</Route>
					</Route>
					<Route element={<RootPage />}>
						<Route path={appRoutes.passwordReset().path}
							   element={<ResetPassword />} />
						<Route path={appRoutes.passwordResetRequest().path}
							   element={<RequestPasswordReset />} />
						<Route path={appRoutes.login().path}
							   element={<LoginPage />} />
						<Route path={"*"}
							   element={<Navigate to={appRoutes.login().path} />} />
					</Route>
				</Routes>
			)}
		</>
	);

};

export default MainRouter;
