import { useMemo } from "react";

import { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { getProjectTagTypeDisplayNameById } from "~/modules/project/utils/projectTagTypeUtils.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";


export function useProjectTagTypeSelectOptions():FormInputOption[] {
	return useMemo(() => {
		return Object.values(ProjectTagTypeId).map((projectTagTypeId) => {
			return createInputOptionData(getProjectTagTypeDisplayNameById(projectTagTypeId), projectTagTypeId);
		});
	}, []);
}


