import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { FunctionComponent, PropsWithChildren } from "react";

import Button from "~/components/buttons/Button";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardTitle from "~/components/Card/components/CardTitle";
import CardWrapper from "~/components/Card/components/CardWrapper";
import { useCopyStyled } from "~/hooks/useCopyStyled.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";

type ProjectReferenceTextCardProps = {
	referenceText: ProjectReferenceText;
	onEditClick?: () => void
};


const SectionHeading = ({ children }: PropsWithChildren) => {
	return (
		<div className="mb-1 text-sm font-bold text-primary-900">{children}</div>
	);
};


const ProjectReferenceTextCard: FunctionComponent<ProjectReferenceTextCardProps> = ({ onEditClick, referenceText }) => {
	const {
		copyToClipboard: copySummaryToClipboard,
		copied: summaryCopied,
		targetRef: summaryTargetRef,
	} = useCopyStyled({ timeout: 1000 });
	const {
		copyToClipboard: copyReferenceTextToClipboard,
		copied: referenceTextCopied,
		targetRef: referenceTextTargetRef,
	} = useCopyStyled({ timeout: 1000 });

	return <CardWrapper hasTitle={true}
						height="full">
		<CardHeader>
			<div className="flex w-full items-center justify-between">
				<CardTitle>{referenceText.title}</CardTitle>
				{onEditClick && <div className="flex items-center gap-x-2">
					<Button size="auto"
							theme="plain"
							className="px-2 py-1 text-xs"
							onClick={onEditClick}>Bearbeiten</Button>
				</div>}
			</div>
		</CardHeader>
		<CardContent className="overflow-y-scroll"
					 padding="none"
					 hasTitle={true}>
			<div className="group relative flex w-full flex-col px-4 py-2">
				<div className="absolute right-2 hidden group-hover:block ">
					<button className={clsx("flex w-32 items-center justify-center gap-x-1  rounded-lg px-2 py-1 text-xs font-medium",
						summaryCopied ? "bg-success-500 text-white" : "bg-white text-gray-600 hover:bg-gray-100",
					)}
							onClick={copySummaryToClipboard}>
						{summaryCopied ?
							<CheckCircleIcon className="w-4" /> : <>
								<ClipboardDocumentIcon className="w-4" /><span>Text kopieren</span></>}

					</button>
				</div>
				<div className="mt-2 flex items-center gap-x-2">
					<SectionHeading>Kurzreferenz</SectionHeading>
				</div>
				{referenceText.summaryText ? <div className="ProseMirror prose w-full text-sm"
												  ref={summaryTargetRef}
												  tabIndex={0}
												  dangerouslySetInnerHTML={{ __html: referenceText.summaryText }} /> :
					onEditClick ? <div className="text-sm italic text-gray-400">Ein Satz, der die Projektleistung
						beschreibt. Beispiel: Entwicklung einer IT-Strategie
						und strategischer Roadmap sowie Durchführung einer IT-Reorganisation bei einer
						global agierenden Reederei.</div>:"--"}
			</div>
			<div className="my-2 border-t border-gray-200" />
			<div className="group relative flex w-full flex-col px-4 py-2">
				<div className="absolute right-2 hidden group-hover:block ">
					<button className={clsx("flex w-32 items-center justify-center gap-x-1  rounded-lg px-2 py-1 text-xs font-medium",
						referenceTextCopied ? "bg-success-500 text-white" : "bg-white text-gray-600 hover:bg-gray-100",
					)}
							onClick={copyReferenceTextToClipboard}>
						{referenceTextCopied ?
							<CheckCircleIcon className="w-4" /> : <>
								<ClipboardDocumentIcon className="w-4" /><span>Text kopieren</span></>}

					</button>
				</div>
				<SectionHeading>Langreferenz</SectionHeading>
				<div className="ProseMirror prose w-full text-sm"
					 ref={referenceTextTargetRef}
					 tabIndex={0}
					 dangerouslySetInnerHTML={{ __html: referenceText.text }} />
			</div>

		</CardContent>
	</CardWrapper>;
};

export default ProjectReferenceTextCard;