import { useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiResponseErrorType } from "~/api/apiResponseTypes.ts";
import { handleApiError } from "~/api/axiosUtils.ts";
import type { DeleteEntityTextElements } from "~/components/DeleteEntityView";
import DeleteEntityView from "~/components/DeleteEntityView";
import { appRoutes } from "~/constants/appRoute.ts";
import { deleteClient } from "~/modules/client/api/client/clientApiDispatchers.ts";
import { ALL_CLIENTS_QUERY_KEY } from "~/modules/client/api/client/clientQueries.ts";


type DeleteClientModalProps = {
	clientId: string;
	isOpen: boolean;
	onClose: () => void;
};

const DeleteClientModal: React.FunctionComponent<DeleteClientModalProps> = ({
	clientId,
	onClose,
	isOpen,
}) => {
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState("");
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			setError("");
		}
	}, [isOpen]);

	const handleConfirmationClick = async () => {
		try {
			setBusy(true);
			await deleteClient(clientId);
			await queryClient.invalidateQueries({ queryKey: ALL_CLIENTS_QUERY_KEY });
			navigate(appRoutes.clients().path, { replace: true });
		} catch (error) {
			const errorData = handleApiError(error);
			if (errorData.type === ApiResponseErrorType.VALIDATION) {
				if (
					errorData.messages.clientContactPersonId.find(
						(validationError) => validationError.rule === "clientIsDeletableRule",
					)
				) {
					setError("Dieser Kunde kann nicht gelöscht werden, da er noch in Verwendung ist.");
				}
			} else {
				setError("Ein unbekannter Fehler ist aufgetreten.");
			}
		}
		setBusy(false);
	};

	const texts: DeleteEntityTextElements = {
		headerText: `Kunden löschen`,
		bodyText: `Möchtest Du diesen Kunden wirklich löschen?`,
		deleteButtonText: `Kunden löschen`,
	};

	return (
		<DeleteEntityView
			error={error}
			texts={texts}
			isOpen={isOpen}
			onCloseClick={onClose}
			busy={busy}
			handleConfirmationClick={handleConfirmationClick}
		/>
	);
};

export default DeleteClientModal;
