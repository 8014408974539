import { Dialog, Transition } from "@headlessui/react";
import {
	CalendarIcon,
	ChartBarIcon,
	Cog6ToothIcon,
	CurrencyEuroIcon,
	MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
	Bars3Icon,
	ChartPieIcon,
	ClockIcon,
	FolderIcon,
	UserGroupIcon,
	UsersIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { Fragment, useMemo, useState } from "react";
import { generatePath, NavLink, useLocation } from "react-router-dom";

import Avatar from "~/components/Avatar";
import Logo from "~/components/Logo";
import ImpersonationWidget from "~/components/MainNavigation/components/ImpersonationWidget";
import UpdateChecker from "~/components/UpdateChecker";
import {
	ADMIN_PATH_WITH_DEFAULT_TAB_ID,
	appRoutes,
	HR_STAFF_MEMBERS_INDEX_PATH,
	PROJECT_DESCRIPTIONS_INDEX_PATH,
	REPORTS_PATH_WITH_TAB_ID,
	STAFF_MEMBER_ABSENCES_PATH,
	TIME_TRACKINGS_PATH,
} from "~/constants/appRoute.ts";
import { APP_MODE } from "~/constants/envConstants.ts";
import { useAuth } from "~/contexts/AuthContext";
import type { HasAnyPermissionParamsType } from "~/contexts/AuthContext/AuthContext.tsx";
import { StaffMemberDetailsTabId } from "~/modules/humanResources/types/staffMemberDetailsTypes.ts";
import { GlobalReportsViewTabId } from "~/modules/reports/types/globalReportsViewTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import { PermissionNames, RoleNames } from "~/types/entityNames.ts";

import NavItem from "./components/NavItem.tsx";

type ChildNavItemType = {
	name: string;
	href: string;
	current: boolean;
	permission: HasAnyPermissionParamsType;
};

type NavItemsType = {
	name: string;
	href: string;
	icon: any;
	current: boolean;
	permission: HasAnyPermissionParamsType;
	childNavItems?: ChildNavItemType[];
};

const baseNavigation = (userId: string, staffMemberId: string | null): NavItemsType[] => {

	return [
		{
			name: "Zeiterfassung",
			href: generatePath(TIME_TRACKINGS_PATH, { userId }),
			icon: ClockIcon,
			current: false,
			permission: null,
		},
		{
			name: "Kunden",
			href: appRoutes.clients().path,
			icon: UsersIcon,
			current: false,
			permission: PermissionNames.CanManageProjects,
		},
		{
			name: "Projekte",
			href: appRoutes.projects().path,
			icon: FolderIcon,
			current: false,
			permission: PermissionNames.CanManageProjects,
		},
		{
			name: "Referenzen",
			href: PROJECT_DESCRIPTIONS_INDEX_PATH,
			icon: MagnifyingGlassIcon,
			current: false,
			permission: PermissionNames.CanManageProjects,
		},
		{
			name: "Abrechnung",
			href: appRoutes.monthlyBillingReport().path,
			icon: CurrencyEuroIcon,
			current: false,
			permission: PermissionNames.CanManageInvoices,
		},
		{
			name: "Abwesenheit",
			href: generatePath(STAFF_MEMBER_ABSENCES_PATH, { staffMemberId: staffMemberId || "" }),
			icon: ChartPieIcon,
			current: false,
			permission: PermissionNames.CanViewAbsences,
		},
		{
			name: "HR",
			href: generatePath(HR_STAFF_MEMBERS_INDEX_PATH, {
				staffMemberId: "1",
				tabId: StaffMemberDetailsTabId.CoreData,
			}),
			icon: UserGroupIcon,
			current: false,
			permission: PermissionNames.CanManageStaff,
		},
		{
			name: "Reports",
			href: generatePath(REPORTS_PATH_WITH_TAB_ID, { tabId: GlobalReportsViewTabId.Utilisation }),
			icon: ChartBarIcon,
			current: false,
			permission: PermissionNames.CanViewReports,
		},
		{
			name: "Kalender",
			href: appRoutes.absenceOverview().path,
			icon: CalendarIcon,
			current: false,
			permission: PermissionNames.CanViewAbsences,
		},
		{
			name: "Admin",
			href: ADMIN_PATH_WITH_DEFAULT_TAB_ID,
			icon: Cog6ToothIcon,
			current: false,
			permission: [PermissionNames.CanManageUsers, PermissionNames.CanManageProjectTags],
		},
	];
};

const userNavigation = {
	profile: { name: "Profile", href: appRoutes.userProfile().path, current: false, permission: null },
	logout: { name: "Logout", href: appRoutes.logout().path, current: false, permission: null },
};

const Navigation: FunctionComponent = () => {
	const location = useLocation();
	const { user, isCheckingAuthStatus, hasAnyPermission, hasAnyRole, isImpersonating } = useAuth();
	const { data: allUsers } = useAllUsers();
	const userData = allUsers?.find(u => u.id === user?.id);
	const navigationAvailableForUser = useMemo(() => {
		if (user) {
			return baseNavigation(user.id, user.staffMemberId).map(navItem => {
				const filteredChildNavItems = navItem.childNavItems?.filter(childNavItem => {
					return hasAnyPermission(childNavItem.permission);
				});
				return { ...navItem, childNavItems: filteredChildNavItems };
			}).filter(({ permission }) => hasAnyPermission(permission));
		}
		return [];
	}, [user, hasAnyPermission]);

	if (!user) return;

	const isStagingEnv = APP_MODE === "stage";
	const isLocalEnv = APP_MODE === "development";

	let bgColor = "bg-primary-500";
	if (isStagingEnv || isImpersonating) {
		bgColor = "bg-accent-900";
	} else if (isLocalEnv) {
		bgColor = "bg-primary-300";
	}

	return (
		<div
			className={clsx("flex grow flex-col gap-y-5 overflow-y-auto px-6 shadow-md", bgColor)}>
			<UpdateChecker />
			<div className="relative flex h-16 shrink-0 items-center">
				{isStagingEnv && <div className="absolute right-0 text-2xl font-bold text-accent-500"
									  style={{ transform: "rotate(30deg)" }}>STAGE</div>}
				<NavLink to={generatePath(TIME_TRACKINGS_PATH, { userId: user.id })}
						 className="w-40 text-white hover:text-secondary-500">
					<Logo />
				</NavLink>
			</div>
			<nav className="flex flex-1 flex-col">
				<ul role="list"
					className="flex flex-1 flex-col gap-y-7">
					<li>
						<ul role="list"
							className="-mx-2 space-y-2">
							{!isCheckingAuthStatus &&
								user &&
								navigationAvailableForUser.map(({ childNavItems, name, href, icon }, index) => (
									<div key={name + index}
										 className="group relative z-50 h-10 hover:shadow-lg">
										<NavItem
											key={name}
											name={name}
											href={childNavItems ? window.location.pathname : href}
											icon={icon}
											current={!!href && location.pathname.indexOf(href) === 0}
										/>
										{childNavItems &&
											<div className="absolute inset-y-0 left-0 mt-10 hidden w-full bg-white group-hover:block group-hover:shadow-lg">
												{childNavItems.map((navItem, index) => {
													return <NavItem
														isChild={true}
														key={navItem.name + index}
														name={navItem.name}
														href={navItem.href}
														current={navItem.href === location.pathname}
													/>;
												})}

											</div>}
									</div>))}

						</ul>
					</li>

					<li className="-mx-6 mt-auto">
						{(hasAnyRole(RoleNames.Admin) || isImpersonating) && <div
							className="flex items-center gap-x-4 pb-3 pl-20 pr-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500">
							<ImpersonationWidget />
						</div>}

						<NavLink
							to={userNavigation.profile.href}
							className="flex items-center gap-x-4 px-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
						>
							<Avatar image={userData?.avatarImage} />
							<span aria-hidden="true">
								{user?.firstName} {user?.lastName}
							</span>
						</NavLink>
						<NavLink
							className="flex items-center gap-x-4 pb-3 pl-20 pr-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
							to={userNavigation.logout.href}
						>
							{userNavigation.logout.name}
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	)
		;
};

const MainNavigation: FunctionComponent = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { user } = useAuth();

	const { data: allUsers } = useAllUsers();
	const userData = allUsers?.find(u => u.id === user?.id);

	return (
		<>
			<Transition.Root show={sidebarOpen}
							 as={Fragment}>
				<Dialog as="div"
						className="relative z-50 lg:hidden"
						onClose={setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-900/80" />
					</Transition.Child>

					<div className="fixed inset-0 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
										<button
											type="button"
											className="-m-2.5 p-2.5"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon className="size-6 text-white"
													   aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<Navigation />
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			<div className="z-50 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
				<Navigation />
			</div>

			<div
				className="sticky top-0 z-40 flex items-center gap-x-6 bg-primary-500 p-4 shadow-sm sm:px-6 lg:hidden">
				<button
					type="button"
					className="-m-2.5 p-2.5 text-white lg:hidden"
					onClick={() => setSidebarOpen(true)}
				>
					<span className="sr-only">Open sidebar</span>
					<Bars3Icon className="size-6"
							   aria-hidden="true" />
				</button>
				<div className="flex flex-1 justify-start">
					<NavLink to={appRoutes.base().path}
							 className="w-28">
						<Logo />
					</NavLink>
				</div>
				<NavLink
					to={userNavigation.profile.href}
					className="flex items-center gap-x-4 px-6 text-sm font-semibold leading-6 text-white hover:text-secondary-500"
				>
					<Avatar firstName={user?.firstName}
							lastName={user?.lastName}
							image={userData?.avatarImage} />
				</NavLink>
			</div>
		</>
	);
};

export default MainNavigation;
