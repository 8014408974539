import type { ColumnDef, SortingState } from "@tanstack/react-table";
import { getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import Table from "~/components/Table";
import type { UtilisationTableData } from "~/modules/project/types/projectViewTypes.ts";

type StaffUtilisationTableProps = {
	columnDefs: ColumnDef<UtilisationTableData, string>[];
	tableData: UtilisationTableData[];
};

const StaffUtilisationTable: FunctionComponent<StaffUtilisationTableProps> = ({
	columnDefs,
	tableData,
}) => {
	const [sorting, setSorting] = useState<SortingState>([]);

	useEffect(() => {
		if (columnDefs.some(col => col.id === "user")) {
			setSorting([{ id: "user", desc: false }]);
		}
	}, [columnDefs]);

	const table = useReactTable({
		data: tableData,
		columns: columnDefs,
		meta: {
			emptyTableDataMessage: "Für diese Phase liegen keine Daten vor.",
		},
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		sortDescFirst: true,
	});

	return <div className="isolate size-full overflow-hidden">
		<Table table={table}
			   verticalBorders={true} />
	</div>;
};

export default StaffUtilisationTable;