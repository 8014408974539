import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllSalesFunnels } from "~/modules/client/api/salesFunnel/salesFunnelApiDispatchers.ts";

const ENTITY_QUERY_KEY = "salesFunnel";

export const ALL_SALES_FUNNELS_QUERY_KEY = [ENTITY_QUERY_KEY, "all"];

export const useAllSalesFunnels = (enabled: boolean = true) =>
	useQuery({
		queryKey: ALL_SALES_FUNNELS_QUERY_KEY,
		queryFn: () => getAllSalesFunnels().then(getDataFromResponse),
		enabled,
	});
