import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import {
	getUsersDeliverablesForAvailableStaffings,
} from "~/modules/timeTracking/api/deliverable/deliverableApiDispatchers.ts";

export const DELIVERABLE_BASE_QUERY_KEY = "deliverable";

export const USER_DELIVERABLES_QUERY_KEY = (userId: string) => [DELIVERABLE_BASE_QUERY_KEY, { userId }];

export const useUsersAvailableDeliverables = (userId:string) =>
	useQuery({
        queryKey: USER_DELIVERABLES_QUERY_KEY(userId),
        queryFn: () => getUsersDeliverablesForAvailableStaffings({ userId }).then(getDataFromResponse),
		enabled: !!userId,
	});
