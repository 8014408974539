import { ComboboxOption } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import ProjectTag from "components/projectTags/ProjectTag";
import ProjectTagWithUsage from "components/projectTags/ProjectTagWithUsage";
import ReferenceTextSearchResultCard
	from "modules/project/components/ProjectReferenceTextsSearchView/components/ReferenceTextSearchResultCard";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useState } from "react";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import Card from "~/components/Card";
import { ComboBoxPlain } from "~/components/formElements/ComboBox/ComboBox.tsx";
import PageHeading from "~/components/headings/PageHeading";
import Section from "~/components/sections/Section";
import { PROJECT_DESCRIPTION_VIEW_PAGE_STATE_KEY } from "~/constants/pageStateStorageKeys.ts";
import usePageStateStorage from "~/hooks/usePageStateStorage";
import ReferenceTextSidebar
	from "~/modules/project/components/ProjectReferenceTextsSearchView/components/ReferenceText";
import type {
	ProjectDescriptionCardData,
	ProjectDescriptionViewPageState,
	ProjectTagComboboxOption,
} from "~/modules/project/types/projectDescriptionViewTypes.ts";
import { getSelectedTagsFromIds, getTagFilterResults } from "~/modules/project/utils/projectDescriptionViewUtils.ts";
import { getProjectTagTypeThemeById } from "~/modules/project/utils/projectTagTypeUtils.ts";

type Props = {
	projectData: ProjectDescriptionCardData[];
	projectTags: ProjectTagComboboxOption[];
};

const projectTagOptionRenderFn = (tag: ProjectTagComboboxOption) => {
	return <ComboboxOption
		key={`${tag.value} ${tag.tagTypeId}`}
		value={tag}
		className={({ focus }) =>
			clsx(
				"relative cursor-default select-none px-3 py-1.5 ",
				focus ? "bg-accent-500 text-white" : "text-gray-900",
			)
		}
	>
		<ProjectTagWithUsage displayName={tag.label}
							 numberUsages={tag.usages}
							 theme={getProjectTagTypeThemeById(tag.tagTypeId)} />
	</ComboboxOption>;
};

const defaultPageState: ProjectDescriptionViewPageState = {
	selectedProjectTagIds: [],
};

const ProjectReferenceTextsSearchView: FunctionComponent<Props> = ({
	projectData,
	projectTags,
}) => {
	const [selectedReferenceId, setSelectedReferenceId] = useState<string | null>(null);
	const { pageState, setPageState } = usePageStateStorage({
		pageKey: PROJECT_DESCRIPTION_VIEW_PAGE_STATE_KEY,
		defaultState: defaultPageState,
	});

	const [selectedTags, setSelectedTags] = useState<ProjectTagComboboxOption[]>(getSelectedTagsFromIds(projectTags, pageState.selectedProjectTagIds));

	useEffect(() => {
		setPageState({
			selectedProjectTagIds: selectedTags.map(tag => tag.value),
		});
	}, [selectedTags, setPageState]);

	const handleAddTag = useCallback((newTagOption: ProjectTagComboboxOption | null) => {
		if (newTagOption === null) return;
		setSelectedTags(prevState => {
			if (prevState.find(tagOption => newTagOption.value === tagOption.value)) return prevState;
			return [...prevState, newTagOption];
		});
	}, []);

	const handleRemoveTag = useCallback((tagToRemove: ProjectTagComboboxOption) => {
		setSelectedTags(prevState => {
			const tagIndex = prevState.findIndex(tagOption => tagToRemove.value === tagOption.value);
			if (tagIndex === -1) return prevState;
			const newState = [...prevState];
			newState.splice(tagIndex, 1);
			return newState;
		});
	}, []);

	const showCta = selectedTags.length === 0;

	const filteredProjects = getTagFilterResults(selectedTags, projectData).map(project => {
		return <ReferenceTextSearchResultCard key={project.id}
											  setSelectedReferenceTextId={setSelectedReferenceId}
											  selectedReferenceTextId={selectedReferenceId}
											  projectData={project} />;
	});

	return <div className="grid h-screen grid-rows-[auto_auto_auto_1fr]">
		<div className="bg-white">
			<BreadcrumbsSection pages={["Suche"]} />
		</div>
		<div className="w-full bg-white pb-2">
			<PageHeading title="Suche" />
		</div>
		<Section className="z-10 mt-4">
			<Card>
				<div className="flex gap-x-4">
					<div className="w-96">
						<ComboBoxPlain optionsData={projectTags}
									   value=""
									   immediate
									   onChange={(tag) => handleAddTag(tag as ProjectTagComboboxOption)}
									   placeholder="Projekt Tags eingeben..."
									   allowNew={false}
									   optionRenderFn={projectTagOptionRenderFn}
						/>
					</div>
					<div className="flex size-full flex-wrap justify-start gap-2 pt-1.5">
						{selectedTags.map(tag => {
							if (!tag) return null;
							return <ProjectTag key={tag.value + tag.tagTypeId}
											   displayName={tag.label}
											   theme={getProjectTagTypeThemeById(tag.tagTypeId)}
											   onDeleteClick={() => handleRemoveTag(tag)} />;
						})}
					</div>
				</div>
			</Card>
		</Section>
		<Section scrollable={true}>
			<div className="mt-4 flex h-full max-w-full flex-col gap-y-6 pb-10">
				{!showCta && filteredProjects}
				{showCta && <div className="flex size-full flex-col items-center justify-center text-gray-400">
					<MagnifyingGlassIcon className="size-48" />Tags eingeben, um Referenztexte zu suchen. </div>}
			</div>
		</Section>
		<ReferenceTextSidebar isOpen={!!selectedReferenceId}
							  close={() => setSelectedReferenceId(null)}
							  referenceTextId={selectedReferenceId} />
	</div>;
};

export default ProjectReferenceTextsSearchView;