import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import Card from "~/components/Card";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import DotPagination from "~/components/pagination/DotPagination";
import { useAggregatedDataV2 } from "~/hooks/useAggregatedDataV2.ts";
import {
	usePhaseStaffUtilisationReport,
	useProjectsProjectPhases,
} from "~/modules/project/api/projectPhase/projectPhaseQueries.ts";
import type { UserPhaseUtilisation } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import { useProjectsStaffings } from "~/modules/project/api/staffing/staffingQueries.ts";
import StaffUtilisationTable
	from "~/modules/project/components/ProjectDetailsView/components/StaffUtilisationSection/StaffUtilisationTable";
import staffUtilisationColumnDefs
	from "~/modules/project/components/ProjectDetailsView/components/StaffUtilisationSection/StaffUtilisationTable/staffUtilisationColumnDefs";
import type { UtilisationTableData } from "~/modules/project/types/projectViewTypes.ts";
import { useAllUsers } from "~/modules/user/api/user/userQueries.ts";
import LoadingPage from "~/pages/LoadingPage.tsx";
import type { FormInputOption } from "~/types/form.ts";
import { formatDateToYYYYMMDD } from "~/utils/dateAndTimeUtils.ts";
import { byObjectProperty, sortArray } from "~/utils/sortFunctions.ts";

type StaffUtilisationSectionProps = {
	projectId: string;
};

const MONTHS_TO_DISPLAY = 6;

const StaffUtilisationSection: FunctionComponent<StaffUtilisationSectionProps> = ({
	projectId,
}) => {
	const [selectedPhaseId, setSelectedPhaseId] = useState<string | null>(null);
	const [monthPages, setMonthPages] = useState<string[][]>([]);
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const { data, options } = useAggregatedDataV2({
		allUsersData: useAllUsers(),
		phases: useProjectsProjectPhases(projectId),
		staffings: useProjectsStaffings(projectId),
		utilisationReport: usePhaseStaffUtilisationReport(projectId, selectedPhaseId || ""),
	});

	const {
		allUsersData,
		phases,
		staffings,
		utilisationReport,
	} = data;

	useEffect(() => {
		if (phases && selectedPhaseId === null) {
			setSelectedPhaseId(phases.sort(byObjectProperty("phaseNumber"))[phases.length - 1].id);
		}
	}, [phases, selectedPhaseId]);

	const phaseSelectData: FormInputOption[] = useMemo(() => {
		if (phases) {
			return phases.sort(byObjectProperty("phaseNumber", "desc")).map((phase) => {
				return {
					label: `${phase.phaseNumber} - ${phase.title}`,
					value: phase.id,
				};
			});
		}
		return [];
	}, [phases]);

	const loadingPercent = options.loadingPercent;

	const [userReportDataByMonth, availableMonths]: [UtilisationTableData[], string[]] = useMemo(() => {
		let userReportDataByMonth: UtilisationTableData[] = [];
		let availableMonthsSorted: string[] = [];
		if (allUsersData && utilisationReport && selectedPhaseId && staffings) {
			if (utilisationReport.length === 0) return [[], []];

			userReportDataByMonth = staffings.filter(staffing => staffing.projectPhaseId === selectedPhaseId).map((staffing) => {
				const user = allUsersData.find((user) => user.id === staffing.userId)!;
				const months: Record<string, UserPhaseUtilisation> = {};
				utilisationReport.forEach((monthlyReport) => {

					const userReport = monthlyReport.userBreakdown.find((userReport) => {
						return userReport.userId === staffing.userId;
					});

					if (userReport) {
						months[monthlyReport.month] = userReport;
					}
				});
				return {
					user,
					months,
				};
			});

			// Get months and sort ascending instead of descending
			availableMonthsSorted = sortArray(
				utilisationReport.map(monthlyReport => new Date(monthlyReport.month)),
				"asc",
			).map((month) => formatDateToYYYYMMDD(month));
		}
		return [userReportDataByMonth, availableMonthsSorted];
	}, [selectedPhaseId, allUsersData, staffings, utilisationReport]);

	useEffect(() => {
		if (availableMonths?.length > 0 && monthPages.length === 0) {
			if (availableMonths.length <= MONTHS_TO_DISPLAY) {
				setMonthPages([availableMonths]);
			} else {
				const pages: string[][] = [];

				// Calculate remainder for first page
				const remainder = availableMonths.length % MONTHS_TO_DISPLAY;
				let currentIndex = 0;

				// Add remainder page if exists
				if (remainder > 0) {
					pages.push(availableMonths.slice(0, remainder));
					currentIndex = remainder;
				}

				// Add full pages
				while (currentIndex < availableMonths.length) {
					pages.push(availableMonths.slice(
						currentIndex,
						currentIndex + MONTHS_TO_DISPLAY,
					));
					currentIndex += MONTHS_TO_DISPLAY;
				}

				setMonthPages(pages);
				setCurrentPageIndex(pages.length - 1);
			}
		}
	}, [availableMonths, monthPages.length]);

	const columnDefs = useMemo(() => {
		if (monthPages.length > 0) {
			return staffUtilisationColumnDefs(monthPages[currentPageIndex]);
		}
		return [];
	}, [currentPageIndex, monthPages]);

	const handlePhaseChange = useCallback((phaseId: string) => {
		setMonthPages([]);
		setCurrentPageIndex(0);
		setSelectedPhaseId(phaseId);
	}, []);

	return <div className="flex size-full overflow-hidden py-4">
		{loadingPercent < 100 ? <LoadingPage /> :
			<div className="grid size-full grid-rows-[auto_1fr] gap-y-4">
				<Card className="w-full">
					<div className="grid w-full grid-cols-3">
						<div className="flex max-w-96 items-center gap-x-2">
							<span className="text-sm font-medium text-gray-700">Phase</span>
							<SelectPlain
								theme="dark"
								onChange={handlePhaseChange}
								value={selectedPhaseId || ""}
								optionsData={phaseSelectData}
							/>
						</div>

						{monthPages.length > 0 ? <DotPagination activeIndex={currentPageIndex}
																totalDots={monthPages.length}
																onDotClick={setCurrentPageIndex} /> : <div />}
						<div className="flex justify-end">
							<div className="flex flex-col items-center">
								<span>⌀ Tage pro Woche</span>
								<span className="text-xs text-gray-500">Tage gesamt Monat</span>
							</div>
						</div>
					</div>
				</Card>
				<StaffUtilisationTable columnDefs={columnDefs}
									   tableData={userReportDataByMonth} />
			</div>
		}
	</div>;
};

export default StaffUtilisationSection;